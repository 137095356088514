import { Component ,Input} from '@angular/core';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import { AiQuizComponent } from '../ai-quiz/ai-quiz.component';
import { AvatarDisplayComponent } from '../avatar-display/avatar-display.component';
import { DigiService } from 'src/app/digi.service'; 
import { CommonModule } from '@angular/common';
import { ImageFloaterComponent } from "../image-floater/image-floater.component";
import { LoaderComponent } from '../loader/loader.component';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { StepperComponent } from '../stepper/stepper.component';

@Component({
  selector: 'app-multi-form',
  templateUrl: './multi-form.component.html',
  standalone:true,
  imports: [ImageUploadComponent, AiQuizComponent, AvatarDisplayComponent, CommonModule, ImageFloaterComponent,LoaderComponent,StepperComponent],
  styleUrls: ['./multi-form.component.scss']
})
export class MultiFormComponent {

  steps = ['About DAG', 'Capture Image', 'Take up Quiz','Generate Avatar'];
  showTextModal: boolean = false; // Control modal visibility
  userInput: string = ''; 
  loading = false; // Track the loader state locally
  customLoadingMessages: string[] = [
    'Analyzing facial features...',
    'Processing quiz responses...',
    'Merging all inputs...',
    'Generating your digital avatar...',
    'Finalizing details...',
  ];

  public currentStep = 0;
  public imageUploaded: boolean = true;
  public quizCompleted: boolean = false;
  @Input() loadingMessage: string = 'Loading, please wait...';

  backgroundImage = 'url(/src/assets/digital-avatar/bg.png)'; 
 
  
  uploadedImage: File | null = null; 
  showchat:boolean = true;

  quizResponses: string = 'abcda'; 
  generatedAvatar: any; 
  personalityTraits: string = '';
  constructor(private digiService: DigiService,private loaderService :LoaderService) {}

  // Step transition
  nextStep(): void {
    if (this.currentStep ===1 && !this.uploadedImage) {
      alert('Please upload an image first.');
      return; 
    }

    this.currentStep++;
    if (this.currentStep === 3) {
      this.generateAvatarAndFetchPersonality();
    }
  }

  // Image upload step
  onImageUploaded(image: File): void {
    this.uploadedImage = image;
    this.imageUploaded = true; 
  }

  // Quiz completion step
  onQuizCompleted(completed: boolean): void {
    this.quizCompleted = completed;
  }

  // Final step to generate avatar and fetch personality traits
  generateAvatarAndFetchPersonality(): void {
    // Check if the image has been uploaded
    if (!this.uploadedImage) {
        alert('Please upload an image first.');
        return;
    }

    this.loading = true;
    this.loaderService.loading.next(true);
    const avatarFormData = new FormData();
    avatarFormData.append('image', this.uploadedImage); 

    // First API call: Generate prompt
    this.digiService.getDigiAvatar(avatarFormData).subscribe(
        (response: any) => {
            if (response) {
                const prompt = response; 
                
                const promptFormData = new FormData();
                promptFormData.append('dalle_prompt', prompt); 

                // Second API call: Generate image using prompt
                this.digiService.getAvatar(promptFormData).subscribe(
                    (imageResponse: any) => {
                        if (imageResponse) {
                            this.generatedAvatar = imageResponse; 
                            this.digiService.updateAvatar(this.generatedAvatar);
                            this.loading = false;
                            this.loaderService.loading.next(false);
                        } else {
                            console.error('No image URL in response:', imageResponse);
                            alert('Failed to generate image.');
                        }
                    },
                    (error) => {
                        console.error('Error generating image:', error);
                        alert('Error occurred while generating the image.');
                    }
                );
            } else {
                console.error('No prompt in response:', response);
                alert('Failed to generate prompt.');
            }
        },
        (error) => {
            console.error('Error generating prompt:', error);
            alert('Error occurred while generating the prompt.');
        }
    );

    // Handle personality traits API call (quiz responses)
    const personalityFormData = new FormData();
    personalityFormData.append('answers', this.quizResponses);

    this.digiService.getpersonality(personalityFormData).subscribe(
        (traits: any) => {
            this.personalityTraits = traits;
            this.digiService.updatePersonalityTraits(traits);
        },
        (error) => {
            console.error('Error fetching personality traits:', error);
        }
    );
}

openTextModal() {
  this.showTextModal = true;
}

closeTextModal() {
  this.showTextModal = false;
}

saveText() {
  console.log("User Input:", this.userInput); // You can send this.userInput to your API

  // Perform your API call here
  // Example: this.yourService.sendText(this.userInput).subscribe(...)

  // After saving, close the modal
  this.closeTextModal();
}

}