<div class="quiz-question-container">
    <h3>{{ currentQuestion.question }}</h3>
  
    <div class="quiz-options">
      <button *ngFor="let answer of answerOptions" 
               
              (click)="selectAnswer(answer)" 
              [class.selected]="selectedAnswer === answer"
              class="option-btn">
        {{ answer }}
      </button>
    </div>
  
    <button  style="background-color:#00aecf" class="btn btn-primary"(click)="nextQuestion()" [disabled]="!selectedAnswer" class="next-btn">
      {{ isLastQuestion ? 'Finish Quiz' : 'Next' }}
    </button>
  </div>
  