<div  
  id="dynamicCarousel"  
  class="carousel slide"  
  [attr.data-bs-ride]="'carousel'"  
  [attr.data-bs-interval]="'3000'"  
>  
    <!-- Indicators -->
    <div class="carousel-indicators mt-5">
      <button
        *ngFor="let item of carouselItems; let i = index"
        type="button"
       id="carbtn"
        data-bs-target="#dynamicCarousel"
        [attr.data-bs-slide-to]="i"
        [class.active]="i === 0"
        [attr.aria-current]="i === 0 ? 'true' : null"
        [attr.aria-label]="'Slide ' + (i + 1)"
      ></button>
    </div>
  
    <!-- Carousel Items -->
    <div class="carousel-inner">
      <div
        *ngFor="let item of carouselItems; let i = index"
        class="carousel-item"
        [class.active]="i === 0"
      >
        <div class="d-flex position-relative">
          <img [src]="item.image" class="d-block w-100" [alt]="'Slide ' + (i + 1)" />
          <div style="top:50px;"  class="carousel-caption  mt-5  start-50 translate-middle text-start text-dark">
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Controls -->
    <!-- <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#dynamicCarousel"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>-->
    <button
    
    style="display: none;"
      class="carousel-control-next"
      type="button"
      data-bs-target="#dynamicCarousel"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button> 
  </div>
  