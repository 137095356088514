import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loading = new Subject();
  celebrate = new Subject();
  loadingMessage = new Subject();

  show(): void {
    this.loading.next(true); // Emit true to indicate loading is active
  }

  hide(): void {
    this.loading.next(false); // Emit false to indicate loading is inactive
  }

  emitMessage(message: string): void {
    this.loadingMessage.next(message); // Emit loading message
  }

}
