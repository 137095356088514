import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { Subscription, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core'; // Import ChangeDetectorRef
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  standalone: true,
  imports: [CommonModule],
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  loading = false;
  sub!: Subscription;
  @Input() customLoadingMessages: string[] = [
    'Analyzing facial features...',
    'Processing quiz responses...',
    'Merging all inputs...',
    'Generating your digital avatar...',
    'Finalizing details...',
  ];
  currentMessageIndex = 0; 
  dynamicMessage = this.customLoadingMessages[0]; 
  private messageSubscription!: Subscription; 

  constructor(private loaderservice: LoaderService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    // Subscribe to loading state changes
    this.sub = this.loaderservice.loading.subscribe((res: boolean) => {
      this.loading = res;
      if (this.loading) {
        // Start changing the loader text when loading is true
        this.startDynamicMessageChange();
      } else {
        // Reset the state and stop changing the text when loading is false
        this.resetLoader();
      }
    });
  }

  startDynamicMessageChange() {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }

    
    this.messageSubscription = interval(4000)
      .pipe(takeWhile(() => this.loading)) 
      .subscribe(() => {
        // Update the message index to show the next message
        this.currentMessageIndex =
          (this.currentMessageIndex + 1) % this.customLoadingMessages.length;
        this.dynamicMessage = this.customLoadingMessages[this.currentMessageIndex];
        this.cd.detectChanges(); // Manually trigger change detection
        console.log('Current Dynamic Message:', this.dynamicMessage);
      });
  }

  resetLoader() {
    this.currentMessageIndex = 0;
    this.dynamicMessage = this.customLoadingMessages[0];

    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }

    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }
}
