import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../shared/service/loader.service';
import { SopService } from '../shared/service/sop.service';
import { ToastMessageService } from '../shared/service/toast-message.service';

@Component({
    selector: 'app-usermanage',
    templateUrl: './usermanage.component.html',
    styleUrls: ['./usermanage.component.scss'],
    standalone: false
})
export class UsermanageComponent implements OnInit, OnDestroy {
  @ViewChild('hiddenButton') hiddenButton!: ElementRef;
  @ViewChild('modal')
  modal!: ElementRef;
  currentId: any;
  processStatus: boolean = false;
  functionList: any = [];
  functionSelected: any;
  fnname!: string;
  ohr: any;
  subscription!: Subscription;
  userOHR: any;
  dupload: boolean = false;
  doclist: any;
  selectedRole: any = 1;
  userohr: string = '';
  function_type: any = this.functionList.find((ele: any) => ele?.value == this.functionSelected)?.name;
  Activestatus: boolean = false;
  selectedOhrId: string = '';
  functionname: string = '';
  rolename: any;
  isDeleted!: boolean;
  isDelete: boolean = false;

  //function_type:this.functionList.find((ele: any) => ele?.value == this.functionSelected)?.name,
  //

  constructor(

    private loaderService: LoaderService,
    private toastMessage: ToastMessageService,
    private sopService: SopService
  ) {

  }

  ngOnInit() {
    this.getOHR();
    console.log('this.userOHR:', this.userOHR);
    this.getUserRole();
   
  }

  getUserRole() {
    this.loaderService.loading.next(true);

    this.sopService.getRagUserRoles()
      .subscribe(
        (res: any) => {
          this.loaderService.loading.next(false);
          // let adminRoles:any ;
          if (res?.response?.status == 'success') {
            // this.onLoading();
            if (res.response?.data?.roles['0']) {
              // adminRoles = { 'name': 'Admin', 'value': 0, data: res.response.data?.roles['0'] };
              this.getDistinctRoles(res.response.data?.roles['0'])
            }



            // this.functionSelected = res['all_roles'][0]['value'];
            // this.function_type = this.functionList.find((ele: any) => ele?.value == this.functionSelected)?.name;
          } else {
            this.toastMessage.showError(res?.response?.message);
          }

        },
        (error) => {
          // Handle the error here
          this.loaderService.loading.next(false);
          this.toastMessage.showError(error?.error?.message);
        }
      );
  }

  getDistinctRoles(res: any) {
    let sortList = res.sort((b: any, a: any) => b.name.localeCompare(a.name)) || [];

    this.functionList = sortList.filter((role: any, index: any, self: any) =>
      index === self.findIndex((r: any) => r.name === role.name)
    );
    console.log(this.functionList);
    this.functionSelected =this.functionList[0]['value'];
    this.function_type = this.functionList.find((ele: any) => ele?.value == this.functionSelected)?.name;

  }

  // create user
  createuser() {
    this.loaderService.loading.next(true);
    const payload = {
      "user_id": this.userohr, // user ohr id
      "process_id": this.getFunctionId(this.functionSelected),   // functional bot id
      "role_id": this.selectedRole,  // user role
    }
    console.log('Sending to API:', payload, this.functionSelected);

    this.sopService.createUser(payload).subscribe(
      (res: any) => {
        console.log(res)
        if (res?.response?.status == "success") {
          this.toastMessage.showSuccess(res?.response?.message);
          this.closeModal();
          this.loaderService.loading.next(false);
          this.getuserlist(this.functionSelected);
        }
        else {
          // Handle cases where status is not 'success'
          this.closeModal();
          this.toastMessage.showError(res?.response?.message);
          this.loaderService.loading.next(false);
          console.log(res?.response?.message);
        }
      },
      (err: any) => {
        console.log(err)
        this.toastMessage.showError('Something went wrong');
      }
    )
    this.closeModal();
  }
  //update user
  updateuser(fileName: any) {
    this.currentId = fileName?.id;
    this.functionname = this.getFunctionName(fileName?.process_id);
    this.selectedOhrId = fileName?.user_id;
    this.selectedRole = fileName?.role_id;
    this.rolename = this.getRoleName(fileName?.role_id);
    this.isDeleted = fileName?.isdeleted;
    this.hiddenButton.nativeElement.click();
    //this.loaderService.loading.next(true);
  }
  onSubmit2(id: any,user:any,ohr:any) {
    user = !user;
    if(user==true){
      console.log("user added")
      this.onSubmit3(ohr,id);
    }
    else{
      console.log("user deleted")
      this.onSubmit1(id);
    }
  }
  //update user
  onSubmit1(id: any) {
    const payload = {                 //active status
      "id": id
    };

    console.log('Sending delete:', payload);

    this.sopService.deleteUser(payload).subscribe(
      (res: any) => {
        if (res?.response?.status == "success") {

          this.toastMessage.showSuccess(res?.response?.message);
          this.closeModal();
          //this.loaderService.loading.next(false);
        }
      },
      (err: any) => {
        console.log(err)
        this.toastMessage.showError('Something went wrong');
      }
    )
    this.closeModal();
  }
  //
  onSubmit3(ohrid: any, id: any) {
    const payload = {
      "user_id": ohrid, // user ohr id
      "process_id": this.getFunctionId(this.functionSelected),   // functional bot id
      "role_id": this.selectedRole,  // user role id
      "isdeleted": this.isDelete,                   //active status
      "id": id
    };

    console.log('Sending to API ddd:', payload);

    this.sopService.postUser(payload).subscribe(
      (res: any) => {
        console.log(res)
        if (res?.response?.status == "success") {

          this.toastMessage.showSuccess('User Created Successfully');
          this.closeModal();
          //this.loaderService.loading.next(false);
        }
      },
      (err: any) => {
        console.log(err)
        this.toastMessage.showError('Something went wrong');
      }
    )
    this.closeModal();
  }
  //popup and update user
  onSubmit(ohrid: any) {
    const payload = {
      "user_id": ohrid, // user ohr id
      "process_id": this.getFunctionId(this.functionSelected),   // functional bot id
      "role_id": this.selectedRole,  // user role id
      "isdeleted": this.isDelete,                   //active status
      "id": this.currentId
    };

    console.log('Sending to API ddd:', payload);

    this.sopService.postUser(payload).subscribe(
      (res: any) => {
        console.log(res)
        if (res?.response?.status == "success") {

          this.toastMessage.showSuccess('User Created Successfully');
          this.closeModal();
          //this.loaderService.loading.next(false);
        }
      },
      (err: any) => {
        console.log(err)
        this.toastMessage.showError('Something went wrong');
      }
    )
    this.closeModal();
  }
  private closeModal(): void {
    // Use nativeElement to access the underlying DOM element
    document.getElementById('close-button1')?.click();
    document.getElementById('close-button')?.click();
  }
  // get user OHR id
  getOHR() {
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      if (ohr_id) {
        this.userOHR = ohr_id;
      }
    }
  }

  //get role name
  getRoleName(roleId: number): string {
    return roleId === 0 ? 'Admin' : 'User';
  }
  // get role Id
  getRoleId(roleName: string): number {
    return roleName === 'Admin' || 'User' ? 0 : 1;
  }
  //get function name
  getFunctionName(functionId: number): string {
    return this.functionList.find((ele: any) => ele?.process_id == functionId)?.name;
  }
  //get function Id
  getFunctionId(functionName: string): number {
    return this.functionList.find((ele: any) => ele?.value == functionName)?.id;
  }
  //toggle switch
  toggleSwitchChange(user: any) {
    // Toggle the isdeleted property for the specific user
    user.isdeleted = !user.isdeleted;
  }
  // get user list
  getuserlist(name: any) {
    let Id = this.getFunctionId(name);
    console.log('Id:', Id, this.functionSelected, name);
    this.dupload = true;
    this.loaderService.loading.next(true);
    this.sopService.userlist(Id).subscribe(
      (response: any) => {
        console.log(response)

        // this.functionSelected = res['data'][0]['value'];

        if (response?.response?.status == "success") {
          this.doclist = response?.response?.data;
          //this.dupload=false;
          //  if(!this.doclist?.isdeleted){
          //   this.toggleSwitchChange();
          //  }
          this.loaderService.loading.next(false);
          console.log('this.doclist:', this.doclist);

        }
        else {
          // Handle cases where status is not 'success'
          console.log("API response status is not 'success'");
        }
      },
      (err: any) => {
        console.log(err)
        this.toastMessage.showError('Something went wrong');
      }
    )
  }


  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed

    this.subscription?.unsubscribe();
  }
}
