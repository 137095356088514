<div class="tab-main">
   <div class="tab-container">
     <ul class="nav nav-tabs">
       <li class="nav-item" (click)="setTab(0)">
         <a class="nav-link" [ngClass]="activatedTab === 0 ? 'active' : '' ">
           <div class="tab-content">
             <div class="img-section">
               <img src="../../../assets/images/codeEnhance.png" alt="Image 0" class="tab-image" style="width:26px" />
             </div>
             <span class="tab-text">Code<br />enhancement</span>
           </div>
         </a>
       </li>
       <li class="nav-item" (click)="setTab(1)">
         <a class="nav-link" [ngClass]="activatedTab === 1 ? 'active' : '' ">
           <div class="tab-content">
            <div class="img-section">
             <img src="../../../assets/images/codeDocunused.png" alt="Image 1" class="tab-image" />
            </div>
             <span class="tab-text">Code<br />documentation</span>
           </div>
         </a>
       </li>
       <li class="nav-item" (click)="setTab(2)">
         <a class="nav-link" [ngClass]="activatedTab === 2 ? 'active' : '' ">
           <div class="tab-content">
            <div class="img-section">
             <img src="../../../assets/images/buildProject.png" alt="Image 2" class="tab-image" />
            </div>
             <span class="tab-text">Build your<br />project</span>
           </div>
         </a>
       </li>
       <li class="nav-item" (click)="setTab(3)">
         <a class="nav-link" [ngClass]="activatedTab === 3 ? 'active' : '' ">
           <div class="tab-content">
            <div class="img-section">
             <img src="../../../assets/images/devTalk.png" alt="Image 3" class="tab-image" style="width: 37px;"/>
            </div>
             <span class="tab-text">codeGenY Lite</span>
           </div>
         </a>
       </li>
     </ul>
   </div>
 </div>

 <div *ngIf="activatedTab === 0">
   <app-code-enhancement></app-code-enhancement>
 </div>

 <div *ngIf="activatedTab === 1">
   <app-code-documentation></app-code-documentation>
 </div>

 <div *ngIf="activatedTab === 2">
   <app-build-your-project></app-build-your-project>
 </div>

 <div *ngIf="activatedTab === 3">
   <app-dev-talk></app-dev-talk>
 </div>
