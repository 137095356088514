<!-- floating right button -->
<!-- <button (click)="changestatus()" class="btn btn-secondary float-right m-2 d-flex"
style="align-items:center;"
>Next
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
  </svg>

</button> -->
<div class="container" >
  <div class="flex-item">
    <div class="navigation">
      <a routerLink="/interview-assist/TalentAdmin" routerLinkActive="active-link">TalentAdmin</a>
        <div class="divider-vertical"></div>

      <ng-container >
        <a  [routerLink]="['/interview-assist', reqid, uniqId, 'IntelliQ&A']" routerLinkActive="active-link">IntelliQ&A</a>
        <div class="divider-vertical"></div>
        <a  [routerLink]="['/interview-assist', reqid, uniqId, 'Report-Gen']" routerLinkActive="active-link">AI ReportGen Pro</a>
      </ng-container>
    </div>
  </div>
</div>

<!-- <div class="col-md-4">
  <ng-multiselect-dropdown
    [placeholder]="'User Id'"
    [settings]="dropdownSettings"
    [data]="userid"
    [(ngModel)]="uniqId">
  </ng-multiselect-dropdown>
</div> -->
<br>
<mat-tab-group>
  <!-- Tab for CV Summary -->
  <!-- <mat-tab label="CV&JD Summary"> -->
<!-- dropdown -->
<!-- <div class="row mb-4 mt-4">
<div class="col-md-4">
  <ng-multiselect-dropdown
    [placeholder]="'Summary Type'"
    [settings]="dropdownSettings"
    [data]="summary"
    [(ngModel)]="selectedsummary">
  </ng-multiselect-dropdown>
</div>
</div>

    <div class="tab-content"  style="padding: 25px;">

      <div *ngIf="cvsumary && selectedsummary[0]?.name === 'CV Summary'">
      <h4>CV - {{ name }}</h4>
      <div *ngIf="contactInfo">
        <h5>Contact Information</h5>
        <div lineNumbers markdown>{{ contactInfo }}</div>
      </div>
      <div *ngIf="objective">
        <h5>Objective</h5>
        <div lineNumbers markdown>{{ objective }}</div>
      </div>
      <div *ngIf="education">
        <h5>Education</h5>
        <div lineNumbers markdown>{{ education }}</div>
      </div>
      <div *ngIf="technicalSkills">
        <h5>Technical Skills</h5>
        <div lineNumbers markdown>{{ technicalSkills }}</div>
      </div>
      <div *ngIf="softSkills">
        <h5>Soft Skills</h5>
        <div lineNumbers markdown>{{ softSkills }}</div>
      </div>
      <div *ngIf="workExperience">
        <h5>Work Experience</h5>
        <div lineNumbers markdown>{{ workExperience }}</div>
      </div>
      <div *ngIf="awardsAndInterests">
        <h5>Awards & Other Interests</h5>
        <div lineNumbers markdown>{{ awardsAndInterests }}</div>
      </div>

    </div>


    <div *ngIf="jdsumary && selectedsummary[0]?.name === 'JD Summary'">
      <h4>Job Description - {{ jobTitle }}</h4>
      <div *ngIf="jobPurpose">
        <h5>Job Purpose</h5>
        <div lineNumbers markdown>{{ jobPurpose }}</div>
      </div>
      <div *ngIf="responsibilities">
        <h5>Responsibilities</h5>
        <div lineNumbers markdown>{{ responsibilities }}</div>
      </div>
      <div *ngIf="qualifications">
        <h5>Preferred Qualifications/Skills</h5>
        <div lineNumbers markdown>{{ qualifications }}</div>
      </div>
      <div *ngIf="technicalSkills">
        <h5>Technical Skills</h5>
        <div lineNumbers markdown>{{ technicalSkills }}</div>
      </div>




    </div>





    </div> -->


    <!-- <button *ngIf="!cvsumary" (click)="cvsummary()" class="btn btn-secondary m-2 d-flex">
      Generate CV Summary
      <div *ngIf="cvload" class="loader"></div>
    </button> -->
  <!-- </mat-tab> -->

  <!-- Tab for JD Summary -->
  <mat-tab label="IntelliChat">
    <div class="tab-content" style="height: 500px; width: 1300px; overflow: scroll; padding-top: 0px !important;">
      <div class="container mt-2">
        <div class="card" style="background-color: #fff; border-radius: 15px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
          <div class="card-header d-flex align-items-center justify-content-between" style="background-color: #f8f9fa; color: #00aecf; border-top-left-radius: 15px; border-top-right-radius: 15px;">
            <h3 class="card-title" style="margin-left: 25px;">IntelliChat</h3>
            <div class="d-flex align-items-center" style="position:absolute; right:0;">
              <button tooltip="CV Summary" style="background-color:transparent !important;" type="button" class="btn btn-primary me-2" id="cvsum" (click)="openModal(cvsum)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00aecf" class="bi bi-file-person-fill" viewBox="0 0 16 16">
                  <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2m-1 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-3 4c2.623 0 4.146.826 5 1.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1.245C3.854 11.825 5.377 11 8 11"/>
                </svg>
              </button>
              <button tooltip="JD Summary" style="background-color:transparent !important;" type="button" class="btn btn-primary" id="jdsum" (click)="openModal(jdsum)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#00aecf" class="bi bi-journal" viewBox="0 0 16 16">
                  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
                  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5.5 0 0 1 0-1z"/>
                </svg>
              </button>
            </div>
          </div>


          <div class="card-body chat-body">
            <div class="chat-container">
              <div class="chat-messages">
                <div *ngIf="chatMessages.length === 0">
                  <div class="message-container bot">
                    <div class="message-content">
                      <span>Hi, Welcome to your Interview Assistant! I'm here to help you for your upcoming interview.</span>
                    </div>
                  </div>
                </div>
                <div class="message-container" *ngFor="let message of chatMessages; let i = index" [ngClass]="{'user': message.user === 'user', 'bot': message.user === 'bot'}">
                  <div class="message-content">
                    <div  class="message-header">
                      <span *ngIf="message.user === 'user'" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                          <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                        </svg>
                      </span>
                      <span *ngIf="message.user === 'bot'" >
                        <img data-v-ac8ca204="" class="app-preview__image-origin" srcset="https://img.icons8.com/?size=256&amp;id=50200&amp;format=png 1x, https://img.icons8.com/?size=512&amp;id=50200&amp;format=png 2x" width="24" height="24" alt="Bot icon" style="">
                      </span>
                      <span lineNumbers markdown>{{ message.text }}</span>
                    </div>
                    <div class="message-actions" style="display: flex; align-items: center; ">
                      <button style="background-color:transparent;color: #0d0d0d;" class="btn btn-outline-secondary btn-sm" (click)="copyMessage(message.text)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                        </svg>


                      </button>
                      <button style="background-color:transparent;color: #0d0d0d;" *ngIf="message.user === 'user'" class="btn btn-outline-secondary btn-sm" (click)="regenerateAnswer(message.text)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
                          <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9"/>
                          <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <br>
                <br>
                <br>
              </div>
            </div>
          </div>
          <div class="card-footer fixed-bottom custom-card-footer" style="background-color: #fff;">
            <div class="input-group custom-input-group" style="background-color: #fff;">
              <textarea
                class="form-control custom-textarea"
                [(ngModel)]="userMessage"
                (keyup.enter)="sendMessage()"
                placeholder="Type your message..."
              ></textarea>
              <button
                *ngIf="!qloader"
                [disabled]="!userMessage.trim()"
                style="background-color: transparent !important; color: #00aecf !important;"
                class="btn btn-primary custom-button"
                (click)="sendMessage()"
              >
                <svg
                  *ngIf="!qloader"
                  style="width: 24px; height: 24px; vertical-align: middle;"
                  viewBox="0 0 24 24"
                >
                  <path fill="currentColor" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
                </svg>

              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Generate Q&A">
    <div
      class="tab-content d-flex flex-column align-items-center"
      style="height: 500px; overflow: auto; padding: 25px;"
    >
      <!-- Dropdowns for selecting parameters for Q&A generation -->
      <div
        class="dropdown-container row mb-4 w-100 d-flex justify-content-center"
        style="max-width: 800px;"
      >
        <div class="col-md-4">
          <ng-multiselect-dropdown
            [placeholder]="'Question Type'"
            [settings]="dropdownSettings"
            [data]="qtype"
            [(ngModel)]="selectedqtype"
            class="w-100"
          >
          </ng-multiselect-dropdown>
        </div>
        <div class="col-md-4">
          <ng-multiselect-dropdown
            [placeholder]="'Difficulty'"
            [settings]="dropdownSettings"
            [data]="level"
            [(ngModel)]="selectedlevel"
            class="w-100"
          >
          </ng-multiselect-dropdown>
        </div>
        <div class="col-md-4" *ngIf="showThirdDropdown()">
          <button
            *ngIf="skill1 && selectedqtype[0]?.id === 'tech'"
            (click)="getskills()"
            class="btn btn-secondary m-0 mb-2 d-flex"
          >
            Get Skills
            <div *ngIf="skillload" class="loader"></div>
          </button>
          <ng-multiselect-dropdown
            *ngIf="selectedqtype[0]?.id === 'tech' && !skill1"
            [placeholder]="'Skills'"
            [settings]="dropdownSettings1"
            [data]="skills"
            [(ngModel)]="selectedskills"
            class="w-100"
          >
          </ng-multiselect-dropdown>
        </div>
      </div>
  
      <!-- Generate Q&A Button -->
      <button
        *ngIf="selectedlevel && selectedqtype"
        (click)="genqa()"
        class="btn btn-info mt-3"
      >
        Generate Q&A
      </button>

    <!-- Display Q&A if available -->
    <div
      *ngFor="let qanda of qandas"
      class="card my-3"
      style="width: 100%; background-color: #f1f1f1; color: #333; padding: 15px; margin-top: 20px; border-radius: 8px;"
    >
      <div class="card-header" style="background-color: #073262; border-radius: 8px;">
        <h5 style="white-space: normal; overflow-wrap: break-word;">{{ qanda.question }}</h5>
        <button
          (click)="toggleAnswer(qanda)"
          class="btn btn-link"
          style="color:#FF555F;"
        >
          {{ qanda.showAnswer ? 'Hide' : 'Show' }} Answer
        </button>
      </div>
      <div
        *ngIf="qanda.showAnswer"
        class="card-body"
        style="background-color: #ffffff; border-radius: 8px; padding: 10px; margin-top: 10px;"
      >
        <p style="white-space: normal; overflow-wrap: break-word;">{{ qanda.answer }}</p>
      </div>
    </div>
  </div>
</mat-tab>
</mat-tab-group>

<div *ngIf="isLoading" class="overlay">
  <div class="spinner"></div>
  <br>

</div>
<div *ngIf="!cvsumary" class="overlay">
  <div class="spinner"></div>
  <br>

</div>
<div *ngIf="!jdsumary" class="overlay">
  <div class="spinner"></div>
  <br>

</div>

<ng-template #cvsum>
  <div class="modal-header">
    <h5 class="modal-title">CV Summary</h5>
    <button type="button" class="close" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="cvsumary">
      <h4>CV - {{ name }}</h4>
      <div *ngIf="contactInfo">
        <h5>Contact Information</h5>
        <div lineNumbers markdown>{{ contactInfo }}</div>
      </div>
      <div *ngIf="objective">
        <h5>Objective</h5>
        <div lineNumbers markdown>{{ objective }}</div>
      </div>
      <div *ngIf="education">
        <h5>Education</h5>
        <div lineNumbers markdown>{{ education }}</div>
      </div>
      <div *ngIf="technicalSkills">
        <h5>Technical Skills</h5>
        <div lineNumbers markdown>{{ technicalSkills }}</div>
      </div>
      <div *ngIf="softSkills">
        <h5>Soft Skills</h5>
        <div lineNumbers markdown>{{ softSkills }}</div>
      </div>
      <div *ngIf="workExperience">
        <h5>Work Experience</h5>
        <div lineNumbers markdown>{{ workExperience }}</div>
      </div>
      <div *ngIf="awardsAndInterests">
        <h5>Awards & Other Interests</h5>
        <div lineNumbers markdown>{{ awardsAndInterests }}</div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modalRef?.hide()">Close</button>
  </div>
</ng-template>


<ng-template #jdsum>
  <div class="modal-header">
    <h5 class="modal-title">JD Summary</h5>
    <button type="button" class="close" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="jdsumary">
      <h4>Job Description - {{ jobTitle }}</h4>
      <div *ngIf="jobPurpose">
        <h5>Job Purpose</h5>
        <div lineNumbers markdown>{{ jobPurpose }}</div>
      </div>
      <div *ngIf="responsibilities">
        <h5>Responsibilities</h5>
        <div lineNumbers markdown>{{ responsibilities }}</div>
      </div>
      <div *ngIf="qualifications">
        <h5>Preferred Qualifications/Skills</h5>
        <div lineNumbers markdown>{{ qualifications }}</div>
      </div>
      <div *ngIf="technicalSkills">
        <h5>Technical Skills</h5>
        <div lineNumbers markdown>{{ technicalSkills }}</div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary"  (click)="modalRef?.hide()">Close</button>
  </div>
</ng-template>
