<app-sidenav></app-sidenav>
<div class="headtext">
  Success Stories
</div>


<div class="top-container">
<!-- images will be added 3 per row  -->
<div class="cont">
  <div class="row">
    <div class="column">
      <img class="img2"  (click)="openModal('assets/successstories/1pop.png')" src="assets/successstories/1.png" alt="1" >

      <img class="img1" (click)="openModal('assets/successstories/2pop.png')" src="assets/successstories/2.png" alt="2" >

      <img class="img1" (click)="openModal('assets/successstories/3pop.png')" src="assets/successstories/3.png" alt="3">
    </div>
  </div></div>

  <div class="row">
    <div class="column">
      <img src="assets/images/successstories/4.jpg" alt="4" style="width:100%">

      <img src="assets/images/successstories/5.jpg" alt="5" style="width:100%">

      <img src="assets/images/successstories/6.jpg" alt="6" style="width:100%">
    </div>
  </div>
