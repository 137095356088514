import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
// import { VoiceRecognitionService } from 'src/app/shared/voice-recognition.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { InterAssistService } from '../service/inter-assist.service';
import { Router } from '@angular/router';

interface Result {
  text: string;
  color: string;
  confidence: any;
}
@Component({
    selector: 'app-image-verify',
    templateUrl: './image-verify.component.html',
    imports: [CommonModule],
    styleUrls: ['./image-verify.component.scss']
})



export class ImageVerifyComponent {

  prompt: any;
  chatData: any = [];
  startspeech: boolean = true;
  stopspeech: boolean = false;
  isButtonDisabled: boolean = false;
 percent:any;
  

  imageToImage = false;

  constructor(private interAssistService: InterAssistService,private router: Router) {}

  methodSelected: any = {
    name: 'Candidate Face Match',
    description: 'Create images using descriptive text with AI'
  }

  pageTitle: any = 'Welcome to GenAI ImageGenerator World!';
  inputAccept = ".jpg,.JPG,.jpeg,.png";
  imageFile: any;
  imageConfigStatus = false;
  @ViewChild('scrollChat', { static: false }) scrollContainer!: ElementRef;

  styleList: any = [];
  dallestyle: any = [];
  sizelist: any = [];
  qualitylist: any = [];
  cfg_scale: any = 7;
  seed: any = 512;
  start_schedule: any = 0.6;
  steps: any = 30;
  image_strength: any = 0.6;
  style_preset: any = 'photographic';
  style:any ='vivid';
  quality:any='standard';
  size:any='1024x1024';
  dalleurl:any;

  endPointDetail: any;
  promptStatus: boolean = false;

  modalRef?: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  modalImageData: any;
  image_model: string = 'titan';
  selectedMethod: 'azure' | 'gcp' = 'azure';

  chkagree: boolean = false;

  
  baseImage: string | ArrayBuffer | null = null; // Base image preview
  baseImageFile: File | null = null;            // Base image file
  additionalImages: (File | null)[] = [];       // Reference image files (can be null initially)
  imagePreviews: (string | ArrayBuffer | null)[] = [null]; // Previews for reference images (can be null)
  // results: string[] = []; 
  results: Result[] = [];   


  /**
   * Handle base image upload
   * @param event Input file change event
   */
  onBaseImageChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.baseImageFile = file;
      this.readFile(file, (result) => (this.baseImage = result));
    }
  }

  /**
   * Handle reference image upload
   * @param event Input file change event
   * @param index Index of the reference image
   */
  onImageChange(event: any, index: number): void {
    const file = event.target.files[0];
    if (file) {
      this.additionalImages[index] = file;
      this.readFile(file, (result) => (this.imagePreviews[index] = result));
    }
  }

  /**
   * Add another reference image field
   */
  addReferenceImage(): void {
    this.imagePreviews.push(null); // Allow null initially
    this.additionalImages.push(null); // Allow null initially
  }

  /**
   * Perform verification with the uploaded images
   */
  // verify(): void {
  //   if (!this.baseImageFile) {
  //     alert('Please upload a base image.');
  //     return;
  //   }

  //   if (this.additionalImages.length === 0 || this.additionalImages.every((file) => !file)) {
  //     alert('Please upload at least one reference image.');
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append('base_face', this.baseImageFile); // Append base image

  //   // Append all reference images as 'faces'
  //   this.additionalImages.forEach((file) => {
  //     if (file) formData.append('faces', file);
  //   });

  //   this.interAssistService.verifyphotogcp(formData).subscribe({
  //     next: (response) => {
  //       this.results = this.mapResults(response.data); // Map response to results
  //     },
  //     error: (error) => {
  //       console.error('API call failed', error);
  //     },
  //   });
  // }

  verify(): void {

    this.isButtonDisabled = true;
    
    if (!this.baseImageFile) {
      alert('Please upload a base image.');
      return;
    }

    if (this.additionalImages.length === 0 || this.additionalImages.every((file) => !file)) {
      alert('Please upload at least one reference image.');
      return;
    }

    const formData = new FormData();
    formData.append('base_face', this.baseImageFile); // Append base image

    // Append all reference images as 'faces'
    this.additionalImages.forEach((file) => {
      if (file) formData.append('faces', file);
    });

    // Call the appropriate API based on the selected model
    if (this.selectedMethod === 'gcp') {
      this.interAssistService.verifyphotogcp(formData).subscribe({
        next: (response) => {
          this.results = this.mapResults(response.data); // Map response to results
        },
        error: (error) => {
          console.error('API call failed', error);
        },
      });
    } else if (this.selectedMethod === 'azure') {
      this.interAssistService.verifyphotoazure(formData).subscribe({
        next: (response) => {
          this.results = this.mapResults(response.data); // Map response to results
        },
        error: (error) => {
          console.error('API call failed', error);
        },
      });
    }
  }

  selectedOption: string = 'option1'; // Default selection

  // selectOption(option: string): void {
  //   this.selectedOption = option;
  // }

  // Function to change the selected method based on the toggle
  toggleMethod(method: 'azure' | 'gcp'): void {
    this.selectedMethod = method;
  }

  /**
   * Map API response data to results
   * @param data API response data
   * @returns Mapped results array
   */
  private mapResults(data: any[]): Result[] {
    const results: Result[] = [];
  
    data.forEach((item) => {
      const [key, value] = Object.entries(item)[0];
  
      // Ensure value is treated as a string
      let valueString = value as string;
  
      // Extract the confidence score (allowing any number of digits after the decimal)
      const confidenceMatch = valueString.match(/confidence of (\d+\.\d+)/i); // Updated regex
  
      let confidenceText = '';
      let confidenceScore = 0;
  
      // If confidence is found, format it and extract the score
      if (confidenceMatch && confidenceMatch[1]) {
        confidenceScore = parseFloat(confidenceMatch[1]);
        confidenceText = `<br><b>Confidence: ${Math.round(confidenceScore * 100)}%</b>`;
        valueString = valueString.replace(/confidence of \d+\.\d+/i, '').trim(); // Remove the confidence text
      }
  
      // Determine the color based on confidence
      let color: string;
      if (confidenceScore < 0.70) {
        color = 'red';   // Confidence < 70%
      } else if (confidenceScore >= 0.70 && confidenceScore <= 0.84) {
        color = '#FFBF00'; // Confidence between 70% and 84%
      } else {
        color = 'green'; // Confidence >= 85%
      }
  
      // Construct the result object with the result text and color
      const result: Result = {
        text: `${key}: ${valueString}${confidenceText}`,
        color: color,
        confidence: `${Math.round(confidenceScore * 100)}%`
      };
  
      // Add the result object to the array
      results.push(result);
    });
  
    return results;
  }
  
  
  
  
  
  
  
  
  
  

  /**
   * Utility function to read file and generate preview
   * @param file File to be read
   * @param callback Callback function with file data
   */
  private readFile(
    file: File,
    callback: (result: string | ArrayBuffer | null) => void
  ): void {
    const reader = new FileReader();
    reader.onload = () => callback(reader.result);
    reader.readAsDataURL(file);
  }

  reloadPage(): void {

    console.log("clicked")
    window.location.reload();
  }
}
