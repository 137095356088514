import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
//C:\Gen-AI repo\kon-verse\Konverse\src\app\codeGenYLite\Services
import { SharedService } from 'src/app/codeGenYLite/Services/shared.service';
import { DevTalkService } from 'src/app/codeGenYLite/Services/dev-talk.service';
import {
  ApiEndpoints,
  MessageTypeSnack,
  StatusCode,
} from 'src/app/codeGenYLite/data/global-vars';
import { ActivityAudit, DevTalkReq } from 'src/app/codeGenYLite/data/app-model';
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { detect, languages, LANG } from 'program-language-detector';
//C:\Gen-AI repo\kon-verse\Konverse\src\app\codeGenYLite\components\includes
import { SettingsComponent } from 'src/app/codeGenYLite/components/includes/popup/settings/settings.component';
import { MatDialog } from '@angular/material/dialog';
import { DisclaimerComponent } from 'src/app/codeGenYLite/components/includes/popup/disclaimer/disclaimer.component';
import { SopService } from 'src/app/shared/service/sop.service';

// Import all major modes using a wildcard import
import 'codemirror/mode/meta'; // Import the meta mode to enable auto-loading of other modes
// import 'codemirror/mode/*';

@Component({
    selector: 'app-chatcode',
    templateUrl: './chatcode.component.html',
    styleUrls: ['./chatcode.component.scss'],
    standalone: false
})
export class ChatcodeComponent  {
 }