import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loading = new BehaviorSubject<boolean>(false);
  celebrate = new Subject();
  loadingMessage = new Subject();
  
  

}
