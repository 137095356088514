<nav id="app_sidenav" class="navbar navbar-expand-lg navbar-light" style="background:#031a35;">
  <a class="navbar-brand ml-2" href="javascript:;">
    <img class="img-logo img-fluid" [routerLink]="['/home-openai']" src="assets/images/glogo.png" />
  </a>

  <div class="mx-auto order-0">
    <h3 style="margin-left: 160px;" class="title ">Interview Assistant</h3>
  </div>

  <div class="navbar-nav ml-auto">
    <!-- Profile icon (example using Font Awesome) -->
    <a class="nav-item nav-link" >
      <i class="fas fa-user text-white"></i> <!-- Replace this with your profile icon -->
    </a>

    <!-- Dropdown menu for user options (e.g., logout, username) -->
    <div class="dropdown">
      <a class="nav-item nav-link dropdown-toggle text-white" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{username}}
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
        <a class="dropdown-item" (click)='logout()'>Logout</a>
        <!-- Add other user options here -->
      </div>
    </div>
  </div>
</nav>
