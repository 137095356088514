import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SopService } from '../../service/sop.service';
import { ToastMessageService } from '../../service/toast-message.service';
import { LoaderService } from '../../service/loader.service';
import { OktaAuthService } from '../../okta/okta.service';

@Component({
    selector: 'app-profile-dropdown',
    templateUrl: './profile-dropdown.component.html',
    styleUrls: ['./profile-dropdown.component.scss'],
    standalone: false
})
export class ProfileDropdownComponent {

  navToggle = false;
  userOHR: any;
  userRoles: any;
  adminRoles: any;
  selectRole: any;
  username: string = 'Guest';
  processList:any =[];
  constructor(
    private router: Router,
    private sopService: SopService,
    private toastMessage: ToastMessageService,
    private loaderService: LoaderService,
    private oktaService:OktaAuthService
  ) {
    this.getOHR();
    this.getUserRole();
  }

  getUserRole() {
    this.loaderService.loading.next(true);

    this.sopService.getRagUserBots()
      .subscribe(
        (res: any) => {
          this.loaderService.loading.next(false);

          if (res?.response?.status == 'success') {
            // this.onLoading();
            if (res.response?.data?.roles['0']) {
              this.adminRoles = { 'name': 'Admin', 'value': 0, data: res.response.data?.roles['0'] };
            }
            if (res.response?.data?.roles['1']) {
              this.userRoles = { 'name': 'User', 'value': 1, data: res.response.data?.roles['1'] };
            }

            if (this.userRoles) {
              this.selectRole = this.userRoles;
            } else if (this.adminRoles) {
              this.selectRole = this.adminRoles;
            }


            //list of all roles of user
            if (this.selectRole) {
              this.getDistinctRoles(res);
              this.selectRole['all_roles'] = this.processList             
            }



            this.sopService.activeUserRole.next(this.selectRole)

          } else {
            this.toastMessage.showError(res?.response?.message);
          }

        },
        (error) => {
          // Handle the error here         
          this.loaderService.loading.next(false);
          this.toastMessage.showError(error?.error?.message);
        }
      );
  }


  // Function to check if an object with a specific property value exists in the result array
  getDistinctRoles(res: any){

    const allRoles = [];
    for (const key in res?.response?.data?.roles) {
      allRoles.push(...res?.response?.data?.roles[key].map((role: any) => role));    }

    let sortList = allRoles.sort((b, a) => b.name.localeCompare(a.name)) || [];

   this.processList = sortList.filter((role: any, index: any, self: any) =>
      index === self.findIndex((r: any) => r.name === role.name)
    );
   
  }

  // get user OHR id
  getOHR() {
    this.username = localStorage.getItem('okta-username') ? localStorage.getItem('okta-username') as string : 'Guest';
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      if (ohr_id) {
        this.userOHR = ohr_id;
      }
    }
  }

  switchRole() {
    if (this.selectRole?.name == 'Admin') {
      this.selectRole = this.userRoles;
    } else {
      this.selectRole = this.adminRoles;
    }

    if (this.selectRole) {      
      this.selectRole['all_roles'] = this.processList             
    }

    this.sopService.activeUserRole.next(this.selectRole);
    this.navToggle = false;
  }

  logout() {
    this.navToggle = false;
    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('okta-username');
    localStorage.removeItem('token');
    localStorage.clear();
    sessionStorage.clear();
    this.oktaService.logout();
    this.router.navigate(['logout']);
  }
}
