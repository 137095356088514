<div class="wrapper">
    <app-sidenav [item]="'playground'"></app-sidenav>
    <!-- Main content -->
    <section class="content">
        <div class="container-fluid responsive p-0">



            <div class="chat-box-new p-0 p-md-2">
                <div class="row">
                    <div class="col-12">
                        <div class="row-x">

                            <div class="conversation">
                                <!-- Page Heading block  Start -->
                                <div class="row genpact-color px-3 p-2 mb-n3 text-center">
                                    <div class="col-md-4 text-md-left">
                                        <h4 class="text-white"> {{methodSelected?.name}} </h4>
                                    </div>
                                    <div class="col-md-4 text-center align-self-center py-md-0 py-2">
                                        <div class="parent-block">
                                            <div class="group-block">
                                                <ul>
                                                    <li>
                                                        <button (click)="image_model='titan'; cfg_scale=7"
                                                            [ngClass]="{'active': image_model =='titan'}">
                                                            <span>Titan</span>
                                                        </button>
                                                    </li>

                                                    <li>
                                                        <button (click)="image_model='stable'"
                                                            [ngClass]="{'active': image_model =='stable'}">
                                                            <span>Stable</span>
                                                        </button>
                                                    </li>
                                                    <li>
                                                      <button (click)="image_model='dalle'"
                                                      [ngClass]="{'active': image_model =='dalle'}">
                                                            <span>DALL·E</span>
                                                        </button>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4 text-md-right" style="align-self: center;">
                                        <span *ngIf="endPointDetail?.value" class="">
                                            Count Left: <b>{{ endPointDetail?.max - endPointDetail?.value}}</b>
                                        </span>
                                    </div>
                                </div>
                                <!-- Page Heading block  End -->



                                <!-- Chat block  start -->
                                <div class="box messages smooth-scroll">

                                    <!-- <div class="message" *ngIf="chatData.length === 0">
                                        <div class="user">
                                            <img src="assets/bot.png" alt="GPT Avatar">
                                            <i class="fa fa-reply icon-send" aria-hidden="true"></i>
                                        </div>
                                        <div class="content">{{methodSelected?.description}}</div>
                                    </div> -->

                                    <div class="row message-alert" *ngIf="chatData.length === 0">
                                        <div class="col-12 text-center p-3 p-md-5 shadow">
                                            <h4>
                                                <!-- Upload Image /  -->
                                                Generate An Image
                                            </h4>
                                            <p class="text-muted">{{methodSelected?.description}}</p>
                                            <div class="mt-4" [innerHTML]="pageTitle"></div>
                                            <div class="disclaimer mt-3">
                                                <p>
                                                    <b> <i class="bi bi-info-circle"></i> </b>
                                                    Use the tool responsibly and do not create any offensive content.
                                                </p>
                                            </div>
                                        </div>


                                    </div>


                                    <div class="message" #scrollChat>
                                        <div class="content w-100">
                                            <div class="row">
                                                <div class="col-md-4 mb-3"
                                                    *ngFor="let chat of chatData;let index = index">
                                                    <div class="image-card"
                                                        [ngClass]="{'image_active':chat?.url== imageFile}">
                                                        <div class="top-section"
                                                            style="background-image: url({{chat?.url}})">

                                                            <div class="border-slide"> </div>
                                                            <div class="icons">
                                                                <div class="logo">
                                                                    <button class="btn-sm btn-secondary"
                                                                        *ngIf="imageToImage"
                                                                        (click)="modifyImage(chat?.url, chat?.prompt)"
                                                                        title="Select image, describe further enhancement in the textbox and submit to get further enhanced image."><i
                                                                            class="fa fa-magic"
                                                                            aria-hidden="true"></i></button>
                                                                    <button class="btn-sm btn-success"
                                                                        title="Download Image"
                                                                        (click)="downloadFile(chat?.url)">
                                                                        <i class="fa fa-download"
                                                                            aria-hidden="true"></i>
                                                                    </button>
                                                                </div>
                                                                <div class="social-media">
                                                                    <button class="btn-sm full-screen"
                                                                        (click)="openModal(image_view_template, chat)">
                                                                        <i class="fa fa-arrows-alt"
                                                                            aria-hidden="true"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="details">
                                                            <!-- <label>Image Description</label> -->
                                                            <p>
                                                                {{chat?.prompt}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- <div class="col-md-4" *ngFor="let chat of chatData;let index = index">
                                                    <div class="row p-3 boder-bold"
                                                        [ngClass]="{'active':chat?.url== imageFile}">
                                                        <div class="col-12 generate-image-block">
                                                            <img [src]="chat?.url" class="generate-image" alt="image">
                                                        </div>

                                                        <div class="col-12 generate-btn">
                                                            <button (click)="modifyImage(chat?.url, chat?.prompt)" *ngIf="imageToImage"
                                                                title="Select image, describe further enhancement in the textbox and submit to get further enhanced image."
                                                                class="bg-primary text-light p-2  border btn-eq">
                                                                Enhance </button>
                                                            <button (click)="downloadFile(chat?.url)"
                                                                class="bg-success text-light p-2  border btn-eq">
                                                                Download </button>
                                                        </div>

                                                        <div class="col-12 generate-text">
                                                            <div>{{chat?.prompt}}</div>
                                                            <button *ngIf="chat?.prompt" (click)="copyToClipboard(chat?.prompt)"
                                                                class="markdown-clipboard-button content-copy">
                                                                <i class="fa fa-clone" aria-hidden="true"></i>
                                                            </button>
                                                        </div>


                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>

                                        <div class="setting" *ngIf="imageConfigStatus">
                                            <div class="row mb-3">
                                                <div class="col-12 py-2">
                                                    <h4>Inference configuration</h4>
                                                    <!-- <a> RESET </a> -->
                                                </div>

                                                <div class="config-block" *ngIf="image_model !== 'dalle'">

                                                    <div class="col-12 py-1">
                                                        <label class="text-xs">Style</label>
                                                        <select class="form-control" [(ngModel)]="style_preset ">
                                                            <option [value]="list?.value"
                                                                *ngFor="let list of styleList">
                                                                {{list?.name}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-12 py-1">
                                                        <label class="text-xs">Scale {{ cfg_scale }}
                                                            <i class="fa fa-info-circle text-muted text-sm"
                                                                tooltip="Dictates how closely the engine attempts to match a generation to the provided prompt"></i>
                                                        </label>
                                                        <input type="range" min="1"
                                                            [max]="image_model =='titan' ? 10 : 35" step="1"
                                                            [(ngModel)]="cfg_scale" class="w-100" />

                                                    </div>
                                                    <div class="col-12 py-1">
                                                        <label class="text-xs">Generation step {{ steps }}
                                                            <i class="fa fa-info-circle text-muted text-sm"
                                                                tooltip="Higher the number of steps better the quality of images"></i>
                                                        </label>
                                                        <input type="range" min="1" max="150" step="5"
                                                            [(ngModel)]="steps" class="w-100" />
                                                    </div>

                                                    <div class="col-12 py-1">
                                                        <label class="text-xs">Seed {{ seed }}
                                                            <i class="fa fa-info-circle text-muted text-sm"
                                                                tooltip="It is an initial value used to control randomness and ensure reproducibility in the generation process."></i>
                                                        </label>
                                                        <input type="range" min="1" max="1000" step="10"
                                                            [(ngModel)]="seed" class="w-100" />
                                                    </div>

                                                    <!-- <div class="col-12 py-1">
                                                        <label class="text-xs">Schedule {{ start_schedule }}</label>
                                                        <input type="range" min="0" max="1" step="0.01"
                                                            [(ngModel)]="start_schedule" class="w-100" />
                                                    </div> -->

                                                    <div class="col-12 py-1" *ngIf="imageFile">
                                                        <label class="text-xs">Image strength {{ image_strength }}
                                                            <i class="fa fa-info-circle text-muted text-sm"
                                                                tooltip="Used only for image-to-image. Values close to 1 will yield images very similar to the initial image while values close to 0 will yield images wildly different than the initial image."></i>
                                                        </label>
                                                        <input type="range" min="0.01" max="1" step="0.01"
                                                            [(ngModel)]="image_strength" class="w-100" />
                                                    </div>
                                                </div>


                                                <div class="config-block" *ngIf="image_model == 'dalle'">

                                                  <div class="col-12 py-1">
                                                      <label class="text-xs">Image Style</label>
                                                      <select class="form-control" [(ngModel)]="style">
                                                          <option [value]="list?.value"
                                                              *ngFor="let list of dallestyle">
                                                              {{list?.name}}</option>
                                                      </select>
                                                  </div>

                                                  <div class="col-12 py-1">
                                                    <label class="text-xs">Image Quality</label>
                                                    <select class="form-control" [(ngModel)]="quality">
                                                        <option [value]="list?.value"
                                                            *ngFor="let list of qualitylist">
                                                            {{list?.name}}</option>
                                                    </select>
                                                </div>

                                                <div class="col-12 py-1">
                                                  <label class="text-xs">Image Size</label>
                                                  <select class="form-control" [(ngModel)]="size">
                                                      <option [value]="list?.value"
                                                          *ngFor="let list of sizelist">
                                                          {{list?.name}}</option>
                                                  </select>
                                              </div>



                                              </div>


                                            </div>
                                        </div>

                                    </div>




                                </div>
                                <!-- Chat block  End -->

                                <!-- Input block  Start -->
                                <div class="user-input">
                                    <div class="box input-box">

                                        <ng-container *ngIf="imageToImage">
                                            <div class="file border btn-upload" title="Upload file" *ngIf="!imageFile"
                                                (click)="generate_upload_input?.click()">
                                                <input class="d-none" #generate_upload_input id="generate_upload_input"
                                                    [accept]="inputAccept" type="file" (change)="uploadFile($event)">
                                                <i class="fa fa-upload"></i>
                                            </div>

                                            <div *ngIf="imageFile" class="file border btn-upload bg-danger"
                                                title="Remove file"
                                                (click)="imageFile = null; imageConfigStatus = false;">
                                                <i class="fa fa-times"></i>
                                            </div>
                                        </ng-container>
                                        <div class="file border btn-upload " title="Image setting"
                                            (click)="imageConfigStatus = !imageConfigStatus">
                                            <i class="fa fa-cog"></i>
                                        </div>

                                        <div class="file border btn-upload"
                                            [ngClass]="{'bg-success' : promptStatus,  'bg-danger':!promptStatus}"
                                            title="Generate prompt" (click)="promptStatus = !promptStatus;">
                                            <i class="fa "
                                                [ngClass]="{'fa-check' : promptStatus,  'fa-circle-thin':!promptStatus}"
                                                aria-hidden="true"></i>
                                        </div>

                                        <textarea *ngIf="image_model !== 'dalle'" [(ngModel)]="prompt" class="message-input" maxlength="1000"
                                            placeholder="Describe your image" rows="1" #textArea
                                            (keyup.enter)="getGeneratedImage()">
                                        </textarea>

                                        <textarea *ngIf="image_model == 'dalle'"  [(ngModel)]="prompt" class="message-input" maxlength="1000"
                                        placeholder="Describe your image" rows="1" #textArea
                                        (keyup.enter)="getdalleImage()">
                                    </textarea>
                                        <!-- <button class="app-speech-input-btn" ></button> -->
                                        <button class="app-speech-input-btn" *ngIf="startspeech"
                                            (click)="startService()"></button>
                                        <button class="app1-speech1-input1-btn1" *ngIf="stopspeech"
                                            (click)="stopService()"><i class="fa fa-microphone fa-beat-fade"
                                                style="font-size:20px;color:red"></i></button>
                                        <!-- <button (click)="welcome()">Welcome</button> -->
                                        <!-- ckl -->

                                        <!-- cl -->
                                        <div *ngIf="image_model !== 'dalle'"  id="send-button" (click)="getGeneratedImage()">
                                            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                                                <path fill="#006ae3" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
                                            </svg>
                                        </div>
                                        <div *ngIf="image_model == 'dalle'"  id="send-button" (click)="getdalleImage()">
                                          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                                              <path fill="#006ae3" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
                                          </svg>
                                      </div>
                                    </div>
                                </div>
                                <!-- Input block  End -->

                            </div>

                        </div>
                    </div>
                </div>
            </div>




        </div>
    </section>


    <!-- <app-stop-process *ngIf="processStatus" (stopProgress)="resetForm($event)"></app-stop-process> -->





    <ng-template #image_view_template>

        <div class="modal-body p-0">
            <div class="row">
                <div class="col-12"  >
                    <button type="button" class="close pop_image" aria-label="Close" (click)="modalRef?.hide()">
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </button>

                    <img [src]="modalImageData?.url" class="img-responsive w-100" alt="selectedImage">
                </div>



            </div>
        </div>
    </ng-template>


    <!-- policy & condition toggle -->
    <button type="button" class="btn btn-primary d-none" id="image_policy_model"
        (click)="openModal(image_policy_model)">
    </button>

    <!-- policy & condition block -->
    <ng-template #image_policy_model>
        <div class="modal-body">

            <div class="row">
                <div class="col-12  fw-bold text-primary">In order to adhere to the Legal and Security policies,
                    please take care of below mentioned points:</div>
            </div>
            <div class="row">
                <div class="col-12" style="font-size: 13px;">
                    <ol style="list-style:decimal; gap:6px; display: grid;">
                        <li>
                            Please consult Genpact’s Code of Conduct document and Use of Generative AI Programs Policy
                            before using Generative AI Playground or any other gen AI platform. (with the adequate links
                            added).
                        </li>
                        <li>
                            The images generated may only be used internally by Genpact employees, such as within
                            internal presentations, events, and other collateral.
                        </li>
                        <!-- change here-->
                        <li>
                            DO NOT USE any of the generated images for: (i) any Genpact digital products, Genpact
                            publicly available materials or client facing applications.
                        </li>
                        <li>
                            DO NOT USE any of the generated images for any client work, including any client facing
                            presentations, materials, work products or within any client meetings
                        </li>
                        <li>
                            DO NOT USE distribute, publish, or use the generated images on any 3rd party websites,
                            client environments, social media or other non-Genpact controlled environments.
                        </li>

                    </ol>

                </div>
            </div>
            <div class="row">
                <div class="col-12 ml-4">
                    <input id="byod_check_label" type="checkbox" [(ngModel)]="chkagree" class="form-check-input" />
                    <label class="form-check-label text-decoration-underline fw-bold text-primary"
                        style="margin-left: 15px;" for="byod_check_label">
                        I acknowledge that I have read, understand and agree to above instructions.
                    </label>
                </div>

            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn button-genpact text-white center cursor-pointer" [disabled]="!chkagree"
                (click)="modalRef?.hide()">
                Proceed
            </button>
        </div>
    </ng-template>
