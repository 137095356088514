<div *ngIf="loading" class="loader-box">
  <div class="row">
    <div class="col-md-12">
      <div class="loader11">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
  <div class="loading-text">
    {{ dynamicMessage }}
  </div>
</div>
