<app-sidenav></app-sidenav><br>
<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">

</head>
<div class="headtext">
  Success Stories
</div>
<!-- <div class="search-container">
  <input type="text" placeholder="Search for success stories..." [(ngModel)]="searchText" (input)="filterStories()">
</div> -->
<div class="success-stories">
  <div *ngFor="let row of getVisibleRows()" class="row">
    <div *ngFor="let story of row" class="col-md-4">
      <div class="image-container">
        <img style="box-shadow:  0 0 10px 0 rgba(0,0,0,0.2);" src="{{story.thumbnail}}" (click)="showImage(story)" />
        <div class="preview" >{{story.previewText}}

          <!-- <button class="button1" (click)="like(story)">
            <i class="fas fa-thumbs-up"></i>
          </button>
          <span class="likes">{{story.likes || 0}} Likes</span> -->
        </div>
        <div style="text-align: center;">
          {{story.text1}}
        </div>

      </div>
    </div>
  </div>
  <!-- <div class="viewexp">
  <button style="margin-right: 10px;" (click)="loadMoreStories()" *ngIf="!isLastRow() || currentRowIndex==1">View More</button>
  <button (click)="viewLess()" *ngIf="currentRowIndex > 1">View Less</button>
</div> -->
</div>

<div class="image-overlay" *ngIf="modalOpen" (click)="closeImageModal()">
  <div class="image-modal">
    <span class="close-button" (click)="closeImageModal()">&times;</span>
    <img class="preview-img"[src]="selectedStory.largeImage" alt="Story Image" />
  </div>
</div>
