import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../shared/service/loader.service';
import  { ToastMessageService } from '../shared/service/toast-message.service';
import  { BgcService } from '../shared/service/bgc.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-bgc',
  templateUrl: './bgc.component.html',
  styleUrls: ['./bgc.component.scss']
})
export class BgcComponent implements OnInit {
  @Input() placeholder: any='Please wait while we are fetching your details...';
  userOHR: any;
  userAccessList: any = [];
  userAccessStatus!: boolean;
  accessuser!: boolean;
  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private toastMessage: ToastMessageService,
    private bgcService: BgcService

  ) {

    // this.userOHR = this.getOHR();
    // this.updateUserAccessList();
  }

  ngOnInit(): void {

    this.accesscheck();

  }
  accesscheck() {
    this.loaderService.loading.next(true);
    this.bgcService.useraccesscheck().subscribe((res: any) => {
      if (res?.response?.status === "success") {
       // this.chkagree = true;
       this.loaderService.loading.next(false);
       this.userAccessStatus = true;
       this.accessuser = true;
        this.toastMessage.showSuccess("User role(s) fetched  successfully.");
      }
      else {
        //this.chkagree = false;
        this.loaderService.loading.next(false);
        this.accessuser = false;
        this.toastMessage.Showhome(res?.response?.message);
        this.placeholder = "You are not authorized to access this page";
        //this.toastMessage.showError(res?.response?.message);
      }
    },
    (error) => {
      console.log(error);
      this.loaderService.loading.next(false);
     // this.router.navigate(['login']);
     this.toastMessage.showError("Your session has expired. refreshing the page please wait...");
    }

    );
  }

  // updateUserAccessList() {
  //   let devList: any = [850069993, 850069994, 850069766, 703329815,
  //     703157733, 703042528, 703021804, 703022515, 703267043, 400215104, 703341684, 850064135
  //   ];

  //   let prodList: any = [
  //     703157733, 703022515, 703042528, 703051942, 703037783, 850069766
  //   ]


  //     this.userAccessList = devList;


  //   this.checkUserAccess();
  // }

  // checkUserAccess() {
  //   let ohr_id = parseInt(this.userOHR)
  //   this.userAccessStatus = this.userAccessList.includes(ohr_id);
  //   console.log( this.userAccessList, this.userOHR,this.userAccessStatus)
  // }




  // get user OHR id
  getOHR() {
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      return ohr_id ? ohr_id : null;
    }
  }
}
