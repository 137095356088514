import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    standalone: false
})
export class ErrorComponent {
  
  constructor(
    public dialogRef: MatDialogRef<ErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onCloseClick()  : void {
    this.dialogRef.close();
  }

  onOpenClick(): void {
    /*this.data.onOpenClick();*/
    this.dialogRef.close();
  }

}
