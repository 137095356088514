import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DigiService {
  endpoint = 'https://api.playground-dev.genpact.com/poc/avatar/';
  endpoint1 = 'https://api.playground-dev.genpact.com/genpro/dag-image-gen'

  private personalityTraitsSubject = new BehaviorSubject<string>('');
  personalityTraits$ = this.personalityTraitsSubject.asObservable();

  private descriptionSource = new BehaviorSubject<string>(''); // Initial value
  currentDescription = this.descriptionSource.asObservable();

  // Update the description
  updateDescription(description: string): void {
    this.descriptionSource.next(description);
  }

  private avatarSubject = new BehaviorSubject<string>('');  // To store the generated avatar
  avatar$ = this.avatarSubject.asObservable();

  constructor(private http: HttpClient) {}

  getDataAsPromise1(url: string, data: any = { method: 'POST' }, headers: any = {}): Promise<any> {
    let token: any = localStorage.getItem('token');
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
    };
    
    return new Promise((resolve, reject) => {
      fetch(url, { ...data, headers })
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => resolve(error));
    });
  }

  getPrompt(payload: { 'dag-input': string; 'user-input': string }): Observable<any> {

    const headers = {
      'Content-Type': 'application/json',
    };
    const promise = this.getDataAsPromise1(this.endpoint1, {
      method: 'POST',
      body: JSON.stringify(payload), 
    },headers);
    return from(promise);
  }

  private correctAnswers: { [key: number]: string } = {
    0: 'B', 1: 'B', 2: 'A', 3: 'A', 4: 'B',
    5: 'C', 6: 'C', 7: 'B', 8: 'A', 9: 'B',
    10: 'B', 11: 'B', 12: 'A', 13: 'A', 14: 'A',
    15: 'A', 16: 'B', 17: 'A', 18: 'A', 19: 'A',
  };

  getCorrectAnswers(): { [key: number]: string } {
    return this.correctAnswers;
  }

  // Method to call the endpoint and return an Observable
  getDigiAvatar(end: any): Observable<any> {
    const promise = this.getDataAsPromise1(`${this.endpoint}generate-prompt/`, { method: 'POST', body: end });
    return from(promise);
  }

  getAvatar(end: any): Observable<any> {
    const promise = this.getDataAsPromise1(`${this.endpoint}generate-image/`, { method: 'POST', body: end });
    return from(promise);
  }

  gethoroscope(end: any): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
    };
    const promise = this.getDataAsPromise1(`${this.endpoint}submit-answers/`, {
      method: 'POST',
      body: JSON.stringify(end), 
    },headers);
    return from(promise);
  }
  
  getquizquestions(): Observable<any> {
    const promise = this.getDataAsPromise1(`${this.endpoint}get-questions/`, { method: 'POST' });
    return from(promise);
  }

  submitquizanswers(end: any): Observable<any> {
    const promise = this.getDataAsPromise1(`${this.endpoint}submit-answers/`, { method: 'POST', body: end });
    return from(promise);
  }

  updatePersonalityTraits(text: string): void {
    this.personalityTraitsSubject.next(text);
  }

  // Method to update the avatar image shared across components
  updateAvatar(avatar: string): void {
    this.avatarSubject.next(avatar);
  }





}

