<div class="wrapper">
  <section class="content">
    <div class="container-fluid responsive p-0">

      <div class="chat-box-new p-0 p-md-2">
        <div class="row">
          <div class="col-12">
            <div class="conversation">
              <!-- Page Heading block Start -->
              <div class="row genpact-color px-3 p-2 mb-n3 text-center">
                <div class="col-md-4 text-md-left">
                  <h4 class="text-white">{{methodSelected?.name}}</h4>
                </div>
                <div class="col-md-4 text-center align-self-center py-md-0 py-2">
                  <div class="parent-block">
                    <div class="group-block">

                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="toggle-bar"> -->
                <!-- Toggle buttons for Azure and GCP -->
                <!-- <button class="toggle-option" id="azure-btn" (click)="toggleMethod('azure')">Azure</button> -->
                <!-- <button class="toggle-option" id="gcp-btn" (click)="toggleMethod('gcp')">GCP</button> -->
              <!-- </div>
               
              -->
              <!-- <div class="toggle-bar">
  <button class="toggle-option" id="azure-btn" (click)="toggleMethod('azure')">Azure</button>
  <button class="toggle-option" id="gcp-btn" (click)="toggleMethod('gcp')">GCP</button>
</div> -->

<!-- <div class="toggle-bar">
  <button 
    class="toggle-option" 
    id="azure-btn" 
    [class.active]="selectedMethod === 'azure'" 
    (click)="toggleMethod('azure')">Option 1</button>

  <button 
    class="toggle-option" 
    id="gcp-btn" 
    [class.active]="selectedMethod === 'gcp'" 
    (click)="toggleMethod('gcp')">Option 2</button>
</div> -->



                                <!-- Page Heading block  End -->



                               
                                <!-- Chat block  End -->

                                <!-- Input block  Start -->
                                
    <!-- <app-stop-process *ngIf="processStatus" (stopProgress)="resetForm($event)"></app-stop-process> -->
    <br>
    <div class="photo-verification-container">

      
        <!-- Left side for base image -->
        <div class="left-side">
          <label for="base-image" style="margin-bottom: 26px;">Upload Base Image <span style="color: red;">&#42;</span> <br>(National Identifier – Aadhar/PAN/Passport)<br></label>
          <input type="file" id="base-image" (change)="onBaseImageChange($event)" accept="image/png" />
          <div class="image-preview" *ngIf="baseImage">
            <img [src]="baseImage" alt="Base Image" />
          </div>
        </div>
      
        <!-- Right side for reference images and results -->
        <div class="right-side">
          <!-- Title for Images -->
          <label for="image-1" *ngIf="results.length === 0" style="margin-left: 128px;">Images captured during hiring/onboarding</label>
      
          <!-- Container for Image 1, Image 2, and Image 3 in the same row -->
          <div class="reference-images" *ngIf="results.length === 0">
            
            <!-- Image 1 -->
            <div class="image-container">
              <label for="image-1">Image 1<span style="color: red;">&#42;</span></label>
              <input type="file" id="image-1" (change)="onImageChange($event, 0)" accept="image/png" />
              <div class="image-preview" *ngIf="imagePreviews[0]">
                <img [src]="imagePreviews[0]" alt="Reference Image 1" />
              </div>
            </div>
      
            <!-- Image 2 -->
            <div class="image-container">
              <label for="image-2">Image 2</label>
              <input type="file" id="image-2" (change)="onImageChange($event, 1)" accept="image/png" />
              <div class="image-preview" *ngIf="imagePreviews[1]">
                <img [src]="imagePreviews[1]" alt="Reference Image 2" />
              </div>
            </div>
      
            <!-- Image 3 -->
            <div class="image-container">
              <label for="image-3">Image 3</label>
              <input type="file" id="image-3" (change)="onImageChange($event, 2)" accept="image/png" />
              <div class="image-preview" *ngIf="imagePreviews[2]">
                <img [src]="imagePreviews[2]" alt="Reference Image 3" />
              </div>
            </div>
      
          </div>
      
         
           <!-- Display Results -->
           <div *ngIf="results.length > 0" class="results" style="width: 640px;">
            <div *ngFor="let result of results" class="result-item">
              <div class="result-image">
                <!-- Result Preview Images -->
                <div class="image-preview">
                  <img [src]="imagePreviews[results.indexOf(result)]" alt="Reference Image" />
                </div>
              </div>
        
              <!-- Color square based on confidence -->
              <div class="color-square" [ngStyle]="{'background-color': result.color}">
                <label style="margin-top: 16px;">{{result.confidence}}</label>
              </div>

            </div>
          </div>
          
        </div>
        </div>

        <button *ngIf="results.length === 0" style="margin-left: 544px;" (click)="verify()" [disabled]="isButtonDisabled" [ngStyle]="{'background-color': isButtonDisabled ? 'grey' : 'black'}">
          Verify
        </button>
        <button *ngIf="results.length > 0" style="background-color: black;margin-left: 544px;" (click)="reloadPage()">Back</button> 
 
        </div>
      </div>

      <div class="confidence-legend" *ngIf="results.length > 0">
        <h6>LEGEND</h6>
        <div class="legend-item">
          <div class="color-square" [ngStyle]="{'background-color': 'red'}"></div>
          <span>RED: Low confidence (&lt; 70%) </span>
        </div>
        <div class="legend-item">
          <div class="color-square" [ngStyle]="{'background-color': '#FFBF00'}"></div>
          <span>AMBER: Medium Confidence (&gt;= 70% till 84%) </span>
        </div>
        <div class="legend-item">
          <div class="color-square" [ngStyle]="{'background-color': 'green'}"></div>
          <span>GREEN: High Confidence (&gt;= 85% tool result)</span>
        </div>
      </div>
      
      