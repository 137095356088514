import { Component, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '../shared/service/message.service';
import { ElementRef, HostListener } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoaderService } from '../shared/service/loader.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { LeaderboardService } from '../shared/service/leaderboard.service';
@Component({
  selector: 'app-home-openai',
  templateUrl: './home-openai.component.html',
  styleUrls: ['./home-openai.component.scss']
})
export class HomeOpenaiComponent {
  chkagree: boolean = false;
  showAlert: boolean = true;
  isCheckboxChecked: boolean = false;
  currentSlide = 0;
  showContent = false;
  showAdvancedSettings: boolean = false;
  rightb: boolean = true;
  // Define an array of slide content

  modalRef?: BsModalRef;


  tablinkData: any = [
    { name: 'ChatGPT Playground', icon: "assets\/newicons\/1.png", link: '/chat-completion', description: "Everything ChatGPT – but data-secure" },
    { name: 'Playground Library', icon: "assets\/newicons\/2.png", link: '/playground', description: "A template library to boost your efficiency" },
    { name: 'Bring Your Own Files', icon: "assets\/newicons\/3.png", link: '/byod', description: "Upload your files and instantly receive concise summaries, precise answers, curated projects, and valuable information" },
    { name: 'Translate Your Files', icon: "assets\/newicons\/4.png", link: '/translate-file', description: "Upload files and instantly receive translated files in selected language" },
    { name: 'Transcribe Your files', icon: "assets\/newicons\/5.png", link: '/video-indexer', description: "Upload Audio/video files and download your transcript in selected language" },
    { name: 'Research Assistant', icon: "assets\/newicons\/7.png", link: '/research-assistant', description: "Get your prompt work on latest internet data" },
    { name: 'Image Generator', icon: "assets\/newicons\/6.png", link: '/image-generator', description: "Write a prompt  and instantly receive generated images" },
    // { name: 'Google Translate', icon: "assets\/newicons\/4.png", link: '/google-translate', description: "Upload files and instantly receive translated files in selected language"}
    // { name: 'Data Analytics', icon: "assets\/newicons\/7.png", link: '/data-analytics', description: "Upload your files and instantly receive data analysis" },
    // { name: 'Leader Board', icon: "assets\/newicons\/7.png", link: '/leaderboard', description: "Track progress, celebrate achievements, and stay engaged with the pulse of our vibrant community at a glance." }
    //api assistant
   { name: 'Data Analyzer', icon: "assets\/newicons\/7.png", link: '/data-analyzer', description: "Data Analyzer Excels at making and handling data visuals, using tools to create charts, read and show data, and gain insights from files." },
   { name: 'Pod-labs Beta', icon: "assets\/newicons\/8.svg", link: '/podcastgen', description: "Genpact PodPulse-Your podcast creation, simplified" },
  //  { name: 'Ask Genpact', icon: "assets\/newicons\/1.png", link: '/ask-genpact', description: "Ask me anything about Genpact" },
  ]


  currentUser:any;
  currentMonth:any;
  constructor(private Router: Router,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private modalService: BsModalService,
    private leaderboardService: LeaderboardService,
    private toastMessage: ToastMessageService,
    private loaderService: LoaderService
  ) {


    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });

    this.currentMonth = month;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; }; }) {
    let screenWidth = event.target.innerWidth;
    let container = this.elementRef.nativeElement.querySelector('.res-container');

    if (container) {
      if (screenWidth < 768) {
        container.style.width = '100%';
      } else {
        container.style.width = '768px';
      }
    }
  }
  // Open model
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md',ignoreBackdropClick: true });
  }



  ngOnInit() {
    this.getUserRank();

    this.messageService.useraccess().subscribe((res: any) => {
      this.onLoading();
    }, (err: any) => {
      console.log('error is', err);
    });
  }
  toggleAdvancedSettings() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
    this.rightb = !this.rightb;// Toggle the variable value
  }

  toggleContent() {
    this.showContent = !this.showContent;
  }
  showSlide(slideIndex: number) {
    this.currentSlide = slideIndex;

    //console.log(this.slideContent[slideIndex])// Trigger change detection
  }
  clickonCard(link: string) {
    window.open(link, '_self');
  }
  username: any = localStorage.getItem('okta-username') ? localStorage.getItem('okta-username') : 'Guest';
  onLoading() {
    const modalbtn = document.getElementById('playground_policy_model') as HTMLLIElement;
    //$('#exampleModal')?.modal('show');
    if (localStorage.getItem('home_policy')) {

    } else {
      localStorage.setItem('home_policy', 'checked');
      modalbtn.click();
    }

  }
  onCheckboxChange(event: any) {
    this.isCheckboxChecked = event.target.checked;
  }

  onProceedClick() {
    this.showAlert = false;
  }

  getUserRank() {
    this.loaderService.loading.next(true);
    this.leaderboardService.getTop10Rank().subscribe(
      (res: any) => {
        this.loaderService.loading.next(false);
        if (res?.response?.status == 'success') {
         let userList = res?.response?.data;

          let userId = this.getOHR();

          userList.forEach((ele: any) => {
            if (ele.user_id == userId) {
              this.currentUser = ele;
            }
          })

        } else {
          this.toastMessage.showError(res?.response?.message);
        }

      },
      (error) => {
        // Handle the error here
        this.loaderService.loading.next(false);
        this.toastMessage.showError(error?.error?.error_message);
      }
    );
  }

  // get user OHR id
  getOHR() {
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      return ohr_id ? ohr_id : null;
    }
  }



}
