<div mat-dialog-content>
    <!-- Your modal content goes here -->
    <div class="model-header">
        <p>Settings</p>
        <div class="close-box" (click)="onClose()">
            <i class="fa-solid fa-xmark"></i>
        </div>
    </div>
    <!-- <div class="model-content">
        <p class="model-content-header">Subscription details</p>
        <div class="row">
            <div class="col-md-9">
                <div class="form-group">
                    <label for="">Code:</label>
                    <input type="text" class="form-control" [value]="subsCode" readonly>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
                <div class="form-group">
                    <label for="">Subscription valid till:</label>
                    <div class="validcode">
                    <input type="text" class="form-control" [value]="subsValidTill" readonly>
                  </div>
                  </div>
            </div>
        </div>
        <div class="action-btn">
            <button class="cancel-btn" (click)="onClose()">Close</button>
             <button class="save-btn">Save</button> 
        </div>
    </div> -->
</div>
