import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DigiService {
  endpoint = 'https://api.playground-dev.genpact.com/poc/avatar/';
  endpoint1 = 'https://api.playground-dev.genpact.com/genpro/dag-image-gen'

  private personalityTraitsSubject = new BehaviorSubject<string>('');
  personalityTraits$ = this.personalityTraitsSubject.asObservable();

  private avatarSubject = new BehaviorSubject<string>('');  // To store the generated avatar
  avatar$ = this.avatarSubject.asObservable();

  constructor(private http: HttpClient) {}

  getDataAsPromise1(url: string, data: any = { method: 'POST' }, headers: any = {}): Promise<any> {
    let token: any = localStorage.getItem('token');
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
    };
    return new Promise((resolve, reject) => {
      fetch(url, { ...data, headers })
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => resolve(error));
    });
  }

  // Method to call the endpoint and return an Observable
  getDigiAvatar(end: any): Observable<any> {
    const promise = this.getDataAsPromise1(`${this.endpoint}generate-prompt/`, { method: 'POST', body: end });
    return from(promise);
  }

  getAvatar(end: any): Observable<any> {
    const promise = this.getDataAsPromise1(`${this.endpoint}generate-image/`, { method: 'POST', body: end });
    return from(promise);
  }

  getpersonality(end: any): Observable<any> {
    const promise = this.getDataAsPromise1(`${this.endpoint}ai-personality/`, { method: 'POST', body: end });
    return from(promise);
  }

  updatePersonalityTraits(text: string): void {
    this.personalityTraitsSubject.next(text);
  }

  // Method to update the avatar image shared across components
  updateAvatar(avatar: string): void {
    this.avatarSubject.next(avatar);
  }
  }

