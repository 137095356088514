import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import lottieWeb from 'lottie-web';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {

  @Input() audioSource: string = '';  // Input for audio link

  @ViewChild('audioPlayer', { static: true }) audioPlayer!: ElementRef;
  @ViewChild('seekSlider', { static: true }) seekSlider!: ElementRef;
  @ViewChild('durationContainer', { static: true }) durationContainer!: ElementRef;
  @ViewChild('playIcon', { static: true }) playIcon!: ElementRef;
  @ViewChild('muteIcon', { static: true }) muteIcon!: ElementRef;
  @ViewChild('volumeSlider', { static: true }) volumeSlider!: ElementRef;
  @ViewChild('bufferedAmount', { static: true }) bufferedAmount!: ElementRef;

  playState = 'play';
  muteState = 'unmute';
  raf: any = null;

  ngOnInit() {
    this.initializePlayer();
  }

  initializePlayer() {
    const audio = this.audioPlayer.nativeElement;
    audio.src = this.audioSource;

    const playIconContainer = this.playIcon.nativeElement;
    const seekSlider = this.seekSlider.nativeElement;
    const volumeSlider = this.volumeSlider.nativeElement;
    const muteIconContainer = this.muteIcon.nativeElement;

    const playAnimation = lottieWeb.loadAnimation({
      container: playIconContainer,
      path: 'https://maxst.icons8.com/vue-static/landings/animated-icons/icons/pause/pause.json',
      renderer: 'svg',
      loop: false,
      autoplay: false,
      name: 'Play Animation'
    });

    const muteAnimation = lottieWeb.loadAnimation({
      container: muteIconContainer,
      path: 'https://maxst.icons8.com/vue-static/landings/animated-icons/icons/mute/mute.json',
      renderer: 'svg',
      loop: false,
      autoplay: false,
      name: 'Mute Animation'
    });

    playAnimation.goToAndStop(14, true);

    audio.addEventListener('loadedmetadata', () => {
      this.durationContainer.nativeElement.textContent = this.calculateTime(audio.duration);
      seekSlider.max = Math.floor(audio.duration);
    });

    audio.addEventListener('progress', () => this.displayBufferedAmount(audio, seekSlider));

    this.setupEventListeners(audio, playAnimation, muteAnimation, seekSlider, volumeSlider);
  }

  calculateTime(secs: number) {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${formattedSeconds}`;
  }

  displayBufferedAmount(audio: HTMLAudioElement, seekSlider: HTMLInputElement) {
    const bufferedAmount = (audio.buffered.end(audio.buffered.length - 1) / audio.duration) * 100;
    this.bufferedAmount.nativeElement.style.width = `${bufferedAmount}%`;
  }

  setupEventListeners(audio: HTMLAudioElement, playAnimation: any, muteAnimation: any, seekSlider: any, volumeSlider: any) {
    seekSlider.addEventListener('input', () => {
      audio.currentTime = seekSlider.value;
    });

    volumeSlider.addEventListener('input', () => {
      audio.volume = volumeSlider.value;
    });

    audio.addEventListener('play', () => {
      this.playState = 'pause';
      playAnimation.playSegments([14, 27], true);
    });

    audio.addEventListener('pause', () => {
      this.playState = 'play';
      playAnimation.playSegments([0, 14], true);
    });

    muteAnimation.addEventListener('click', () => {
      audio.muted = !audio.muted;
      if (audio.muted) {
        muteAnimation.playSegments([0, 15], true);
      } else {
        muteAnimation.playSegments([15, 25], true);
      }
    });
  }

  togglePlayPause() {
    const audio = this.audioPlayer.nativeElement;
    audio.paused ? audio.play() : audio.pause();
  }

  toggleMute() {
    const audio = this.audioPlayer.nativeElement;
    audio.muted = !audio.muted;
  }
}
