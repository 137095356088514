<div class="main-box">
    <ng-template #popTemplate>
        <div class="row parent">
            <div class="col-12">
                <div class="row nav-list">
    
                    
                    <!-- <input [(ngModel)]="searchText" (input)="filterLinks()" placeholder="Search by name" />
                    <ul>
                      <li *ngFor="let link of filteredLinks">
                        {{ link.name }} - {{ link.link }}
                      </li>
                    </ul> -->
    
    
                    
                    <!-- <div class="col-12 list">
                        <a href="javascipt:;" target="_self">How can I help you?</a>
                    </div> -->
                    <div class="col-12 list" *ngFor="let link of filteredLinks.length ? filteredLinks : linkTitles">
                        <a [href]=" link.link" target="_self"> {{ link.name }} </a>
                    </div>
                   
                </div>
    
                <div class="row mt-1 nav-input">
                    <div class="col-12 p-0">
                        <textarea  [(ngModel)]="searchText" placeholder="Enter your query" class="border-0 p-2" rows="2"
                            style="width: 100%;"></textarea>
                    </div>
                </div>
            </div>
        </div>
    
    </ng-template>
    <button type="button " class="btn btn-muted custom-css" [adaptivePosition]="true"
    popoverTitle="How can I help you?"
    placement="top right"
    [delay]="500"
        [outsideClick]="true" [popover]="popTemplate" containerClass="popover-content-pg">
        <img src="../../../assets/icons/chatbot.png" alt="" class="pg-bot">
    </button>
</div>