import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class BedrockService {

  constructor(private http: HttpClient) { }

  getBedRockResponse(query: string) {

    // const apiUrl = 'https://api1.playground.genpact.com/bedrock-chat';
const apiUrl = 'https://gapiw.playground-poc.viren.in/data/redshift';

    const headers = new Headers({ });

    const requestOptions = {
      method: 'POST', // Replace with your HTTP method (GET, POST, etc.)
      headers: headers,
      body:JSON.stringify({ "query": "how many accounts are there?" }),
    };

    return new Promise((resolve, reject) => {
      fetch(apiUrl, requestOptions)
        .then(response => {
          // Handle the response here
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          resolve(response.json());
        })
        .catch(error => {
          // Handle errors here
          reject(error);
        });
    })

  }

  getBedRockResponse2(query: string) {
    const apiUrl = 'https://gapiw.playground-poc.viren.in/data/redshift';

    const headers = new HttpHeaders({

    });

   let data =   { "query": query }

    return this.http.post(`${apiUrl}`,data);

  }

  getprompt(payload:any){
    const apiUrl = 'https://api.playground-dev.genpact.com/genpro/gen-prompt';

    const headers = new HttpHeaders({

    });

   let data =   { "text": payload,
                 
    }

    return this.http.post(`${apiUrl}`,data);
  }

  //genpro/submit like function
  submit(payload:any){
    const apiUrl = 'https://api.playground-dev.genpact.com/genpro/submit';

   



    return this.http.post(`${apiUrl}`,payload);
  }

  getprompt1(payload:any,image:boolean){
    const apiUrl = 'https://api.playground-dev.genpact.com/genpro/gen-prompt';

    const headers = new HttpHeaders({

    });

   let data =   { "text": payload,
                  "checkbox":image}

    return this.http.post(`${apiUrl}`,data);
  }
  
}
