<div class="wrapper">
    <app-sidenav [item]="'playground'"></app-sidenav>
    <!-- Main content -->
    <section class="content">
        <div class="container-fluid responsive p-2">
            <div class="container">
 
                <!-- <app-chat-section></app-chat-section> -->
 
                <div class="row flex-nowrap">
                    <main id="main">
                        <div class="row">
                            <div class="col-12 text-center">
                                <h4>
                                    Bring Your Own File(s)
                                </h4>
 
                                <div *ngIf="endPointDetail?.value" class="">
                                    Count Left: <b>{{ endPointDetail?.max - endPointDetail?.value}}</b>
                                  </div>
                            </div>
                        </div>
                        <div class="row">
                            <app-file-upload (filename)="filenameevent($event)" (fileuploaded)="fileuploaded($event)"
                                *ngIf="Isuploading">
                            </app-file-upload>
                        </div>
 
                        <div class="row px-md-4 " *ngIf="!Isuploading">
 
                            <div class="col-12 file-list" >
                              File Name:
                              <h6 style="display: contents; text-decoration: underline;">
                                {{filename}}
                                 <span (click)="Isuploading=true;filename='';queryresponse='';relatedQuestionList='';searchquery='';summary=''; chatData=[];">
                                  <i class="fa fa-close" style="color:red;"></i>
                                </span>
                              </h6>
                            </div>
                          </div>

                        <!-- Loading icon -->
                        <div *ngIf="showLoading" class="loading-indicator">
                            <div class="spinner"></div>
                            <div>Getting Your Data...</div>
                        </div>
                        <!-- Loading icon -->

                                    <div class="row-x" *ngIf="!Isuploading">
                                        <div class="conversation">
                                                <div class="row m-0 px-2">
                                                    <div class="col-md-12 text-md-left">
                                                        <div class=" text-md-right d-md-none" style="align-self: center;">
                                                            <span *ngIf="endPointDetail?.value" class="">
                                                                Count Left: <b>{{ endPointDetail?.max - endPointDetail?.value}}</b>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                                                   
                                            <div id="text-d" class="box messages smooth-scroll" #scrollChat>
                                                <div class="message" *ngFor="let chat of chatData; let index = index">
                                                  <div class="user" *ngIf="chat.role === 'user'; else botTemplate">
                                                    <img src="assets/people-1.png" class="shadow-sm" alt="User Avatar">
                                                    <i class="fa fa-mail-forward icon-send" aria-hidden="true"></i>
                                                  </div>
                                                  <ng-template #botTemplate>
                                                    <div class="user">
                                                      <img src="assets/bot.png" class="shadow-sm" alt="GPT Avatar">
                                                      <i class="fa fa-reply icon-send" aria-hidden="true"></i>
                                                    </div>
                                                  </ng-template>
                        
                                                  <div class="content" *ngIf="chat.content">
                                                    <div *ngIf="chat.role != 'user' && chat.section != 'sources' && chat.section != 'question'" class="timestamp-block" style="display: flex; justify-content: space-between; margin-bottom:10px"> 
                                                    <button [hidden]="isCopied" *ngIf="chat.role !== 'user' && chat.section !== 'sources' && chat.section !== 'question'" class="markdown-clipboard-button content-copy" (click)="copyToClipboard(chat.content)">
                                                      <i class="fa fa-clone" aria-hidden="true"></i>
                                                    </button>
                                                    <button [hidden]="!isCopied" *ngIf="chat.role !== 'user' && chat.section !== 'sources' && chat.section !== 'question'" class="markdown-clipboard-button content-copy">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                    </button>
                                                  </div>
                                                     <div *ngIf="chat.role == 'user'">
                                                        <div markdown mermaid lineNumbers clipboard [data]="chat.content" [disableSanitizer]="true">
                                                        </div>
                                                     </div>
                        
                                                   <div *ngIf="chat.role !== 'user'">
                                                      <div markdown mermaid lineNumbers clipboard [data]="chat.content" [disableSanitizer]="true">
                                                      </div>
                                                    </div> 
                        
        
                                                  <div class="content" *ngIf="chat?.section == 'question'">
                                                      <div class="language-buttons">
                                                          <h6>{{chat?.title}}</h6>
                                                          <div class="question-list" (click)="inputPrompt(question)" 
                                                              *ngFor="let question of chat?.content">
                                                              <span>{{question}}</span>
                                                          </div>
                                                      </div>
                                                  </div>
        
                        
                                                  </div>
                                                  
                                                </div>
                                                <div id="targetRed" style="opacity: 0;;">
                                                    ...
                                                </div>
                                            </div>

                                            <div class="user-input" >
                                              <div class="box input-box" *ngIf="!generateResponse">
                                                  <textarea id="t1" [(ngModel)]="searchquery " [maxlength] = "5000"
                                                    (keydown.enter)="onSearchButtonClicked()"
                                                id="txtquery" class="form-control byod-input "
                                                [disabled]="!tarea"
                                                autosize="true" placeholder="Enter your search query here"></textarea>
                                                <div>
                                                <button class="app-speech-input-btn" *ngIf="startspeech" (click)="startService()"></button>
                        
                                                <button class="app1-speech1-input1-btn1" *ngIf="stopspeech" (click)="stopService()">
                                                    <i class="fa fa-microphone fa-beat-fade" style="font-size:20px;color:red"></i>
                                                    <div class="micro-animation"></div>
                                                </button>
                                              </div>
                                              <div class="d-flex" style="align-items: center; gap: 10px;">
                                                <div id="send-button" (click)="onSearchButtonClicked()" >
                                                    <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                                                        <path fill="#006ae3" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
                                                    </svg>
                                                </div>
                                              </div>
                                            </div>
                                            </div>
                                        </div>
                            </div>
                    </main>
                </div>

                
            </div>
        </div>
    </section> 
</div>
 
 
 
<!-- policy & condition toggle -->
<button type="button" class="btn btn-primary d-none" id="byod_policy_model"  (click)="openModal(byod_policy_model)">
</button>
 
<!-- policy & condition block -->
<ng-template #byod_policy_model>
    <div class="modal-body">
 
        <div class="row">
            <div class="col-12  fw-bold text-primary">In order to adhere to the Legal and Security policies,
                please take care of below mentioned points:</div>
        </div>
        <div class="row">
            <div class="col-12" style="font-size: 13px;">
                <ol style="list-style:decimal;">
                    <li>
                        SOPs/Documents/Images should be hosted on Genpact network.
                    </li>
                    <li>
                        Only documents hosted on the Genpact network and that Genpact has contractual
                        permission to maintain on the Genpact network may be uploaded here.
                    </li>
                    <!-- change here-->
                    <li>
                        This application is for Genpact internal employees usage only.
                    </li>
 
                </ol>
 
            </div>
        </div>
        <div class="row">
            <div class="col-12 ml-4">
                <input id="byod_check_label" type="checkbox" [(ngModel)]="chkagree" class="form-check-input" />
                <label class="form-check-label text-decoration-underline fw-bold text-primary" style="margin-left: 15px;" for="byod_check_label">
                    I acknowledge that I have read, understand and agree to above instructions.
                </label>
            </div>
 
        </div>
 
    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-genpact text-white center cursor-pointer" [disabled]="!chkagree"
            (click)="modalRef?.hide()">
            Proceed
        </button>
    </div>
</ng-template>
 
<app-stop-process *ngIf="processStatus" (stopProgress)="resetForm($event)"></app-stop-process>