import { Component, OnInit } from '@angular/core';
import { LeaderboardService } from '../shared/service/leaderboard.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { LoaderService } from '../shared/service/loader.service';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit { 
  userList: any = [];
  currentUser: any;
  currentMonth: any;
  constructor(
    private leaderboardService: LeaderboardService,
    private toastMessage: ToastMessageService,
    private loaderService: LoaderService,
  ) { }

  ngOnInit(): void {

    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });

    this.currentMonth = month;

    this.getUserRank();
  }

  updateName(name: string) {
    if (name) {

      const [lastName,firstName] = name.split(',').map(str => str.trim());
      return `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
    };
    return name;
  }

getUserRank() {
  this.loaderService.loading.next(true);
  this.leaderboardService.getTop10Rank().subscribe(
    (res: any) => {
      this.loaderService.loading.next(false);
      if (res?.response?.status == 'success') {
        this.userList = res?.response?.data;

        let userId = this.getOHR();

        this.userList.forEach((ele: any) => {
          if (ele.user_id == userId) {
            this.currentUser = ele;
          }
        })

        console.log(this.currentUser)

      } else {
        this.toastMessage.showError(res?.response?.message);
      }

    },
    (error) => {
      // Handle the error here
      this.loaderService.loading.next(false);
      this.toastMessage.showError(error?.error?.error_message);
    }
  );
}

// get user OHR id
getOHR() {
  let userData = localStorage.getItem('okta-token-storage');
  if (userData) {
    let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
    return ohr_id ? ohr_id : null;
  }
}

}
