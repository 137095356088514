import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ToastMessageService } from '../service/toast-message.service';
import { Location } from '@angular/common';
@Injectable({
  providedIn: 'root'
})

export class CommonService {
  apiurl =  environment.apiURL;
  ToastMessageService: ToastMessageService | undefined;
  // constructor(
  //   private ToastMessageService: ToastMessageService,
  // ) {

  // }

  getMethodAxios = (url:any) => new Promise((resolve, reject) => {
    axios.get(url)
    .then((response) => {
      resolve(response);
    }).catch((error) => {
        if(error.response && error.response.status === 401){
          // Check if the error response has status 401
          // You can access error.response.data to handle the specific error message
          if (error.response.data && error.response.data.error === "Unauthorized" && error.response.data.message === "Authentication Token is missing!") {
              // Redirect to login page if token is missing or unauthorized
             // this.ToastMessageService.showError('Session Expired, Please login again');
             alert('Session Expired, Please login again');
            //  const currentUrl = window.location.pathname;
            // localStorage.setItem('p_redirect', '/use-case-three')
              window.location.href = '/';
              //window.location.href = '/use-case-three';
          }
      }
        console.log(error);
        reject(error);
    });
  })

  postMethodAxios = (url:any,body:any) =>  new Promise((resolve, reject) => {
    let token:any = localStorage.getItem('token');
    axios.post(url, body, {headers:{"Content-Type":'application/json',
    'Authorization': 'Bearer ' + token}
  })
      .then((response) => {
        resolve(response);
      }).catch((error) => {
        if(error.response && error.response.status === 401){
          // Check if the error response has status 401
          // You can access error.response.data to handle the specific error message
          if (error.response.data && error.response.data.error === "Unauthorized" && error.response.data.message === "Authentication Token is missing!") {
              // Redirect to login page if token is missing or unauthorized
              alert('Session Expired, Please login again');
              window.location.href = '/';
          }
      }
        console.log(error);
        reject(error);
      });
  })
  postMethodAxios1 = (url:any,body:any) =>  new Promise((resolve, reject) => {
    let token:any = localStorage.getItem('token');
    axios.post(url, body, {headers:{"Content-Type":'multipart/form-data',
    'Authorization': 'Bearer ' + token}})
      .then((response) => {
        resolve(response);
      }).catch((error) => {
        if(error.response && error.response.status === 401){
          // Check if the error response has status 401
          // You can access error.response.data to handle the specific error message
          if (error.response.data && error.response.data.error === "Unauthorized" && error.response.data.message === "Authentication Token is missing!") {
              // Redirect to login page if token is missing or unauthorized
              alert('Session Expired, Please login again');
              window.location.href = '/';
          }
      }
        console.log(error);
        reject(error);
      });
  })
  Location: any;

}
