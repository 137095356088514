<div class="stepper">
  <div class="step" *ngFor="let step of steps; let i = index" (click)="goToStep(i)">
    <div
      class="step-indicator"
      [ngClass]="{
        active: isActive(i),
        completed: isCompleted(i)
      }"
    ></div>
    <div class="step-label" [ngClass]="{ active: isActive(i) }">
      {{ step }}
    </div>
  </div>
</div>
