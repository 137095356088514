import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute} from '@angular/router';
import { Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-contactus',
    templateUrl: './contactus.component.html',
    styleUrls: ['./contactus.component.scss'],
    standalone: false
})
export class ContactusComponent implements OnInit {
  currentRoute: any;

  constructor(public dialogRef: MatDialogRef<ContactusComponent>,
    private route: ActivatedRoute,
  ) { }

  onCloseClick()  : void {
    this.dialogRef.close();
  }

 ngOnInit(): void {
 this.currentRoute =localStorage.getItem('domain');
//  alert(this.currentRoute);
 }

}
