<div class="wrapper">
  <app-sidenav [item]="'playground'"></app-sidenav>

  <!-- Main content -->
  <div *ngIf="showHeading" class="mt-2 genpact-color" style="background-color:transparent;  text-align: left;margin-left:5px;margin-right:5px;">
    <div *ngIf = "showUpload" class="btn-group-lg px-2 text-center genpact-color" role="group" aria-label="Basic radio toggle button group" style="padding-top: 10px; margin-left: 2px !important; border-radius:0px;">
      <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked (change)="azureSelected()">
      <label class="btn btn-outline-primary text-white border-0" for="btnradio1">Azure Translate</label>
      <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" (change)="googleSelected()">
      <label class="btn btn-outline-primary text-white border-0" for="btnradio2">Google Translate</label>
  </div>


  <!-- <button *ngIf = "showUpload" type="button" class="btn btn-primary" data-toggle="modal" data-target="#modalsr">
    <i class="bi bi-gear-fill"></i>

  </button> -->

  <!-- Modal -->
  <!-- <div class="modal right fade" id="modalsr" tabindex="-1" role="dialog" aria-labelledby="modalsrLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" >
          <h5 class="modal-title" id="modalsrLabel">Options</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group" style="text-align:left;">
            <label for="translationModel">Select Model</label>
            <div class="btn-group-lg px-2 text-center " role="group" aria-label="Basic radio toggle button group" style="padding-top: 10px; margin-left: 2px !important; border-radius:0px;">
              <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked (change)="azureSelected()">
              <label class="btn btn-outline-primary " for="btnradio1">Azure</label>
              <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" (change)="googleSelected()">
              <label class="btn btn-outline-primary " for="btnradio2">Google</label>
            </div>
          </div>

          <div class="form-group mt-4" style="text-align:left;">
            <label for="translationType">Select Translate Type</label>
            <div class="btn-group-lg px-2 text-left " role="group" aria-label="Basic radio toggle button group" style="padding-top: 10px; margin-left: 2px !important; border-radius:0px;">
              <input type="radio" class="btn-check" name="btnradioType" id="btnradioType1" autocomplete="off" (click)="textselected()" >
              <label class="btn btn-outline-primary " for="btnradioType1">Text</label>
              <input type="radio" class="btn-check" name="btnradioType" id="btnradioType2" checked autocomplete="off" (click)="filesSelected()">
              <label class="btn btn-outline-primary " for="btnradioType2">File</label>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div> -->









</div>
  <!-- <div class="row genpact-color px-3 p-1 text-center">
    <div class="col-md-4 text-md-left">
        <h4 class="text-white"> Translate your Files </h4>
    </div>
    <div class="col-md-6 text-center align-self-center py-md-0 py-2">
        <div class="parent-block">
            <div class="group-block">
                <ul>
                    <li>
                        <button (click)="translate_model='azure'"
                            [ngClass]="{'active': translate_model =='azure'}">
                            <span>Titan</span>
                        </button>
                    </li>

                    <li>
                        <button (click)="translate_model='google'"
                            [ngClass]="{'active': translate_model !='azure'}">
                            <span>Stable</span>
                        </button>
                    </li>

                </ul>
            </div>
        </div>
    </div>

    <div class="col-md-4 text-md-right" style="align-self: center;">
        <span *ngIf="endPointDetail?.value" class="">
            Count Left: <b>{{ endPointDetail?.max - endPointDetail?.value}}</b>
        </span>
    </div>
</div> -->
  <section class="content">
    <div class="container-fluid responsive">
      <div class="container">

        <div class="row flex-nowrap">
          <main id="main">
            <div class="row mt-4 mb-3">
              <div class="col-12" style="text-align: center;">
                <h4>
                  Translate Your Files
                </h4>


                <div *ngIf="endPointDetail?.value" class="" style="color:#00aecf">
                  Count Left: <b>{{ endPointDetail?.max - endPointDetail?.value}}</b>
                </div>

              </div>
            </div>

            <div class="row py-3">
              <div class="col-md-12">
                <div class="selectlang" align="center">
                  <ng-container >
                    From
                         <ng-multiselect-dropdown
                          [placeholder]="'Select'"
                          [settings]="dropdownSettings"
                          [data]="languageArray1"
                          [(ngModel)]="convertFrom"
                        >
                        </ng-multiselect-dropdown>

                        <button  (click)="interchange()" class="btn btn-primary"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5"/>
                        </svg></button>

                    Translate To
                    <ng-multiselect-dropdown [placeholder]="'Select'" [settings]="dropdownSettings"
                      [data]="languageArray" [(ngModel)]="convertTo">
                    </ng-multiselect-dropdown>
</ng-container>
<ng-container *ngIf="fileUploadedStatus">

                    <button [disabled]="(convertFrom[0].value == convertTo[0].value)" *ngIf="!isGoogleSelected" class="bg-primary text-light p-2 px-4 border border-r1" (click)="translateAzureContent()">
                      Translate </button>

                      <button [disabled]="(convertFrom[0].value == convertTo[0].value)" *ngIf="isGoogleSelected" class="bg-primary text-light p-2 px-4 border border-r1" (click)="translateGoogleContent()">
                        Translate </button>

                  </ng-container>
                  <button *ngIf="downloadStatus && !isGoogleSelected" class="bg-success text-light p-2 px-4 border border-r1"
                    (click)="downloadAzureFile()"> Download </button>

                    <button *ngIf="downloadbtn && isGoogleSelected" class="bg-success text-light p-2 px-4 border border-r1"
                    (click)="downloadGoogleFile()"> Download </button>

                </div>

              </div>
            </div>
            <div class="row" *ngIf = "showUpload">

              <!-- <app-upload-file placeholder="Only .txt, .doc, .pdf, .xls,.png ,.jpeg,.jpg,.PNG,.tif,.tiff and .ppt file are allowed (max Size 16 MB)"
               multiple inputAccept=".txt,.xlsx,.xls,.pdf,.PDF,.pptx,.PPTX,.ppt,.pptx,.doc,.DOC,.docx,.DOCX,.png ,.jpeg,.jpg,.PNG,.tif,.tiff"
                (fileuploaded)="postTranslateUpload($event)" maxFileSize=16 ></app-upload-file> -->
                <!-- [accept]="ImageStatus ? '.png ,.jpeg,.jpg,.PNG,.tif,.tiff' : '.txt,.xlsx,.xls,.pdf,.PDF,.pptx,.PPTX,.ppt,.pptx,.doc,.DOC,.docx,.DOCX'" [multiple]="ImageStatus" -->

                <app-multiple-upload *ngIf="!istext"  placeholder="Only .txt, .docx, .pdf, .xls,.png ,.jpeg,.jpg,.tif,.tiff and .ppt file are allowed"
                  header="Instructions"

                multiple
                 class="disabled-upload"
                  inputAccept=".txt,.xlsx,.xls,.pdf,.PDF,.pptx,.PPTX,.ppt,.pptx,.doc,.DOC,.docx,.DOCX,.png ,.jpeg,.jpg,.PNG,.tif,.tiff"
                       (change)="onFileUpload($event)"
                 >
              </app-multiple-upload>

              <div class="copy-textbox-container" *ngIf="istext">
                <textarea type="text" [(ngModel)]="textoutput" placeholder="translatedText" disabled class="form-control"></textarea>
               <!-- cross icon -->
               <!-- <span class="cross-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707 5.354 11.354a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </span> -->

                <span class="copy-icon"  (click)="copyText(textoutput)">
                  <span *ngIf="!copied"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                  </svg></span> <!-- Copy icon Unicode -->
                  <span *ngIf="copied"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                    <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/>
                  </svg></span> <!-- Checkmark icon Unicode -->
                  <!-- <span class="cross-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707 5.354 11.354a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </span> -->
                </span>
                <span tooltip="Refresh" class="copy-icon1" (click)="textoutput=''; textinput='';">

                  <span class="cross-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                    </svg>
                  </span>
                </span>
              </div>



              <!-- <app-multiple-upload *ngIf="isGoogleSelected" placeholder="Only .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .png, .jpg, .jpeg, .PNG, .tif, .tiff file are allowed"
                  inputAccept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .png, .jpg, .jpeg, .PNG, .tif, .tiff"
                  (change)="onGoogleUpload($event)" >
                </app-multiple-upload> -->
              <!-- <app-multiple-upload placeholder="Only .txt, .doc, .pdf, .xls,.png ,.jpeg,.jpg,.PNG,.tif,.tiff and .ppt file are allowed"
              [accept]="fileUploadAcceptValue()"
              [multiple]="fileUploadMultipleValue()"
              (change)="onupload($event)" >
            </app-multiple-upload> -->

                <!-- note -->


            </div>
            <!--  && !isGoogleSelected -->
            <!-- <div class="item pt-2 pl-1" *ngIf = "showUpload" >
              <label class="checkbox-label">
                <input type="checkbox" id="uploadCheckbox" [(ngModel)]="ImageStatus"

                  required [ngModelOptions]="{standalone: true}">
                check the box if uploading  Image(s)
              </label>

                  <div  class="disclaimer mt-3">
                    <p>
                        <b> <i class="bi bi-info-circle"></i> </b>
                        Multiple file upload is only for Images.
                    </p>
                </div>

            </div> -->


            <div class="row px-md-5 " *ngIf="fileUploadedStatus || downloadStatus">

              <div  class="col-12 file-list" *ngFor="let fname of Images;let i= index">
                File Name:
                <h6  style="display: contents; text-decoration: underline;">
                  {{fname?.name}}
                  <span (click)="resetFile()" *ngIf="i==0">
                    <i class="fa fa-close" style="color:red;"></i>
                  </span>
                </h6>
              </div>&nbsp;

              <!-- <div *ngIf="isGoogleSelected" class="col-12 file-list">
                File Name:
                <h6 style="display: contents; text-decoration: underline;">
                  {{file?.name}}
                  <span (click)="resetFile()">
                    <i class="fa fa-close" style="color:red;"></i>
                  </span>
                </h6>
              </div>&nbsp; -->
              <div class="item">
                <label class="checkbox-label">
                  <input type="checkbox" id="uploadCheckbox" [(ngModel)]="glossaryStatus"
                    required [ngModelOptions]="{standalone: true}">

                  Do you want to add a glossary for keywords /brandnames?
                </label>
                <!-- <div class="row" *ngIf = "glossaryStatus">
                  <table>
                    <tr>
                      <th>Current Text</th>
                      <th>Translate To</th>
                    </tr>
                    <tr *ngFor="let data of tableData">
                      <td><input [(ngModel)]="currentText" type="text" placeholder="Current Text"></td>
                      <td><input [(ngModel)]="translatedText" type="text" placeholder="Translate To"></td>
                    </tr>
                  </table>
                  <div class="row py-3">
                    <div class="col-sm-12 text-center">
                  <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="addRow()">Add More</button>
                  <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="removeRow()">Remove</button>
                 </div>
                 <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="glossaryFileUpload(tableData)">Submit</button>
                </div>
                  <app-upload-file placeholder="You can only upload csv files (max Size 16 MB)"
                    inputAccept=".csv"
                    (fileuploaded)="glossaryFileUpload($event)" maxFileSize=16>
                  </app-upload-file> -->
                  <!-- <div class="row px-md-5 " *ngIf="glossaryFileUploadedStatus || downloadStatus">

                <div class="col-12 file-list">
                File Name:
                <h6 style="display: contents; text-decoration: underline;">
                  {{glossaryFileData?.name}}
                  <span (click)="resetglossaryFile()">
                    <i class="fa fa-close" style="color:red;"></i>
                  </span>
                </h6>
                </div>
                  </div> -->
                <!-- </div> -->
                <div class="row table-responsive m-0" *ngIf="glossaryStatus" >
                  <table class="table" >

                      <div class="row py-3 m-0">
                        <div class="col-sm-12 text-right">
                          <button  class="bg-primary text-light p-2 px-4 border border-r1" (click)="addGlossaryEntry()"><i class="bi bi-plus"></i>Add More</button>
                          <!-- <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="removeGlossaryEntry()">Less</button> -->
                        </div></div>
                    <tr>
                      <th style="text-align: left; background-color:#ececec">Current Text</th>
                      <th style="text-align: left;background-color:#ececec">Translate To</th>
                      <th style="background-color:#ececec">Action</th>
                    </tr>
                    <tbody>
                    <tr *ngFor="let data of tableData;let i= index">
                      <td ><input class="form-control" [(ngModel)]="data.currentText" type="text" placeholder="Current Text"></td>
                      <td ><input class="form-control" [(ngModel)]="data.translatedText" type="text" placeholder="Translate To"></td>
                      <td class="rmbtn" >
                        <button *ngIf="i > 0" class="bg-danger text-light p-2 px-4 border border-r1" (click)="removeGlossaryEntry(i)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                          </svg>
                        </button>
                      </td>
                    </tr></tbody>

                  </table>
                  <br><br><br>
                    <!-- <div class="col-sm-12 text-center">
                    <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="glossaryFileUpload()">Submit</button>
                  </div> -->
                  <!-- </div> -->
                </div>

              </div>

            </div>
            <br>
            <div class="textarea-container" >
              <textarea
                 [disabled]="!showUpload"
                rows="3"
                [(ngModel)]="textinput"
                class="form-control message-input"
                placeholder="Input text to translate"
                (keydown.enter)="modelcheck()"
              ></textarea>
              <div class="input-group-append">
                <button

                [disabled]="textinput.trim().length > 0 "
                  id="image-upload-btn"
                  style="border:none;"
                  tooltip="Multiple Upload Allowed for Images Only"
                  class="btn btn-outline-secondary"
                  type="button"
                  (click)="imageinput.click();ImageStatus=true;istext=false;"



                >
                <svg
                id="image-upload-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="url(#grad1)"
                class="bi bi-image"
                viewBox="0 0 16 16"
              >
                <defs>
                  <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style="stop-color:#073262;stop-opacity:1" />
                    <stop offset="100%" style="stop-color:#00aecf;stop-opacity:1" />
                  </linearGradient>
                </defs>
                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                <path
                  d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z"
                />
              </svg>

                </button>
                <input
                  #imageinput
                  type="file"
                  accept=".png, .jpeg, .jpg, .PNG, .tif, .tiff"
                  multiple
                  style="display: none;"
                  (change)="onFileUpload($event)"
                />

                <button

                [disabled]="textinput.trim().length > 0 "
                  style="border:none;"
                  class="btn btn-outline-secondary"
                  type="button"
                  tooltip="Multiple Upload not Allowed "

                  (click)="fileInput.click();ImageStatus=false;istext=false;"


                >
                <i class="bi bi-paperclip gradient-icon"></i>


                </button>
                <input
                  type="file"
                  accept=".txt, .xlsx, .xls, .pdf, .PDF, .pptx, .PPTX, .ppt, .pptx, .doc, .DOC, .docx, .DOCX, .png, .jpeg, .jpg, .PNG, .tif, .tiff"
                  #fileInput
                  style="display: none;"
                  (change)="onFileUpload($event)"
                />

                <button
                [disabled]="textinput.trim().length <= 0"
                  style="border:none; color:#00AECF"
                  class="btn btn-outline-secondary"
                  type="button"
                 (click)="modelcheck();istext=true;"
                >
                <i class="bi bi-send-fill gradient-icon"></i>
                </button>
              </div>
            </div>
<br><br><br>



          </main>
        </div>
      </div>



    </div>
  </section>



  <app-stop-process *ngIf="processStatus" (stopProgress)="resetForm($event)"></app-stop-process>
<!-- Button trigger modal -->
<button style="display: none;" type="button" id="notibtn" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Note</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <h6 class="text-danger"> {{notification}}</h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>








<!-- policy & condition toggle -->
<button type="button" class="btn btn-primary d-none" id="byod_policy_model"  (click)="openModal(byod_policy_model)">
</button>

<!-- policy & condition block -->
<ng-template #byod_policy_model>
    <div class="modal-body">

        <div class="row">
            <div class="col-12  fw-bold text-primary">In order to adhere to the Legal and Security policies,
                please take care of below mentioned points:</div>
        </div>
        <div class="row">
            <div class="col-12" style="font-size: 13px;">
                <ol style="list-style:decimal;">
                    <!-- <li>
                        SOPs/Documents/Images should be hosted on Genpact network.
                    </li> -->
                    <li>
                        Only documents hosted on the Genpact network and that Genpact has contractual
                        permission to maintain on the Genpact network may be uploaded here.
                    </li>
                    <!-- change here-->
                    <li>
                        This application is for Genpact internal employees usage only.
                    </li>

                </ol>

            </div>
        </div>
        <div class="row">
            <div class="col-12 ml-4">
                <input id="byod_check_label" type="checkbox" [(ngModel)]="chkagree" class="form-check-input" />
                <label class="form-check-label text-decoration-underline fw-bold text-primary" style="margin-left: 15px;" for="byod_check_label">
                    I acknowledge that I have read, understand and agree to above instructions.
                </label>
            </div>

        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-genpact text-white center cursor-pointer" [disabled]="!chkagree"
            (click)="modalRef?.hide()">
            Proceed
        </button>
    </div>
</ng-template>
