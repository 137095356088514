<div class="container mt-4">





    <!-- //dynamic table style="justify-content: flex-end;align-items: flex-end;"-->
    <div class="d-flex py-0" style="    align-content: center;flex-wrap: wrap;justify-content: flex-end;align-items: baseline;">
       <div class=" my-3 text-center" style="padding-left: 6px;">
        <!-- <button type="button" class="btn btn-primary"   style="font-family: georgia;height: 38px;margin-top: -5px;"
        data-bs-toggle="modal" data-bs-target="#Newuser">View candidate details
       </button> -->
       <button type="button" class="btn btn-primary"   style="font-family: georgia;border-radius: 0px;height: 38px;margin-top: -5px;"
       (click)="getuserdata()">View candidate details
       <!-- <i class="fas fa-plus"></i> -->
      </button>
      </div>

      <div class="d-flex py-0" style="    align-content: center;flex-wrap: wrap;justify-content: flex-end;align-items: baseline;">
        <div class=" my-3 text-center" style="padding-left: 6px;">
          <!-- <button  type="button" class="btn btn-primary" 
            style="font-family: georgia;height: 38px;margin-top: -5px;"
            data-bs-toggle="modal" data-bs-target="#cvjd">

        Fetch user data
      </button> -->
      <button  type="button" class="btn btn-primary" 
            style="font-family: georgia;height: 38px;margin-top: -5px; border-radius: 0px;"
            (click)="getfetchdata()">

        Fetch user data
      </button>
      </div>
      </div>

      <div class="text-right" style="padding-left: 5px;">
        <button id="refreshbtn" class="bg-primary text-light p-2 px-4 border border-r1" style="background-color: #021D44!important;
        color: #ffffff!important;
        border: none!important;" (click)="getlist()">
          <!-- <i class="fa fa-refresh"></i> -->
          Refresh
        </button>
      </div>

      

    </div>











  <div class="mat-elevation-z8" *ngIf="userdata">
    <!-- Search Box -->

    <div class="mb-3">
      <input type="text" placeholder="Search users..." matInput (keyup)="applyFilter($event)" class="form-control">
    </div>

    <!-- Table with sorting and pagination -->
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort class="table">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- For Requisition ID -->
<ng-container matColumnDef="requisition_id">
  <th mat-header-cell *matHeaderCellDef mat-sort-header> Requisition ID</th>
  <td mat-cell *matCellDef="let element"> {{element.requisition_id}} </td>
</ng-container>

<!-- For Candidate -->
<ng-container matColumnDef="candidate_name">
  <th mat-header-cell *matHeaderCellDef mat-sort-header> Candidate </th>
  <td mat-cell *matCellDef="let element"> {{element.candidate_name}} </td>
</ng-container>

<!-- For Position -->
<ng-container matColumnDef="position">
  <th mat-header-cell *matHeaderCellDef mat-sort-header> Position </th>
  <td mat-cell *matCellDef="let element"> {{element.position}} </td>
</ng-container>

<!-- For Interviewer -->
<ng-container matColumnDef="interviewer_name">
  <th mat-header-cell *matHeaderCellDef mat-sort-header> Interviewer </th>
  <td mat-cell *matCellDef="let element"> {{element.interviewer_name}} </td>
</ng-container>

<!-- For Status -->
<ng-container matColumnDef="status">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Current Status </th>
  <td mat-cell *matCellDef="let element"> {{element.status =='completed' ? 'completed' : 'In progress' }} </td>
</ng-container>


        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Actions </th>
          <td mat-cell *matCellDef="let element" style="display: flex; gap:10px;">
            <button class="btn btn-info btn-sm"  (click)="Navigate(element.id,element.requisition_id)">Go</button>
            <button class="btn btn-info btn-sm" style="background-color:#073262;color:#ffffff;" [disabled]="element.status !== 'completed'" (click)="downloadreport(element.requisition_id,element.id)">Final Report
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
              </svg>
            </button>
            <button class="btn btn-info btn-sm"  (click)="openModel()">Photo Verification</button>
            <button class="btn btn-info btn-sm"  (click)="openSingleImageModal(element.id)">ID Verification</button>
            <button class="btn btn-info btn-sm"  (click)="offerLetter()">Generate offer Letter</button>
            <!-- <button class="btn btn-danger btn-sm">Delete</button> -->
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="pt-2"></mat-paginator>
  </div>



<!-- fetch data -->
 <div class="table-responsive" *ngIf="fetchdata">

  
  <div class="mb-3">
    <input class="form-control" [(ngModel)]="searchTerm" placeholder="Search by Requisition ID">
  </div>
  
  
  
  
  <table class="table">
    <thead>
      <tr>
        <th scope="col">
          <input type="checkbox" (click)="toggleAllCheckboxes($event)">
        </th>
        <th scope="col" (click)="sortData('rid')">
          Requisition ID
          <span *ngIf="sortKey === 'rid'">
            <i *ngIf="sortDirection === 'asc'" class="fas fa-arrow-up"></i>
            <i *ngIf="sortDirection === 'desc'" class="fas fa-arrow-down"></i>
          </span>
        </th>
        <th scope="col">JD</th>
        <th scope="col">CV</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of paginatedItems">
        <td>
          <input type="checkbox" [(ngModel)]="item.selected">
        </td>
        <td>{{ item.rid }}</td>
        <td>
          <a [href]="item.jdLink" target="_blank">{{ item.jd }}</a>
        </td>
        <td>
          <span *ngFor="let cv of item.cv; let isLast = last">
            <a [href]="cv.link" target="_blank">{{ cv.name }}</a>{{ isLast ? '' : ', ' }}
          </span>
        </td>
        <td>
          <button class="btn btn-primary" [disabled]="!item.selected" (click)="analyzeFiles(item)">Analyze</button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="cv-jd-comparison-container">
    <h2>CV-JD Comparison Results</h2>
  
    <div *ngFor="let result of comparisonResults" class="comparison-section">
      <h3>File Name: {{ result.fileName }}</h3>
      <h3>Overall Matching Score</h3>
      <div class="progress">
        <div
          class="progress-bar"
          [ngClass]="getProgressBarClass(result.overallScore)"
          [style.width.%]="result.overallScore"
          attr.aria-valuenow="{{ result.overallScore }}"
          aria-valuemin="0"
          aria-valuemax="100">
          {{ result.overallScore }}%
        </div>
      </div>
  
      <h3>Education Analysis</h3>
      <p [innerHTML]="result.educationMatch.details"></p>
  
      <h3>Experience Analysis</h3>
      <p [innerHTML]="result.experienceMatch.details"></p>
  
      <h3>Skills Analysis</h3>
      <p [innerHTML]="result.skillsMatch.details"></p>
    </div>
  </div>
  
  <div *ngIf="isLoading" class="loading-spinner text-center">
    <p>Loading...<div *ngIf="isLoading" class="loader"></div>
  </div>
  
  <div class="pagination-container d-flex align-items-center justify-content-center mb-3">
    <label for="itemsPerPage" class="me-2">Items per page:</label>
    <select id="itemsPerPage" class="form-select me-3" (change)="onItemsPerPageChange($event)">
      <option *ngFor="let option of [5,10,15,20]" [value]="option">{{ option }}</option>
    </select>
  
    <!-- Pagination Controls -->
    <div class="pagination-controls d-flex align-items-center">
      <button class="btn btn-outline-secondary me-2" [disabled]="currentPage === 1" (click)="previousPage()">
        <i class="fas fa-angle-double-left"></i>
      </button>
      <span class="me-2"> {{ currentPage }} of {{ totalPages }} </span>
      <button class="btn btn-outline-secondary" [disabled]="currentPage === totalPages" (click)="nextPage()">
        <i class="fas fa-angle-double-right"></i>
      </button>
    </div>
  </div>
  
  <div style="display: flex; justify-content: center; align-items: center;">
    <button type="button" style="background-color: #0dcaf0;" class="btn btn-secondary" data-bs-dismiss="modal" (click)="onSave()">Save</button>
</div>

 </div>


</div>

<!-- Image Upload modal Photo verification-->

<div class="modal-overlay" *ngIf="isModalOpen">
  <div class="modal-content">
    <span class="close-button" (click)="closeModal()">×</span>
    
    <div class="image-upload-container">
      <!-- Left side image upload -->
      <div class="upload-section">
        <h3>Upload Passport Size Photo</h3>
        <input type="file" accept="image/png, image/jpeg" (change)="onfileSelected($event, 'left')">
        <img *ngIf="leftImageUrl" [src]="leftImageUrl" alt="Uploaded Left Image">
      </div>

      <!-- Right side image upload -->
      <div class="upload-section">
        <h3>Upload Screenshot</h3>
        <input type="file" accept="image/png, image/jpeg" (change)="onfileSelected($event, 'right')">
        <img *ngIf="rightImageUrl" [src]="rightImageUrl" alt="Uploaded Right Image">
      </div>

      <button class="btn btn-primary" style="display: block;" *ngIf="!verificationResponse" [disabled]="!rightImageUrl || !leftImageUrl" (click)="imgverify()">Verify Image</button>
    </div>

    
    <!-- Display the verification response -->
    <div *ngIf="verificationResponse" class="verification-result">
      <h3>Verification Result</h3>
      <div class="result-box">
        <p class="status" [ngClass]="{'success': verificationResponse.status === 'success', 'error': verificationResponse.status !== 'success'}">
          {{ verificationResponse.data }}
        </p>
        <p class="message">{{ verificationResponse.message }}</p>
        <p class="confidence">Confidence Level: <strong>{{ extractConfidence(verificationResponse.data) }}</strong></p>
      </div>
    </div>

    <!-- Error message -->
    <div *ngIf="errorMessage" class="error-message">
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</div>


<!-- Verify Id modal Photo verification-->
<!-- Second Modal -->
<div class="modal-overlay" *ngIf="isSingleImageModalOpen">
  <div class="modal-content">
    <span class="close-button" (click)="closeSingleImageModal()">×</span>
    <div class="single-image-upload-container">
      <div class="upload-section">
        <h3>Upload Image</h3>
        <input type="file" accept="image/png, image/jpeg" (change)="onSingleFileSelected($event)">
        <img *ngIf="singleImageUrl" [src]="singleImageUrl" alt="Uploaded Image">
        <button class="btn btn-primary" [disabled]="!singleImageUrl" (click)="verifyImage()">Verify</button>
      </div>
    </div>

    <!-- Verification Result Section -->
    <div *ngIf="verificationResult" class="verification-result">
      <h3>Verification Result</h3>
      <p>Status: {{ verificationResult.status }}</p>
      <p>Message: {{ verificationResult.message }}</p>
      <div *ngIf="verificationResult.data">
        <h4>Details:</h4>
        <p>Address Verified: {{ verificationResult.data.address_verify }}</p>
        <p>DOB Verified: {{ verificationResult.data.dob_verify }}</p>
        <p>Mobile Verified: {{ verificationResult.data.mobile_verify }}</p>
        <p>Name Verified: {{ verificationResult.data.name_verify }}</p>
        <p>Feedback: {{ verificationResult.data.feedback }}</p>
      </div>
    </div>
  </div>
</div>


<!-- add user modal -->
<!-- Modal -->
<div class="modal fade" id="Newuser" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Enter the Details</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3 row d-flex align-items-center">
          <label for="staticEmail" class="col-sm-2 col-form-label">ID</label>
          <div class="col-sm-10">
            {{tabid}}
          </div>
        </div>
        <div class="mb-3 row">
          <label for="staticEmail" class="col-sm-2 col-form-label">Requisition ID</label>
          <div class="col-sm-10">
            <input [(ngModel)]="reqid" type="text" class="form-control" id="ID" >
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputPassword" class="col-sm-2 col-form-label">Position Name</label>
          <div class="col-sm-10">
            <input [(ngModel)]="position" type="text" class="form-control" id="position">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="staticEmail" class="col-sm-2 col-form-label">Candidate Name</label>
          <div class="col-sm-10">
            <input [(ngModel)]="candidatename" type="text"  class="form-control" id="Candidate Name" >
          </div>
        </div>
        <div class="mb-3 row">
          <label for="staticEmail" class="col-sm-2 col-form-label">Interviewer Name</label>
          <div class="col-sm-10">
            <input [(ngModel)]="interviewername" type="text"  class="form-control" id="Interviewer Name" >
          </div>
        </div>
        <div class="mb-3 row">
          <label for="staticEmail" class="col-sm-2 col-form-label">upload CV/JD</label>
          <div class="col-sm-10">
            <div class="col-md-6">
              <label for="cv-upload" class="btn btn-primary btn-block cvjd">Add CV</label>
              <input id="cv-upload" type="file" class="d-none" (change)="onFileSelected($event, 'CV')">
            </div>

            <!-- Upload JD Button -->
            <div class="col-md-6">
              <label for="jd-upload" class="btn btn-primary btn-block cvjd">Add JD</label>
              <input id="jd-upload" type="file" class="d-none" (change)="onFileSelected($event, 'JD')">
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fileDetail of displayedFiles(); let i = index">
                  <td>{{ fileDetail.file.name }}</td>
                  <td>{{ fileDetail.type }}</td>

                  <td>
                    <button class="btn btn-danger btn-sm" (click)="removeFile(i)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>





      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="createuser()">Save changes</button>
      </div>
    </div>
  </div>
</div>

<!-- cv jd modal -->
<!-- Modal -->
<!-- Button trigger modal -->
<!-- <button type="button" id="cvjd" style="display: none;" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button> -->

<!-- Modal -->
<div class="modal fade" id="cvjd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content" style="width:550px">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">CV JD Upload</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                <input type="checkbox" (click)="toggleAllCheckboxes($event)">
              </th>
              <th scope="col">Requisition ID</th>
              <th scope="col">JD</th>
              <th scope="col">CV</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of items">
              <td>
                <input (change)="logfun(item.rid,item.selected)" type="checkbox" [(ngModel)]="item.selected">
              </td>
              <td>{{ item.rid }}</td>
              <td>
                <a style="text-decoration: none;" href="{{ item.jdLink }}" target="_blank">{{ item.jd }}</a>
              </td>
              <td>
                <span *ngFor="let cv of item.cv; let isLast = last">
                  <a style="text-decoration: none;" href="{{ cv.link }}" target="_blank">{{ cv.name }}</a>{{ isLast ? '' : ', ' }}
                </span>
              </td>
              <td>
                <button class="btn btn-primary d-flex" [disabled]="!item.selected"  (click)="analyzeFiles(item)">Analyze
                  <div *ngIf="isLoading && item.selected " class="loader"></div>

                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="onSave()">Save</button>
      </div>
    </div>
  </div>
  
  
</div>

<button id="cvsumbtn" type="button" style="display:none;" class="btn btn-primary" (click)="openModal(sum)">Open CV Summary Modal</button>

<ng-template #sum>
  <div class="modal-header">
    <h5 class="modal-title">Analysis</h5>
    <button type="button" class="close" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div  markdown lineNumbers>{{refsummary}}</div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modalRef?.hide()">Close</button>
  </div>


</ng-template>