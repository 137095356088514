import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '../../service/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit, OnDestroy {
  loading= false;
  sub!:Subscription;

  constructor(private loaderservice:LoaderService){

  }

  ngOnInit(): void {
    this.sub = this.loaderservice.celebrate.subscribe((res:any)=>{
      this.loading = res;
      setTimeout(() => {
        this.loading= false;
      }, 1400);
    })
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }
}
