import { Component } from '@angular/core';
import { AiQuizComponent } from './ai-quiz/ai-quiz.component';
import { AvatarDisplayComponent } from './avatar-display/avatar-display.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ImageFloaterComponent } from './image-floater/image-floater.component';
import { HeaderComponent } from './header/header.component';
import { MultiFormComponent } from './multi-form/multi-form.component';



@Component({
  selector: 'app-digital-avatar',
  templateUrl: './digital-avatar.component.html',
  standalone:true,
  imports:[ImageUploadComponent,AvatarDisplayComponent,ImageFloaterComponent,AiQuizComponent,HeaderComponent,MultiFormComponent],
  styleUrls: ['./digital-avatar.component.scss']
})
export class DigitalAvatarComponent {
  generatedAvatarUrl: string = ''; 
  successMessage: string = "Avatar is Ready!! Enjoy DAG'innn..."; 
  title = 'digital-avatar-generator';

  // Function to handle the emitted avatar URL from ImageUploadComponent
  handleAvatarGenerated(avatarUrl: string) {
    this.generatedAvatarUrl = avatarUrl; 
  }

}
