import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Component,OnInit, OnDestroy } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { DigiService } from 'src/app/digi.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { EventEmitter } from '@angular/core';
import { AvatarDisplayComponent } from 'src/app/digital-avatar/avatar-display/avatar-display.component';
import { Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { MultiFormComponent } from "../../digital-avatar/multi-form/multi-form.component";
import { Router } from '@angular/router';
import { AiQuizComponent } from "../../digital-avatar/ai-quiz/ai-quiz.component";
import { LoaderComponent } from 'src/app/digital-avatar/loader/loader.component';
import { PersonalityTextComponent } from 'src/app/digital-avatar/personality-text/personality-text.component';

@Component({
  selector: 'app-generator',
  templateUrl: './generator.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, MatCardModule, MatGridListModule, AvatarDisplayComponent, AiQuizComponent,LoaderComponent,PersonalityTextComponent],
  styleUrls: ['./generator.component.scss']
})
export class GeneratorComponent implements OnInit, OnDestroy {

  constructor(private route: ActivatedRoute,private router: Router,private loaderService: LoaderService,private digiservice:DigiService) {}

  @Input() userPrompt: string = '';
  isQuiz: boolean = false;
  progress: number = 0;

  payload_submit ={
    "questions": [
      {
        "id": 2,
        "question": "Which AI platform is owned by Google?"
      },
      {
        "id": 19,
        "question": "What is OpenAI’s primary monetization strategy for its AI products?"
      },
      {
        "id": 1,
        "question": "NVIDIA is best known for its contributions to which technology?"
      },
      {
        "id": 10,
        "question": "Which AI framework is open-source and backed by Meta?"
      },
      {
        "id": 15,
        "question": "Which AI company is focused on building safer AI with models like Claude?"
      }
    ],
    "responses": ["B", "C", "A", "B", "D"]
  }
  
  loading = false;
  public text_gen: boolean = false;
  uploadedImage: string | ArrayBuffer | null = null;
  imageFile: File | null = null;

  horoscope: string = '';
  score: number = 0;
  parsedHoroscope: any = {};

  generatedImages: string[] = [
    'assets/avatar-craft/generated_venky_latest.png',
    'assets/avatar-craft/generated_aditi_27.png',
    'assets/avatar-craft/generated_vinoth_27.png',
    'assets/digital-avatar/generated_hafeez.png',
    'assets/digital-avatar/generated_monalisa.png',
    'assets/digital-avatar/generated_rahman.png',
    'assets/avatar-craft/generated_ananya_27.png',
    'assets/avatar-craft/generated_atul_27.png'
  
  ]; 

  generatedNames = [
    'Vengatesh',
    'Aditi',
    'Vinoth',
    'Hafeez',
    'Monalisa',
    'Rahman',
    'Ananya',
    'Atul Raj'

    // Add names corresponding to the images
  ];

  activeTab: string = 'explore';
  selectedButton: string = 'generate';
  generatedResponse: string = '';
  public quizCompleted: boolean = false;
  generatedAvatarUrl: string = ''; // To store the generated avatar URL
  avatarGenerated = new EventEmitter<string>();
  usePromptGen: boolean = false;
  questions:string[]=[];
  quizResponses: string = 'abcba'; 
  responses: string[] = this.quizResponses.split('').map(char => char.toUpperCase());
  personalityTraits: string = '';

  gotohome(): void {
    this.router.navigate(['/prompt-gen/avatar-kraft/'], {
      queryParams: { prompt: this.userPrompt },
    });
    console.log('Navigate with prompt:', this.userPrompt);
  }

  // Handle tab switching in the top navigation
  setActiveTab(tabName: string) {
    this.activeTab = tabName;
  }

  onQuizCompleted(completed: boolean): void {
    this.quizCompleted = completed;
  }

  nextStep(): void {


    // Prepare the request payload as a JSON object
    const payload = {
      questions: this.questions,
      responses: this.responses, // Ensure this.responses is an array like ["A", "B", "C", "D", "A"]
      
    };
  
    console.log('Request Payload:', payload);
  
    this.loading = true;
    this.loaderService.loading.next(true);
  
    // Send the JSON payload to the API
    this.digiservice.gethoroscope(payload).subscribe(
      (response: any) => {
        this.personalityTraits = response.horoscope; 
        console.log(this.personalityTraits);// Save the score and horoscope from the response
        this.digiservice.updatePersonalityTraits(this.personalityTraits); 
        this.score = response.score;
        this.horoscope = response.horoscope;
        this.progress = this.score * 20;
        this.text_gen=true;

    // Parse the horoscope text
    this.parsedHoroscope = this.parseHoroscope(this.horoscope);// Update traits in the shared service
        this.loading = false;
        this.loaderService.loading.next(false);
      },
      (error) => {
        console.error('Error fetching personality traits:', error);
        this.loading = false;
        this.loaderService.loading.next(false);
      }
    );
  }

  parseHoroscope(horoscopeText: string): string {
    let parsedText = horoscopeText
      .replace(/### (.*?)/g, '<h3 class="title">$1</h3>')  // Main title as h3
      .replace(/#### (.*?)/g, '<h4 class="subheading">$1</h4>') // Subheading as h4
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')  // Bold text
      .replace(/- (.*?)\n/g, '<ul><li>$1</li></ul>');  // Bullet points as unordered list items

    parsedText = parsedText.replace(/\n/g, '<br/>');  // Replace newlines with <br>

    return parsedText;
  }
  
  

  // Handle sidebar button selection
  selectButton(buttonName: string) {
    this.selectedButton = buttonName;
    if (buttonName==='quiz'){
      this.isQuiz=true;
    }
    else if (buttonName==='generate'){
      this.isQuiz=false;
    } else if(buttonName==='capture'){
      this.router.navigate(['/prompt-gen/avatar-kraft/capture'], {
        queryParams: { prompt: this.userPrompt },
      }); 
    }
  }

  // Function to handle file selection
  onFileSelect(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput?.files?.length) {
      this.imageFile = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = () => (this.uploadedImage = reader.result);
      reader.readAsDataURL(this.imageFile);
    }
  }

  // Function to handle image drop
  onDrop(event: DragEvent): void {
    event.preventDefault();
  
    // Get the dropped file
    const file = event.dataTransfer?.files[0];
    if (file) {
      this.imageFile = file; // Store the file for further processing
  
      // Read the file to display a preview
      const reader = new FileReader();
      reader.onload = () => {
        this.uploadedImage = reader.result; // Update preview image
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please drop a valid image file.');
    }
  }
  
  // Generate image based on prompt and uploaded image getPrompt
  generateImage(): void {
    if (!this.imageFile) {
      alert('Please upload an image first.');
      return;
    }
  
    this.loading = true; // Start loader
    this.loaderService.loading.next(true);
  
    const formData = new FormData();
    formData.append('image', this.imageFile);
  
    this.digiservice.getDigiAvatar(formData).subscribe(
      (response) => {
        if (response) {
          const dallePrompt = response;
  
          if (this.usePromptGen) {
            const payload = {
              'dag-input': dallePrompt,
              'user-input': this.userPrompt,
            };
  
            this.digiservice.getPrompt(payload).subscribe(
              (promptResponse) => {
                if (promptResponse) {
                  this.generatedResponse = promptResponse.data;
                  this.generateFinalAvatar(promptResponse.data);
                }
              },
              (error) => {
                console.error('Error in prompt combination:', error);
                this.loading = false;
                this.loaderService.loading.next(false);
              }
            );
          } else {
            this.generateFinalAvatar(dallePrompt + this.userPrompt);
          }
        }
      },
      (error) => {
        console.error('Error in initial prompt generation:', error);
        this.loading = false;
        this.loaderService.loading.next(false);
      }
    );
  }
  

  // Generate the final avatar
  private generateFinalAvatar(finalPrompt: string): void {
    const finalPayload = new FormData();
    finalPayload.append('dalle_prompt', finalPrompt);
  
    this.digiservice.getAvatar(finalPayload).subscribe(
      (imageResponse) => {
        if (imageResponse) {
          this.generatedAvatarUrl = imageResponse;
          this.avatarGenerated.emit(this.generatedAvatarUrl);
        }
        this.loading = false; // Stop loader when done
        this.loaderService.loading.next(false);
      },
      (error) => {
        console.error('Error in final image generation:', error);
        this.loading = false;
        this.loaderService.loading.next(false);
      }
    );
  }
  
  


  aiFacts: string[] = [
    "AI can analyze massive amounts of data in seconds.",
    "The term 'Artificial Intelligence' was coined in 1956.",
    "AI is widely used in healthcare for diagnostics.",
    "Self-driving cars heavily rely on AI technology.",
    "AI chatbots can simulate human conversations."
  ];
  currentFactIndex: number = 0;
  private intervalId: any; // To store the interval ID for cleanup

  ngOnInit() {
    // Start auto-changing facts every 2 seconds
    this.intervalId = setInterval(() => {
      this.nextFact();
    }, 2000);

    this.route.queryParams.subscribe(params => {
      this.userPrompt = params['prompt'] || ''; // Retrieve prompt from query params
      console.log('Received prompt:', this.userPrompt);
    });

    this.digiservice.getquizquestions().subscribe(
      (response: any) => {
        this.questions=response.questions;
        console.log(response);
      },
      (error) => {
        console.error('Error fetching questions:', error);
      }
    );
  
  }

  ngOnDestroy() {
    // Clear interval to prevent memory leaks when component is destroyed
    clearInterval(this.intervalId);
  }

  factImages: string[] = [
    "assets/avatar-craft/ai.jpg",
    "assets/avatar-craft/brain.jpg",         
    "assets/avatar-craft/health.jpg",     
    "assets/avatar-craft/Av.jpg",
    "assets/avatar-craft/human.jpg"   
  ];
  
  
  nextFact() {
    this.currentFactIndex = (this.currentFactIndex + 1) % this.aiFacts.length;
  }
  
  prevFact() {
    this.currentFactIndex =
      (this.currentFactIndex - 1 + this.aiFacts.length) % this.aiFacts.length;
  }

  

}
