import { Component, Input, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
import { TranslateService } from 'src/app/shared/service/translate.service';
import { SystranService } from 'src/app/shared/service/systran.service';
import * as Papa from 'papaparse';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-file-translate',
  templateUrl: './file-translate.component.html',
  styleUrls: ['./file-translate.component.scss']
})
export class FileTranslateComponent implements OnInit{
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,  // or false if you want multiple selections
    idField: 'id',          // 'id' is the unique identifier in your options array
    textField: 'name',      // 'name' is the field used for display text
    itemsShowLimit: 3,
    maxHeight: 160,
    allowSearchFilter: true
  };
  fileName: any;
  source = [
    { id: 'Auto-detect', name: 'Auto-detect' },
    { id: 'en', name: 'English' },
    { id: 'nl', name: 'Dutch' },
    { id: 'fr', name: 'French' }
  ];

  target = [
    { id: 'en', name: 'English' },
    { id: 'nl', name: 'Dutch' },
    { id: 'fr', name: 'French' }
  ];
  TargetLanguage: any;
  sourceLanguage: any;
  isButtonDisabled: boolean = true;
  input:any;
  output:any;
  vocabulary:any;
  uploadedFiles: any[] = [];
  isImageFormat = false;
  acceptedFormats = '.txt,.xlsx,.xls,.pdf,.PDF,.pptx,.PPTX,.ppt,.pptx,.doc,.DOC,.docx,.DOCX';
  glossaryStatus = false;
  tableData: any = [{ currentText: '', translatedText: '' }];
  glossaryFileUploadedStatus = false;
  userOHR: any;
  Images:File[]=[];
  glossaryFileData: any;
  targetSASUrl: any;
  intervalId: any;
  processStatus: boolean = false;
  downloadStatus: boolean=false;
  fileData: any;
  uploadbtn:boolean=true;
  translatebtn:boolean=false;


  constructor(
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private toastMessage:ToastMessageService,
    private systranService:SystranService
  ) { }

  removeFile(fileToRemove: any): void {
    this.uploadedFiles = this.uploadedFiles.filter(file => file !== fileToRemove);
    if (this.uploadedFiles.length === 0) {
        this.uploadbtn=true;
        this.translatebtn=false;
    }
}
// storeLogs(){
//   const payload = {
//     'event_type' : 'AON',
//     'event_data' : {
//       "type":"File_translation",
//       'target_language' : this.TargetLanguage[0].id,
//     }
//   }
//   this.systranService.logsStorage(payload).subscribe((res)=>{
//     // console.log(res);
//   })
// }
  ngOnInit(): void {
    // Auto-select "Auto-detect" option
    this.sourceLanguage = [{ id: 'Auto-detect', name: 'Auto-detect' }];
    console.log('format:'+ this.acceptedFormats);
    this.getOHR();
  //  this.startInterval();
  }
  startInterval(): void {
    if(localStorage.getItem('f_tarnslate')){
    this.getTranslateStatus();

    this.intervalId = setInterval(() => {
      // Your function to be called every 15 seconds
      console.log('interval call')
      this.getTranslateStatus();

    }, 15000); // 15 seconds in milliseconds

  }
}
 // check translation status

 getTranslateStatus() {
  let translateData = null;
  this.processStatus = true;
  if(localStorage.getItem('f_tarnslate')){
  this.loaderService.loading.next(true);

   translateData = JSON.parse( localStorage.getItem('f_tarnslate') || '');

   this.fileData = {name:translateData?.file_name};
   this.targetSASUrl = translateData?.target_blob_url || '';


  const formData = new FormData();
  formData.append('translation_id', translateData?.translation_id);


  this.translateService.translateStatus(formData)
    .subscribe(
      (res: any) => {
        if( res?.status == 'Succeeded'){

          this.stopInterval();
          this.loaderService.loading.next(false);
          this.downloadStatus = true;
          this.toastMessage.showSuccess(res?.message);
        }else{
          if(res?.status == 'NotStarted' || res?.status == 'Running'){
            this.toastMessage.showWarning(res?.message);
            // do nothing
          }else{
            this.stopInterval();
            this.resetFile();
            this.loaderService.loading.next(false);
            this.toastMessage.showError(res?.message);
          }
        }

      },
      (error) => {
        // Handle the error here
        this.stopInterval();

        this.loaderService.loading.next(false);
        console.error(error);
        this.toastMessage.showError(error?.message);
      }
    );


  }
}
stopInterval() {
  this.processStatus = false;
  if (this.intervalId) {
    clearInterval(this.intervalId);
    this.intervalId = null;
  }
}
resetFile() {
//clear all uploaded files
this.uploadedFiles = [];



  this.downloadStatus = false;

  this.targetSASUrl = '';

  localStorage.removeItem('f_tarnslate');
}
  getOHR() {
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      if (ohr_id) {
        this.userOHR = ohr_id;
        console.log('OHR:', this.userOHR);
      }
    }
  }
  updateAcceptedFormats(): void {
    this.acceptedFormats = this.isImageFormat ? '.png,.jpeg,.jpg,.PNG,.tif,.tiff' : '.txt,.xlsx,.xls,.pdf,.PDF,.pptx,.PPTX,.ppt,.pptx,.doc,.DOC,.docx,.DOCX';
}
  onDropdownChange() {
    // Call this function whenever your dropdown values change
    // Update the button state
   // console.log('this works');
    this.updateButtonState();
  }

  private updateButtonState() {
    // Adjust the condition based on your requirements
    this.isButtonDisabled = !this.sourceLanguage.length || !this.TargetLanguage.length ;
  }
translate(){

}
onFileSelected(event: any): void {
  const files = event.target.files;
  this.Images = Array.from(event.target.files);
  if (files) {
      for (let i = 0; i < files.length; i++) {
          const file = files[i];
            this.fileName = file.name;
          // Check if file size exceeds the limit (16MB)
          if (file.size > 16 * 1024 * 1024) {
              // File size exceeds the limit, show an alert
              alert("File size exceeds the limit of 16MB.");
              continue; // Skip processing this file and continue with the next one
          }

          // Store file details
          else{
          this.uploadedFiles.push({
              name: file.name,
              size: this.convertBytesToKB(file.size), // Convert size to KB
              uploadTime: new Date().toLocaleString(),
              language: this.TargetLanguage[0].name
          });

      // Upload files using service
      this.isImageFormat ? this.uploadFilesToService(files) : this.uploadFileToService(files[0]);
  }
}
}
}


convertBytesToKB(bytes: number): number {
  return bytes / 1024;
}
uploadFilesToService(files: File[]): void {
  // this.storeLogs();
 this.loaderService.loading.next(true);
  const formData: FormData = new FormData();
  for (let i = 0; i < files.length; i++) {
      formData.append('images', files[i], files[i].name); // Append files with the same key 'files'
  }
formData.append('user_id', this.userOHR);
formData.append('glossary_status', this.glossaryStatus.toString());
  // Call service to upload files
  this.translateService.translateimageUpload(formData)
      .subscribe(
          (response:any) => {
        if(response?.status == 'Success'){
              // Handle response if needed
              this.loaderService.loading.next(false);
              this.toastMessage.showSuccess(response?.message);
              console.log('Files uploaded successfully:', response);
              this.uploadbtn=false;
              this.translatebtn=true;
        }
        else{
          this.toastMessage.showError(response?.message);
          this.loaderService.loading.next(false);
        }
          },
          error => {
              // Handle error
              this.loaderService.loading.next(false);
              this.toastMessage.showError(error?.message);
              console.error('Error uploading files:', error);
          }
      );
}
uploadFileToService(file: File): void {
  // this.storeLogs();
 // this.toastMessage.showmessage('File translation is not supported yet');
 this.loaderService.loading.next(true);
  const formData: FormData = new FormData();
  formData.append('file', file, file.name);
  formData.append('user_id', this.userOHR);
  formData.append('glossary_status', this.glossaryStatus.toString());

  // Call service to upload file
  this.translateService.translateUpload(formData)
      .subscribe(
          (response:any) => {
              // Handle response if needed
              if(response?.status == 'Success'){
              this.loaderService.loading.next(false);
              this.toastMessage.showmessage(response.message);
              console.log('File uploaded successfully:', response);
              this.uploadbtn=false;
              this.translatebtn=true;
              }else{
                this.loaderService.loading.next(false);
                this.toastMessage.showError(response?.message);
              }
          },
          error => {
              // Handle error
              this.loaderService.loading.next(false);
              console.error('Error uploading file:', error);
          }
      );
}
// glossary add and remove
addGlossaryEntry() {
  this.tableData.push({ currentText: '', translatedText: '' });
}
removeGlossaryEntry(index: number) {
  this.tableData.splice(index, 1);
}
glossaryFileUpload() {
  this.loaderService.loading.next(true);

  // Convert glossary data to CSV
  const csvData = Papa.unparse(this.tableData);

  const blob = new Blob([csvData], { type: 'text/csv' });
  const formData = new FormData();
  formData.append('file', blob, 'glossary.csv');
  formData.append('user_id', this.userOHR);
  formData.append('glossary_status', this.glossaryStatus.toString());

  this.translateService.translateUploadGlossary(formData).subscribe(
    (res: any) => {
      this.loaderService.loading.next(false);

      if (res?.status == 'Success') {
        this.glossaryFileUploadedStatus = true;
        this.toastMessage.showSuccess(res?.message);
      } else {
        this.toastMessage.showError("Error uploading the glossary file. Please try again.");
      }
    },
    (error) => {
      this.loaderService.loading.next(false);
      console.error(error);
      this.toastMessage.showError(error?.message);
    }
  );
}
translateContent() {
  // this.storeLogs();
  if(this.glossaryStatus){
    this.glossaryFileUpload()
  }

      this.loaderService.loading.next(true);
      this.getOHR();

      // Adding the data to the tableData array
      // this.tableData.push({ currentText: this.currentText, translatedText: this.translatedText });

      // const json = JSON.stringify(this.tableData);

      const formData = new FormData();
      if(this.isImageFormat)
      {
        formData.append('filename', this.userOHR + '.' + 'pdf');
      }
      else{
        for(const file of this.Images){
        formData.append('filename', file.name);
        }
      }

      formData.append('glossary_filename', 'glossary.csv');
      formData.append('user_id', this.userOHR);
      formData.append('target_lang', this.TargetLanguage[0].id);
      formData.append('glossary_status', this.glossaryStatus.toString());
      // add this    source_language: this.sourceLanguage[0].id =='Auto-detect' ? '' : this.sourceLanguage[0].id, to formdata
      formData.append('source_lang', this.sourceLanguage[0].id =='Auto-detect' ? '' : this.sourceLanguage[0].id);
      //formData.append('file', new Blob([json], { type: 'application/json' }));



      this.translateService.translateDownload1(formData)
        .subscribe(
          (res: any) => {
            this.loaderService.loading.next(false);

            if( res?.status == 'Success' && res?.data?.target_blob_url){
              this.targetSASUrl = res?.data?.target_blob_url || '';
               this.downloadStatus = true;
              this.toastMessage.showSuccess(res?.message);

              //set to localStorage
              let translateData = res?.data;
              translateData['file_name'] = this.fileData?.name;
              localStorage.setItem('f_tarnslate',JSON.stringify(translateData));

              let translateglossaryData = res?.data;
              translateglossaryData['glossary_file_name'] = this.glossaryFileData?.name;
              localStorage.setItem('glossary_tarnslate',JSON.stringify(translateglossaryData));

              this.startInterval();

            }else{
              this.toastMessage.showError(res?.message);
            }

          },
          (error) => {
            // Handle the error here
            this.loaderService.loading.next(false);
            console.error(error);
            this.toastMessage.showError(error?.message);
          }
        );
    }
    downloadaFile() {
      window.location.href = this.targetSASUrl;
      this.loaderService.loading.next(true);

      setTimeout(() => {
        this.loaderService.loading.next(false);
        this.toastMessage.showSuccess('Downloading File.');

        // remove file data from localStorage
        // localStorage.removeItem('f_tarnslate');

            this.uploadbtn=true;
            this.translatebtn=false;

         this.resetFile();
         this.resetglossaryFile();

      }, 2000);
    }
    resetglossaryFile() {
      this.glossaryFileUploadedStatus = false;
      this.glossaryFileData = '';
      this.glossaryStatus = false;
      // this.showUpload = true;
      this.downloadStatus = false;
      this.targetSASUrl = '';
      localStorage.removeItem('glossary_tarnslate');
    }


    downloadFile() {
      this.loaderService.loading.next(true);
       // Determine the file extension based on the content type
       const urlParts = this.targetSASUrl.substring(this.targetSASUrl.indexOf('/', 1) + 1, this.targetSASUrl.lastIndexOf('.'));
       //       const filename = urlParts[urlParts.length - 1];

            //  let filename = urlParts;

             const start = this.targetSASUrl.indexOf("playground-translate-out/") + "playground-translate-out/".length;
             const end = this.targetSASUrl.indexOf("?", start);
             const extractedPartWithExtension = this.targetSASUrl.substring(start, end);

             // Extract only the name part without the extension
             let filename = extractedPartWithExtension.split("/").pop().split(".")[0];


    const fname = this.isImageFormat ? this.userOHR+this.userOHR + '.' + 'pdf' : this.userOHR+ this.fileName;

      const payload = {
        'url': this.targetSASUrl,
        'filename': fname,
      };

      // if(this.ImageStatus)
      // {

      //   payload.filename= this.userOHR + '.' + 'pdf'
      // }



      this.translateService.translatefileDownload(payload, { responseType: 'blob' }).subscribe(
        (response: Blob) => {
          const contentType = response.type || 'application/octet-stream';


          if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            filename += '.xlsx';
          } else if (contentType.includes('application/pdf')) {
            filename += '.pdf';
          } else if (contentType.includes('application/msword')) {
            filename += '.doc';
          } else if (contentType.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
            filename += '.docx';
          } else if (contentType.includes('text/plain')) {
            filename += '.txt';
          } else if (contentType.includes('image/png')) {
            filename += '.png';
          } // Add more conditions for other file types

          this.toastMessage.showmessage('Downloading file...');

          // Trigger file download
          saveAs(response, filename);

          // Hide loader and show success message
          this.loaderService.loading.next(false);
          this.toastMessage.showSuccess('File downloaded successfully');
          this.resetFile();
          this.resetglossaryFile();
          this.translatedelete(filename);
          this.uploadbtn=true;
          this.translatebtn=false;
        },
        (error: any) => {
          this.loaderService.loading.next(false);
          this.resetFile();
          this.resetglossaryFile();
          console.error('Error downloading file:', error);
          this.toastMessage.showError('Error downloading file');
        }
      );
    }
    translatedelete(filename: any) {
      this.getOHR();
      this.loaderService.loading.next(true);
      // const payload = new FormData();
      // payload.append('filename', this.userOHR+filename);
      const fname = this.isImageFormat ? this.userOHR + '.' + 'pdf' :this.fileName;

      this.translateService.translatedelete( fname,this.userOHR).subscribe(
        (response: any) => {
          this.loaderService.loading.next(false);
        //  this.toastMessage.showSuccess('File Deleted Successfully');
          console.log(response?.response);    },
        (error: any) => {
          this.loaderService.loading.next(false);
          console.error('Error deleting file:', error);
         // this.toastMessage.showError('Error deleting file');
        }
      );
    }

}
