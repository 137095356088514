<div class="history-container">
    <h2>Notebooks</h2>
    <div class="grid-container">
      <!-- New Notebook Card -->
      <div class="notebook-card new-notebook">
        <div class="card-content">
          <span>+</span>
          <a href="/notebooklm">
          <p>New Notebook</p>
        </a>
        </div>
      </div>
  
      <!-- History Cards -->
      <div *ngFor="let item of historyItems" class="notebook-card">
        <div class="card-content">
          <h3>{{ item.title }}</h3>
          <p>{{ item.date }} • {{ item.sourceCount }} sources</p>
        </div>
      </div>
    </div>
  </div>
  
 <!-- history.component.html -->
<div class="audio-player-container">
  <audio #audioPlayer (timeupdate)="updateSlider()" controls>
    <source src="assets/sample-3s.mp3" type="audio/mpeg">
    Your browser does not support the audio element.
  </audio>
  <div class="audio-controls">
    <button (click)="audioPlayer.play()">Play</button>
    <button (click)="audioPlayer.pause()">Pause</button>
    <button (click)="audioPlayer.currentTime = 0">Reset</button>
    <button (click)="changeVolume(0.1)">Increase Volume</button>
    <button (click)="changeVolume(-0.1)">Decrease Volume</button>
  </div>
  <mat-slider 
      min="0" 
      [max]="audioPlayer.duration" 
      step="1" 
      [(ngModel)]="audioPlayer.currentTime" 
      (change)="seekAudio($event)" 
      aria-label="Audio playback time slider"
  ></mat-slider>
  <div class="playback-time-container">
    <span>{{ formatTime(audioPlayer.currentTime) }} / {{ formatTime(audioPlayer.duration) }}</span>
  </div>
</div>

  