import { Component, OnInit } from '@angular/core';
import { LeaderboardService } from '../shared/service/leaderboard.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { LoaderService } from '../shared/service/loader.service';

@Component({
  selector: 'app-pgenleaderboard',
  templateUrl: './pgenleaderboard.component.html',
  styleUrls: ['./pgenleaderboard.component.scss']
})
export class PgenleaderboardComponent implements OnInit { 
  userList: any = [];
  currentUser: any;
  currentMonth: any;
  constructor(
    private leaderboardService: LeaderboardService,
    private toastMessage: ToastMessageService,
    private loaderService: LoaderService,
  ) { }

  ngOnInit(): void {

    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });

    this.currentMonth = month;

    this.getUserRank();
  }

  updateName(name: string) {
    if (name) {

      const [lastName,firstName] = name.split(',').map(str => str.trim());
      return `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
    };
    return name;
  }

getUserRank() {
  this.loaderService.loading.next(true);
  this.leaderboardService.readFeedback().subscribe(
    (res: any) => {
      this.loaderService.loading.next(false);
      if (res?.status == 'success') {
        this.userList = res?.data;

      

      

      } else {
        this.toastMessage.showError(res?.response?.message);
      }

    },
    (error) => {
      // Handle the error here
      this.loaderService.loading.next(false);
      this.toastMessage.showError(error?.error?.error_message);
    }
  );
}



}

