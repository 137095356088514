<nav class="navbar navbar-expand-lg navbar-dark bg-dark shadow"
  style=" background-image: url('../../assets/newicons/topbar_new.png');     background-position-y: center;">
  <a class="navbar-brand ml-md-3" href="javascript:;">
    <img class="img-logo img-fluid" [routerLink]="['/home-openai']" src="assets/images/glogo.png" />

    <h3 *ngIf="stat" class="display-12  mt-md-2" style="color:#00aecf;">Gen AI Playground</h3>
  </a>


  <button style="border-radius:0px;" class="navbar-toggler" type="button" data-toggle="collapse"
    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav  mr-auto mt-md-5 mr-md-5">


      <li *ngIf="stat" class="nav-item ">
        <a class="nav-link" [routerLink]="['/home-openai']" routerLinkActive="active">Home <span
            class="sr-only">(current)</span></a>
      </li>
      <li *ngIf="!stat" class="nav-item ">
        <a class="nav-link" [routerLink]="['/prompt-gen']" routerLinkActive="active">Home <span
            class="sr-only">(current)</span></a>
      </li>
      <li *ngIf="!stat" class="nav-item ">
        <a class="nav-link" [routerLink]="['/prompt-gen//imgview']" routerLinkActive="active">Copilot<span
            class="sr-only">(current)</span></a>
      </li>
      <li *ngIf="!stat" class="nav-item ">
        <a class="nav-link" [routerLink]="['/prompt-gen//dalle']" routerLinkActive="active">Dall.E<span
          class="sr-only">(current)</span></a>
      </li>
      <li *ngIf="!stat" class="nav-item ">
        <a class="nav-link" [routerLink]="['/prompt-gen//DAG']" routerLinkActive="active">DAG<span
          class="sr-only">(current)</span></a>
      </li>

      <li *ngIf="stat" class="nav-item ">
        <a class="nav-link" [routerLink]="['/leaderboard']" routerLinkActive="active">Leaderboard <span
            class="sr-only">(current)</span></a>
      </li>
      <li *ngIf="!stat" class="nav-item ">
        <a class="nav-link" [routerLink]="['/prompt-gen/leaderboard']" routerLinkActive="active">Leaderboard <span
            class="sr-only">(current)</span></a>
      </li>

      <li  *ngIf="stat" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="javascript:;" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Playground
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" [routerLink]="['/chat-completion']" routerLinkActive="active">ChatGPT Playground</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/playground']" routerLinkActive="active">Playground Library</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/byod']" routerLinkActive="active">Bring your own Files</a>
        </div>
      </li>
      <li  *ngIf="stat" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Resources
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">



          <a class="dropdown-item" target="_blank"
            href="https://prodapps.genpact.com/RSAarcher/default.aspx?requestUrl=../Foundation/workspaceDashboard.aspx?workspaceId%3d4">
            ChatGPT Policy</a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item" target="_blank"
            href="https://genpactonline.sharepoint.com/sites/IT/Documents/Policies/Open%20AI%20policy/Azure%20openai%20IT%20enablement-%20v3.pdf">ChatGPT
            Enablement</a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item" target="_blank"
            href="https://genpactindprod.service-now.com/sp?id=sc_cat_item&sys_id=5107df6b47c7251823a95ffbd36d43a9">
            HelpMate</a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item" target="_blank" href="https://genome.genpact.com/my-plan">Genome</a>

          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/success-stories']" routerLinkActive="active">Success Stories</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="downloadFile()">Zoom background</a>

        </div>
      </li>

    </ul>
    <form class="form-inline my-2 my-lg-0">

      <button (click)="logout()" class="btn btn-outline-success my-2 my-sm-0 desktop-logout" type="submit">Logout <img
          src="assets/images/logouticon.png" alt="icon" /></button>
    </form>

  </div>
</nav>