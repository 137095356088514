import { Component, ViewChild, } from '@angular/core';
import { QuizComponent } from './quiz/quiz.component';
import { ResultComponent } from './result/result.component';
import { CommonModule } from '@angular/common';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ai-quiz',
  standalone:true,
  imports:[QuizComponent,ResultComponent,CommonModule],
  templateUrl: './ai-quiz.component.html',
  styleUrls: ['./ai-quiz.component.scss']
})
export class AiQuizComponent {

  public showMainMenu = true;
  public showQuizScreen = false;
  public showResultScreen = false;
  public finalResponse: string = '';
  @Output() quizCompleted = new EventEmitter<boolean>();

  startQuiz(): void {
    this.showMainMenu = false;
    this.showQuizScreen = true;
  }

  finalResult(response: string): void {
    this.finalResponse = response;
    this.quizCompleted.emit(true);
  }
}
