
<section class="content">
  <div class="container-fluid responsive p-0" >
    <div class="">
      <div class="row ">
          <div class="col-12" style="margin:0; padding-left: 85px;" >
<div>
<div class="block-dropdown d-grid  d-md-flex content1">
<div class="col-item pl-2">
    <ng-multiselect-dropdown [placeholder]="'source language'" [settings]="dropdownSettings" [data]="source" [(ngModel)]="sourceLanguage" (ngModelChange)="onDropdownChange()">
    </ng-multiselect-dropdown>
</div>
<button class="btn btn-primary"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5"/>
</svg></button>
<div class="col-item">
    <ng-multiselect-dropdown [placeholder]="'Target language'" [settings]="dropdownSettings" [data]="target" [(ngModel)]="TargetLanguage" (ngModelChange)="onDropdownChange()">
    </ng-multiselect-dropdown>
</div>


   <!-- Empty flex item to push the button to the right -->

<div class=""> <!-- Translate button div -->
    <div class="text-right" style="padding-right: 10px;">
      <div class="text-center" *ngIf="uploadbtn">
        <input type="file" (change)="onFileSelected($event)" #fileInput style="display: none; height:40px;" [accept]="acceptedFormats" [multiple]="isImageFormat ? null : null">
        <button tooltip="Upload files to translate" [disabled]="isButtonDisabled" style="border:none; height:40px;" (click)="fileInput.click()" type="button" id="yourButton" class="btn btn-primary px-2 py-1 btn-block">
            <span class="button-text">Upload File to translate</span>
        </button>
    </div>
    <div class="text-center" *ngIf="translatebtn" >
     <button class="btn btn-primary px-2 py-1 btn-block" style="border:none; height:40px;"  (click)="translateContent()">Translate</button>
  </div>
    </div>
</div>
</div>

              </div>
              <!--  -->
          </div>
      </div>





  </div>
  <br>
<div>

<div class="row px-md-0" >
          <div class="col-12" *ngIf="uploadbtn" style="padding-left: 97px;">
            <input  type="checkbox" [(ngModel)]="isImageFormat" (change)="updateAcceptedFormats()"> Check if you are uploading image
        </div>
        <div class="col-12" *ngIf="translatebtn" style="padding-left: 97px;">
        <label class="checkbox-label">
          <input type="checkbox" id="uploadCheckbox" [(ngModel)]="glossaryStatus"
            required [ngModelOptions]="{standalone: true}">

          Do you want to add a glossary for keywords /brandnames?
        </label>
      </div>
    </div>
      <!-- glossary start -->
      <div class="row table-responsive m-0" *ngIf="glossaryStatus" style="padding-left: 81px; width:48%;" >
        <table class="table" >

            <div class="row py-3 m-0">
              <div class="col-sm-12 text-right">
                <!-- <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="removeGlossaryEntry()">Less</button> -->
              </div></div>
          <tr>
            <th style="text-align: left; background-color:#042040">Current Text</th>
            <th style="text-align: left;background-color:#042040">Translate To</th>
            <th style="background-color:#042040">Action</th>
          </tr>
          <tbody>
          <tr *ngFor="let data of tableData;let i= index">
            <td ><input class="form-control" [(ngModel)]="data.currentText" type="text" placeholder="Current Text"></td>
            <td ><input class="form-control" [(ngModel)]="data.translatedText" type="text" placeholder="Translate To"></td>
            <td class="rmbtn" >

              <button  class="bg-primary text-light p-2 px-4 border border-r1" (click)="addGlossaryEntry()"><i class="bi bi-plus"></i></button>

              <button *ngIf="i > 0" class="bg-danger text-light p-2 px-4 border border-r1" (click)="removeGlossaryEntry(i)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
              </button>

            </td>
          </tr></tbody>

        </table>
        <br><br><br>
          <!-- <div class="col-sm-12 text-center">
          <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="glossaryFileUpload()">Submit</button>
        </div> -->
        <!-- </div> -->
      </div>
      <!-- glossary end -->

      </div>

<!-- file-upload.component.html -->

<br>
<!-- Display uploaded files in a table -->
<div  class="row table-responsive m-0" *ngIf="translatebtn" style="padding-left: 81px;width:97%;" >
<table class="table">

      <tr>
          <th>File Name</th>
          <th>language</th>
          <th>Size</th>
          <th>Upload Time</th>
          <th>Download</th>
          <th>Delete</th>
      </tr>

  <tbody>
      <tr *ngFor="let file of uploadedFiles">
          <td>{{ file.name }}</td>
          <td>{{ file.language }}</td>
          <td>{{ file.size }}KB</td>
          <td>{{ file.uploadTime }}</td>
          <td  style="text-align: center;">
           <button  (click)="downloadFile()" class="btn btn-sm btn-primary" style="background-color:#ffffff;color:#073262;border:none;" [disabled]="!downloadStatus">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
            </svg>
          </button>
          </td>
          <td style="text-align: center;">
           <button (click)="removeFile(file)" class="btn btn-sm btn-primary" style="background-color:#ffffff;color:#073262;border:none;">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
            </svg>
          </button>
          </td>
      </tr>
  </tbody>
</table>
  </div>

  </div>

</section>

