import { Component } from '@angular/core';
import { RoundLoaderService } from '../../../Services/roundloader.service';

@Component({
    selector: 'app-roundloader',
    templateUrl: './roundloader.component.html',
    styleUrls: ['./roundloader.component.scss'],
    standalone: false
})
export class RoundLoaderComponent {
  constructor(public loaderService: RoundLoaderService) {

  }
}
