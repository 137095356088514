<app-sidenav [item]="'playground'"></app-sidenav>
<div class="chat-box-new ">
    <div class="row">
        <div class="col-12">
            <div class="row-x">
                <!-- History  block  Start -->

                <div class="box conversations history-bg" *ngIf="historyTab && hideMobileView">

                    <div class="new-chat">
                        <button class="new_convo" (click)="new_conversations()">
                            <i class="fa fa-plus"></i>
                            <span>New Conversation</span>
                        </button>
                        <button class="new_convo" (click)="historyTab=false">
                            <i class="fa fa-indent" aria-hidden="true"></i>
                        </button>
                    </div>

                    <div class="top">

                        <div class="convo" [ngClass]="{'chat-active':active_chatId == history?.chat_id}"
                            *ngFor="let history of chatHistoryList">

                            <div class="left" (click)="detailHistory(history?.chat_id ,history?.chat_model )">
                                <i class="fa fa-comments"></i>
                                <span class="convo-title" [title]="history?.title">{{ history?.title}}</span>
                            </div>

                            <i class="fa fa-trash" *ngIf="!(histroyDeleteId == history?.chat_id)"
                                (click)="histroyDeleteId = history?.chat_id"></i>

                            <i class="fa fa-check icon-green" *ngIf="histroyDeleteId == history?.chat_id"
                                (click)="openModal(confirm_model)"></i>

                            <i class="fa fa-times icon-red" *ngIf="histroyDeleteId == history?.chat_id"
                                (click)="histroyDeleteId = ''"></i>
                        </div>
                    </div>

                </div>
                <!-- History  block  Start -->




                <div class="conversation">
                    <!-- Page Heading block  Start -->
                    <div class="chat-box-header genpact-color py-2 mb-n3">

                        <div class="row m-0 px-2">
                            <div class="col-md-12 text-md-left">

                                <div class="head-item">
                                    <!-- History collaps block  Start -->

                                    <div *ngIf="!historyTab">
                                        <button  *ngIf="hideMobileView;else notoggle" class="new_convo mr-2" (click)="historyTab=true" title="Chat History">
                                            <i class="fa fa-indent" aria-hidden="true"></i>
                                        </button>
                                        <ng-template #notoggle>
                                            <div class="px-3"></div>
                                        </ng-template>
                                    </div>

                                    <!-- History collaps block  End -->
                                <div class="cp text-align-center align-items-center">
                                        <h4 class="display-12 text-center-white r-heading">ChatGPT Playground</h4>
                                         <div class="nav-item dropdown" *ngIf="!checkm"> 
                                          <a class="nav-link dropdown-toggle py-0" href="javascript:;" id="navbarDropdown" role="button" data-toggle="dropdown"
                                             aria-haspopup="true" aria-expanded="false">
                                             {{ selectedMode }}
                                          </a>
                                          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <ng-container *ngFor="let mode of modeArray; let last = last">
                                              <a class="dropdown-item" (click)="selectedMode = mode;selectMode();">{{ mode }}</a>
                                              <div class="dropdown-divider" *ngIf="!last"></div>
                                            </ng-container>
                                          </div>
                                      </div>
                                </div>

                                <div id="downloadbtn" class="d-md-flex d-grid gap-1 align-items-center">


                                    <div class=" text-md-right d-none d-md-block mr-2" style="align-self: center;">
                                        <span *ngIf="endPointDetail?.value" class="">
                                            Count Left: <b>{{ endPointDetail?.max - endPointDetail?.value}}</b>
                                        </span>
                                    </div>
                                  
                                    <div  class="genpact-color" style="text-align: center;margin-left:5px;margin-right:5px;">
                                      <div  class="btn-group-lg px-2 text-center genpact-color" role="group" aria-label="Basic radio toggle button group" style="padding-top: 10px; margin-left: 2px !important; border-radius:0px;">
                                        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off"
                                      [checked]="!checkm"   (change)="azures()">
                                  <label class="btn btn-outline-primary" for="btnradio1">Azure</label>
                                  
                                  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"
                                       [checked]="checkm"   (change)="geminis()">
                                  <label class="btn btn-outline-primary" for="btnradio2">GCP</label>
                                  
                                        <!-- <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" (change)="optionSelected2()">
                                        <label class="btn btn-outline-primary" for="btnradio3">Custom search</label> -->
                                    </div>
                                    </div>




                                    <button  style="height: fit-content;" *ngIf="chatData.length > 0" class="btn btn-outline-info btn-sm rounded-0" title="Download PDF" (click)="downloadAsPdf()">
                                        <i class="fa fa-download" aria-hidden="true"></i>
                                    </button>
                                    <!-- <div class="px-3"  *ngIf="chatData.length == 0">&nbsp;</div> -->

                                    <button  style="height: fit-content;" class="btn btn-outline-warning btn-sm rounded-0 " title="Setting" (click)="openModal(chat_config, 'model-md')">

                                      <i class="fa fa-cog" aria-hidden="true"></i>
                                  </button>
                                </div>



                                </div>

                                <div class=" text-md-right d-md-none" style="align-self: center;">
                                    <span *ngIf="endPointDetail?.value" class="">
                                        Count Left: <b>{{ endPointDetail?.max - endPointDetail?.value}}</b>
                                    </span>
                                </div>

                            </div>

                        </div>

                        <!-- Download button start -->

                        <!-- Download button end -->

                    </div>


                    <!-- Page Heading block  End -->

                    <!-- Stop Generation & Regenerate button  start -->
                    <div class="res_generating" *ngIf="generateResponse">
                        <button (click)="unsubscribeChat()">
                            <span>Stop Generating</span>
                            <i class="fa fa-stop" aria-hidden="true"></i>
                        </button>
                    </div>

                    <div class="res_generating" *ngIf="!generateResponse && (chatData?.length > 0)">
                        <button (click)="getAiResponse(true)">
                            <span>Regenerate</span>
                            <i class="fa fa-retweet" aria-hidden="true"></i>
                        </button>
                    </div>
                    <!-- Stop Generation & Regenerate button  end -->

                    <!-- <button  class="btn btn-light setting-btn" title="Setting" (click)="openModal(chat_config, 'model-md')">
                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                        <i class="fa fa-cog" aria-hidden="true"></i>
                    </button> -->

                    <!-- Chat block  start -->
                    <div id="text-d" class="box messages smooth-scroll" #scrollChat>



                        <div class="message" *ngIf="chatData.length === 0">
                            <div class="user">
                                <img src="assets/bot.png" alt="GPT Avatar">
                                <i class="fa fa-reply icon-send" aria-hidden="true"></i>
                            </div>
                            <div class="content">Hello, how can I assist you?</div>
<br>
                            <!-- <div class="uploaded-images" *ngIf="uploadedImages.length > 0">
                              <div *ngFor="let image of uploadedImages; let i = index" class="image-container">
                                  <img [src]="image" class="uploaded-image" alt="Uploaded Image">
                                  <button class="remove-image" (click)="removeImage(i)">✖</button>
                              </div>
                          </div> -->
                        </div>




                        <div class="message" *ngFor="let chat of chatData; let index = index">
                          <div class="user" *ngIf="chat.role === 'user'; else botTemplate">
                            <img src="assets/people-1.png" class="shadow-sm" alt="User Avatar">
                            <i class="fa fa-mail-forward icon-send" aria-hidden="true"></i>
                          </div>
                          <ng-template #botTemplate>
                            <div class="user">
                              <img src="assets/bot.png" class="shadow-sm" alt="GPT Avatar">
                              <i class="fa fa-reply icon-send" aria-hidden="true"></i>
                            </div>
                          </ng-template>

                          <!-- Content section -->
                          <div class="content" *ngIf="chat.content">
                            <div *ngIf="chat.role != 'user' && chat.section != 'sources' && chat.section != 'question'" class="timestamp-block" style="display: flex; justify-content: space-between; margin-bottom:10px;">{{ chat?.timeStamp | date: "EEE MMM d y 'at' h:mm:ss a" }} <p style="margin-right: 32px!important;"  class="disclaimer-text">AI-generated content may be inaccurate</p><br> 
                            <!-- <div class="disclaimer-text" style="cursor:text;" *ngIf="chat.role != 'user' && chat.section != 'sources' && chat.section != 'question'">
                                <p>AI-generated content may be inaccurate</p>
                            </div> -->
                            <!-- Buttons and content display based on role -->
                            <button [hidden]="isCopied" *ngIf="chat.role !== 'user' && chat.section !== 'sources' && chat.section !== 'question'" class="markdown-clipboard-button content-copy" (click)="copyToClipboard(chat.content)">
                              <i class="fa fa-clone" aria-hidden="true"></i>
                              <!-- <i class="fa fa-check" aria-hidden="true"></i> -->
                            </button>
                            <button [hidden]="!isCopied" *ngIf="chat.role !== 'user' && chat.section !== 'sources' && chat.section !== 'question'" class="markdown-clipboard-button content-copy">
                              <!-- <i class="fa fa-clone" aria-hidden="true"></i> -->
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </button>

 <button style="margin-right: 108px;" *ngIf="chat.role !== 'user' && chat.section !== 'sources' && chat.section !== 'question'  " class="markdown-clipboard-button content-copy"  (click)="regenerate(index)">
  <img style="height:12px" src="/assets/images/icons/refresh.svg" alt="refresh" title="Regenerate">
 </button>

                            <button style="margin-right: 72px;" *ngIf="chat.role !== 'user' && chat.section !== 'sources' && chat.section !== 'question'  " class="markdown-clipboard-button content-copy"  (click)="likeResponse(index)">
                            <i class="fa" [ngClass]="{ 'fa-thumbs-up': chat?.isliked, 'fa-thumbs-o-up': !chat?.isliked }" aria-hidden="true"></i>
                           </button>
                           <button style="margin-right: 36px;" *ngIf="chat.role !== 'user' && chat.section !== 'sources' && chat.section !== 'question' " class="markdown-clipboard-button content-copy" (click)="dislikeResponse(index)">
                           <i class="fa" [ngClass]="{ 'fa-thumbs-down': chat?.isdisliked, 'fa-thumbs-o-down': !chat?.isdisliked }" aria-hidden="true"></i>
                           </button>
                          </div>
                            <!-- <textarea [value]="chat.content" style="display: none;"></textarea> -->

                           <div *ngIf="chat.role !== 'user'">

                              <div markdown mermaid lineNumbers clipboard [data]="chat.content" [disableSanitizer]="true">
                              </div>

                           
                              <!-- <div *ngIf="getUrlsForResponse(chatData.indexOf(chat)).length > 0" class="url-container">
                                <p style="color: #00aecf;"> To know More: </p>
                              
                                <div class="row">
                                  <div class="col-md-4" *ngFor="let pair of (showAll ? getPairs(chatData.indexOf(chat)).slice(0, 5) : getPairs(chatData.indexOf(chat)).slice(0, maxVisible)); let i = index">
                                    <div class="url-item">
                                      <a [href]="pair.url" target="_blank">{{ i + 1 }}. {{ pair.name }}</a>
                                    </div>
                                  </div>
                                </div>

                                <div class="button-container">
                                  <button *ngIf="!showAll && getUrlsForResponse(chatData.indexOf(chat)).length > maxVisible" (click)="showMore()" class="btn btn-primary">+2 More</button>
                                  <button *ngIf="showAll && getUrlsForResponse(chatData.indexOf(chat)).length > maxVisible" (click)="showLess()" class="btn btn-secondary">Show Less</button>
                                </div>
                              </div> -->

                            </div> 



                            <!-- <div *ngIf="chat.role === 'user' && chat_model  !== 'gpt-4o'">
                              {{ chat.content }}
                           

                            </div> -->
                            <!-- chat-gpt.component.html -->
                            <div *ngIf="chat.role === 'user' && chat_model === 'gpt-4o'">
                           <!-- Display text content if chat.content is not an object -->
                          <div *ngIf="isNotObject(chat.content)">{{ chat.content }}</div>
                            </div>

                            
                            


                            <div class="image1" *ngIf="chat.role === 'user' && chat_model === 'gpt-4o' && !checkm ">
                              <!-- Display text and image if present -->
                              <div *ngFor="let content of chat.content">
                                <img   *ngIf="content.image_url" [src]="content.image_url.url" class="uploaded-image1" alt="Uploaded Image">
                                <!-- <div *ngIf="content.text">{{ content.text }}</div> -->
                              </div>
                             

                            </div>
                            <div class="image1" *ngIf="chat.role === 'user' && checkm ">
                              <!-- Display text and image if present -->
                              <div *ngFor="let content of chat.content">
                                <img   *ngIf="content.image_url" [src]="content.image_url.url" class="uploaded-image1" alt="Uploaded Image">
                                <!-- <div *ngIf="content.text">{{ content.text }}</div> -->
                              </div>
                             

                            </div>
                            
                            


                            <div class="content sources" *ngIf="chat?.section == 'sources'">
                              <h6>{{ chat?.title }}</h6>
                              <!-- <div class="language-buttons">
                                
                                <div class="question-list" *ngFor="let item of chat?.content">
                                      <div *ngIf="item.imageTag.startsWith('<img')"  style="display: flex; gap: 5px;">
                                          <div [innerHTML]="item.imageTag"></div>
                                          <a href="{{ item?.url }}" target="_blank">{{ item.name }}</a>
                                      </div>
                                      <div *ngIf="item.imageTag.startsWith('<svg')" style="display: flex;">
                                          <div >
                                              <svg style="margin-right: 5px;" xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16"> <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z"
                                                  /></svg>
                                          </div>
                                          
                                          <a href="{{ item?.url }}" target="_blank">{{ item.name }}</a>
                                      </div>
                                      
                                </div>
                              </div> -->
                              <div class="row prompts">
                                  <a class="col-6 prompt" *ngFor="let item of chat?.content" target="_blank" href="{{ item?.url }}">
                                      <div *ngIf="item.imageTag.startsWith('<img')" style="display: flex; gap: 5px;">
                                          <div [innerHTML]="item.imageTag"></div>
                                          <span class="ellipsis" >{{ item.name }}</span>
                                      </div>
                                      <div *ngIf="item.imageTag.startsWith('<svg')">
                                          <div style="display: flex; gap: 10px;">
                                              <img src="../../../assets/images/icons/search web 1.svg" alt="search web"
                                                  class="image-icon">
                                              <span class="ellipsis" >{{ item.name
                                                  }}</span>
                                          </div>
  
  
                                      </div>
                                  </a>
                              </div>
                          </div>
                          
                          <div class="content" *ngIf="chat?.section == 'question'">
                              <div class="language-buttons">
                                  <h6>{{chat?.title}}</h6>
                                  <div class="question-list" (click)="inputPrompt(question)"
                                      *ngFor="let question of chat?.content">
                                      <span>{{question}}</span>
                                  </div>
                              </div>
                          </div>









                          </div>
                        </div>

                    </div>
                    <!-- Chat block  End -->

                    <!-- Input block  Start -->
                    <div class="user-input" >

                      <div class="box input-box" *ngIf="!generateResponse">
                        <div class="uploaded-images" *ngIf="uploadedImages.length > 0">
                          <div *ngFor="let image of uploadedImages; let i = index" class="image-container">
                              <img [src]="image" class="uploaded-image" alt="Uploaded Image">
                              <button class="remove-image" (click)="removeImage(i)">✖</button>
                          </div>
                      </div>
                    
                        <div class="file-upload-block pb-2" *ngFor="let file of selectedFiles">
                          <div class="file_icon_remove" (click)="uploadFile1('reset')">
                            <img src="/assets/images/icons/cross.svg">
                          </div>
                          <div class="file_icon">
                            <img [attr.title]="file?.name + '.' + file?.type" src="/assets/images/icons/file_upload.svg" alt="File Upload Icon">
                          </div>
                          <!-- <div class="file-detail">
                            <div [title]="file?.name" class="file-lable ellipsis">{{ file?.name }}</div>
                            <div [title]="file?.type" class="file-type ellipsis">{{ file?.type }}</div>
                          </div> -->
                        </div>
                      

                      <!-- <div *ngIf="!['gpt-4o', 'gpt-4' , 'gpt-3.5 New' , 'gpt-3.5'].includes(chatmodel)" class="pro-toggle-container" [title]="'Our most powerful search, ideal for longer answers to complex questions'" >
                        <label class="switch" >
                          <input type="checkbox" [(ngModel)]="isPro" class="toggle-input">
                          <span class="slider round"></span>
                        </label>
                        <span class="pro-toggle-label" (click)="togglePro()">Pro</span>
                      </div> -->







                        <!-- <input type="file" #fileInput  accept=".gif, .tiff, .jpeg, .pjpeg, .pjp, .jpg, .webp, .bmp, .apng, .png, .avif" (change)="onFileSelected1($event)" hidden>
                        <button [title]="'only .gif, .tiff, .jpeg, .pjpeg, .pjp, .jpg, .webp, .bmp, .apng, .png, .avif are allowed.Max File size Limit(12MB)'" *ngIf="chat4o && atchbtn" class="attachment-icon" (click)="fileInput.click()">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
                            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z"/>
                          </svg>
                        </button> -->
                        <textarea [(ngModel)]="prompt" class="message-input" [maxlength]="chat_model == 'gpt-4' ? 10000 : 5000"
                            placeholder="Ask a question" rows="1" #textArea (input)="resizeTextarea(textArea)"
                            (keydown.enter)="getAiResponse(false)" (input)="onInputChat(textArea.value)">

                          </textarea>

                        <!-- <div class="uploaded-images" *ngIf="uploadedImages.length > 0">
                            <div *ngFor="let image of uploadedImages; let i = index" class="image-container">
                                <img [src]="image" class="uploaded-image" alt="Uploaded Image">
                                <button class="remove-image" (click)="removeImage(i)">✖</button>
                            </div>
                        </div> -->
                        <!-- <div>
                          <input type="file" #fileInput  accept=".gif, .tiff, .jpeg, .pjpeg, .pjp, .jpg, .webp, .bmp, .apng, .png, .avif" (change)="onFileSelected1($event)" hidden>
                          <button [title]="'only .gif, .tiff, .jpeg, .pjpeg, .pjp, .jpg, .webp, .bmp, .apng, .png, .avif are allowed.Max File size Limit(12MB)'" *ngIf="chat4o && atchbtn" class="attachment-icon" (click)="fileInput.click()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
                              <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z"/>
                            </svg>
                          </button>
                        </div> -->
<div>



                        <button class="app-speech-input-btn" *ngIf="startspeech" (click)="startService()"></button>

                        <button class="app1-speech1-input1-btn1" *ngIf="stopspeech" (click)="stopService()">
                            <i class="fa fa-microphone fa-beat-fade" style="font-size:20px;color:red"></i>
                            <div class="micro-animation"></div>
                        </button>
                      </div>
                      <div class="d-flex" style="align-items: center; gap: 10px;">
                      <div>
                        <input class="d-none" [accept]="inputAccept" (change)="uploads($event)" type='file' id="upload_chatFile" />

                        <input type="file" id="fileInput" [accept]="inputAccept" (change)="onFileSelected1($event)" hidden>
                        <button [title]='inputAccept' *ngIf="chat4o && atchbtn" class="attachment-icon" (click)="filecheck()">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
                            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z"/>
                          </svg>
                        </button>
                        
                      </div>



                        <div id="send-button" (click)="getAiResponse(false)">
                            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                                <path fill="#006ae3" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
                            </svg>
                        </div>
                      </div>
                    </div>
<!--
                    <div  style="display:flex;align-items:center;">
                      <div *ngIf="chatStyle" class="btn-group" role="group" aria-label="Basic radio toggle button group" style="padding-top: 10px;margin-left: 2px !important;">
                        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked  (change)="optionSelected()">
                        <label class="btn btn-outline-primary" for="btnradio1">Genpact</label>

                        <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"  (change)="optionSelected1()">
                        <label class="btn btn-outline-primary" for="btnradio2">Custom</label>
                      </div>
                      <button *ngIf="(isGenpactSelected1 && chatStyle)" tooltip="Edit your custom style guide & Sentiment Analysis" style="border:none; margin-left: 2px; background-color:#ffffff00; color:#042040" class="btn btn-primary" [disabled] ="!chatStyle" data-toggle="modal" data-target="#exampleModal1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                        </svg>
                      </button>
                    </div> -->



                    <!-- style guide remove -->
                    <div *ngIf="chatmodel == 'gpt-4' || chatmodel=='gpt-4o' && checkm == false " class="pro-toggle-container" style="    margin-top: 5px;

                    position: absolute;
                    display: flex;
                    align-items: flex-start;
                    margin-left: -10px;" [title]="'Genpact style guide'" >
                      <label class="switch" >
                        <input type="checkbox" [(ngModel)]="chatStyle" class="toggle-input">
                        <span class="slider round"></span>
                      </label>
                      <span class="pro-toggle-label" (click)="optionSelected();"></span>
                      <span>
                        <a style="text-decoration: none; margin-left:-15px;" title="This guide shows you how we do style at Genpact. Have a read to make sure your writing looks like us. It is recommended to adjust the settings before starting the conversation." class="text-skyblue cursor-pointer"
                        href="https://brand.genpact.com/brandsource/web/our-voice/writing-style-guide/some-rules-on-style"
                        target="_blank">Genpact Style Guide</a>
                      </span>
                    </div>
                    <!-- style guide remove -->
                        <div  class="d-flex px-2 justify-content-between">
                          <div class="form-check mr-2" style="display:flex;align-items:center;">
                            <!-- <div *ngIf="chat_model  !== 'gpt-3.5'" >
                        <div class="d-flex" *ngIf="chat4r">
                            <input  class="form-check-input" [(ngModel)]="chatStyle" (change)="style()" type="checkbox" value="" id="style_guide">
                            <label class="form-check-label text-dark" for="style_guide">
                              Add Style Guide
                            </label>
</div>
</div> -->


<!-- <div *ngIf="chatStyle" class="btn-group" role="group" aria-label="Basic radio toggle button group" style="padding-top: 10px;margin-left: 2px !important;">
  <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked  (change)="optionSelected()">
  <label class="btn btn-outline-primary" for="btnradio1">Genpact</label>

  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off"  (change)="optionSelected1()">
  <label class="btn btn-outline-primary" for="btnradio2">Custom</label>
</div> -->











                            <!-- <button *ngIf="(isGenpactSelected  && chatStyle)" tooltip="view style guide" style="border:none; margin-left: 2px; background-color:#ffffff00; color:#042040" class="btn btn-primary" [disabled] ="!chatStyle" data-toggle="modal" data-target="#exampleModal2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
                                <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
                              </svg>
                            </button> -->






                            <!-- <button *ngIf="(isGenpactSelected1 && chatStyle)" tooltip="Edit your custom style guide & Sentiment Analysis" style="border:none; margin-left: 2px; background-color:#ffffff00; color:#042040" class="btn btn-primary" [disabled] ="!chatStyle" data-toggle="modal" data-target="#exampleModal1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                              </svg>
                            </button> -->
                          </div>


                          <div class="px-2 text-danger" *ngIf="inputLeft != null">
                              {{inputLeft}} characters left.
                           </div>
                        </div>


                    </div>
                    <!-- Input block  End -->

                </div>

            </div>
        </div>
    </div>
</div>

<ng-template #confirm_model>
    <div class="modal-body text-center">
        <p>Do you want to confirm?</p>
        <button type="button" class="btn btn-default" (click)="deleteHistory()">Yes</button>
        <button type="button" class="btn btn-primary" (click)="modalRef?.hide()">No</button>
    </div>
</ng-template>



<ng-template #chat_config >
    <div class="modal-header">
        <h4 class="modal-title pull-left"> Advance Setting </h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
    <div class="modal-body">
        <!-- <h4> Advance Setting </h4> -->

        <div class="">

            <!-- <div class="row mb-3">
                <div class="col-7">
                  <label class="text-xs">Select Model</label>
                </div>
                <div class="col">

                  <select [(ngModel)]="chat_model" class="form-control" (ngModelChange)="max_tokens = 800">
                    <option value="gpt-3.5">GPT 3.5</option>
                    <option value="gpt-4">GPT 4</option>
                </select>
                </div>
              </div> -->
            <!-- Temperature -->
            <div class="row">
              <div class="col-7">
                <label class="text-xs">Temperature
                  <!-- Info icon here -->
                  <i class="bi bi-info-circle"
                    title="Controls randomness. Lowering the temperature means that the model will produce more repetitive and deterministic responses. Increasing the temperature will result in more unexpected or creative responses. Try adjusting temperature or Top P but not both.">
                  </i>
                </label>
              </div>
              <div class="col text-right pr-3">
                <b>{{temperature}}</b>
             </div>
            </div>
            <!-- Temperature slider here -->

              <div class="range  my-2">
                <input type="range" min="0" max="1" step="0.01" value="0" class="w-100" [(ngModel)]="temperature" />
              </div>

            <!-- Max tokens -->
            <div class="row">
              <div class="col-7">
                <label class="text-xs">Max length (tokens)
                  <!-- Info icon here -->
                  <i class="bi bi-info-circle"
                    title="Set a limit on the number of tokens per model response. The API supports a maximum of tokens shared between the prompt (including system message, examples, message history, and user query) and the model's response. One token is roughly 4 characters for typical English text."></i>
                </label>

              </div>
              <div class="col text-right pr-3">
                <b>{{max_tokens}}</b>
             </div>

            </div>
            <!-- Max tokens slider here -->

              <div class="range  my-2">
                <input type="range" [(ngModel)]="max_tokens" min="1"
                [max]="chat_model === 'gpt-4' ? 16000 : (chat_model === 'gpt-4o' ? 4000 : 8000)"
                value="1" class="w-100" />

              </div>

            <!-- Top probabilities -->
            <div class="row">
                <div class="col-7">
                  <label class="text-xs">Top probabilities
                    <!-- Info icon here -->
                    <i class="bi bi-info-circle"
                      title="Similar to temperature, this controls randomness but uses a different method. Lowering Top P will narrow the model’s token selection to likelier tokens. Increasing Top P will let the model choose from tokens with both high and low likelihood. Try adjusting temperature or Top P but not both."></i></label>

                </div>

                <div class="col text-right pr-3">
                    <b>{{top_p}}</b>
                 </div>
              </div>
            <!-- Top probabilities slider here -->
            <div class="range  my-2">
              <input type="range" min="0" max="1" step="0.01" value="0.01" class="w-100" [(ngModel)]="top_p" />
            </div>
            <!-- Frequency penalty -->
            <div class="row">
                <div class="col-7">
                  <label class="text-xs">Frequency penalty
                    <!-- Info icon here -->
                    <i class="bi bi-info-circle"
                      title="Reduce the chance of repeating a token proportionally based on how often it has appeared in the text so far. This decreases the likelihood of repeating the exact same text in a response."></i>
                  </label>
                </div>

                <div class="col text-right pr-3">
                    <b>{{frequency_penalty}}</b>
                  </div>
              </div>
            <!-- Frequency penalty slider here -->
            <div class="range  my-2">
              <input type="range" min="0" max="2" step="0.01" value="0.01" class="w-100"
                [(ngModel)]="frequency_penalty" />
            </div>
            <!-- Presence penalty -->
            <div class="row">
                <div class="col-7">
                  <label class="text-xs">Presence penalty
                    <!-- Info icon here -->
                    <i class="bi bi-info-circle"
                      title="Reduce the chance of repeating any token that has appeared in the text at all so far. This increases the likelihood of introducing new topics in a response."></i></label>

                </div>
                <div class="col text-right pr-3">
                  <b>{{presence_penalty}}</b>
                </div>
              </div>
            <!-- Presence penalty slider here -->
            <div class="range my-2">
              <input type="range" min="0" max="2" step="0.01" value="0.01" class="w-100"
                [(ngModel)]="presence_penalty" />
            </div>
          <!-- Style guide -->
          <!-- <div class="row">
            <div class="col-7">
          <div *ngIf="chatmodel == 'gpt-4' || chatmodel=='gpt-4o' " class="pro-toggle-container" style="    margin-top: 5px;
         display: flex;
    align-items: flex-start;
    margin-left: auto;
    flex-direction: column-reverse;
;" [title]="'Genpact style guide'" >
            <label style="margin-left: -10px;    margin-top: -20px;"  class="switch" >
              <input type="checkbox" [(ngModel)]="chatStyle" class="toggle-input">
              <span class="slider round"></span>
            </label>
            <span style="margin-left: -10px;font-family:Source Sans Pro;" class="text-xs" (click)="optionSelected()"></span>
            <label  style="margin-left: -10px;" class="text-xs">Genpact Style Guide</label>
          </div>
</div>
</div> -->


          </div>

    </div>
</ng-template>
<!-- Button to trigger the modal -->


<!-- Button to trigger the modal -->


<!-- Modal -->
<div class="modal" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" [ngClass]="{'show': showModal}">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" style="border-radius: 0px; font-family:GuardianEgyp-Regular;">
      <div class="modal-header" >
        <button  style="color:#00AECF;" tooltip="style guide" type="button" class="modal-title1" (click)="styleguide()"  >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
          </svg>
        </button>
        <div class="divider-vertical"></div>
        <!-- <h5 class="modal-title" id="exampleModalLabel1">Style Settings</h5> -->
    <button style="color:#073262;" tooltip="style settings" type="button" class="modal-title1" (click)="styleguide1()"  >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
        <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
        <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
      </svg>
    </button>


    <div *ngIf="sanalysis" class="divider-vertical"></div>
    <button style="color: #FF555F" tooltip="sentiment analysis" type="button" class="modal-title1" *ngIf="sanalysis" (click)="styleguide2()"  >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-emoji-smile" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
        <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5"/>
      </svg>
    </button>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- sentiment analysis -->


      <div class="modal-body" *ngIf="sguide">
        <!-- Textarea -->
        <div style="max-height:300px; background-color:#ececec; overflow: scroll;" *ngIf="!edit" [innerHTML]="htmlText"></div>

<textarea *ngIf="edit" style="height:300px" [(ngModel)]="textareaContent" class="form-control" rows="5"></textarea>

</div>

<div class="modal-body" *ngIf="analysis">
  <h4>Sentiment Analysis</h4>
  <!-- Textarea -->
  <div *ngIf="isLoading" class="loader"></div>
  <div style="max-height:300px; background-color:#ececec; overflow: scroll;"  [innerHTML]="sentiment"></div>



</div>

<div class="modal-body" *ngIf="ssetting">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div *ngFor="let rule of rules" class="form-group">
          <div class="toggle-container d-flex"> <!-- New container div -->
            <!-- Toggle switch -->
            <label class="switch">
              <input type="checkbox"  [(ngModel)]="rule.checked" (ngModelChange)="updateTextAreaContent(rule)">
              <span class="slider round"></span>
            </label>
            <!-- Rule name -->
            <span [tooltip]="(rule.tooltip)" style="margin-left: 12px; margin-top: -2px;">{{ rule.name }}</span>
            <!-- Tooltip with '?' symbol -->
            <span class="tooltip-text" data-toggle="tooltip" data-placement="top" [attr.title]="rule.tooltip"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




      <div class="modal-footer">
        <!-- <div style="margin-top: 13px; height: 45px;"> -->
          <label tooltip="Add sentiment/tone" for="file-upload" class="btn btn-primary" style="height: 37.33px;">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22.75" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
              <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
            </svg>

          </label>
          <input id="file-upload" type="file" (change)="onFileSelected($event)" accept=".txt" style="display: none;">
        <!-- </div> -->



        <button type="button" tooltip="Reset" class="btn btn-primary" (click)="oldstyle()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9"/>
            <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"/>
          </svg>
        </button>
        <button *ngIf="editbtn1" type="button"  tooltip="Edit" class="btn btn-primary" (click)="enableEditing()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
          </svg>
        </button>

        <!-- <button type="button"  class="btn btn-danger" data-dismiss="modal" tooltip="close">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
          </svg>
        </button> -->
        <button #saveButton *ngIf="!editbtn1" style="background-color:#1BA641; border:none;" type="button" class="btn btn-primary save-button" tooltip="save"  (click)="saveChanges()">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-floppy2-fill" viewBox="0 0 16 16">
              <path d="M12 2h-2v3h2z"/>
              <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v13A1.5 1.5 0 0 0 1.5 16h13a1.5 1.5 0 0 0 1.5-1.5V2.914a1.5 1.5 0 0 0-.44-1.06L14.147.439A1.5 1.5 0 0 0 13.086 0zM4 6a1 1 0 0 1-1-1V1h10v4a1 1 0 0 1-1 1zM3 9h10a1 1 0 0 1 1 1v5H2v-5a1 1 0 0 1 1-1"/>
          </svg>
      </button>

      </div>
    </div>
  </div>
</div>


<!-- model2 -->
<div class="modal" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" [ngClass]="{'show': showModal}">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" style="border-radius: 0px; font-family:GuardianEgyp-Regular;">
      <div class="modal-header" >
        <button style="color:#00AECF;" tooltip="style guide" type="button" class="modal-title1"  >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
            <path d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
          </svg>
        </button>
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
  <div class="modal-body" >
        <!-- Textarea -->
        <!-- <div style="max-height:300px; background-color:#ececec; overflow: scroll;" [innerHTML]="gentext"></div> -->
        <ngx-doc-viewer style="height:350px;" [url]="docxUrl"></ngx-doc-viewer>

      </div>








      <div class="modal-footer">


      </div>
    </div>
  </div>
</div>



<!-- policy & condition toggle -->
<button type="button" class="btn btn-primary d-none" id="chat_compilation_policy_model" (click)="openModal(chat_compilation_policy_model,'modal-md')">
</button>

<!-- policy & condition block -->
<ng-template #chat_compilation_policy_model>
  <div class="modal-body tcolor">
    <div class="row">
      <div class="col-12 text-decoration-underline fw-bold text-primary">Important Instructions</div>
    </div>
    <div class="row">
      <div class="col-12 pt-2" style="font-size: 13px;">
        <ul>
          <li>
            Please be aware that the messages have been generated by an artificial intelligence model. While
            efforts have been made to provide accurate and relevant information, there is a possibility of
            errors or inaccuracies. The response you receive should not be considered as professional or
            expert advice. We strongly recommend cross-checking the information with reliable sources or
            consulting a human expert when necessary. The purpose of this AI is to assist and provide general
            information, but it may not always be perfect. Use the information at your own discretion.
          </li>
          <li>
            The AI-generated response will be limited to 1,000 characters to ensure concise and focused
            answers.
          </li>
          <li>
            If you have any inquiries, we kindly encourage you to consult Genpact's Code of Conduct Document
            and <a
              href="https://prodapps.genpact.com/RSAarcher/default.aspx?requestUrl=../Foundation/workspaceDashboard.aspx?workspaceId%3d4"
              target="_blank">ChatGPT and Use of AI Programs Policy</a>.
          </li>
          <li>
            Kindly note that your inputs maybe subject to audit, and may be used for refining the accuracy of
            responses provided by the BOT

          </li>
          <li>
            Only documents hosted on the Genpact network and that Genpact has contractual permission to maintain on the Genpact network may be uploaded here.
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12 ml-4">
        <input id="byod_check_label" type="checkbox" [(ngModel)]="chkagree" class="form-check-input" />
        <label class="form-check-label text-decoration-underline fw-bold text-primary" style="margin-left: 15px;"
          for="byod_check_label">
          I acknowledge that I have read, understand and agree to above instructions.
        </label>
      </div>

    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn button-genpact text-white  cursor-pointer" [disabled]="!chkagree"
      (click)="modalRef?.hide()">
      Proceed
    </button>
  </div>
</ng-template>
<!-- Button trigger modal -->
<button type="button" id="sentimentbtn" style="display: none;" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModals">
  Launch demo modal
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModals" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Sentiment analysis</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <textarea class="form-control" [(ngModel)]="sentiment" rows="3"></textarea>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>
<div *ngIf="isLoading" class="overlay">
  <div 
  class="loader"></div>
</div>

<div *ngIf="isLoading" class="overlay">
  <div *ngIf="checkm"
  class="loader"></div>
</div>