<!doctype html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <title>Digital Avatar Generator</title>
  <base href="/">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="icon" type="image/x-icon" href="Genpact_logo.png">
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet">
  <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet">
</head>

<!-- <body> 
  <br>
  <div class="container">
<div class="container1">
  <div class="equal-height-container">
    <div class="left-side equal-component">
      <app-image-upload (avatarGenerated)="handleAvatarGenerated($event)"></app-image-upload>
    </div>
    <div class="right-side equal-component">
      <app-avatar-display [avatarUrl]="generatedAvatarUrl" [successMessage]="successMessage"></app-avatar-display>
    </div>
  </div>
</div>
<app-ai-quiz></app-ai-quiz>
  </div> 
</body> -->

<div class="overlay-background"></div>
<body>
  <app-header></app-header>
  <app-multi-form></app-multi-form>
</body>
</html>

