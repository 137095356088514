import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, forkJoin, map } from 'rxjs';
import { ChatGptService } from 'src/app/shared/service/chat-gpt.service';
import { ChatStateService } from 'src/app/shared/service/chat-state.service';

import { LoaderService } from 'src/app/shared/service/loader.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';

import { AskGenpactService } from 'src/app/shared/service/ask-genpact.service';
// import { GcpService } from '../shared/service/gcp.service';
// import { CustomItService } from '../shared/service/custom-it.service';
import { MsaAssistService } from '../shared/service/msa-assist.service';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';

@Component({
  selector: 'app-msa-assist',
  templateUrl: './msa-assist.component.html',
  styleUrls: ['./msa-assist.component.scss']
})
export class MsaAssistComponent {

  selectedAccount: string = '';
  user_prompt: string='';

  accounts: string[] = ['ABBOTT', 'BUPA', 'Cardinal Health Inc', 'GO DADDY']; 

  accountFileMap: { [key: string]: string } = {
    ABBOTT: 'Abbott.Pdf',
    BUPA: 'Bupa.Pdf',
    'Cardinal Health Inc': 'Cardinal Health Inc.Pdf',
    'GO DADDY': 'Go Daddy MSA.Pdf',
  };


  onAccountChange() {
    console.log(`Selected account: ${this.selectedAccount}`);
  }
  
  @ViewChild('scrollChat') scrollChat!: ElementRef;
  chatData: any[] = []; // Array to hold chat data
  selectedFiles: File[] = []; // Array to hold selected files
  prompt: string = ''; // User input prompt
  inputAccept: string = '.pdf,.doc,.docx,.png,.jpg,.jpeg,.csv,.xlsx,.ppt,.pptx'; // Accepted file types
  //Pdf,docx,excel,png,jpg,jpeg
isLoading: boolean = false;

  chatSubscriber!: Subscription;
  chatPayload: any;
  // chatData: any = [];
  resetChatStatus: boolean = false;
  selectedCheckboxes: boolean[] = [];
  token: string = '';
  txtarea:boolean = true;
  uploadfilename: string = '';

  constructor(

    private toastMessage: ToastMessageService,
    private loaderService: LoaderService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private askGenpactService: AskGenpactService,
    private msaService:MsaAssistService,
    // private citService: CustomItService,
  ) {}

  // ngOnInit(): void {
  //   // Initialize or fetch chat data if necessary
  //   this.login();
  //   this.msalBroadcastService.inProgress$.subscribe((status: InteractionStatus) => {
  //     if (status === InteractionStatus.None && this.authService.instance.getAllAccounts().length > 0) {
  //       this.authService.acquireTokenSilent({
  //         scopes: ["Sites.Read.All"]
  //       }).toPromise().then((result: AuthenticationResult | undefined) => {
  //         if (result) {
  //           this.token = result.accessToken;
  //           localStorage.setItem('mstoken', this.token);
  //         }
  //       }).catch(error => {
  //         console.error('Token acquisition error:', error);
  //       });
  //     }
  //   });
  // }
  ngOnDestroy(): void {
    if (this.chatSubscriber) {
      this.chatSubscriber.unsubscribe();
    }
  }

  login(): void {
    this.authService.loginPopup({
      scopes: ["Sites.Read.All"]
    }).subscribe((response: AuthenticationResult) => {
      this.authService.instance.setActiveAccount(response.account);
    }, error => {
      console.error('Login error:', error);
    });
  }

  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  handleEnter(): void {
    // Handle Enter key press (e.g., send message)
    if (this.prompt.trim()) {
      this.sendMessage();
      // this.getResponse(this.prompt);
    }
  }
  scrollToBottom(): void {
    //scroll to bootom of page
    this.scrollChat.nativeElement.scrollTop = this.scrollChat.nativeElement.scrollHeight;
    
  }

  getResponse(event: any): void {
    this.loaderService.loading.next(true);
    this.resetChatStatus = false;
    const messagesPayload = this.chatData.map(chat => ({ role: chat.role, content: chat.content }));
    messagesPayload.push({ role: 'user', content: this.prompt });
  
    this.msaService.sendMessage(this.selectedAccount, this.prompt).subscribe(
      (res: any) => {
        this.loaderService.loading.next(false);
  
        if (res && res.data) {
          const resultResponse = { role: 'assistant', content: res.data };
          this.chatData.push(resultResponse);
        } else {
          this.chatData.push({ role: 'assistant', content: 'No response data available' });
        }
  
        this.prompt = '';
        this.resetChatStatus = true;
      },
      (error: any) => {
        this.loaderService.loading.next(false);
        this.toastMessage.showError('Failed to get response');
        console.error('Response error:', error);
        this.resetChatStatus = true;
      }
    );
  }
  
  sendQuery(type: string):void{

    if (type === 'compliance') {
      this.prompt ='Get HR compliances';
      this.user_prompt = `Include this line in response above the table in bold letters"Listing the Compliances from the perspective of the People Function or HR team." Create a detailed and comprehensive table listing all the compliances from the perspective of the People Function or HR team. Ensure the table includes the following columns: Compliances, Detailed Description, Reference Clause/Section Numbers, and Action. Each entry should provide a clear and thorough description of the compliances, along with the relevant legal or regulatory references. The Action column should specify the steps or measures to be taken to adhere to each compliance in bullet points. Additionally, indicate whether each compliance is generic (applicable across various industries) or specific (applicable to certain industries or regions).
 
Example:
 
| Compliances | Detailed Description | Reference Clause/Section Numbers | Action | Generic/Specific |
|-------------|-----------------------|----------------------------------|--------|------------------|
| Employee Transfer and Employment Opportunities | Ensure that transferred employees are given the same employment opportunities as similarly situated employees of the Service Provider. | Section 8.1 | - Negotiate employee transfer agreements <br> - Recognize and maintain service time, vacation, and benefits <br> - Waive drug screening and background checks for transferred employees <br> - Provide health insurance covering pre-existing conditions | Specific |
| Substance Abuse Policy | Maintain substance abuse policies in conformance with applicable laws and ensure compliance by all personnel. | Section 8.9 | - Implement and maintain substance abuse policies <br> - Ensure all personnel adhere to these policies <br> - Remove personnel engaging in substance abuse from Abbott sites | Generic |
| Conduct and Compliance | Ensure Service Provider Personnel comply with Abbott's rules and conduct themselves professionally at Abbott sites. | Section 8.7 | - Train personnel on Abbott's rules and professional conduct <br> - Ensure personnel wear identification badges <br> - Restrict unauthorized marketing activities | Specific | 
do not miss any points , try to include all`;

    } else if (type === 'obligation') {
      this.prompt ='Get HR obligations';
      this.user_prompt = `Include this line in response above the table in bold letters"Listing the Obligations from the perspective of the People Function or HR team." Create a detailed and comprehensive table listing all the Obligations from the perspective of the People Function or HR team. Ensure the table includes the following columns: Obligations, Detailed Description, Reference Clause/Section Numbers, and Action. Each entry should provide a clear and thorough description of the Obligations, along with the relevant legal or regulatory references. The Action column should specify the steps or measures to be taken to adhere to each Obligations in bullet points. Additionally, indicate whether each obligation is generic (applicable across various industries) or specific (applicable to certain industries or regions).
 
Example:
 
| Obligations | Detailed Description | Reference Clause/Section Numbers | Action | Generic/Specific |
|-------------|-----------------------|----------------------------------|--------|------------------|
| Employee Transfer and Employment Opportunities | Ensure that transferred employees are given the same employment opportunities as similarly situated employees of the Service Provider. | Section 8.1 | - Negotiate employee transfer agreements <br> - Recognize and maintain service time, vacation, and benefits <br> - Waive drug screening and background checks for transferred employees <br> - Provide health insurance covering pre-existing conditions | Specific |
| Substance Abuse Policy | Maintain substance abuse policies in conformance with applicable laws and ensure compliance by all personnel. | Section 8.9 | - Implement and maintain substance abuse policies <br> - Ensure all personnel adhere to these policies <br> - Remove personnel engaging in substance abuse from Abbott sites | Generic |
| Conduct and Compliance | Ensure Service Provider Personnel comply with Abbott's rules and conduct themselves professionally at Abbott sites. | Section 8.7 | - Train personnel on Abbott's rules and professional conduct <br> - Ensure personnel wear identification badges <br> - Restrict unauthorized marketing activities | Specific | 
do not miss any points , try to include all`;

    }

    if (this.prompt.trim()) {
      this.isLoading = true;

      // Determine the file name based on the selected account
      const fileName = this.accountFileMap[this.selectedDropdown];
      console.log(fileName);

      // If no account is selected or no file mapping is found, show an error
      if (!fileName) {
        this.toastMessage.showError('Please select a valid account.');
        this.isLoading = false;
        return;
      }
  
      // Update the call to include `selectedAccount` and `prompt`:
      this.msaService.sendMessage(fileName, this.user_prompt).subscribe(
        (res: any) => {
          this.isLoading = false;
          const responseContent = res?.data || 'No data found';
          this.chatData.push({ role: 'assistant', content: responseContent });
          this.scrollToBottom();
        },
        (error: any) => {
          this.isLoading = false;
          const errorMessage = error.message || 'Something went wrong';
          this.toastMessage.showError(errorMessage);
          console.error('Send message error:', error);
        }
      );
  
      this.chatData.push({ role: 'user', content: this.prompt });
      this.prompt = '';
    } else {
      this.toastMessage.showError('Question is required');
    }

    

  }

  sendMessage(): void {
    if (this.prompt.trim()) {
      this.isLoading = true;

      // Determine the file name based on the selected account
      const fileName = this.accountFileMap[this.selectedAccount];

      // If no account is selected or no file mapping is found, show an error
      if (!fileName) {
        this.toastMessage.showError('Please select a valid account.');
        this.isLoading = false;
        return;
      }
  
      // Update the call to include `selectedAccount` and `prompt`:
      this.msaService.sendMessage(fileName, this.prompt).subscribe(
        (res: any) => {
          this.isLoading = false;
          const responseContent = res?.data || 'No data found';
          this.chatData.push({ role: 'assistant', content: responseContent });
          this.scrollToBottom();
        },
        (error: any) => {
          this.isLoading = false;
          const errorMessage = error.message || 'Something went wrong';
          this.toastMessage.showError(errorMessage);
          console.error('Send message error:', error);
        }
      );
  
      this.chatData.push({ role: 'user', content: this.prompt });
      this.prompt = '';
    } else {
      this.toastMessage.showError('Question is required');
    }
  }

  setPrompt(type: string): void {
    if (type === 'compliance') {
      this.prompt = `I need to extract all compliance-related information from my Master Service Agreement (MSA). Please focus on the following areas:
  Regulatory Compliance: Any clauses or sections that mention adherence to laws, regulations, and industry standards.
  Data Protection and Privacy: Sections related to data security, privacy policies, and handling of personal information.
  Audit Rights: Clauses that outline the rights to audit and inspect compliance with the agreement.
  Confidentiality: Provisions regarding the protection of confidential information.
  Compliance Reporting: Requirements for reporting compliance status and any related documentation.
  Third-Party Compliance: Obligations related to third-party vendors or subcontractors.
  Penalties for Non-Compliance: Any penalties or consequences outlined for failing to comply with the agreement.
  Please provide a summary of each compliance-related section along with the corresponding section numbers or headings.`;
    } else if (type === 'obligation') {
      this.prompt = `I need to extract all obligations-related information from my Master Service Agreement (MSA). Please focus on the following areas:
  Service Delivery Obligations: Any clauses or sections that outline the responsibilities and deliverables of each party.
  Performance Standards: Sections related to the expected performance levels, service level agreements (SLAs), and key performance indicators (KPIs).
  Payment Obligations: Provisions regarding payment terms, invoicing, and financial responsibilities.
  Reporting Obligations: Requirements for regular reporting, status updates, and documentation.
  Compliance Obligations: Clauses that specify adherence to laws, regulations, and industry standards.
  Confidentiality Obligations: Provisions regarding the protection and handling of confidential information.
  Termination Obligations: Any obligations related to the termination of the agreement, including notice periods and post-termination responsibilities.
  Please provide a summary of each obligations-related section along with the corresponding section numbers or headings.`;
    }
  
    // Directly send the message with the defined prompt
    this.sendMessage();
  }


  // Checkbox options
  checkboxes = [
    { value: 'Obligations', label: 'HR Obligations' },
    { value: 'Compliances', label: 'HR Compliances' },
    // { value: 'option3', label: 'Option 3' },
  ];
 
  // Dropdown options ABBOTT', 'BUPA', 'Cardinal Health Inc', 'GO DADDY' Abbott.Pdf',
    // BUPA: 'Bupa.Pdf',
    // 'Cardinal Health Inc': 'Cardinal Health Inc.Pdf',
    // 'GO DADDY': 'Go Daddy Msa.Pdf'

  dropdownOptions = [
    { value: 'ABBOTT', label: 'ABBOTT' },
    { value: 'BUPA', label: 'BUPA' },
    { value: 'Cardinal Health Inc', label: 'Cardinal Health Inc' },
    { value: 'GO DADDY', label: 'GO DADDY' },
  ];
 
  // Selected values
  selectedCheckbox: string = '';
  selectedDropdown: string = '';
 
  // Generate function
  generate() {
    if (!this.selectedCheckbox) {
      alert('Please select a checkbox option.');
      return;
    }
 
    if (!this.selectedDropdown) {
      alert('Please select a dropdown option.');
      return;
    }
 
    // console.log({
    //   selectedCheckbox: this.selectedCheckbox,
    //   selectedDropdown: this.selectedDropdown,
    // });

    if (this.selectedDropdown){
      
      if (this.selectedCheckbox==="Obligations"){
        this.newChat('obligation');
      }else if (this.selectedCheckbox==="Compliances"){
        this.newChat('compliance');
      }
    }


 
    // alert(
    //   `Generated values:\nCheckbox: ${this.selectedCheckbox}\nDropdown: ${this.selectedDropdown}`
    // );
  }


  reloadPage(): void {

    console.log("clicked")
    window.location.reload();
  }
  
  
  
  

  copyToClipboard(content: string): void {
    navigator.clipboard.writeText(content).then(() => {
      console.log('Copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  regenerate(index: number) {
    this.prompt = this.chatData[index - 1]?.content;
   // this.toastMessage.showSuccess(this.prompt);
    this.sendMessage();
  }

 // Handles file upload input
 uploadFile(action: string = 'upload'): void {
  if (action === 'reset') {
    this.selectedFiles = []; // Reset selected files
    this.uploadfilename = '';
    this.txtarea = true;
  } else {
    // Open the file upload dialog when 'upload' is triggered
    document.getElementById('upload_chatFile')?.click();
    // this.txtarea = false;
  }
}


 // Processes the selected files
 uploads(event: Event): void {
  const input = event.target as HTMLInputElement;
  if (input.files) {
    let fileSizeMB = 0;
    const maxFileSize = 16; // Set max file size limit to 16MB

    // Iterate through selected files
    for (const file of Array.from(input.files)) {
      const fileType = file.name.split('.').pop()?.toLowerCase();
      
      // Check if file type is allowed
      if (!this.inputAccept.includes(`.${fileType}`)) {
        this.toastMessage.showmessage(`The file type .${fileType} is not accepted. Allowed types: ${this.inputAccept}`);
        this.clearFileInput('upload_chatFile');
        return;
      }

      // Calculate file size in MB
      fileSizeMB += file.size / 1024 / 1024;

      // Check for file size limit
      if (fileSizeMB > maxFileSize) {
        this.clearFileInput('upload_chatFile');
        this.toastMessage.showmessage('Maximum file size limit is 16MB.');
        return;
      }

      // Add the file to the selectedFiles array if size and type are within limits
      this.selectedFiles.push(file);
      this.gcpupload(); // Call your upload method
    }
  }
}

//gcp file upload
gcpupload(): void {

  this.isLoading = true;
   let formData = new FormData();
    formData.append('file', this.selectedFiles[0]);
    this.msaService.uploadFile(formData).subscribe((res: any) => {
      console.log(res);

      if(res?.status === 'success'){
      this.chatData.push({ role: 'user', content: this.selectedFiles[0].name });
     // this.chatData.push({ role: 'bot', content: res.data });
     this.toastMessage.showSuccess(res?.message);
     this.uploadfilename = res.data;
      this.scrollToBottom();
  this.isLoading = false;
      this.txtarea = false;

      }
      else{
        this.toastMessage.showError(res?.message);
        this.isLoading = false;

      }

    },
    (error: any) => {
      this.toastMessage.showError('Something went wrong');
      this.isLoading = false;

    }
  );

}

  // Clears the file input
  clearFileInput(elementId: string): void {
    const fileInput = document.getElementById(elementId) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Reset file input field
      this.txtarea = true;
    }
  }
  inputPrompt(question: string): void {
    // Handle question input
    console.log(`Prompting with question: ${question}`);
  }

  newChat(type: string): void {
    // Logic to start a new chat
    this.chatData = [];
    this.selectedFiles = [];
    this.prompt = '';
    this.txtarea = true;
    this.clearFileInput('upload_chatFile');
    if (type === 'compliance'){
      this.sendQuery('compliance');
    } else if (type === 'obligation'){
      this.sendQuery('obligation');
    }

    console.log('Starting a new chat');
  }

  sentToInput(): void {
    // Handle sending user input
      this.sendMessage();
    // this.getResponse(this.prompt);
  }


}
