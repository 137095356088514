import { Component } from '@angular/core';

@Component({
  selector: 'app-image-floater',
  templateUrl: './image-floater.component.html',
  styleUrls: ['./image-floater.component.scss'],
  standalone:true,
})
export class ImageFloaterComponent {

}
