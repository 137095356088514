import { Component,Input, Output, EventEmitter, Inject  } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-completionpopup',
    templateUrl: './completionpopup.component.html',
    styleUrls: ['./completionpopup.component.scss'],
    standalone: false
})
export class CompletionpopupComponent {
  fileUrl: string = '';
  fileName: string = '';
  constructor(public dialogRef: MatDialogRef<CompletionpopupComponent>, @Inject(MAT_DIALOG_DATA) private data: any) {
    if (data) {
      this.fileUrl = data.fileUrl;
      this.fileName = data.fileName;

    }
  }

    onClose(): void {
    this.dialogRef.close();
  }

  // openDoc(){
  //   this.dialogRef.close();
  //   console.log('This will Open file');
  // }

  openDoc(){
    //this.dialogRef.close();
    //console.log('This will Open file');
  }
  downloadDoc(){
    this.dialogRef.close();
    //console.log('This will downlaod file');
    
  }

}
