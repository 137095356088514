import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { SharedService } from './shared.service';
import { Observable } from 'rxjs';
import { BRD } from '../data/add-project-model';
import { FileModel } from '../data/app-model';
import { GenCode } from '../data/gen-code-model';
import { ApiEndpoints } from '../data/global-vars';

@Injectable({
  providedIn: 'root'
})
export class BuildProjectService {

  constructor(
    private baseHttp: BaseHttpService,
    private sharedService: SharedService) { }

  generateBrd(data: BRD): Observable<any> {
    //let formData: FormData = this.sharedService.addDataToForm(data, null);
    return this.baseHttp.post<any>(ApiEndpoints.GenerateBrd, data, true, true);
  }

  generateSdd(files: FileModel[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('brd_file', files[0].file_data, files[0].file_data.name);
    //let formData: FormData = this.sharedService.addDataToForm(data, null);
    return this.baseHttp.post<any>(ApiEndpoints.GetSDD, formData, true, true);
  }

  generateCode(data: GenCode): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('coding_lang', data.coding_lang);
    formData.append('brd_doc', data.brd_doc, data.brd_doc.name);
    formData.append('sdd_doc', data.sdd_doc, data.sdd_doc.name);

    return this.baseHttp.post<any>(ApiEndpoints.GenerateCode, formData, true, true);
  }

}
