import { Component } from '@angular/core';

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss'],
    standalone: false
})
export class UnauthorizedComponent {
  currentItem='playground'
}
