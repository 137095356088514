import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoundLoaderService {
  private loadingCount = 0;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  scrollToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  showLoader(): void {
    //this.loadingCount++;
    this.loadingSubject.next(true);
  }

  hideLoader(): void {
    //if (this.loadingCount > 0) {
    //  this.loadingCount--;
    //}

    //if (this.loadingCount === 0) {
      this.loadingSubject.next(false);
    //}
  }
}
