import { CommonModule } from '@angular/common';
import { Component, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-quiz',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  @Output() finalResult = new EventEmitter<string>();

  // Define the question type explicitly
  public questions: { question: string; answers: string[] }[] = [];
  private allQuestions: { question: string; answers: string[] }[] = [
    { question: "Which company developed the GPT series of AI models, including ChatGPT?", answers: ["NVIDIA", "OpenAI", "Google DeepMind", "Meta"] },
    { question: "NVIDIA is best known for its contributions to which technology?", answers: ["Central Processing Units (CPUs)", "Graphics Processing Units (GPUs)", "Quantum Computing", "Blockchain"] },
    { question: "Which AI platform is owned by Google?", answers: ["Bard", "Watson", "Anthropic", "DALL-E"] },
    { question: "What is Intel’s AI accelerator designed for deep learning called?", answers: ["Habana Labs Gaudi", "CUDA TensorRT", "Intel Neural Stick", "Core i9 AI Engine"] },
    { question: "What is the focus of Meta’s open-source LLM project?", answers: ["Bard", "LLaMA (Large Language Model Meta AI)", "GPT-NeoX", "Codex"] },
    { question: "Which company developed the Turing architecture for AI and GPUs?", answers: ["AMD", "Intel", "NVIDIA", "Qualcomm"] },
    { question: "What is the primary purpose of Microsoft Azure OpenAI Service?", answers: ["Cloud GPU rentals", "Training personal AI models", "Access to OpenAI models for business applications", "Edge AI deployments"] },
    { question: "Which company recently introduced the 'Gemini' AI model?", answers: ["NVIDIA", "Google DeepMind", "OpenAI", "Anthropic"] },
    { question: "What AI product is IBM most associated with?", answers: ["Watson", "DALL-E", "Bard", "PyTorch"] },
    { question: "Which of the following companies primarily focuses on Responsible AI development?", answers: ["OpenAI", "Anthropic", "DeepMind", "Tesla"] },
    { question: "Which AI framework is open-source and backed by Meta?", answers: ["TensorFlow", "PyTorch", "PaddlePaddle", "ONNX"] },
    { question: "NVIDIA’s CUDA platform is primarily used for:", answers: ["AI ethics research", "GPU-accelerated computing", "Software automation", "Blockchain security"] },
    { question: "Which company acquired DeepMind?", answers: ["Google", "Microsoft", "Meta", "Amazon"] },
    { question: "Which chipmaker is challenging NVIDIA in AI hardware with its ROCm software and GPUs?", answers: ["AMD", "Intel", "Qualcomm", "ARM"] },
    { question: "OpenAI has a partnership with which major tech company for AI infrastructure?", answers: ["Amazon", "Microsoft", "Google", "IBM"] },
    { question: "Which AI company is focused on building safer AI with models like Claude?", answers: ["Anthropic", "OpenAI", "Hugging Face", "Salesforce"] },
    { question: "What is Hugging Face best known for?", answers: ["Cloud AI services", "Open-source AI models and tools", "Building AI-powered hardware", "Developing LLM chips"] },
    { question: "Tesla’s AI initiative, Dojo, is aimed at:", answers: ["Autonomous driving and vision systems", "Large-scale NLP models", "AI-based medical diagnostics", "Quantum computing"] },
    { question: "Which company introduced the AI tool 'Firefly' for creative design?", answers: ["Adobe", "Canva", "Autodesk", "Meta"] },
    { question: "What is OpenAI’s primary monetization strategy for its AI products?", answers: ["Subscription-based API services", "Selling GPUs", "Offering AI-powered consumer apps", "Licensing pre-trained models"] }
  ];

  public currentIndex = 0;
  public flag=1;
  public selectedAnswer: string | null = null;
  public responseList: string[] = [];

  ngOnInit(): void {
    this.randomizeQuestions();
  }

  private randomizeQuestions(): void {
    // Shuffle and select 5 random questions
    this.questions = [...this.allQuestions]
      .sort(() => Math.random() - 0.5)
      .slice(0, 5);
  }

  get currentQuestion() {
    return this.questions[this.currentIndex];
  }

  get answerOptions() {
    return this.currentQuestion?.answers;
  }

  get isLastQuestion() {
    return this.currentIndex === this.questions.length - 1;
  }

  selectAnswer(answer: string): void {
    this.selectedAnswer = answer;
  }

  nextQuestion(): void {
    if (this.selectedAnswer) {
      this.responseList.push(this.selectedAnswer);
      this.selectedAnswer = null;
      

      if (this.isLastQuestion) {
        this.finalResult.emit(this.responseList.join(', '));
      } else {
        this.currentIndex++;
        this.flag++;
      }
    }
  }
}
