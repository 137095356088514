// VoiceRecognitionService1

import { Injectable, EventEmitter } from '@angular/core';

declare var webkitSpeechRecognition: any;

@Injectable({
  providedIn: 'root'
})
export class VoiceRecognitionService {

  recognition = new webkitSpeechRecognition();
  isStoppedSpeechRecog = true;
  public text = ''; // Use this variable to store the complete recognized text

  // Create an EventEmitter for broadcasting voice recognition updates
  voiceRecognitionUpdate = new EventEmitter<string>();

  constructor() {
    this.init();
  }

  init() {
    this.recognition.interimResults = true;

    this.recognition.addEventListener('result', (e: { results: SpeechRecognitionResultList }) => {
      try {
        const transcript = Array.from(e.results)
          .map((result) => result[0])
          .map((result: SpeechRecognitionAlternative) => result.transcript)
          .join('');
        this.text = transcript; // Update this.text with the new recognized text

        // Emit the updated text to subscribers
        this.voiceRecognitionUpdate.emit(this.text);
      } catch (error) {
        // Suppress the error without logging
      }
    });

    this.recognition.addEventListener('end', () => {
      try {
        if (!this.isStoppedSpeechRecog) {
          this.recognition.start();
        }
      } catch (error) {
        // Suppress the error without logging
      }
    });
  }

  start() {
    try {
      this.isStoppedSpeechRecog = false;
      this.text = ''; // Reset this.text when starting recognition
      this.recognition.start();
      console.log("Speech recognition started");
    } catch (error) {
      // Suppress the error without logging
    }
  }

  stop() {
    try {
      this.isStoppedSpeechRecog = true;
      this.recognition.stop();
      console.log("End speech recognition");
    } catch (error) {
      // Suppress the error without logging
    }
  }
}
