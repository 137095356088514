import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SettingsComponent } from 'src/app/codeGenYLite/components/includes/popup/settings/settings.component';

import { SharedService } from 'src/app/codeGenYLite/Services/shared.service';
import { Router } from '@angular/router';
import { RoundLoaderService } from 'src/app/codeGenYLite/Services/roundloader.service';
import { ContactusComponent } from 'src/app/codeGenYLite/components/includes/popup/contactus/contactus.component';
import { DisclaimerComponent } from 'src/app/codeGenYLite/components/includes/popup/disclaimer/disclaimer.component';








import { ActivatedRoute} from '@angular/router';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cheader',
  imports: [],
  templateUrl: './cheader.component.html',
  styleUrl: './cheader.component.scss'
})
export class CheaderComponent implements OnInit, OnChanges {

  username: any;
  fname: any;
  isPageVisited: boolean = true;
  title:boolean = true;
  title1:boolean = false;
  domain:any;

  isChatVisible: boolean = false;

  toggleChat(): void {
    // this.isChatVisible = !this.isChatVisible;
    this.router.navigate(['./cgy/ai-assist'])
  }

  constructor(public dialog: MatDialog, private router: Router,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private loaderService: RoundLoaderService) { }
    
    ngOnInit(): void {
      this.getUserName();
      this.domain = localStorage.getItem('domain'); 
      this.route.params.subscribe(params => {
        const currentRoute = this.router.url;

        if (currentRoute === '/codeGenYLite') {
          this.title = true;
          this.title1 = true;
        } else if (currentRoute === '/codeGenYLiteAI') {
          this.title = true;
          this.title1 = false;
        }
        else {
          this.title = false;
        }

        // Add more else if conditions for other routes
      });
      // this.getSubscriptionStatus();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isSubscribed']) {
      // this.getSubscriptionStatus();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DisclaimerComponent);
}

opencontactus(){
  this.dialog.open(ContactusComponent, {
    width: '500px', // Set the width of the modal
  });
}

  getUserName(): void {
    const fullName = localStorage.getItem('okta-username') as string ;
    this.fname = fullName;
    let initials = '';

    // Check if there's a comma in the username
    if (fullName.includes(',')) {
      // Split the username by comma
      const nameParts = fullName.split(',');
      const firstNamePart = nameParts[0].trim(); // Part before the comma
      const secondNamePart = nameParts[1].trim(); // Part after the comma

      // Extract the first letter from both parts
      initials = firstNamePart[0].toUpperCase() + secondNamePart[0].toUpperCase();
    } else {
      // If no comma, just take the first two initials
      const nameParts = fullName.split(' ');
      if (nameParts.length > 1) {
        initials = nameParts[0][0].toUpperCase() + nameParts[1][0].toUpperCase();
      } else {
        initials = nameParts[0][0].toUpperCase();
      }
    }

    this.username = initials; // Set the initials as the username
  }

  openSetting(){
     this.dialog.open(SettingsComponent, {
      width: '800px', // Set the width of the modal
    });
  }

  productPage()
  {
    this.router.navigate(['codeGenYLite/products']);
    localStorage.setItem('tab', '0');
  }

  exitApp() {//closeApplication
    localStorage.removeItem('okta-username');
    localStorage.removeItem('token');
    localStorage.removeItem('okta-token-storage');
    

    this.router.navigate(['logout']);
  }

  // open subscription popup ---------------------

  // openSubscriptionPopup() {
  //   if (!this.subsService.isAuthenticated) {
  //     const dialogRef = this.dialog.open(SubscriptionComponent, {
  //       width: '500px'
  //     })
  //     dialogRef.afterClosed().subscribe((data: any) => {
  //       if (data) {
  //         this.getSubscriptionStatus();
  //       }
  //     });
  //   }


  // }

  goToHome() {

    this.router.navigate(['codeGenYLite']);


  }
  // scrollToSection(): void {
  //   console.log('Scrolling to section...');
  //   this.router.navigate(['/']);
  //   const element = document.getElementById('features-section');
  // console.log('Element:', element);

  //   this.loaderService.scrollToElement('features-section'); // Replace with the actual ID of your section
  // }

  getDomain(): string | null {
    return localStorage.getItem('domain');
  }

  isHomePage(): boolean {
    return this.router.url === '/codeGenYLite';
  }

  scrollToSection(section: string) {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  navigateTo(section: string) {
    this.router.navigate(['/codeGenYLite']).then(() => {
      setTimeout(()=>{
        this.scrollToSection(section);
      }, 300);
    });
  }
}

