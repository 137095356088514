export const environment = {
  production: false,
  dev: true,
  uat: false,
  config: 'dev',
  reqPoolingInterval: 8000,//in milisecons
  reqPoolingTimeout: 300000, // in miliseconds
  apiURL: "https://api.playground-dev.genpact.com", // End point of translate file in python
  apiURL1: "https://api.playground-dev.genpact.com/konv", // End point of translate file in python
  languageApi: "https://api.cognitive.microsofttranslator.com/languages?api-version=3.0&scope=translation",

  nodeEndpoint: 'https://api.playground-dev.genpact.com/nodejs/',
  // nodeEndpoint : 'http://localhost:3000/nodejs/',

  oidc: {
    clientId: '0oa5vunv7xo0hZTuc0x7',
    issuer: 'https://genpact.oktapreview.com/oauth2/default',
    redirectUri: 'https://konverse-dev.genpact.com/home',
    // redirectUri: 'http://localhost:4200/home',

    scopes: ['openid', 'profile', 'email'],
  },

  //okta
  LOGOUT_REDIRECT_URI: 'https://konverse-dev.genpact.com/logout',
  // LOGOUT_REDIRECT_URI: 'http://localhost:4200/logout',
};





