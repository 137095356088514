import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AskGenpactService {

  constructor(private http: HttpClient) { }

  getResponse(query: string) {
    // const apiUrl = 'http://127.0.0.1:8081/ask-genpact';
    const apiUrl = 'https://gapi1.playground-uat.genpact.com/ask-genpact';
    const headers = new HttpHeaders({});
    let data =   { "message": query }

    return this.http.post(`${apiUrl}`,data);
  }

  getResponseFromteams(query: string) {
    // const apiUrl = 'http://
    const apiUrl = 'https://gapi1.playground-uat.genpact.com/ask-genpact';
    const headers = new HttpHeaders({});
    let data =   { "chatMessage": query }

    return this.http.post(`${apiUrl}`,data);
  }

  searchSharePointDocuments(query: string,token:any): Observable<any> {
    const url = 'https://graph.microsoft.com/v1.0/search/query';
    const accessToken = localStorage.getItem('mstoken');
    const requestBody = {
      requests: [
        {
          entityTypes: ['chatMessage'],
          query: {
            queryString: query,
          },
        },
      ],
    };

    return this.http.post(url, requestBody, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
  }

}
