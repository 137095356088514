<div class="quiz-container">
    <div class="start-screen" *ngIf="showMainMenu">
      <h1>AI-Awareness Quiz</h1>
      <p>Test your knowledge about Artificial Intelligence!</p>

      <button style="background-color:#00aecf;border-radius:0px;" class="btn btn-primary" (click)="startQuiz()">Start Quiz

        <!-- right arrow -->
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
        </svg>
      </button>
      
      <!-- <button (click)="startQuiz()" class="start-btn">Start Quiz</button> -->
    </div>
  
    <app-quiz *ngIf="showQuizScreen" (finalResult)="finalResult($event)"></app-quiz>
  
    <app-result *ngIf="showResultScreen" [finalResponse]="finalResponse"></app-result>
  </div>
  