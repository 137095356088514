<div class="body">

<!-- Top Navigation Bar -->
<nav class="navbar">
  <!-- Left Section: Menu and Logo -->
  <div class="navbar-left">
    <i class="menu-icon material-icons" (click)="gotohome()">home</i>
    <span class="logo" (click)="gotohome()">Avatar Kraft</span>
  </div>

  <!-- Center Navigation Tabs -->
  <div class="navbar-center">
    <button class="nav-tab" [class.active]="activeTab === 'explore'" (click)="setActiveTab('explore')">Explore</button>
    <button class="nav-tab" [class.active]="activeTab === 'create'" (click)="setActiveTab('create')">Create</button>
    <button class="nav-tab" [class.active]="activeTab === 'edit'" (click)="setActiveTab('edit')">Edit</button>
    <button class="nav-tab" [class.active]="activeTab === 'my-creations'" (click)="setActiveTab('my-creations')">My creations</button>
  </div>

  <!-- Right Section: Notification and Profile -->
  <div class="navbar-right">
    <!-- <div class="notification-circle">20</div> -->
    <div class="profile-circle">V</div>
  </div>
</nav>
<div class="main-content">
<aside class="sidebar">
  <button 
    class="sidebar-btn" 
    [class.active]="selectedButton === 'generate'" 
    (click)="selectButton('generate')">
    <i class="material-icons">widgets</i>
    <span>Generate</span>
  </button>
  <button 
    class="sidebar-btn" 
    [class.active]="selectedButton === 'capture'" 
    (click)="selectButton('capture')">
    <i class="material-icons">camera</i>
    <span>Capture Image</span>
  </button>
  <button 
    class="sidebar-btn" 
    [class.active]="selectedButton === 'quiz'" 
    (click)="selectButton('quiz')">
    <i class="material-icons">quiz</i>
    <span>Quiz</span>
  </button>
</aside>


<div class="upload-section">
    <div class="button-container">
      
      <input type="file" #fileInput (change)="onFileSelected($event)" accept="image/jpeg, image/png" hidden>
      <!-- <label for="fileInput" style="background-color:#00aecf" class="btn btn-primary">Upload Image</label> -->
      <!-- <button  style="background-color:#00aecf" class="btn btn-primary" (click)="triggerFileInputClick()" >Upload Image</button> -->
      
      <!-- <button class="button3" (click)="captureImage()">Capture Image</button> -->
      <button style="background-color:#073262" class="btn btn-primary" (click)="captureImage()">Capture Image</button>
    </div>
  
  
    <canvas #uploadCanvas width="400" height="350"></canvas> 

    <!-- Modal Trigger Button -->
<!-- <button style="background-color:#00aecf" class="btn btn-primary" (click)="openTextModal()">Customise Your Avatar with PromptGen!</button> -->

<!-- Text Modal -->
<div class="text-modal" *ngIf="showTextModal">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Customization Text</h5>
      <button type="button" class="close" (click)="closeTextModal()">&times;</button>
    </div>
    <div class="modal-body">
      <textarea [(ngModel)]="userInput" class="form-control" rows="5" placeholder="Type your text here"></textarea>
    </div>
    <div class="modal-footer">
      <button style="background-color:#00aecf" class="btn btn-primary" (click)="saveText()">Save</button>
      <button style="background-color:#00aecf" class="btn btn-primary" (click)="closeTextModal()">Close</button>
    </div>
  </div>
</div>


    <button style="background-color:#073262" class="btn1 btn-primary" (click)="generateAvatar()">Generate Avatar

      
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
      </svg>
    </button> 
    <!-- <button class="button1" (click)="generateAvatar()" class="generate-avatar-button">Generate Avatar</button>

  </div>-->
  
</div>
  <app-loader 
  *ngIf="loading" >
</app-loader>

  <!-- <app-popup [showPopup]="showPopup"></app-popup> -->
  
  <!-- Modal for capturing image -->
  <div id="captureModal" class="modal">
    <div class="modal-content">
      <span class="close" (click)="closeModal()">&times;</span>
      <video #video width="640" height="360" autoplay></video>
      <canvas #captureCanvas width="640" height="360" style="display:none;"></canvas>
      <button style="background-color:#00aecf" class="btn btn-primary" (click)="captureImageFromVideo()">Capture Image</button>
    </div>
  </div>
  
  <!-- Avatar display component -->
  <app-avatar-display [avatarUrl]="generatedAvatarUrl" [successMessage]="successMessage"></app-avatar-display>
 
  </div>