import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BgcService } from 'src/app/shared/service/bgc.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
//import { VoiceRecognitionService } from 'src/app/shared/voice-recognition.service';

@Component({
  selector: 'app-bgc-init',
  templateUrl: './bgc-init.component.html',
  styleUrls: ['./bgc-init.component.scss']
})
export class BgcInitComponent {
  key: string = '';
  inputtask: any;
  inputname: any;
  windowRef = window;
  pendingpid: any;
  isButtonDisabled: boolean = true;


  selectedOption: any = {};
  options = [
    // { id: 1, name: 'Australia' },
    // { id: 2, name: 'Europe' },
    // { id: 3, name: 'China' },
    { id: 4, name: 'India' },
    // { id: 5, name: 'Latin America' },
    // { id: 6, name: 'North America' },
    // { id: 7, name: 'South Africa' },
    // { id: 8, name: 'Phillippines' },
    // { id: 9, name: 'Other'}

    // Add more options as needed
  ];

  testingOption = [
    { id: 'Non-GE', name: 'Non-GE' },
    { id: 'Digital', name: 'Digital' }
  ];

  taskNameOption = [
    { id: 'New Hire', name: 'New Hire' },
    { id: 'Internal Movement', name: 'Internal Movement' }
  ];

  dropdownSettings: IDropdownSettings = {
    singleSelection: true,  // or false if you want multiple selections
    idField: 'id',          // 'id' is the unique identifier in your options array
    textField: 'name',      // 'name' is the field used for display text
    itemsShowLimit: 3,
    maxHeight: 160,
    allowSearchFilter: true
  };


  processId: any;
  bgcDetailData: any;
  constructor(

    private loaderService: LoaderService,
    private toastMessage: ToastMessageService,
   // public speechservice: VoiceRecognitionService,
    private bgcService: BgcService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.pendingpid = ''; // Provide appropriate default value
    this.selectedOption = []; // Initialize as an array
    this.inputtask = []; // Initialize as an array
    this.inputname = []; // Initialize as an array
    this.processId = '';
    this.route.params.subscribe((res: any) => {
      if (res?.id) {
        this.processId = res.id;
        this.getBGCDetail();
      }
    })

  }
  onDropdownChange() {
    // Call this function whenever your dropdown values change
    // Update the button state
   // console.log('this works');
    this.updateButtonState();
  }

  private updateButtonState() {
    // Adjust the condition based on your requirements
    this.isButtonDisabled = this.pendingpid === 'false' || !this.selectedOption.length || !this.inputtask.length || !this.inputname.length || this.processId;
  }


  bgcintiate() {
    if (this.processId) {
      this.toastMessage.showError('Already process ID generated.')
      return;
    }

    this.loaderService.loading.next(true);
    let token: any = localStorage.getItem('token');
    const payload = {
      "region": this.selectedOption[0].name,
      "taskName": this.inputname[0].name,
      "testingType": this.inputtask[0].name,
      //"token": 'Bearer ' + token,
    }
    this.bgcService.bgcinitiate(payload).subscribe((response: any) => {
      this.loaderService.loading.next(false);
      if (response.status == "success" && response?.data) {
        this.toastMessage.showSuccess(response.message);
        this.loaderService.loading.next(true);
        let tempid=response.data;
        this.bgcService.fatchBGC(tempid).subscribe((response: any) => {
          if (response.status == "success") {
            this.loaderService.loading.next(false);
            this.router.navigate(['bgc/' + tempid, 'upload']);
          }
          else {
            this.loaderService.loading.next(false);
            this.toastMessage.showError(response.error_message);
          }

        },
          (error) => {
            this.loaderService.loading.next(false);
            this.toastMessage.showError(error.error_message);
          });

      }
      else {
        this.toastMessage.showError(response.error_message);
      }
    }, (error) => {
      this.loaderService.loading.next(false);
    });
  }


  getBGCDetail() {

    this.loaderService.loading.next(true);

    this.bgcService.fatchBGC(this.processId).subscribe((response: any) => {
      this.loaderService.loading.next(false);
      if (response.status == "success") {
        this.bgcDetailData = response.data;

        let selectedRegion = this.options.find((ele: any) => ele.name == this.bgcDetailData?.region);
        let selectedTask = this.testingOption.find((ele: any) => ele.name == this.bgcDetailData?.testingType);
        let selectedName= this.taskNameOption.find((ele: any) => ele.name == this.bgcDetailData?.taskName);

        this.selectedOption = [selectedRegion] || '';
        this.inputtask = [selectedTask] || '';
        this.inputname = [selectedName] || '';
      }
      else {
        this.toastMessage.showError(response.error_message);
        //this.router.navigate(['bgc']);
      }
    }, (error:any) => {
      if (error?.error?.error_message) {
        this.router.navigate(['bgc']);
      }
      this.toastMessage.showError(error?.error?.error_message);
      this.loaderService.loading.next(false);
      console.log(error);
    });
  }
}
