<div class="devTalk">
  <p class="code-enhance-header">codeGenY Lite improves developer efficiency with a GenAI-powered chat system, reducing
    development time and increasing productivity. It reduces turnaround time by swiftly providing resolutions to coding
    queries.</p>
    <div class="header-links">
      <a class="privacypopup" (click)="openDialog()">Privacy Policy</a>
      <span class="code-enhance-header">Generated Code Plagiarism Checker Tool: <a class="goToPlagCheck" href="https://www.duplichecker.com/" target="_blank"> Duplichecker Tool</a></span>
    </div>
</div>
<div class="devTalk">
  <div class="row gx-1 gy-1">
    <div class="col">
      <div class="chat-section">
        <div class="chat-window" #ChatWindow>
          <div class="message">
            <div class="user-message">
              <div class="profile-icon">
                <img src="../../../assets/images/aiProfile.png" alt="">
              </div>
              <div class="message-content" style="background: #fff;">
                <div class="message-text" style="color: #212529; letter-spacing: 1.2px;">Hello and welcome! I am your
                  personal Coding AI Assistant, codeGenY Lite, your Infinite Subject Matter Expert, ready to assist you across
                  all subject areas and design principles for coding. You can refer to me as 'codeGenY Lite' throughout our
                  interaction. My primary mission is to craft top-notch software solutions.As an expert in programming,
                  security, documentation, and a follower of industry best practices, including SOLID and DRY
                  principles, I will diligently work on your software project.I'll be sure to ask questions to gain a
                  complete understanding of your specifications before I engineer the software. No need for you to
                  provide code – I'm here to assist you in every step.</div>
              </div>
            </div>
          </div>
          <div *ngFor="let message of chatMessages" class="message"
            [ngClass]="{'user': message.type === 'user', 'bot': message.type === 'bot'}">
            <!-- User Message -->
            <div *ngIf="message.type === 'user'" class="user-message">
              <div class="profile-icon">
                <img src="../../../assets/images/userProfile.png" alt="">
              </div>
              <div class="message-content">
                <div class="message-text">{{ message.message }}</div>
              </div>
            </div>
            <!-- Bot Message -->
            <div *ngIf="message.type === 'bot'" class="bot-message">
              <div class="profile-icon">
                <img src="../../../assets/images/aiProfile.png" alt="">
              </div>
              <div class="message-content">

                <div *ngIf="blocks.length > 0">
                  <div *ngFor="let block of message.message; let i = index">

                    <div *ngIf="block.type === 'text' && block.content && block.content!=''">
                      <pre class="precontent" [innerHTML]="block.content"></pre>
                      <br *ngIf="!(i==(message.message-1))" />
                    </div>
                    <div *ngIf="block.type === 'code' && block.content && block.content!=''">
                      <!--<pre>{{ block.content }}</pre>-->

                      <div class="enhanced-header">
                        <p>{{block.lng}}</p>
                        <div class="icons">
                          <img class="img-1" src="../../../../assets/images/copy.png" alt=""
                            (click)="copyText(block.content)" [matTooltip]="tooltipText" [matTooltipPosition]="'above'">
                          <img class="img-2" src="../../../../assets/images/download.png" alt="" (click)="downloadFile(block.content)" [matTooltip]="'Download'" [matTooltipPosition]="'above'">
                        </div>
                      </div>
                      <div class="enhanced-content">
                        <!--<pre><code>{{codeEnhance.improved_code}}</code></pre>-->
                        <ngx-codemirror [(ngModel)]="block.content" [options]="codeMirrorOptions"></ngx-codemirror>
                      </div>
                      <br *ngIf="!(i==(message.message-1))" />
                    </div>

                  </div>
                </div>


                <!--<div class="message-text">{{message.message}}</div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="input-area">
        <textarea #textarea id="textarea" class="form-control custom-control" (input)="adjustTextareaHeight($event)"
          [(ngModel)]="userMessage" (keydown.enter)="sendMessage($event)" placeholder="Start typing here....."
          [formControl]="messageFormControl"></textarea>
        <!-- <textarea class="form-control custom-control" [(ngModel)]="userMessage" (keydown.enter)="sendMessage($event)"
          placeholder="Start typing here....."
          [formControl]="messageFormControl">

        </textarea> -->
        <!-- <div class="custom-control" contenteditable="true">Start Typing Here....</div> -->
        <button class="chat-btn" (click)="sendMessage()">
          <img src="../../../../assets/images/sendIcon.png" alt="">
        </button>



        <!-- Display custom loader based on the showCustomLoader property -->
        <div *ngIf="showCustomLoader" class="custom-loader">
          <!-- <div class="custom-loader"> -->
          <!-- Your custom loader content goes here -->
          <div class="dots"></div>
        </div>
      </div>
      <small class="text-danger" *ngIf="messageFormControl.hasError('minlength')">
        <!-- <small class="text-danger"> -->
        Message must be at least 2 characters long.
      </small>
      <small class="text-danger" *ngIf="messageFormControl.hasError('maxlength')">
        Message cannot exceed 40000 characters.
      </small>






      <!-- <div class="input-area">
        <textarea
          class="form-control custom-control"
          [(ngModel)]="userMessage"
          (keyup.enter)="sendMessage()"
          placeholder="Start typing here....."
          (input)="adjustTextareaHeight($event)"
        ></textarea>
        <button class="chat-btn" (click)="sendMessage()">
          <i class="fa-solid fa-paper-plane"></i>
        </button>
      </div> -->
    </div>
  </div>
</div>
