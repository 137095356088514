import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AskGenpactService } from '../shared/service/ask-genpact.service';
import { LoaderService } from '../shared/service/loader.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';

@Component({
  selector: 'app-ask-genpact',
  templateUrl: './ask-genpact.component.html',
  styleUrls: ['./ask-genpact.component.scss']
})
export class AskGenpactComponent implements OnInit, OnDestroy {
  chatSubscriber!: Subscription;
  chatPayload: any;
  chatData: any = [];
  resetChatStatus: boolean = false;
  token: string = '';

  constructor(
    private askGenpactService: AskGenpactService,
    private toastMessage: ToastMessageService,
    private loaderService: LoaderService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.chatPayload = {
      name: 'Ask Genpact',
      description: "Welcome to the Ask Genpact AI Assistant!. How can I assist you today?"
    };
  }

  ngOnInit(): void {
    this.login();
    this.msalBroadcastService.inProgress$.subscribe((status: InteractionStatus) => {
      if (status === InteractionStatus.None && this.authService.instance.getAllAccounts().length > 0) {
        this.authService.acquireTokenSilent({
          scopes: ["Sites.Read.All"]
        }).toPromise().then((result: AuthenticationResult | undefined) => {
          if (result) {
            this.token = result.accessToken;
            localStorage.setItem('mstoken', this.token);
          }
        }).catch(error => {
          console.error('Token acquisition error:', error);
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.chatSubscriber) {
      this.chatSubscriber.unsubscribe();
    }
  }

  login(): void {
    this.authService.loginPopup({
      scopes: ["Sites.Read.All"]
    }).subscribe((response: AuthenticationResult) => {
      this.authService.instance.setActiveAccount(response.account);
    }, error => {
      console.error('Login error:', error);
    });
  }

  getResponse(event: any) {
    let prompt = this.chatData[this.chatData?.length - 1];

    this.loaderService.loading.next(true);
    this.resetChatStatus = false;

    this.askGenpactService. searchSharePointDocuments(prompt?.content,this.token).subscribe(
      (res: any) => {
        this.loaderService.loading.next(false);
        console.log(res);

        // Extract and format the content
        if (res && res.data) {
          let formattedContent = this.formatResponse(res.data);
          if (formattedContent) {
            let resultResponse = { "role": "assistant", "content": formattedContent };
            this.getResponse1( res.data);

            //this.chatData.push(resultResponse);
          } else {
            let resultResponse = { "role": "assistant", "content": 'Unable to parse response' };
            this.getResponse1( res.data);
           // this.chatData.push(resultResponse);
          }
        } else {
          let resultResponse = { "role": "assistant", "content": 'Invalid response format' };
          this.getResponse1(prompt?.content);
          // this.chatData.push(resultResponse);
        }

        this.resetChatStatus = true;
      },
      (error: any) => {
        this.loaderService.loading.next(false);
        this.toastMessage.showError('Something went wrong');
        this.resetChatStatus = true;
      }
    );
  }

  getResponse1(ans: any) {
    let prompt = this.chatData[this.chatData?.length - 1];

    this.loaderService.loading.next(true);
    this.resetChatStatus = false;

    this.askGenpactService.getResponse(prompt?.content).subscribe(
      (res: any) => {
        this.loaderService.loading.next(false);
        console.log(res);

        // Extract and format the content
        if (res && res.data) {
          let formattedContent = this.formatResponse(res.data);
          if (formattedContent) {
            let resultResponse = { "role": "assistant", "content": formattedContent };
            this.chatData.push(resultResponse);
          } else {
            let resultResponse = { "role": "assistant", "content": 'Unable to parse response' };
            this.chatData.push(resultResponse);
          }
        } else {
          let resultResponse = { "role": "assistant", "content": 'Invalid response format' };
          this.chatData.push(resultResponse);
        }

        this.resetChatStatus = true;
      },
      (error: any) => {
        this.loaderService.loading.next(false);
        this.toastMessage.showError('Something went wrong');
        this.resetChatStatus = true;
      }
    );
  }



  formatResponse(response: string): string {
    // Clean up the response to handle escape characters
    const cleanedResponse = response.replace(/\\n/g, '\n').replace(/\\\\/g, '\\');

    // Check if the response contains table-like structure
    const tableRegex = /<p>\s*\|\s*(.*?)\s*\|\s*<\/p>/g;
    if (tableRegex.test(cleanedResponse)) {
      // Format as HTML table
      let tableHtml = '<table>';
      let match;
      while ((match = tableRegex.exec(cleanedResponse)) !== null) {
        const rowContent = match[1].trim();
        const cells = rowContent.split(/\s*\|\s*/).filter(cell => cell !== '');
        tableHtml += '<tr>';
        cells.forEach(cell => {
          tableHtml += `<td>${cell}</td>`;
        });
        tableHtml += '</tr>';
      }
      tableHtml += '</table>';
      return tableHtml;
    } else {
      // Return plain text content if no table-like structure found
      return `<p>${cleanedResponse}</p>`;
    }
  }

  escapeForMarkdown(data: string): string {
    return data.replace(/\\/g, '\\\\');
  }
}
