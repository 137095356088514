import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
// import { ImageFloaterComponent } from "../../digital-avatar/image-floater/image-floater.component";
// import { MultiFormComponent } from 'src/app/digital-avatar/multi-form/multi-form.component';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  standalone:true,
  imports: [CommonModule, FormsModule],
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  constructor(private router: Router) {}

  @Output() promptProvided = new EventEmitter<string>();

  userPrompt: string = '';
  public currentStep = 0;

  generateImage(): void {
    this.router.navigate(['/prompt-gen/avatar-kraft/generator'], {
      queryParams: { prompt: this.userPrompt },
    });
    console.log('Navigate with prompt:', this.userPrompt);
  }
  generateprompt() : void{
    this.router.navigate(['/prompt-gen/'], {
    });
  }

  sendQuery(type: string):void{
    if (type === 'Fish'){
      this.userPrompt=`# Prompt:
                      Create a highly detailed and realistic image of a fish walking on land. The fish should have legs that resemble those of an amphibian, allowing it to move on the ground. The background should depict a natural terrestrial environment, such as a forest or a grassy field, with appropriate lighting and shadows to enhance realism. Ensure the fish's scales, colors, and textures are vividly rendered, and its expression should convey the novelty of this unusual situation. Use a high-resolution format suitable for both digital and print purposes.

                      # Example:
                      An image of a brightly colored fish with amphibian-like legs walking through a lush, green forest clearing. The fish's scales glisten in the sunlight filtering through the trees, and its eyes show a curious, almost bewildered look as it navigates the land.`;
    }else if (type === 'Horse'){
      this.userPrompt=`#Prompt:  
                      A breathtaking image of a flying horse soaring above the clouds. The horse is pure white with shimmering golden wings that catch the sunlight, creating a radiant glow. Its mane flows gracefully in the wind, blending with the vibrant hues of a sunrise sky. The background features layers of pink, orange, and purple clouds with streaks of sunlight breaking through. Below, a dreamy landscape of rolling green hills and sparkling rivers is visible. The atmosphere feels magical and serene, evoking a sense of wonder and freedom. The horse appears majestic, embodying strength, grace, and the essence of fantasy.

                      #Example:  
                      Imagine a Pegasus-like horse gliding through pastel-colored skies at dawn. Its wings are wide and glowing, the sky filled with soft, warm colors, and faint mountains are visible in the distance. The entire scene feels enchanting, as though from a mythical storybook.`;
    }else if (type === 'Super'){
      this.userPrompt=`#Prompt: 
                      A heroic image of Superman flying high above a bustling city skyline. He is wearing his iconic blue suit with the bold red "S" emblem on his chest, a flowing red cape, and red boots. His determined expression reflects his unwavering resolve to protect humanity. The background features tall skyscrapers, a glowing sunset casting golden hues across the scene, and clouds parting as he soars through the air. His fists are clenched, and his body is positioned dynamically as if ready to take on any challenge. The atmosphere is vibrant and inspiring, embodying hope and strength.
                      #Example:  
                      Picture Superman mid-flight over Metropolis, his red cape billowing against a golden-orange sunset. The city below is alive with light and activity. His iconic costume stands out, and his confident gaze reflects his readiness to face danger. The scene radiates courage and optimism.`;
    }else if (type === 'Spidey'){
      this.userPrompt=`#Prompt:  
                      A dynamic image of Spider-Man swinging through a bustling city at night. He wears his iconic red and blue suit with intricate web patterns, highlighted by the glowing lights of skyscrapers around him. His pose is action-packed, with one hand shooting a web and the other gripping a webline. The background features a vibrant cityscape with lit windows, neon signs, and a full moon casting a soft glow. The streets below are dotted with traffic and blurred motion, emphasizing his agility and speed. The atmosphere captures Spider-Man’s heroic and energetic spirit, set against the lively urban setting.
                      #Example:  
                      Visualize Spider-Man leaping between tall buildings, his suit gleaming under the city lights. One arm is extended, firing a webline, while his surroundings reflect a vivid, bustling cityscape at night. The entire scene captures his iconic web-slinging action and the thrill of his acrobatics.`;
    }else if (type === 'Hulk'){
      this.userPrompt=`Create a high-resolution image of the Hulk. Ensure the following details are included:
                        - The Hulk should be in a dynamic action pose, showcasing his immense strength and size.
                        - The background should be a cityscape, partially destroyed to emphasize the Hulk's power.
                        - Use vibrant colors to highlight the Hulk's green skin and muscular build.
                        - Include fine details such as veins, textures on the skin, and realistic shadows to enhance the overall quality of the image.
                        - The image should be suitable for use as a desktop wallpaper or in a digital art portfolio.
                        
                        # Example:
                        A detailed and vibrant image of the Hulk smashing through a city street, with debris flying around and his muscles prominently displayed.`;
    }else if (type === 'Mfish'){
      this.userPrompt=`#Prompt:  
                      A striking depiction of a muscular fish with a humanoid physique, blending aquatic and athletic features. The fish has bulging, well-defined muscles, resembling a bodybuilder, with a shimmering blue and silver-scaled body. Its fins are exaggerated, appearing powerful and sleek, while its face retains sharp, predatory features with piercing eyes. The background shows an underwater scene illuminated by beams of sunlight filtering through the water's surface, surrounded by coral reefs and schools of smaller fish. The atmosphere is vibrant and dynamic, emphasizing the fish's strength and dominance in its environment, creating a mix of fantasy and surrealism.
                      #Example:  
                      Picture a fish with the upper body of a muscular human, featuring chiseled arms and a broad chest, yet retaining its scaled texture. Its environment is a glowing ocean floor with coral and shimmering light rays, creating an otherworldly but compelling visual.`;
    }else if (type === 'Cap'){
      this.userPrompt=`#Prompt:
                      Create a high-resolution digital image of Captain America. Ensure the following elements are included:
                      1. Captain America should be in his iconic costume, featuring the star on his chest, red and white stripes, and blue field.
                      2. Include his shield prominently, either being held or in action.
                      3. The background should be dynamic, such as a cityscape, battlefield, or an American flag.
                      4. Pay attention to lighting and shadows to give the image depth and realism.
                      5. The overall tone should be heroic and inspiring, capturing the essence of the character.

                      #Example:
                      Generate a high-resolution image of Captain America standing on a battlefield, holding his shield in a defensive stance, with an American flag waving in the background.`;
    }else if (type === 'Bat'){
      this.userPrompt=`#Prompt:  
                      A dynamic depiction of Batman standing atop a towering Gotham City skyscraper at night. The scene is illuminated by the Bat-Signal glowing against a cloudy, moonlit sky. Batman’s iconic black suit is sleek and armored, with a long flowing cape dramatically swept by the wind. His piercing white eyes reflect determination, and his muscular stance radiates power and vigilance. The cityscape below is dimly lit, showcasing a mix of modern and gothic architecture. Dark storm clouds and scattered rain add a gritty, noir atmosphere, enhancing the essence of Gotham’s brooding protector.

                      #Example:  
                      Visualize Batman on a rain-soaked rooftop, his cape billowing in the wind. The glowing Bat-Signal and Gotham's skyline frame the scene, creating a dark and heroic ambiance. The rain and dramatic lighting highlight his sharp silhouette.`;
    }else if (type === 'Widow'){
      this.userPrompt=`# Prompt:
                      Create a high-resolution digital image of Black Widow, the Marvel superhero. Ensure that the character is depicted in her iconic black tactical suit, with red hair and a confident pose. The background should be a modern cityscape at night, with subtle lighting that highlights her figure. Use realistic textures and shading to bring out the details in her suit and surroundings.
                      # Example:
                      Generate an image of Black Widow standing on a rooftop overlooking a city at night, with her hair flowing and the city lights in the background.`;
    }else if (type === 'Wand'){
      this.userPrompt=`Magic wand in the hand`;
    } 

    this.router.navigate(['/prompt-gen/avatar-kraft/generator'], {
      queryParams: { prompt: this.userPrompt },
    }); 
  }


}
