import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, from, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PodcastService{
  private apiUrl = environment.podurl; // Replace with your backend URL

  constructor(private http: HttpClient) {}


  getDataAsPromiseGet(url: string, headers: any = {}): Promise<any> {
    let token: any = localStorage.getItem('token');
  
    // Add authorization header with bearer token
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    };
  
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'GET',
        headers: headers,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(result => resolve(result))
        .catch(error => reject(error));
    });
  }
  
  getDataAsPromisedelete(url: string, headers: any = {}): Promise<any> {
    let token: any = localStorage.getItem('token');
  
    // Add authorization header with bearer token
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    };
  
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'DELETE',
        headers: headers,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(result => resolve(result))
        .catch(error => reject(error));
    });
  }

  //ulpoad file to /podcasts post api no pipe
  uploadFile(file: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/podcasts`, file);
  }

  //get podcast list from /podcasts get api no pipe with id url
//   getPodcastList(pid: string): Observable<any> {
//     const token = localStorage.getItem('token');
    
//     // Check if token exists
//     if (!token) {
//         console.error('Token not found');
//         return throwError(() => new Error('No token available')); // Return an error observable
//     }

//     const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

//     return this.http.get<any>(`${this.apiUrl}/podcasts/${pid}`, { headers })
//         .pipe(
//             catchError(error => {
//                 console.error('Error fetching podcast list:', error);
//                 return throwError(() => error); // Forward the error
//             })
//         );
// }

//using getdarta promise get podcast list from /podcasts get api no pipe with id url
getPodcastList(pid: string): Observable<any> {

  const promise = this.getDataAsPromiseGet(`${this.apiUrl}/podcasts/${pid}`, { method: 'GET' }); // Calling your method that returns a Promise
    return from(promise);
}

//get promise {{API_URL}}/podcasts/<pod_id>
deletePodcast(pid: string): Observable<any> {
  const promise = this.getDataAsPromisedelete(`${this.apiUrl}/podcasts/${pid}`, { method: 'DELETE' }); // Calling your method that returns a Promise
  return from(promise);
}

// /POST - /podcasts/{podid}/generate
  // generatePodcast(pid:any): Observable<any> {
  //   return this.http.post(`${this.apiUrl}/podcasts/${pid}/generate`, {});
  // }
  // history GET - /podcasts
  getHistoryData(): Observable<any> {
    return this.http.get(`${this.apiUrl}/podcasts`);
  }
//post /podcasts/${pid}/generate-conversation
generateConversation(pid: any,istoggled:boolean): Observable<any> {
  const ttsService = !istoggled ? 'GOOGLE' : 'ELEVENLABS'; // Replace 'istoggled' with your logic

  return this.http.post(`${this.apiUrl}/podcasts/${pid}/generate-conversation`, {
    tts_service: ttsService
// You can change this to "GOOGLE" as needed
  });
}


  // post podcasts/${pid}/generate-audio
  generateAudio(pid:any,istoggled:boolean): Observable<any> {
    const ttsService = !istoggled ? 'GOOGLE' : 'ELEVENLABS'; // Replace 'istoggle' with your logic

    return this.http.post(`${this.apiUrl}/podcasts/${pid}/generate-audio`, {
      tts_service: ttsService
      // You can change this to "ELEVENLABS" as needed
    });
  }

  //{{API_URL}}/podcasts/96a5c6/conversion-status?type=text
  // get podcasts/${pid}/conversion-status?type=text
  getConversionStatus(pid:any): Observable<any> {
    return this.http.get(`${this.apiUrl}/podcasts/${pid}/conversion-status?type=text`);
  }
  // get podcasts/${pid}/conversion-status?type=audio
  getConversionStatusAudio(pid:any): Observable<any> {
    return this.http.get(`${this.apiUrl}/podcasts/${pid}/conversion-status?type=audio`);
  }
  //GET - {{API_URL}}/podcasts/{pod_id}/audio - GET the generated full audio.
  getGeneratedAudio(pid:any): Observable<any> {
    return this.http.get(`${this.apiUrl}/podcasts/${pid}/audio?download=true`);
  }
//PUT - {{API_URL}}/podcasts/<pod_id> use fetch method
  updatePodcast(pid:any, data:any): Observable<any> {
    const token = localStorage.getItem('token');
    let headers = {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json'
    };
    return new Observable((observer) => {
      fetch(`${this.apiUrl}/podcasts/${pid}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(result => {
          observer.next(result);
          observer.complete();
        })
        .catch(error => {
          observer.error(error);
          observer.complete();
        });
    });
  }

 

}

