import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomItService {

  

  endpoint = 'https://api.playground-dev.genpact.com/custom-it-assist';
  endpoint1 = environment.apiURL;
  endpoint2 = '';

  constructor(private http: HttpClient, private router: Router) { }

  sendMessage(payload: { messages: { role: string; content: string }[] }): Observable<any> {
    const query = payload.messages[payload.messages.length - 1].content;
    return this.http.post(`${this.endpoint}/getanswer`, { messages: payload.messages })
      .pipe(catchError(this.handleError));
  }

  uploadFile(payload: FormData): Observable<any> {
    return this.http.post(`${environment.apiURL}/upload`, payload)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('An error occurred:', error.message);
    return throwError(() => new Error('An error occurred; please try again later.'));
  }

}