import { Component, Input, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DigiService } from 'src/app/digi.service'; 

@Component({
  selector: 'app-personality-text',
  templateUrl: './personality-text.component.html',
  standalone:true,
  imports:[CommonModule],
  styleUrls: ['./personality-text.component.scss']
})
export class PersonalityTextComponent implements OnInit, AfterViewInit{

  @Input() avatarUrl: string = ''; 
  @Input() successMessage: string = "Avatar is Ready!! Enjoy AK's creations...";
  @Input() avatar: string = '';
  @Input() personalityTraits: string = '';

  @ViewChild('textCanvas', { static: false }) textCanvas!: ElementRef<HTMLCanvasElement>;

  text: string = 'Take our AI quiz next to find out what kind of AI Leader you are!';
  isTextGenerated: boolean = false;

  constructor(private digiService: DigiService) {}

  ngOnInit() {
    
    this.digiService.personalityTraits$.subscribe((text: string) => {
      if (text) {
        this.displayTextOnCanvas(text);
        this.isTextGenerated = true; 
      }
    });
  }

  ngAfterViewInit() {
    this.displayText(this.text);
  }

  displayTextOnCanvas(text: string) {
    const canvas = this.textCanvas.nativeElement;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      canvas.width = 700;
      canvas.height = 200;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = '#000';
      ctx.font = '22px Georgia Pro';

      const maxWidth = canvas.width * 0.9; 
      const lineHeight = 24; 
      const x = canvas.width * 0.05; 
      let y = 30;

      const words = text.split(' ');
      let line = '';

      for (let i = 0; i < words.length; i++) {
        const testLine = line + words[i] + ' ';
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;

        if (testWidth > maxWidth && i > 0) {
          ctx.fillText(line, x, y); 
          line = words[i] + ' ';
          y += lineHeight; 
        } else {
          line = testLine;
        }
      }
      ctx.fillText(line, x, y);
      this.isTextGenerated = true;
    } else {
      console.error('Canvas context is not available');
    }
  }

  displayText(text: string) {
    const canvas = this.textCanvas.nativeElement;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      canvas.width = 600;
      canvas.height = 300;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.fillStyle = '#000';
      ctx.font = '22px Georgia Pro'; 

      const maxWidth = canvas.width * 0.85; 
      const lineHeight = 28; 
      let y = canvas.height / 4; 

      const words = text.split(' ');
      let line = '';
      const lines: string[] = [];

      for (let i = 0; i < words.length; i++) {
        const testLine = line + words[i] + ' ';
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;

        if (testWidth > maxWidth && i > 0) {
          lines.push(line);
          line = words[i] + ' ';
        } else {
          line = testLine;
        }
      }
      lines.push(line);

      const totalTextHeight = lines.length * lineHeight;
      y = (canvas.height - totalTextHeight) / 2 + lineHeight;

      lines.forEach(line => {
        const metrics = ctx.measureText(line);
        const x = (canvas.width - metrics.width) / 2; 
        ctx.fillText(line, x, y);
        y += lineHeight;
      });
    } else {
      console.error('Canvas context is not available');
    }
  }

}
