import { Component, OnInit, ViewChild } from '@angular/core';
import { HistoryService } from 'src/app/shared/service/history.service';
import { MatSliderChange, MatSliderModule } from '@angular/material/slider'; // Add this import statement

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  historyItems: any[] = [];
  audioTitle: string = 'Mugifier Documents';
  audioUrl: string = 'path/to/your/audio/file.mp3'; // Replace with your audio file path
  audio!: HTMLAudioElement ;
  isPlaying: boolean = false;
  currentTime: number = 0;
  duration: number = 0;
  interval: any;
  @ViewChild('audioPlayer') audioPlayer!: HTMLAudioElement;
  constructor(private historyService: HistoryService) {}

  ngOnInit(): void {
    this.historyService.getHistoryData().subscribe((data) => {
      this.historyItems = data; // Data will be real or dummy depending on API response
    });

  
  }

  seekAudio(event: any): void {
    const newValue = event.value; // Access the slider's new value
    this.audioPlayer.currentTime = newValue; // Seek to the new value
    console.log('New audio time:', newValue);
  }

  updateSlider(): void {
    // This method updates the slider as the audio plays
    if (this.audioPlayer) {
      // You might need to trigger change detection here if you're using `ngModel`
      // this.currentTime = this.audioPlayer.currentTime;
    }
  }

  changeVolume(amount: number): void {
    // Change volume within the range of 0.0 to 1.0
    if (this.audioPlayer) {
      this.audioPlayer.volume = Math.min(Math.max(this.audioPlayer.volume + amount, 0), 1);
      console.log('Volume:', this.audioPlayer.volume);
    }
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${this.pad(minutes)}:${this.pad(secs)}`;
  }

  pad(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }
}
