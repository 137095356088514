import { Component } from '@angular/core';
import { ModalService } from '../shared/service/modal.service';

@Component({
  selector: 'app-successstories',
  templateUrl: './successstories.component.html',
  styleUrls: ['./successstories.component.scss']
})
export class SuccessstoriesComponent {

  modalOpen: boolean = false;
  modalImage: string = '';
  showMore: boolean = false;
  images: string[] = [
    'assets/newicons/1.png',
    'assets/image2.jpg',
    'assets/image3.jpg',
    'assets/image4.jpg',
    'assets/image5.jpg',
    // Add more image paths as needed
  ];
  index: number = 0;
  j: number = 0;
  showContent = false;
  toggleContent() {
    this.showContent = !this.showContent;
  }
  constructor(private modalService: ModalService) {}

//


openModal(imageUrl: string) {
  this.modalService.openModal(imageUrl);
}

testClick() {
  console.log('test click');
}

  // Flag to control displaying more images



}
