import { Component } from '@angular/core';

@Component({
  selector: 'app-converse-ai',
  templateUrl: './converse-ai.component.html',
  styleUrls: ['./converse-ai.component.scss']
})
export class ConverseAiComponent {

}
