export class MessageTypeSnack {
  public static readonly Error: string = "Error!";
  public static readonly Warning: string = "Warning!";
  public static readonly Success: string = "Success!";
}

export class StatusCode {
  public static readonly Error: number = 400;
  public static readonly ServerError: number = 500;
  public static readonly Success: number = 200;
}

export class GlobalVars {
  //public static readonly ApiBaseUrl: string = "http://127.0.0.1:8051";
}

export class ApiEndpoints {
  // public static readonly CheckSubs: string = "/check_subscription";
  // public static readonly CheckSubsCustom: string = "/check_subscription";
  // public static readonly SetSubs: string = "/set_subscription";
  // public static readonly SetSubsCustom: string = "/set_subscription";
  // public static readonly CloseApp: string = "/close_application";
  // public static readonly CloseAppCustom: string = "/close_application";
  public static readonly ActivityAudit: string = "/log_activity";
  public static readonly ActivityAuditCustom: string = "/log_activity";
  public static readonly GetGptResponse: string = "/get_gpt_response";
  public static readonly GetGptResponseCustom: string = "Dev_Talk/get_gpt_response";
  public static readonly ResetChat: string = "/reset_chat";
  public static readonly ResetChatCustom: string = "/reset_chat";
  public static readonly UploadFile: string = "/upload_file";
  public static readonly UploadFileCustom: string = "/upload_file";
  public static readonly Enhance: string = "/enhance";
  public static readonly EnhanceCustom: string = "Code_Enhancement/enhance";
  public static readonly GetDocumentation: string = "/get_documentation";
  public static readonly GetDocumentationCustom: string = "Code_Documentation/get_documentation";
  public static readonly DownloadCodeDocumentation: string = "/download_code_documentation";
  public static readonly DownloadCodeDocumentationCustom: string = "/download_code_documentation";
  public static readonly GenerateBrd: string = "/generate_brd";
  public static readonly GenerateBrdCustom: string = "Build_Your_Project/generate_brd";
  public static readonly GetSDD: string = "/get_sdd";
  public static readonly GetSDDCustom: string = "Build_Your_Project/get_sdd";
  public static readonly GenerateCode: string = "/generate_code";
  public static readonly GenerateCodeCustom: string = "Build_Your_Project/generate_code";
  public static readonly CheckDocAvailability: string = "/check_document_status";
  public static readonly CheckDocAvailabilityCustom: string = "/check_document_status";
}
