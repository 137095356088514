<app-sidenav [item]="'playground'"></app-sidenav>
<div class="container-row">
  <div class="container-col">
    <div class="row">
      <div class="col-12 mt-2">
        <div class="header">
          <h1 class="gradient-text">MSA Assist</h1>
        </div>
      </div>

      <div class="text-dark" style="text-align: center;">
        <div class="content mt-2" style="display: flex; align-items: center; justify-content: center; gap: 0.5em;">
            <!-- Label next to the dropdown -->
            <!-- <label for="accountDropdown" style="font-family: Georgia; font-size: 1.1em;">
                Choose your Account name:
            </label> -->
    
            <!-- Dropdown for selecting account name -->
            <!-- <select 
                id="accountDropdown" 
                [(ngModel)]="selectedAccount" 
                (change)="onAccountChange()" 
                style="padding: 0.5em; font-size: 1em; border-radius: 5px; border: 1px solid #ccc;">
                <option value="" disabled selected>Select an Account</option>
                <option *ngFor="let account of accounts" [value]="account">{{ account }}</option>
            </select> -->
            <!-- newChat() sendQuery('compliance') sendQuery('obligation') -->
            <!-- <button 
                    class="hover-button compliance-btn" 
                    (click)="newChat('compliance')" 
                    title="Get Compliance"
                    style=" top: -55px; right: 700px; z-index: 10; background-color: #00aecf;">
                    Get HR Compliances
                  </button>
                  <button 
                    class="hover-button obligation-btn" 
                    (click)="newChat('obligation')" 
                    title="Get Obligations"
                    style=" top: -55px; right: 550px; z-index: 10;background-color: #00aecf;">
                    Get HR Obligations
                  </button>
        </div> -->
        
        <div class="container">
          <div class="row align-items-stretch justify-content-center">
            <!-- Left Box: Checkboxes -->
            <div class="col-md-5">
              <div class="box">
                <h4>Fields</h4>
                <div *ngFor="let checkbox of checkboxes">
                  <input
                    type="radio"
                    [id]="checkbox.value"
                    [value]="checkbox.value"
                    name="checkboxGroup"
                    [(ngModel)]="selectedCheckbox"
                  />
                  <label [for]="checkbox.value">{{ checkbox.label }}</label>
                </div>
              </div>
            </div>
        
            <!-- Right Box: Dropdown -->
            <div class="col-md-5">
              <div class="box">
                <h4>Select an Account</h4>
                <select class="form-select" [(ngModel)]="selectedDropdown">
                  <option *ngFor="let option of dropdownOptions" [value]="option.value">
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mt-3" style="display: flex !important; justify-content: space-around;">
              <button class="btn btn-primary" style="background-color: #00aecf;" (click)="generate()">Generate</button>
            </div>
          </div>
        
          <!-- Generate Button -->
          
        </div>
        
        
        
    
        <!-- Greeting message -->
        
   
     
            <!-- <br><br><br><br><br><br><br><br> -->
          </div>
        </div>
      </div>
    </div>

    <!-- text area user input -->
     <div *ngIf="selectedFiles">
    <!-- <br><br><br><br><br><br> -->
  </div>
    <div class="question-block">
      <div class="row">
        <div class="offset-lg-1 col-lg-10 col-12">
          <div class="row">
            <div class="col-12">
              <div class="chat-container">
                <div class="new-topic" (click)="newChat('obligation')">
                  <img class="w-100" placement="top" ngbPopover="New Topic" popoverClass="pop-block" triggers="mouseenter:mouseleave" src="/assets/images/new_chat.png" alt="">
                </div>
                <div class="ask-question">
                  <div class="query-section">
                    <div class="upload_file">
                      <div class="file-upload-block pb-2" *ngFor="let file of selectedFiles">
                        <div class="file_icon_remove" (click)="uploadFile('reset')">
                          <img src="/assets/images/icons/cross.svg">
                        </div>
                        <div class="file_icon">
                          <img src="/assets/images/icons/file_upload.svg">
                        </div>
                        <div class="file-detail">
                          <div [title]="file?.name" class="file-lable ellipsis">{{ file?.name }}</div>
                          <div [title]="file?.type" class="file-type ellipsis">{{ file?.type }}</div>
                        </div>
                      </div>
                    </div>

                    

                    <textarea [maxlength]="5000" id="textArea" rows="1" class="form-control" placeholder="Ask your question" (input)="autoResize($event)" (keyup.enter)="handleEnter()" [(ngModel)]="prompt"></textarea>
                  </div>
                  <!-- <button class="btn " (click)="uploadFile()">
                    <input class="d-none" [accept]="inputAccept" (change)="uploads($event)" type='file' id="upload_chatFile" />
                    <img [title]="inputAccept" src="/assets/images/icons/attachment.svg" alt="">
                  </button> -->
                  <button class="btn" (click)="sentToInput()">
                    <img src="/assets/images/icons/send.svg" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row px-4">
            <div class="col-12"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->

<div class="chat-scroll smooth-scroll" #scrollChat style="background-color:#ffffff">
  <div class="col-12">
    <div class="row" style="gap: 20px;">
      <ng-container *ngFor="let chat of chatData; let i = index">
        <div class="col-12" *ngIf="chat.role == 'user'">
          <div class="user-chat float-right" markdown mermaid lineNumbers clipboard [data]="chat?.content">
          </div>
        </div>
        <div class="col-12" *ngIf="chat.role != 'user'">
          <div class="bot-chat" *ngIf="chat?.section != 'question' && chat?.section != 'sources'" markdown mermaid lineNumbers clipboard [data]="chat?.content">
          </div>
          <div class="bot-chat" *ngIf="chat?.section == 'sources'">
            <h6>{{ chat?.title }}</h6>
            <div class="row prompts">
              <div class="col-6 prompt" *ngFor="let item of chat?.content">
                <div *ngIf="item.imageTag.startsWith('<img')" style="display: flex; gap: 5px;">
                  <div [innerHTML]="item.imageTag"></div>
                  <a class="ellipsis" href="{{ item?.url }}" target="_blank">{{ item.name }}</a>
                </div>
                <div *ngIf="item.imageTag.startsWith('<svg')">
                  <div style="display: flex; gap: 10px;">
                    <img src="../../../assets/images/icons/search web 1.svg" alt="search web" class="image-icon">
                    <a class="ellipsis" href="{{ item?.url }}" target="_blank">{{ item.name }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bot-chat" *ngIf="chat?.section == 'question'">
            <div class="language-buttons">
              <h6>{{ chat?.title }}</h6>
              <div class="question-list" (click)="inputPrompt(question)" *ngFor="let question of chat?.content">
                <span>{{ question }}</span>
              </div>
            </div>
          </div>

          
          <div class="user-action" *ngIf="chat?.section != 'question' && chat?.section != 'sources'">
            <div class="btn btn-sm" (click)="copyToClipboard(chat?.content)" title="Copy to clipboard">
              <img src="/assets/images/icons/copy.svg" alt="copy">
            </div>
            <!-- <div class="btn btn-sm" (click)="regenerate(i)">
              <img src="/assets/images/icons/refresh.svg" alt="refresh" title="Regenerate">
            </div>  -->
            <!-- <div class="btn btn-sm disclaimer-text" style="cursor:text;">
              <p>AI-generated content may be inaccurate</p>
            </div> -->
          </div>
          <button class="btn btn-primary" style="background-color: #00aecf; margin-top: 15px;
    margin-left: 593px;" (click)="reloadPage()">Back</button>
        </div> 
      </ng-container>


<div *ngIf="isLoading" class="overlay">
  <div 
  class="loader"></div>
</div>

