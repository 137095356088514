import { Component } from '@angular/core';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent {
  images = [
    { url: 'assets/promptimgview/America.jpg', prompt: 'Caption America' },
    { url: 'assets/promptimgview/batman.jpg', prompt: 'Batman' },
    { url: 'assets/promptimgview/blackwidow.jpg', prompt: 'Black Widow' },
    { url: 'assets/promptimgview/hulk.jpg', prompt: 'Hulk' },
    { url: 'assets/promptimgview/Media (1).jpg', prompt: 'Lion vs Foxs' },
    { url: 'assets/promptimgview/Media (2).jpg', prompt: 'Riding horse in war' },
    { url: 'assets/promptimgview/Media (3).jpg', prompt: 'Riding motorcycles' },
    { url: 'assets/promptimgview/Media (4).jpg', prompt: 'Playing crickets' },
    { url: 'assets/promptimgview/Media.jpg', prompt: 'Warrior fighting Dragons' },


    // Add more images with prompts
  ];
}
