<div class="webpage">
  <app-image-floater *ngIf="currentStep===0"></app-image-floater>
<div class="form-container">  
    <!-- <app-stepper *ngIf="currentStep===1 || currentStep===2" [steps]="steps" [currentStep]="currentStep" style="align-content: center;align-items: center;float:center;"></app-stepper> -->
    <app-stepper 
  *ngIf="currentStep === 1 || currentStep === 2 || currentStep===3" 
  [steps]="steps" 
  [(currentStep)]="currentStep" 
  style="align-content: center;align-items: center;float:center;">
</app-stepper>
    <!-- Step 1: Image Upload -->
    <div *ngIf="currentStep === 1" style="padding-top: 0px;">
      <app-image-upload style="z-index: 2;" (imageUploaded)="onImageUploaded($event)"></app-image-upload>
      <button class="btn btn-primary next-btn" (click)="nextStep()" [disabled]="!imageUploaded">
        Next
      </button>
    </div>
  
    <!-- Step 2: AI Quiz -->
    <div *ngIf="currentStep === 2">
      <app-ai-quiz (quizCompleted)="onQuizCompleted($event)"></app-ai-quiz>
      <button class="btn btn-primary next-btn" (click)="nextStep()" [disabled]="!quizCompleted">
        Submit and Generate!
      </button>
    </div>
  
    <!-- Step 3: Avatar and Personality Results -->
    <div *ngIf="currentStep === 3">
      <app-loader 
    *ngIf="loading" >
  </app-loader>
      <app-avatar-display style="padding-top: 5px;" [avatarUrl]="generatedAvatar" [personalityTraits]="personalityTraits"></app-avatar-display>
      
    </div>

    <div *ngIf="currentStep === 0">
      <div class="instructions-box" style="margin-top: 50px;">
        <h4 class="chat1-header " style="text-align: center;">
          <span class="greeting" >Digital Avatar Generator
          </span><br>
          <!-- <span class="question">Please select/Add a prompt to get started</span> -->
          <span class="question">Utilise AI to create your Digital Avatar </span>
  
        </h4>
        <p >Welcome to the cutting-edge Digital Avatar Generator! Our platform harnesses advanced AI technology to analyze your facial features and responses to an interactive quiz, crafting a personalized digital avatar that’s uniquely yours. Through sophisticated neural networks and data-driven insights, we reveal not just your digital likeness, but also three core personality traits that define your tech-savvy self. Experience the future of AI-driven identity creation and uncover a whole new version of yourself!</p>
      </div>
      <button class="btn btn-primary next-btn" style="margin-right: 10px; margin-left: 10px;margin-top: 20px;"  (click)="nextStep()">
        Let's try DAG!
      </button>
      
    </div>
  </div>
</div>
  