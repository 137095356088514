<div class="flex-container">

<div class="top-bar">
  <button (click)="toggleSidebar()"><i class="bi bi-list"></i></button>
  <h1>NotebookLM</h1>
</div>

<div class="sidebar" [class.open]="isSidebarOpen">
  <div class="d-flex  align-items-center">

  <h3>Sources</h3>
  <button class="plus-btn"  (click)="showSourcePopup = true"><i class="bi bi-plus-square"></i></button>
</div>

<div class="d-flex justify-content-flex-start align-items-center">
  <h3>Select All</h3>
  <label>
    <input type="checkbox" [(ngModel)]="allSourcesSelected" (change)="toggleSelectAllSources()" /> 
  </label>
</div>

  <div class="sources" *ngFor="let source of sources">
    
    {{ source.name }}
    <label>
      <input type="checkbox" [(ngModel)]="source.checked" />
    </label>     

  </div>
  <div class="d-flex align-items-center">

    <h3>Notes</h3>
    <button class="plus-btn"  (click)="showSourcePopup = true"><i class="bi bi-plus-square"></i></button>
  </div>

 
<div class="d-flex justify-content-flex-start align-items-center">
  <h3>Select All</h3>
    <label>
      <input type="checkbox" [(ngModel)]="allNotesSelected" (change)="toggleSelectAllNotes()" /> 
    </label>
  </div>

  <div class="notes" *ngFor="let note of notes">

    {{ note.text }}
    <label>
      <input type="checkbox" [(ngModel)]="note.checked" />

  </label> 

  </div>
</div>


<div class="chat-container1" *ngIf="isChatOpen" [ngClass]="{'collapsed': !isSidebarOpen}">
  <div class="messages">

    <!-- <div class="user" *ngIf="chat.role === 'user'; else botTemplate">
      <img src="assets/people-1.png" class="shadow-sm" alt="User Avatar">
      <i class="fa fa-mail-forward icon-send" aria-hidden="true"></i>
    </div>
    <ng-template #botTemplate>
      <div class="user">
        <img src="assets/bot.png" class="shadow-sm" alt="GPT Avatar">
        <i class="fa fa-reply icon-send" aria-hidden="true"></i>
      </div>
    </ng-template> -->


    <ng-container *ngFor="let message of messages; let i = index">
      <!-- User Message -->
      <div style="background-color: #ffffff;  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);" class="col-12" *ngIf="message.type === 'user'">
        <div class="user-message">
          <img src="assets/people-1.png" class="shadow-sm" alt="User Avatar">
          <i class="fa fa-mail-forward icon-send" aria-hidden="true"></i>
          {{ message.text }}
        </div>
      </div>
      <!-- Bot Message -->
      <div class="col-12 botc" style="background-color: #ffffff;  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
" *ngIf="message.type === 'bot'">
        <div class="bot-message">
          <img src="assets/bot.png" class="shadow-sm" alt="GPT Avatar">
          <i class="fa fa-reply icon-send" aria-hidden="true"></i>
          {{ message.text }}
        </div>
        <div class="user-action">
          <!-- Copy & Regenerate buttons for Bot Message -->
          <div class="btn btn-sm" (click)="copyToClipboard(message.text)" title="Copy to clipboard">
            <img src="/assets/images/icons/copy.svg" alt="copy">
          </div>
          <div class="btn btn-sm" (click)="regenerate(i)">
            <img src="/assets/images/icons/refresh.svg" alt="refresh" title="Regenerate">
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>


<div *ngIf="!isChatOpen" class="card-container"[ngClass]="{'collapsed': !isSidebarOpen}" >

<div class="cheader">
  <button class="plus-btn"  (click)="showNotePopup = true"><i class="bi bi-stickies-fill"></i>Add note</button>
  <!-- select all button -->
  <div class="d-flex justify-content-start align-items-center">
    <!-- Select all checkbox for toggling cards -->
    <label for="cardcheck" class="d-flex align-items-center cursor-pointer">
      <input 
        id="cardcheck" 
        type="checkbox" 
        [(ngModel)]="allCardsSelected" 
        (change)="toggleSelectAllCards()" 
        style="display: none;" 
      />
      <!-- Replace with an icon or text to indicate it's clickable -->
      <i class="bi bi-check-all" *ngIf="allCardsSelected"></i>
      <i class="bi bi-check-all" *ngIf="!allCardsSelected"></i>
      <span class="ml-2">Select All</span>
    </label>
  </div>
  
</div>
</div>

<div *ngIf="!isChatOpen" class="card-container"[ngClass]="{'collapsed': !isSidebarOpen}" >
  
 
  <div class="card" *ngFor="let card of cards">
   
    <div class="card-header">
      <div>
        <h4>{{ card.title }}</h4>
      </div>
    
      <label>
        <input type="checkbox" [(ngModel)]="card.checked" />
  
    </label> 
    </div>
    <div class="card-content">
      <p>{{ card.content }}</p>
    </div>
    <div class="card-actions">
      <button (click)="performAction(card)" class="card-action-btn">Edit</button>
    </div>
  </div>
</div>


<div class="chat-container" [ngClass]="{'collapsed': !isSidebarOpen}">
  

  <button (click)="toggleChat()" class="chat-toggle">
    <i class="bi bi-toggles"></i> <!-- Icon added here -->
    {{ isChatOpen ? 'Close Chat' : 'Open Chat' }}
  </button>
  
  <div class="chat-box">
    <div class="input-area">
     <div>
      <!-- will be h3 sources along with no of sorces check same for notes if both then both -->
       


     </div>

      <textarea [(ngModel)]="chatInput" placeholder="Ask your question..."></textarea>
      <button (click)="sendChat()" class="send-btn">
        <i class="bi bi-send"></i>
      </button>
    </div>
  
  </div>
  <button (click)="openNotebookGuide()" class="notebook-guide">
    <i class="bi bi-asterisk"></i>
    Notebook Guide</button>



<!-- Source Popup -->
<div *ngIf="showSourcePopup" class="modal">
  <input [(ngModel)]="sourceInput" placeholder="Paste link or text" />
  <button (click)="addSource(sourceInput); showSourcePopup = false">Add Source</button>
  <button (click)="showSourcePopup = false">Close</button>
</div>

<!-- Note Popup -->
<div *ngIf="showNotePopup" class="modal">
  <input [(ngModel)]="noteInput" placeholder="Add your note" />
  <button (click)="addNote(noteInput); showNotePopup = false">Add Note</button>
  <button (click)="showNotePopup = false">Close</button>
</div>
</div>