
<section class="content">
  <div class="container-fluid responsive p-0" >
      <div class="">
          <div class="row ">
              <div class="col-12" style="margin:0; padding-left: 85px;" >
<div>
  <div class="block-dropdown d-grid  d-md-flex content1">
    <div class="col-item pl-2">
        <ng-multiselect-dropdown [placeholder]="'source language'" [settings]="dropdownSettings" [data]="source" [(ngModel)]="sourceLanguage" (ngModelChange)="onDropdownChange()">
        </ng-multiselect-dropdown>
    </div>
<button class="btn btn-primary"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5"/>
</svg></button>
    <div class="col-item">
        <ng-multiselect-dropdown [placeholder]="'Target language'" [settings]="dropdownSettings" [data]="target" [(ngModel)]="TargetLanguage" (ngModelChange)="onDropdownChange()">
        </ng-multiselect-dropdown>
    </div>

     <!-- <div class="col-item flex-grow-1"></div>

     <div class="col-item flex-grow-1"></div>
     <div class="col-item-8 flex-grow-1"></div> -->
       <!-- Empty flex item to push the button to the right -->

    <div class=""> <!-- Translate button div -->
        <div class="text-right">
            <button [disabled]="(isButtonDisabled || !input)" style="border:none;height:40px;" (click)="translate()"  type="button" id="yourButton" class="btn btn-primary px-2 py-1 btn-block">
                <span class="button-text">Translate</span>
                <span class="vertical-line"></span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
                </svg>
            </button>
        </div>
    </div>
</div>

                  </div>
                  <!--  -->
              </div>
          </div>





      </div>
      <div class="text-area-container " style="padding-left: 88px; padding-top: 30px;">
<div class="d-flex" style="flex-direction: column; width:32%; height:400px">
         <label for="input" class="text-area-label">Input text
          <button style="background-color:transparent;" class="app-speech-input-btn" *ngIf="startspeech" (click)="startService()"></button>



         <button  style="background-color:transparent;" class="app1-speech1-input1-btn1" *ngIf="stopspeech" (click)="stopService()">
             <i class="fa fa-microphone fa-beat-fade" style="height:1.6rem;color: #FF555F"></i>
             <div class="micro-animation"></div>
         </button>
         </label>

        <textarea  [(ngModel)]="input" class="text-area" placeholder=""></textarea>

</div>
<div class="d-flex" style="flex-direction: column;width:32%;height:400px">
  <label for="input" class="text-area-label">Translated text
    <button *ngIf="output1" (click)="copyTranslatedText()" style="background-color: transparent; position: absolute; border: none;">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16" *ngIf="!showCopiedTranslatedText">
        <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
      </svg>
      <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill=" #1BA641" class="bi bi-check-all" viewBox="0 0 16 16" *ngIf="showCopiedTranslatedText">
        <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/>
      </svg>
      <!-- <span *ngIf="showCopiedTranslatedText">copied</span> -->
    </button>

    <!-- <button  *ngIf="output1" style="position: absolute;margin-left: 40px; background-color:transparent;border:none" (click)="toggleSpeaking()">
      <svg *ngIf="!speaking" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-volume-down" viewBox="0 0 16 16">
        <path d="M9 4a.5.5 0 0 0-.812-.39L5.825 5.5H3.5A.5.5 0 0 0 3 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 9 12zM6.312 6.39 8 5.04v5.92L6.312 9.61A.5.5 0 0 0 6 9.5H4v-3h2a.5.5 0 0 0 .312-.11M12.025 8a4.5 4.5 0 0 1-1.318 3.182L10 10.475A3.5 3.5 0 0 0 11.025 8 3.5 3.5 0 0 0 10 5.525l.707-.707A4.5 4.5 0 0 1 12.025 8"/>
      </svg>
      <svg *ngIf="speaking" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill=" #FF555F" class="bi bi-volume-up" viewBox="0 0 16 16">
        <path d="M11.536 14.01A8.47 8.47 0 0 0 14.026 8a8.47 8.47 0 0 0-2.49-6.01l-.708.707A7.48 7.48 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303z"/>
        <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.48 5.48 0 0 1 11.025 8a5.48 5.48 0 0 1-1.61 3.89z"/>
        <path d="M10.025 8a4.5 4.5 0 0 1-1.318 3.182L8 10.475A3.5 3.5 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.5 4.5 0 0 1 10.025 8M7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11"/>
      </svg>
      <div *ngIf="speaking" class="micro-animation"></div>
    </button> -->


  </label>
  <!-- <div  class="d-flex" style="justify-content: flex-end;margin-right: 5px;">
    <button (click)="copyTranslatedText()" style="background-color: transparent; position: absolute; border: none;">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16" *ngIf="!showCopiedTranslatedText">
        <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16" *ngIf="showCopiedTranslatedText">
        <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"/>
        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"/>
      </svg>
      <span *ngIf="showCopiedTranslatedText">copied</span>
    </button>
  </div> -->

        <textarea  [(ngModel)]="output" class="text-area output mr-2" placeholder="" disabled></textarea>
</div>
<div class="d-flex" style="flex-direction: column;width:32%;height:400px">
  <label style="padding-left: 10px;" for="input" class="text-area-label">Vocabulary
    <!-- <button *ngIf="output1" (click)="download()" style="background-color: transparent; position: absolute; border: none;">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
      </svg>
    </button> -->
  </label>
        <div  [(ngModel)]="vocabulary"class="text-area" placeholder="vocabulary"
        lineNumbers clipboard markdown [innerHTML]="vocabulary"  [disableSanitizer]="true" style="margin-left: 10px;"
        ></div>
</div>

      </div>


  </div>

</section>

