import { Injectable, OnInit } from '@angular/core';
import { ActivityAudit, FileModel } from '../data/app-model';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ApiEndpoints, MessageTypeSnack } from '../data/global-vars';
import { ErrorComponent } from '../components/includes/popup/error/error.component';
import { MatDialog } from '@angular/material/dialog';
import { BaseHttpService } from './base-http.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharedService implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  //activityAudit: ActivityAudit = {
  //  username: '',
  //  timestamp: '',
  //  page: '',
  //}
  constructor(private snackBar: MatSnackBar, public dialog: MatDialog, private baseHttp: BaseHttpService) { }


  ngOnInit(): void {
        //throw new Error('Method not implemented.');
  }

  addDataToForm(data: any, files: FileModel[] | undefined | null): FormData {
    let formData: FormData = new FormData();

    if (data) {
      let jData = JSON.stringify(data);
      formData.append('request_params', jData);
    }

    if (files != undefined && files != null) {
      for (let i = 0; i < files.length; i++) {
        formData.append(files[i].name, files[i].file_data);
      }
    }

    return formData;
  }

  receiveMessage(event: string) {
    //return this.socket.fromEvent(event);
  }

  openSnackBar(message: string, action: string) {
    let pnlClass = '';
    switch (action) {
      case MessageTypeSnack.Error:
        pnlClass = 'error-snackbar';
        const dialogRef = this.dialog.open(ErrorComponent, {
          width: '27vw',
          data: {
            popupIcon: 'assets/images/warning.png',
            popupHeader: 'Error!',
            popupContent: message,
            //onOpenClick: this.onOpenClick.bind(this),
            firstBtnName: 'Close',
            secondBtnName: 'Ok',
          }
        });

        break;
      case MessageTypeSnack.Warning:
        pnlClass = 'warning-snackbar';
        const dialogRefWarn = this.dialog.open(ErrorComponent, {
          width: '27vw',
          data: {
            popupIcon: 'assets/images/error.png',
            popupHeader: 'Warning!',
            popupContent: message,
            //onOpenClick: this.onOpenClick.bind(this),
            firstBtnName: 'Close',
            secondBtnName: 'Ok',
          }
        });
        break;
      case MessageTypeSnack.Success:
        pnlClass = 'success-snackbar';
        break;

    }
    //this.snackBar.open(message, action, {
    //  duration: 3000,
    //  horizontalPosition: this.horizontalPosition,
    //  verticalPosition: this.verticalPosition,
    //  panelClass: [pnlClass],
    //});
  }

  postActivityLog(activity_info: ActivityAudit): Observable<any> {
    return this.baseHttp.post<any>(ApiEndpoints.ActivityAudit, activity_info, false, true);
  }

  checkDocumentStatus(file_path: string): Observable<any> {
    //const params = new HttpParams()
    //  .set('file_path', "test path");
    //debugger;
    return this.baseHttp.post<any>(ApiEndpoints.CheckDocAvailability, file_path, true, true);
  }
}
