import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { MessageTypeSnack, StatusCode } from '../../data/global-vars';
import { SharedService } from '../../Services/shared.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TermsService } from '../../Services/terms.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccesscheckService } from 'src/app/shared/service/accesscheck.service';
import { RoundLoaderService } from '../../Services/roundloader.service';
import {SopService} from 'src/app/shared/service/sop.service';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent1 implements OnInit {
  @Output() switchTab = new EventEmitter<number>();
  @ViewChild('autoShownModal', { static: false }) autoShownModal!: ModalDirective;
  isModalShown: boolean = false;
  checkbox1Accepted: boolean = false;
  checkbox2Accepted: boolean = false;
  title!: boolean;
  userAccessStatus: boolean = false;
  domaincheckstatus: any;
  selectedfunction:any;
  terms:any;
  constructor(private router: Router, public dialog: MatDialog,
    private route: ActivatedRoute,
     private sharedService: SharedService,
     private termsService: TermsService,
     private useraccesscheckService: AccesscheckService,
     private sopService: SopService,
     private loaderService: RoundLoaderService) { }

  ngOnInit(): void {
    this.accesscheck();
    this.selectedfunction === "IT"
    // get value from domain check function
    this.domaincheck();
     if(this.selectedfunction === "IT"){
      this.update1();
     // this.activatedTab = 2;


     }
     else if (this.selectedfunction === "LDT"){
      this.update();
      this.activatedTab = 0;
     }

    this.terms = localStorage.getItem('termsAccepted');
    // this.update1();
    // this.getSubscriptionStatus();
    // console.log('ngOnInit is running');

    if (localStorage.getItem('termsAccepted') == 'false') {
      // console.log('terms not accepted, showing modal');
      document.getElementById('btnbrd')?.click();
    }
    else{
      const modal = document.getElementById('myModal');
      if (modal) {
        modal.style.display = 'none';
    }
    }


    this.route.params.subscribe(params => {
      const currentRoute = this.router.url;

      if (currentRoute === '/codeGenYLite') {
        this.title = true;

      } else if (currentRoute === '/codeGenYLiteAI') {
        this.title = false;

      }
      else {
        this.title = false;
      }

      // Add more else if conditions for other routes
    });
  }
update(){
  this.selectedfunction = "LDT"
  this.activatedTab = 0;
  localStorage.setItem('domain', this.selectedfunction);
}
update1(){
  this.selectedfunction = "IT"
  this.router.navigate(['/cgl']);
this.activatedTab = 2;
localStorage.setItem('domain', this.selectedfunction);
  //alert
  this.router.navigate(['/cgl']);


}

domaincheck(){
  this.loaderService.showLoader();
  this.sopService.getdomain().subscribe((res: any) => {
    if (res) {
      this.loaderService.hideLoader();
     // alert(res?.domain);
      this.selectedfunction = res?.domain ;
      this.domaincheckstatus = res?.domain;
      localStorage.setItem('domain', this.domaincheckstatus);
      if(this.selectedfunction === "IT"){
        this.activatedTab = 2;
      }
      else if (this.selectedfunction === "LDT"){
        this.activatedTab = 0;
      }
    }
    else {
      //this.chkagree = false;
      this.loaderService.hideLoader();
    }
  },
  (error: any) => {
    console.log(error);
    this.loaderService.hideLoader();
  }
  );
}

  accesscheck() {
    this.loaderService.showLoader();
    this.useraccesscheckService.useraccesscheck().subscribe((res: any) => {
      if (res?.response?.status === "success") {
       this.userAccessStatus = true;
       this.loaderService.hideLoader();
      }
      else {
        //this.chkagree = false;
        this.loaderService.hideLoader();
      }
    },
    (error: any) => {
      console.log(error);
      this.loaderService.hideLoader();
    }
    );
  }

  showModal(): void {
    // console.log('showModal function is running');
    if (localStorage.getItem('termsAccepted') === 'true') {
      this.acceptTermsAndConditions();
    }
    else{
    this.isModalShown = true;
    }
  }

  hideModal(): void {
  //   console.log('hideModal function is being called');
  // this.autoShownModal.toggle();
  // this.autoShownModal.toggle();
// this.modalRef.hide();
  // debugger;
    const modal = document.getElementById('myModal');
    if (modal) {
      modal.style.display = 'none';
  }
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  acceptTermsAndConditions(): void {
    if(this.checkbox1Accepted && this.checkbox2Accepted){
      // console.log('acceptTermsAndConditions function is being called');
      this.hideModal();
      this.onHidden();
      localStorage.setItem('termsAccepted', 'true');
      this.termsService.setTermsAccepted(true);
    }
  }


  // this is new tab section ---------------------
  activatedTab: number = 0;

  setTab(index: number) {
    this.activatedTab = index;
  }

  // features cards date is Here ......................

  cardsData = [
    {
      iconImage: 'assets/images/bugResolution.png',
      title: 'Intelligent bug resolution',
      content:
        'Experience a leap in productivity as our application intelligently identifies and resolves bugs in your code. Say goodbye to tedious debugging sessions, saving valuable time and reducing the cost of bug-fixing efforts.',
    },
    {
      iconImage: 'assets/images/automated.png',
      title: 'Automated code documentation',
      content:
        'Elevate code understanding and collaboration with automated documentation. codeGenY Lite, generates comprehensive documentation and enhances team efficiency by eliminating the need for manual documentation efforts.',
    },
    {
      iconImage: 'assets/images/endtoendsupport.png',
      title: 'End-to-end SDLC support',
      content:
        'Transform your software development process with end-to-end support. From crafting business requirement documents to generating solution design documents and actual code, codeGenY Lite streamlines the entire SDLC. Boost productivity and minimize costs by consolidating multiple stages into one cohesive solution.',
    },
    {
      iconImage: 'assets/images/efficientcode.png',
      title: 'Efficient code generation',
      content:
        'Witness a revolution in code creation as our application intelligently generates code snippets and even entire functions. Cut down on development time and costs by leveraging the power of AI to create reliable, optimized, and bug-free code. Watch your projects come to life with unprecedented speed and accuracy.',
    },
    {
      iconImage: 'assets/images/chatsupport.png',
      title: 'Integrated GPT powered chat assistant ',
      content:
        'Empower your developers with instant, real-time support through our integrated chat system. Whether it’s a coding roadblock or a query about best practices, our AI-powered chat support is there to assist. Improve developer satisfaction and reduce downtime, ultimately contributing to higher productivity and lower operational costs.',
    },
    // ... more cards
  ];

  scrollToSection(section: string) {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  openEnhanceCode() {
    if(this.selectedfunction === "LDT"){
     this.router.navigate(['/codeGenYLite/products']);
      localStorage.setItem('tab', '0');
    }
    if(this.selectedfunction === "IT")  {
      this.router.navigate(['/cgy/codeassist']);
    }
  }

  openCodeDoc() {
    if(this.selectedfunction === "LDT"){
    this.router.navigate(['/codeGenYLite/products']);
      localStorage.setItem('tab', '1');
    }
   if(this.selectedfunction === "IT"){
      this.router.navigate(['/cgy/codeassist']);
    }
  }

  openBIP() {
    if(this.selectedfunction === "LDT"){
    this.router.navigate(['/codeGenYLite/products']);
      localStorage.setItem('tab', '2');
    }
    if(this.selectedfunction === "IT"){
      this.router.navigate(['/cgy/brd']);
    }
  }
  openBIP1() {

    this.router.navigate(['/codeGenYLiteAI/brd']);


  }

  openDevTalk() {
    if(this.selectedfunction === "LDT"){
    this.router.navigate(['/codeGenYLite/products']);
      localStorage.setItem('tab', '3');
    }
    if(this.selectedfunction === "IT"){
      this.router.navigate(['/cgy/ai-assist']);
    }
  }

  //checking dynamic-error and warning popup here............
  // openErrorPopup(){

  //  const dialogRef = this.dialog.open(CompletionpopupComponent, {
  //    width: '27vw',
  //    data: {
  //      popupIcon:  'assets/images/error.png',
  //      popupHeader: 'Error Heading.',
  //      popupContent: 'Please check your heading it is an error buddy. ',
  //      onOpenClick: this.onOpenClick.bind(this),
  //      firstBtnName: 'Close',
  //      secondBtnName:'Ok',
  //    }
  //  });
  // }

  // onOpenClick(): void {
  //   // Implement the logic for the "Open" button in the home component
  // }

  // dynamic subscription popup -------------

  // openexpirePopup(){
  //   const dialogRef = this.dialog.open(RenewSubsctiptionComponent, {
  //     width: '32vw',
  //     data: {
  //       popupIcon:  'assets/images/bell.png',
  //       popupHeader: 'Renew Subscription',
  //       popupContent: "Please drop a mail to 'aiplay-dev@genpactonline.onmicrosoft.com' to renew your subscription.",
  //       onOpenClick: this.onOpenClick.bind(this),
  //       firstBtnName: 'Close',
  //       secondBtnName:'Ok',
  //     }
  //   });
  // }

}
