import { Component } from '@angular/core';
import  { SystranService } from '../shared/service/systran.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-systran',
  templateUrl: './systran.component.html',
  styleUrls: ['./systran.component.scss']
})
export class SystranComponent {

  constructor(

    private systranService:SystranService,
    private router: Router, private route: ActivatedRoute
  ) {

   }
  ngOnInit(): void {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.storelogs();
    //   }
    // });

  }
// storelogs(){
//   const currentRoute = this.router.url;

//   const payload = {
//     'event_type' : currentRoute,
//     'event_data' : {
//       currentRoute : true,
//     }
//   }
//   this.systranService.storelogs(payload).subscribe((res: any) => {
//     console.log(res);
//   },
//   (error: any) => {
//     console.log(error);
//   }

//   );
// }


}
