import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TermsService {
  private termsAccepted = false;

  setTermsAccepted(value: boolean): void {
    // console.log("before acceptance",this.termsAccepted);
    // console.log('setTermsAccepted function is being called');
    this.termsAccepted = value;
    // console.log("after acceptance",this.termsAccepted);
  }

  getTermsAccepted(): boolean {
    // console.log("termscalled",this.termsAccepted);
    return this.termsAccepted;
  }

}
