import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssistapiService {
  endpoint = environment.apiURL;

  constructor(private http: HttpClient,) { }

  createassistant(payload:any): Observable<any> {
    return this.http.post(this.endpoint + '/create-assistant', payload);
  }
  firstquery(payload:any): Observable<any> {
    return this.http.post(this.endpoint + '/first-query', payload);
  }
  addquery(payload:any): Observable<any> {
    return this.http.post(this.endpoint + '/add-query', payload);
  }
  runstatus(payload:any): Observable<any> {
    return this.http.post(this.endpoint + '/run-status', payload);
  }
  modifyassistant(payload:any): Observable<any> {
    return this.http.post(this.endpoint + '/modify-assistant', payload);
  }
  getFile(fileUrl: string): Observable<Blob> {
    return this.http.get(fileUrl, { responseType: 'blob' });
  }
}
