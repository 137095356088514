import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { marked } from 'marked';
import { HistoryService } from 'src/app/shared/service/history.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { LoaderService } from '../shared/service/loader.service';
import { PodcastService } from '../shared/service/podcast.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-podcastgen',
  templateUrl: './podcastgen.component.html',
  styleUrls: ['./podcastgen.component.scss']
})
export class PodcastgenComponent {
  @ViewChild('fileInput') fileInput: any;

  @ViewChild('audioElement') audioElement!: HTMLAudioElement;
  isPlaying = false;
  currentTime = 0;
  duration = 0;
  @ViewChild('container1', { static: false }) scrollContainer!: ElementRef;

  fileuploaded: boolean = false;
  chatData: any = [];
  isCopied: boolean = false;
  filename: string = '';
  Isuploading: boolean = false;
  summary: string = '';
  searchquery: string = '';
 queryresponse:string = '';
 isloading:boolean = false;
 @ViewChild('audioPlayer') audioPlayer!: ElementRef<HTMLAudioElement>;
  // isPlaying = false;
  //path ti audio file in selectedaudio
  selectedAudio = '';
  poid: any;
  audiostatus: any;
  audiop:boolean = false;
  imgurl: any;
  token1: any;
  chkagree: boolean = false;
  // tokenurl:string = `https://gapi1.playground.genpact.com/podcasts/${id}/audio?download=true&token=${token}`;
  modalRef?: BsModalRef;
  historyItems: any[] = [];
  searchTerm: string = ''; // Holds the search term
  isupload: boolean = false;
  percent!:any;
  podcasturl = environment.podcastsurl;
  isEditing: boolean[] = [];
  editedContent: string[] = [];
  isregen: boolean = false;
  isToggled: boolean = true;

  constructor(
    private historyService: HistoryService,
    private toastMessageService: ToastMessageService,
    private loaderService: LoaderService,
    private elementRef: ElementRef,
    private modalService: BsModalService,
    private podcastService: PodcastService
  ) { 
    
  }

  onToggleChange() {
    console.log('Toggle state:', this.isToggled);
    this.audiop = false;

  }
  // filteredHistoryItems() {
  //   if (!this.searchTerm) {
  //     return this.historyItems; // If no search term, return all items
  //   }

  //   return this.historyItems.filter(item =>
  //     item.base_filename.toLowerCase().includes(this.searchTerm.toLowerCase())
  //   );
  // }

  togglePlay() {
    if (this.isPlaying) {
      this.audioElement.pause();
    } else {
      this.audioElement.play();
    }
    this.isPlaying = !this.isPlaying;
  }

  updateCurrentTime() {
    this.currentTime = this.audioElement.currentTime;
    this.duration = this.audioElement.duration || 0; // Handle NaN
  }

  seekAudio() {
    this.audioElement.currentTime = this.currentTime;
  }

  refresh(){
    this.gethistory();
    window.location.reload();
  }
  refresh1(){
    this.gethistory();
    //window reload
    window.location.reload();
  }
   // Open model
   openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md',ignoreBackdropClick: true });
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' + secs : secs}`;
  }

  onEnded() {
    this.isPlaying = false; // Reset play state when audio ends
  }

  extractPercentage(audioString: string): string {
    this.loaderService.loading.next(false);
    const percentageMatch = audioString.match(/(\d+(\.\d+)?)%/);
    return percentageMatch ?  `progress:${percentageMatch[0]}` : ''; // Return the matched percentage or an empty string
  }
  
  getFileIcon(filetype: string): string {
    switch (filetype) {
      case 'application/pdf':
        return 'assets/podpulse/pdf.png';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
        return 'assets/podpulse/word.png';
      case 'text/plain':
        return 'assets/podpulse/text.png';
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'assets/podpulse/ppt.png';
      default:
        return 'assets/podpulse/mp3.png'; // Default icon (e.g., for audio files)
    }
  }
  

  ngOnInit(): void {

    // this.historyService.getHistoryData().subscribe((data) => {
    //   this.historyItems = data; // Data will be real or dummy depending on API response
    // });
    const modalbtn = document.getElementById('playground_policy_model') as HTMLLIElement;
    modalbtn.click();

    this.token1=localStorage.getItem('token');

    this.gethistory();
    // this.sortHistoryItemsByTimestamp();

  //  this.chatData.push({role: 'user', content: 'Hello, I would like to generate a podcast from a text file.'});
  }

  sortHistoryItemsByTimestamp() {
    this.historyItems.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

  }

  async copyToClipboard(markdown: string) {
    const html = this.convertMarkdownToHtml(markdown);
    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([html], { type: 'text/html' })
        })
      ]);
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied  = false;
      }, 3000);
      console.log('HTML copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
   
  convertMarkdownToHtml(markdown: string): string {
    return marked(markdown);
  }
  // togglePlay() {
  //   const audio = this.audioPlayer.nativeElement;
  //   if (audio.paused) {
  //     audio.play();
  //     this.isPlaying = true;
  //   } else {
  //     audio.pause();
  //     this.isPlaying = false;
  //   }
  // }

  // seekAudio(event: any) {
  //   const audio = this.audioPlayer.nativeElement;
  //   audio.currentTime = event.target.value;
  // }
  clickf() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }
  //fileupload handle 
  fileChange(event: any) {
    // Define allowed file extensions and maximum size (16MB)
    const allowedFileExtensions = ['.txt', '.docx', '.pdf', '.pptx', '.jpg', '.jpeg', '.png'];
    const maxSizeInBytes = 10 * 1024 * 1024; // 16MB
    
    const files = event.target.files;
    if (!files || files.length === 0) {
      alert('No file(s) selected.');
      return;
    }
    
    this.fileuploaded = false;
    this.Isuploading = true;
    this.filename = ''; // To store the names of valid files
    
    // Process each file
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
  
      // Check if the file extension is allowed
      const fileExtension = '.' + file.name.split('.').pop();
      if (!allowedFileExtensions.includes(fileExtension)) {
        this.toastMessageService.showWarning(`Invalid file type for ${file.name}. Please upload a .txt, .docx, .pdf,.pptx or '.jpg', '.jpeg', '.png' file.`);
        this.fileInput.nativeElement.value = '';
        event.target.value = '';
        continue; // Skip this file
      }
  
      // Check if the file size exceeds the limit
      if (file.size > maxSizeInBytes) {
        this.toastMessageService.showWarning(`File size for ${file.name} exceeds the maximum limit of 10MB.`);
        this.fileInput.nativeElement.value = '';
        event.target.value = '';
        continue; // Skip this file
      }
      
      if(files.length > 1){
        this.toastMessageService.showmessage('Multiple file uploads are not supported.');
        return;
      }
      // Add the valid file name to the filename string
      else{
      this.filename += (this.filename ? ', ' : '') + file.name;
      }
    }
  
    // When all files are processed, update the fileuploaded and Isuploading flags
    this.fileuploaded = true;
    this.Isuploading = false;
  
    // Display file names
    console.log('Selected files: ', this.filename);
  
    // Proceed with all valid files in one go
    if (this.filename) {
      this.proceed(files);
    } else {
      alert('No valid files to upload.');
    }
  }
  
  proceed(files: FileList) {
    // Start the loader
    this.scrollToBottom();
    this.loaderService.loading.next(true);
  
    // Show the initial toast message for file upload
    this.toastMessageService.showmessage('Your files are being uploaded: ' + this.filename);
  
    // Create a FormData object for multiple file upload
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
  
      // Append only valid files that match the allowed extensions and size limit
      const fileExtension = '.' + file.name.split('.').pop();
      if (this.filename.includes(file.name)) {
        formData.append('file', file); // Append each valid file to the FormData object
      }
    }
  
    // Call the podcastService to handle file upload
    this.podcastService.uploadFile(formData).subscribe(
      (res: any) => {
        // Check if the upload was successful
        if (res.status === 'success') {
          this.poid = res.data.podId;
  
          // Stop the loader and show success message with the latest podId
          this.loaderService.loading.next(false);
          this.toastMessageService.showmessage('Files uploaded successfully. Latest podId: ' + this.poid);
  
          // You can trigger further actions like generating conversations with this.poid
          this.generateConversation(this.poid);
        } else {
          // Handle failure, stop loader and show failure message
          this.loaderService.loading.next(false);
          this.toastMessageService.showmessage('Failed to upload files');
        }
      },
      (error) => {
        // Handle error, stop loader and show failure message
        this.fileuploaded = false;
        this.loaderService.loading.next(false);
        this.toastMessageService.showmessage('Failed to upload files due to an error');
      }
    );
  }
  
  
  generateConversation(id: any) {
    this.loaderService.loading.next(true);
    this.audiop=false;
  
    // Call podcastService to generate the conversation
    this.podcastService.generateConversation(id,this.isToggled).subscribe(
      (res: any) => {
        if (res.status === 'success') {
          if (res.data.messages && res.data.messages.length > 0 && res.data.messages !== 'Generating podcast conversation, kindly wait....') {
            // Loop through the messages array and push each message to chatData
            res.data.messages.forEach((message: any) => {
              // Always assign 'user' role
              this.chatData.push({
                role: 'user',
                content: message.text,
              });
            });
  
            // Stop the loader after processing messages
            this.loaderService.loading.next(false);
          } else if (res.data.messages && res.data.messages.length <= 0) {
            // If messages is an empty array, check the conversation status
            this.getconversionstatus(id);
          } else if (res.data.status === 'PENDING') {
            // Handle pending status separately
            this.getconversionstatus(id);
          } 
          else if (res.data.messages == 'Generating podcast conversation, kindly wait....'){
            this.getconversionstatus(id);
          }
            
          else {
            // If no messages and not in PENDING status, show an error message
            this.loaderService.loading.next(false);
            this.toastMessageService.showmessage('No messages found or status is not handled.');
          }
        } else {
          // Handle failure and stop loader
          this.loaderService.loading.next(false);
          this.toastMessageService.showmessage('Failed to generate conversation');
        }
      },
      (error) => {
        // Handle error and stop loader
        this.loaderService.loading.next(false);
        this.toastMessageService.showmessage('Failed to generate conversation');
      }
    );
  }
  
  

generateAudio(id:any){
 if(this.audiostatus){
  this.getConversionStatusAudio(id);
  return;
 }

  this.isloading = true;
 // this.loaderService.loading.next(true);
  this.podcastService.generateAudio(id,this.isToggled)
  .subscribe((res:any) => {
    if(res.status == 'success'){

// this.getconversionstatus(id);
this.getConversionStatusAudio(id);
      // this.audiop=true;
      // this.selectedAudio = res.data.audio;
      // this.loaderService.loading.next(false);
      this.toastMessageService.showmessage(res.data);
    }
    else{
      // this.loaderService.loading.next(false);
      this.isloading = false;
      this.toastMessageService.showmessage('Failed to generate audio');
    }
  },
  (error) => {
    // this.loaderService.loading.next(false);
    this.isloading = false;
    this.toastMessageService.showmessage('Failed to generate audio');
  });
}

getconversionstatus(id:any){

  // this.loaderService.loading.next(true);
  // this.isloading = true;
  this.podcastService.getConversionStatus(id)
  .subscribe((res:any) => {
    if(res.status == 'success' && res.message == 'Podcast conversation completed successfully.'){
      // this.audiostatus = res.data;
      // this.loaderService.loading.next(false);
      // this.getConversionStatusAudio(id);
      this.getconvo(id,this.filename);
    }
else if(res.status !== 'success'){
    this.loaderService.loading.next(false);
    this.toastMessageService.showError(res.message)

    this.fileuploaded = false;
    return;
}

    else{
      // this.loaderService.loading.next(true);
      // this.isloading = true;
      //this.toastMessageService.showmessage('Failed to get conversion status');
      //call getconversionstatus again after 15secs
      setTimeout(() => {
        this.getconversionstatus(id);
      }, 15000);
    }
  },
  (error) => {
    // this.loaderService.loading.next(false);
    this.isloading = false;
    this.toastMessageService.showmessage('Failed to get conversion status');
  });

}



getConversionStatusAudio(id:any){
  // this.loaderService.loading.next(true);
  // this.isloading = true;
  this.podcastService.getConversionStatusAudio(id)
  .subscribe((res:any) => {
    if(res.status == 'success' && res.message == 'Podcast audio generation completed successfully.'){
      // this.audiop=true;
      // this.selectedAudio = res.data.audio;
      // this.toastMessageService.showmessage(res.data);
      // this.getaudio(id);
      this.audiop = true;
      this.isloading = false;
      const token=localStorage.getItem('token');
      this.selectedAudio = `${this.podcasturl}/${id}/audio?download=false&token=${token}`;
      // this.loaderService.loading.next(false);
      
      // this.loaderService.loading.next(false);
      // this.isloading = false;
    }

  else if(res.data.length > 0 && res.data !=='Podcast audio generation started...'){
    this.percent = this.extractPercentage(res.data);
    setTimeout(() => {
      this.toastMessageService.showmessage(res.data);
      this.percent = this.extractPercentage(res.data);
      this.getConversionStatusAudio(id);

    }, 10000);
  }

  else if(res.status !== 'success'){
    this.loaderService.loading.next(false);
    this.toastMessageService.showError(res.message)
    this.audiop = false;
    return;
}


    else{
      // this.loaderService.loading.next(true);
      // this.audiostatus = true;
      // this.isloading = true;
      // this.toastMessageService.showmessage(res.data);
      //call getconversionstatus again after 15secs
      setTimeout(() => {
        this.getConversionStatusAudio(id);
      }, 15000);
    }
  },
  (error) => {
    // this.loaderService.loading.next(false);
    this.isloading = false;
    this.toastMessageService.showmessage('Failed to get conversion status');
  });
}

// getaudio(id:any){
//   // this.loaderService.loading.next(true);
//   this.isloading = true;
//   this.podcastService.getGeneratedAudio(id)
//   .subscribe((res:any) => {
//     if(res.status == 'success'){
//       this.audiop=true;
//       this.selectedAudio = res.data.audio;
//       this.loaderService.loading.next(false);
//       this.isloading = false;
//     }
//     else{
//       this.loaderService.loading.next(false);
//       this.toastMessageService.showmessage('Failed to get audio');
//     }
//   },
//   (error:any) => {
//     this.audiop = true;
//     this.selectedAudio = 'assets/sample-3s.mp3';
//     this.loaderService.loading.next(false);
//     this.isloading = false
//     this.loaderService.loading.next(false);
//     this.toastMessageService.showmessage('Failed to get audio');
//   }
// );
// }

getaudio(id: string) {
  this.isloading = true; // Optional: Set loading indicator

  this.podcastService.getGeneratedAudio(id).subscribe(
    (response: Blob) => {
      //this.isLoading = false;
      const blob = new Blob([response], { type: 'audio/mpeg' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;

      // Generate a dynamic filename based on your requirements
      const timestamp = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
      const filename = `Podcast_${id}_${timestamp}.mp3`; // Adjust filename pattern as needed

      // Set the filename for the download
      a.download = filename;
      document.body.appendChild(a);
    //  a.click();

      // Assign the URL to the selectedAudio variable for playback
      this.selectedAudio = url;
      this.audiop = true;

      //this.toastMessageService.showSuccess('Audio Downloaded Successfully');
      
      // Cleanup the download link element
      document.body.removeChild(a);
    },
    (error) => {
      this.audiop = false;
    //this.selectedAudio = 'assets/Mugifier Documents.wav';
    this.isloading = false;
    this.loaderService.loading.next(false);
      this.toastMessageService.showError('Failed to download audio'); // Optional: Show error message
      console.error('Error downloading audio:', error);
    }
  );
}



gethistory(){
  this.loaderService.loading.next(true);
  this.podcastService.getHistoryData()
  .subscribe((res:any) => {
    if(res.status == 'success'){
      this.historyItems = res.data;
      this.historyItems = this.historyItems.sort((a, b) => {
        // Sort in descending order by timestamp
        return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
      });
      this.loaderService.loading.next(false);
    }
    else{
      this.loaderService.loading.next(false);
      this.toastMessageService.showmessage('Failed to get history');
    }
  },
  (error) => {
    this.loaderService.loading.next(false);
    this.toastMessageService.showmessage('Failed to get history');
  }
);

}

// getconvo(id: any, filename: any) {
//   this.toastMessageService.showmessage('Generating conversation for: ' + id + ':' + filename);
//   this.filename = filename;
//   this.chatData = [];
//   this.fileuploaded = true;
//   this.poid = id;

//   this.loaderService.loading.next(true);
//   this.podcastService.getPodcastList(id).subscribe(
//     (res: any) => {
//       if (res.status === 'success') {
//         if (res.data.messages && res.data.messages.length > 0) {
//           // Loop through the messages array and push each message to chatData
//           res.data.messages.forEach((message: any) => {
//             // Always assign 'user' role
//             this.chatData.push({
//               role: 'user',
//               speaker: message.speaker,
//               content: message.text,
//             });
//           });

//           // Stop the loader after processing messages
//           this.loaderService.loading.next(false);
//           return;
//         } else if (res.data.status === 'PENDING') {
//           this.getconversionstatus1(id, filename);
//         } else {
//           this.loaderService.loading.next(false);
//           this.toastMessageService.showmessage('No messages found or status is not handled.');
//         }
//       } else {
//         // Handle failure and stop loader
//         this.loaderService.loading.next(false);
//         this.toastMessageService.showmessage('Failed to generate conversation');
//       }
//     },
//     (error) => {
//       this.loaderService.loading.next(false);
//       this.toastMessageService.showmessage('Failed to get conversation');
//     }
//   );
// }
podcastcheck(id:any,filename:any,status:any,messages:any){
  this.scrollToBottom();

  if(status === 'CONVERSATION - COMPLETED, AUDIO - COMPLETED'){
    this.selectedAudio = `${this.podcasturl}/${id}/audio?download=false&token=${localStorage.getItem('token')}`;

    this.audiop = true;
    this.filename = filename;

  
  this.fileuploaded = true;
  this.poid = id;
  this.getconvo(id,filename);

    // if (messages && messages.length > 0) {
    //   // Loop through the messages array and push each message to chatData
    //   messages.forEach((message: any) => {
    //     let role = 'user'; // Default role

    //     // Check the speaker and assign roles accordingly
    //     if (message.speaker === 'John') {
    //       role = 'user';
    //     } else  {
    //       role = 'assistant';
    //     }

    //     this.chatData.push({
    //       role: role,
    //       speaker: message.speaker,
    //       content: message.text,
    //       isEditing: false,
    //     });
    //   });

    //   // Stop the loader after processing messages
    //   this.loaderService.loading.next(false);
    //   return;
    // }

   
    return;
  }
  else{
    this.getconvo(id,filename);
    return;
  }

}


getconvo(id: any, filename: any) {
  this.toastMessageService.showmessage('Generating conversation for: ' + id + ':' + filename);
  this.filename = filename;
  this.chatData = [];
  this.fileuploaded = true;
  this.poid = id;

  this.loaderService.loading.next(true);
  this.podcastService.getPodcastList(id).subscribe(

    (res: any) => {
      if (res.status === 'success') {
        if (res.data.messages && res.data.messages.length > 0) {
          // Loop through the messages array and push each message to chatData
          res.data.messages.forEach((message: any) => {
            let role = 'user'; // Default role

            // Check the speaker and assign roles accordingly
            if (message.speaker === 'John') {
              role = 'user';
            } else  {
              role = 'assistant';
            }

            this.chatData.push({
              role: role,
              speaker: message.speaker,
              content: message.text,
              isEditing: false
            });
          });

          // Stop the loader after processing messages
          this.isToggled = false;
          this.loaderService.loading.next(false);
          return;
        } else if (res.data.status === 'PENDING') {
          this.getconversionstatus1(id, filename);
        } else {
          this.loaderService.loading.next(false);
          this.toastMessageService.showmessage('No messages found or status is not handled.');
        }
      } else {
        // Handle failure and stop loader
        this.loaderService.loading.next(false);
        this.toastMessageService.showmessage('Failed to generate conversation');
      }
    },
    (error) => {
      this.loaderService.loading.next(false);
      this.toastMessageService.showmessage('Failed to get conversation');
    }
  );
}


  getconversionstatus1(id:any,filename:any){

    this.loaderService.loading.next(true);
    // this.isloading = true;
    this.podcastService.getConversionStatus(id)
    .subscribe((res:any) => {
      if(res.status == 'success' && res.message == 'Podcast conversation completed successfully.'){
        // this.audiostatus = res.data;
        this.loaderService.loading.next(false);
        // this.getConversionStatusAudio(id);
        this.getconvo(id,filename);
      }
      else{
        this.loaderService.loading.next(true);
        // this.isloading = true;
        //this.toastMessageService.showmessage('Failed to get conversion status');
        //call getconversionstatus again after 15secs
        setTimeout(() => {
          this.getconversionstatus1(id,filename);
        }, 25000);
      }
    },
    (error) => {
      // this.loaderService.loading.next(false);
      this.isloading = false;
      this.loaderService.loading.next(false);
      this.toastMessageService.showmessage('Failed to get conversion status');
    });
  
  }
  
  downloadPodcast(url: string,name:any,id:any) {

    const token=localStorage.getItem('token');
    url = `${this.podcasturl}/${id}/audio?download=true&token=${token}`;
    // Create a new anchor element
    const link = document.createElement('a');
    // Set the href attribute to the podcast URL
    link.href = url;
    // Set the download attribute with a default filename
    link.download = `podcast_${name}.mp3`; // You can customize this filename
    // Append the anchor to the document body (required for Firefox)
    document.body.appendChild(link);
    // Programmatically click the link to trigger the download
    link.click();
    // Remove the anchor from the document
    document.body.removeChild(link);
  }
  
  download() {
    this.loaderService.loading.next(true);

    // Convert chatData (messages) into a structured string format
    let textData = this.chatData.map((message: any) => {
      return `Speaker: ${message.speaker}\nContent: ${message.content}\n\n`;
    }).join(''); // Join all messages into a single string with line breaks

    // Create a Blob containing the text data
    const textBlob = new Blob([textData], { type: 'text/plain' });

    // Create a new anchor element
    const link = document.createElement('a');
    // Set the href attribute to the Blob URL
    link.href = URL.createObjectURL(textBlob);
    // Set the download attribute with a default filename
    link.download = `${this.filename || 'download'}_transcript.txt`; // Customize the filename if needed
    // Append the anchor to the document body (required for Firefox)
    document.body.appendChild(link);
    // Programmatically click the link to trigger the download
    link.click();
    // Remove the anchor from the document
    document.body.removeChild(link);


    this.loaderService.loading.next(false);
    this.isupload = true;
}


  deletepodcast(id:any){
    // this.loaderService.loading.next(true);
    this.toastMessageService.showmessage('Deleting podcast');
    this.podcastService.deletePodcast(id)
    .subscribe((res:any) => {
      if(res.status == 'success'){
        this.gethistory();
        this.loaderService.loading.next(false);
        this.toastMessageService.showmessage(res.message);
      }
      else{
        this.loaderService.loading.next(false);
        this.toastMessageService.showmessage('Failed to delete podcast');
      }
    });
   }


   onFileSelected(event: any,id:any) {
    const file = event.target.files[0];
  
    if (file) {
      const reader = new FileReader();
  
      reader.onload = (e: any) => {
        const fileContent = e.target.result;
  
        // Call the updatePodcast function with the content of the file
        this.updatepodcast(fileContent, id); // Replace 'poid' with the actual podcast ID
      };
  
      reader.readAsText(file); // Read the file as text
    }
  }
   
  updatepodcast(event: any, id: any) {
    this.audiop=false;
    this.loaderService.loading.next(true);
    const file = event.target.files[0]; // Assuming the file input is the event target
  
    if (!file) {
      console.error('No file selected');
      return;
    }
  
    const reader = new FileReader();
  
    reader.onload = (loadEvent) => {
      const content = loadEvent.target?.result as string; // Extract file content
  
      // Parse the content to create the desired JSON structure
      const messages = this.convertToJSON(content);
  
      // Prepare the payload for the update
      const payload = {
        messages: messages
      };
  
      // Call the updatePodcast API using podcast service
      this.podcastService.updatePodcast(id, payload).subscribe(
    
    (res: any) => {
      if (res.status === 'success') {
        if (res.data.messages && res.data.messages.length > 0) {
          // Loop through the messages array and push each message to chatData
          // res.data.messages.forEach((message: any) => {
          //   let role = 'user'; // Default role

          //   // Check the speaker and assign roles accordingly
          //   if (message.speaker === 'John') {
          //     role = 'user';
          //   } else  {
          //     role = 'assistant';
          //   }

          //   this.chatData.push({
          //     role: role,
          //     speaker: message.speaker,
          //     content: message.text,
          //   });
          // });
          this.getconvo(id,this.filename);
          this.generateAudio(id);
          

          // Stop the loader after processing messages
          this.loaderService.loading.next(false);
          return;
        }  else {
          this.loaderService.loading.next(false);
          this.toastMessageService.showmessage('No messages found or status is not handled.');
        }
      } else {
        // Handle failure and stop loader
        this.loaderService.loading.next(false);
        this.toastMessageService.showmessage('Failed to generate conversation');
      }
    },
    (error) => {
      this.loaderService.loading.next(false);
      this.fileuploaded = false;
      this.loaderService.loading.next(false);
      this.toastMessageService.showmessage('Failed to get conversation');
    }
  );
    };
  
    // Read the file as text
    reader.readAsText(file);
  }
  

  convertToJSON(text:any) {
    const lines = text.split('\n');
    const data: { speaker: any; text: any; }[] = [];
    let currentSpeaker: null = null;
   
    lines.forEach((line: { startsWith: (arg0: string) => any; replace: (arg0: string, arg1: string) => null; }) => {
      if (line.startsWith('Speaker: ')) {
        currentSpeaker = line.replace('Speaker: ', '');
      } else if (line.startsWith('Content: ')) {
        const content = line.replace('Content: ', '');
        data.push({ speaker: currentSpeaker, text: content });
      }
    });
   
    return data;
  }

  parseContentToJson(content: string): Array<{ speaker: string; text: string }> {
    // Parse the content into JSON format
    const parsedData = JSON.parse(content);
    
    // Transform the parsed data into the desired structure
    return parsedData.map((item: { speaker: string; text: string }) => ({
      speaker: item.speaker,
      text: item.text.trim() // Trim any leading/trailing whitespace
    }));
  }
  
  //  // Method to start editing
   startEditing(index: number) {
    const chat = this.chatData[index];
    chat.isEditing = true;
    chat.editContent = chat.content; // Set current content to the editContent for editing
  }

  // // Method to save the edited content
  // saveEdit(index: number) {
  //   const chat = this.chatData[index];
  //   chat.content = chat.editContent; // Update content with edited value
  //   chat.isEditing = false; // Exit edit mode
  // }

  // // Method to cancel editing
  // cancelEdit(index: number) {
  //   const chat = this.chatData[index];
  //   chat.isEditing = false; // Exit edit mode without saving
  // }

   // Start editing the selected chat content
   startEdit(index: number) {
    this.isEditing[index] = true;
    this.editedContent[index] = this.chatData[index].content; // Save current content for editing
  }

  // Save the edited content
  saveEdit(index: number) {
    this.chatData[index].content = this.editedContent[index];
    this.isEditing[index] = false; // Exit editing mode
    this.isregen = true;
  }

  // Cancel the edit operation
  cancelEdit(index: number) {
    this.isEditing[index] = false; // Exit editing mode without saving
  }

  scrollToBottom() {
    setTimeout(() => {
      const containerElement = this.scrollContainer.nativeElement;
      containerElement.scrollTop = containerElement.scrollHeight;
    }, 1000);
  }

  regen(id:any){

    this.audiop=false;
     // Convert chatData (messages) into a structured string format
     let textData = this.chatData.map((message: any) => {
      return `Speaker: ${message.speaker}\nContent: ${message.content}\n\n`;
    }).join(''); // Join all messages into a single string with line breaks

    const messages = this.convertToJSON(textData);
  
    // Prepare the payload for the update
    const payload = {
      messages: messages
    };

    // Call the updatePodcast API using podcast service
    this.podcastService.updatePodcast(id, payload).subscribe(
  
  (res: any) => {
    if (res.status === 'success') {
      if (res.data.messages && res.data.messages.length > 0) {
        // Loop through the messages array and push each message to chatData
        this.getconvo(id,this.filename);
        this.generateAudio(id);
         this.isregen=false;

        // Stop the loader after processing messages
        this.loaderService.loading.next(false);
        return;
      }  else {
        this.loaderService.loading.next(false);
        this.toastMessageService.showmessage('No messages found or status is not handled.');
      }
    } else {
      // Handle failure and stop loader
      this.loaderService.loading.next(false);
      this.toastMessageService.showmessage('Failed to generate conversation');
    }
  },
  (error) => {
    this.loaderService.loading.next(false);
    this.fileuploaded = false;
    this.loaderService.loading.next(false);
    this.toastMessageService.showmessage('Failed to get conversation');
  }
);

   
  }

}
