<div class="navigation" [ngClass]="navToggle? 'active' : ''">
    <div class="user-box">
        <p *ngIf="navToggle" class="username">
            <!-- <b>{{username}}</b> -->
            <b>{{userOHR}}</b>
        </p>
        <div class="image-box" (click)="navToggle = !navToggle"></div>
        <p *ngIf="!navToggle" class="username text-light"><b>{{selectRole?.name}}</b></p>
    </div>
    <ul class="menu">
        <li *ngIf="selectRole">
            <b>Switch Role</b>
            <span>
                <button class="btn btn-sm btn-primary btn-width" (click)="switchRole()">{{selectRole?.name}}</button>
            </span>
        </li>
        <li>
            <b>Logout</b>
            <span>
                <button class="btn btn-sm btn-primary btn-width" (click)="logout()">
                    <img src="assets/images/logouticon.png" alt="icon" />
                </button>

            </span>

        </li>
    </ul>
</div>