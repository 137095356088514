import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../shared/service/loader.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AssistapiService } from '../shared/service/assistapi.service';
import { AfterViewInit } from '@angular/core';
declare var $: any;
import { saveAs } from 'file-saver';



@Component({
  selector: 'app-api-assistant',
  templateUrl: './api-assistant.component.html',
  styleUrls: ['./api-assistant.component.scss']
})
export class ApiAssistantComponent implements OnInit, AfterViewInit {

  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('imageinput') imageInput!: ElementRef;
  @Input() fileTypes = ['.txt', '.doc', '.pdf','.xlsx', '.png', '.jpeg', '.jpg', '.PNG', '.tif', '.tiff', '.ppt', '.pptx'];
  chatVisible: boolean = false;
  userMessage: string = '';
  chatMessages: any[] = [];
 copied:boolean = false;
 imgtype!:string;

chatstatus:boolean = false;

selectedFile: any;
instructions: any;
assistid: any;
fileid: any[] =[];
runid: any;
threadid: any;
assistname: any;
firstquerystatus: boolean = true;
createassistantstatus: boolean = false;
userOHR:any;
txtstatus: boolean = true;
imagedata:any;
asst_name: any;

prompts: string[] = [
  "'You are a helpful AI assistant who is an Excel Expert, and your task is to perform various operations in Excel. Your goal is to provide accurate and efficient solutions to the user's Excel-related queries by understanding and executing a wide range of data analysis and manipulation tasks.'",
  'A template library to boost your efficiency',
  'Upload your files and instantly receive concise summaries',
  'Upload files and instantly receive translated files in selected language',
  'Upload Audio/video files and download your transcript in selected language',
  'Get your prompt work on latest internet data',
  'Write a prompt and instantly receive generated images'
];

prompts1: any = [
  {
    name: 'Data Analyser',
    description: 'Assistant that excels in data visualization and manipulation using tools like pandas, pyplot, and seaborn to create charts, read data, display code, and handle errors efficiently.',
    value: `You are a helpful AI assistant who is an Excel Expert, and your task is to perform various operations in Excel. Your goal is to provide accurate and efficient solutions to the user's Excel-related queries by understanding and executing a wide range of data analysis and manipulation tasks. Specifically, you will:
- Create Visualizations: Develop a variety of charts (e.g., bar, line, pie, scatter plots) to visually represent data trends and comparisons using pyplot and seaborn.
- Data Reading and Download: Use pandas for reading data from various sources (e.g., CSV, Excel,json files) and for downloading data after processing.
- Display Code: Anytime you write new code, display a preview of the code to show your work.
- Run and Test Code: Run the code to confirm that it executes correctly.
- Display Results: If the code is successful, display the visualization or the result of the data manipulation.
- Error Handling: If the code is unsuccessful, display the error message and revise the code, then rerun it, following the steps above again.`
  },
//   {
//     name: 'Generate SQL',
//     description: 'SQL Expert for database management and queries.',
//     value: `You are a helpful AI assistant who is an SQL Expert, and your task is to perform various database operations using SQL. Your goal is to provide accurate and efficient solutions to the user's SQL-related queries by understanding and executing a wide range of database management tasks. Specifically, you will:
// - Query Development: Write and optimize SQL queries to retrieve, insert, update, and delete data from databases.
// - Data Analysis: Perform complex data analysis tasks, including aggregations, joins, subqueries, and window functions, to extract meaningful insights from the data.
// - Database Design: Assist in designing and normalizing database schemas to ensure data integrity and optimal performance.
// - Data Reading and Export: Use SQL to read data from various database management systems (e.g., MySQL, PostgreSQL, SQLite) and export data into different formats (e.g., CSV, JSON) after processing.
// - Display Code: Anytime you write new SQL code, display a preview of the code to show your work.
// - Run and Test Code: Execute the SQL code to confirm that it runs correctly and produces the desired results.
// - Display Results: If the code is successful, display the query results or the outcome of the database manipulation.
// - Error Handling: If the code is unsuccessful, display the error message and revise the code, then rerun it, following the steps above again.
// - Documentation: Provide comments and explanations within the SQL code to ensure it is understandable and maintainable.
// - Security Best Practices: Ensure that all SQL operations follow best practices for security, such as avoiding SQL injection vulnerabilities.`
//   },
//   {
//     name: 'Python Assist',
//     description: 'Python Expert for programming and automation.',
//     value: `You are a helpful AI assistant who is a Python Expert, and your task is to perform various programming operations using Python. Your goal is to provide accurate and efficient solutions to the user's Python-related queries by understanding and executing a wide range of coding tasks. Specifically, you will:
// - Code Development: Write and optimize Python code to solve specific problems, implement algorithms, or develop applications.
// - Data Analysis and Manipulation: Use libraries such as pandas, NumPy, and scipy to perform data analysis, manipulation, and statistical computations.
// - Visualization: Create visualizations using matplotlib, seaborn, or other visualization libraries to represent data trends and insights.
// - Automation: Develop scripts to automate repetitive tasks, such as data cleaning, file management, and web scraping.
// - Integration: Integrate Python code with various APIs and services to enhance functionality and data exchange.
// - Display Code: Anytime you write new Python code, display a preview of the code to show your work.
// - Run and Test Code: Execute the Python code to confirm that it runs correctly and produces the desired results.
// - Display Results: If the code is successful, display the output or the result of the data manipulation or analysis.
// - Error Handling: If the code is unsuccessful, display the error message and revise the code, then rerun it, following the steps above again.
// - Documentation: Provide comments and explanations within the code to ensure it is understandable and maintainable.
// - Best Practices: Follow best practices for Python programming, including code readability, modularity, and efficiency.`
//   },
//   {
//     name: 'File Summarization',
//     description: 'Specialist in summarizing various types of files.',
//     value: `You are a helpful AI assistant who specializes in summarizing files. Your task is to provide concise and comprehensive summaries for various types of files, such as text documents, PDFs, and spreadsheets. Your goal is to extract and present key information in a clear and organized manner. Specifically, you will:
// - Content Extraction: Read and extract content from various file formats (e.g., .txt, .pdf, .docx, .xlsx) using appropriate libraries and tools.
// - Key Points Identification: Identify and highlight the main points, themes, and significant information within the file, focusing on the most relevant and important details.
// - Structured Summaries: Organize the extracted information into a well-structured summary, ensuring that it is easily digestible and provides a clear overview of the file's content.
// - Display Code: If you write any code to process the file, display a preview of the code to show your work.
// - Run and Test Code: Execute the code to confirm that it processes the file correctly.
// - Display Results: If the code is successful, display the summary of the file.
// - Error Handling: If the code is unsuccessful, display the error message and revise the code, then rerun it, following the steps above again.
// - Documentation: Provide comments and explanations within the code to ensure it is understandable and maintainable.
// - Adaptability: Adjust the level of detail in the summary based on user preferences, whether they need a high-level overview or a detailed breakdown.`
//   },
//   {
//     name: 'Media Assist',
//     description: 'Assistant that uses Python libraries like pyplot and Pillow to edit user-uploaded images, preparing and executing code, handling errors, and delivering the desired output efficiently.',
//     value: `Your work is to edit the image files which are uploaded by the user .

// Follow the below instructions to address the user query:

// 1. use python libraries like pyplot and pillow, etc. for the desired output.
// 2. prepare the necessary code and execute them.
// 3. if you encounter any error do the necessary changes and execute it again.
// 4. Send the desired output or output file to the user.`
//   }
];


addedPrompts: { name: string; value: string; }[] = [];



relatedQuestionList: string[] = [
  "1.Plot a graph, such as a bar graph, pie chart, line graph, etc., to illustrate the relationship between [Column Name 1] and [Column Name 2].",
  "2.Summarize the content of the file, highlighting the key points and main themes, and provide detailed insights and analysis based on the data or information presented.",
  "3.Perform various calculations such as average, maximum, minimum, and sum on the data present in the file."
];




  // uniqId: any[] = [];
  isLoading: boolean = false;
  cmessage!: string;
  qloader: boolean = false;
  fileData: any;
  fileName: any;
  selectedPrompt: string = '';
  selectedPrompt1: string = '';


  newPrompt: string = '';
  newName: string = '';

  showInput: boolean = false;
  username!: string;
  isHidden: boolean = false;

  isExpanded: boolean = true;
 desc!: string;
 fileLink!: string;
 modalRef?: BsModalRef;
chkagree: boolean = false;

  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private toastservice : ToastMessageService,
    private loaderservice : LoaderService,
    private modalService: BsModalService,
    private assistservice: AssistapiService,
    private router: Router
) {

}



clearChat(){
//location  reload
window.location.reload();
}

triggerFileInputClick(): void {
  this.fileInput.nativeElement.click();
  //imageinput
}
triggerFileInputClick1(): void {
  this.imageInput.nativeElement.click();
  //imageinput
}


toggleVisibility() {
  this.isHidden = !this.isHidden;
}

toggleBox() {
  this.isExpanded = !this.isExpanded;
}


ngOnInit(){
  this.getOHR();
  this.getUserName();
  this.onLoading();
  this.selectPrompt(this.prompts1[0])
}

onLoading() {
  const modalbtn = document.getElementById('byod_policy_model') as HTMLLIElement;
  modalbtn.click();
}

// Open model
openModal(template: TemplateRef<any>) {
  this.modalRef = this.modalService.show(template, { class: 'modal-md',ignoreBackdropClick: true });
}
getUserName() {
  this.username = localStorage.getItem('okta-username') ? localStorage.getItem('okta-username') as string : 'Guest';
}

ngAfterViewInit(): void {
  //this.initializeSlickSlider();
}

initializeSlickSlider(): void {
  $('.slick-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    centerMode: true,
    variableWidth: true,
    prevArrow: '<button class="slick-prev">&lt;</button>',
    nextArrow: '<button class="slick-next">&gt;</button>'
  });
}

onFileChange(event: any): void {
  const files = event.target.files;
  this.fileData = Array.from(event.target.files);
  if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let isValidFile = false;

        // Check if file type is valid based on this.fileTypes
        isValidFile = this.checkFileType(this.fileTypes, file);

        if (!isValidFile) {
            // Handle invalid file type
            console.log('Invalid file type for:', file.name);
            this.fileData = ''; // Clear the file data
            this.fileInput.nativeElement.value = ''; // Clear the file input
            this.toastservice.showError('Invalid file type for: ' + file.name + `. Please upload a valid ${this.fileTypes.join(', ')} type.`);
            continue; // Skip processing this file
        }

            this.fileName = file.name;
            this.selectedFile = file;
            this.isHidden = !this.isHidden;

          // Check if file size exceeds the limit (16MB)
  //         if (file.size > 16 * 1024 * 1024) {
  //             // File size exceeds the limit, show an alert
  //             alert("File size exceeds the limit of 16MB.");
  //             continue; // Skip processing this file and continue with the next one
  //         }

  //         // Store file details
  //         else{
  //         this.uploadedFiles.push({
  //             name: file.name,
  //             size: this.convertBytesToKB(file.size), // Convert size to KB
  //             uploadTime: new Date().toLocaleString(),
  //             language: this.TargetLanguage[0].name
  //         });


  // }
}
}
}

checkFileType(allowedExtensions: string[], file: File): boolean {
  const extension = file.name.split('.').pop()?.toLowerCase();
  return !!extension && allowedExtensions.includes('.' + extension);
}

selectPrompt(prompt: any) {
  this.selectedPrompt = prompt.value;
  this.selectedPrompt1 = prompt.name;
  this.instructions = prompt.value;
  this.desc = prompt.description;
 // this.toastservice.showSuccess('You have selected:'+prompt.name);
  if(prompt.name == 'Data Analyser'){
    this.asst_name = 'data_analyzer';
    this.fileTypes=['.csv', '.xlsx'];
  }
 else if(prompt.name == 'Media Assist'){
  this.asst_name = 'media_assist';
    this.fileTypes=['.png', '.jpeg', '.jpg', '.PNG'];

 }

}

toggleInput(): void {
  this.showInput = !this.showInput;
}

addNewPrompt(): void {
  if (this.newName.trim() && this.newPrompt.trim()) {
    this.addedPrompts.push({ name: this.newName.trim(), value: this.newPrompt.trim() });
    // Clear the input fields after adding the prompt
    this.newName = '';
    this.newPrompt = '';
    this.toggleInput();
  }
}

removePrompt(index: number): void {
  this.addedPrompts.splice(index, 1);
}

submit() {
  console.log('File:', this.selectedFile);
  // const myModalEl = document.getElementById('myModal');
  // const modal = bootstrap.Modal.getInstance(myModalEl);
  // modal.hide();
}


details(){
  document.getElementById('upbtn')?.click();
}









postUpload(event: any) {
//   this.loaderService.loading.next(true);

  if (event?.name) {
    this.fileData = event;
    console.log(this.fileData?.file, this.fileData?.name)

  }
//   this.getOHR();

//   const formData = new FormData();
//   formData.append('ra_sheet', this.fileData?.file);
//   //formData.append('user_id', this.userOHR);
// //  formData.append('app-name', this.functionSelected);
//   this.tcgService.fileupload(formData).subscribe(
//     (response: any) => {

//       this.loaderService.loading.next(false);
//       console.log(response)
//       if( response?.status == 'success'){
//         this.generatetestcase();
//         this.testcase=response.data;
//         this.fileUploadedStatus = true;
//         this.filestatus1=false;
//         this.toastMessage.showSuccess(response?.message+"\nFileName: "+this.fileData?.name);
//        }else{
//         this.toastMessage.showError(response?.message);
//        }
//     },
//     (error:any) => {
//       // Handle the error here
//       this.loaderService.loading.next(false);
//       console.error(error);
//       this.toastMessage.showError(error?.message);
//     }
//   );

}


resetFile() {
  // this.fileUploadedStatus = false;
  this.fileData = '';
  this.fileName = '' ;
  this.userMessage = '';
  this.toggleVisibility()
  this.fileInput.nativeElement.value = null;
  // this.downloadStatus = false;
  // this.targetSASUrl = '';
  // this.filestatus1=true;
  // localStorage.removeItem('f_tarnslate');
}

resetins(){
  this.selectedPrompt = '';
}












copyMessage(message: string) {
  navigator.clipboard.writeText(message).then(() => {
   this.toastservice.showSuccess('Copied to clipboard');
  });
}


regenerateAnswer(index: any) {
  this.userMessage = index;
  this.checkassistantstatus();

   // this.toastservice.showmessage(this.cmessage);
    // const userMessage = index.trim();
    // if (!userMessage) return;

    // this.chatMessages.push({ user: 'user', text: userMessage });
    // this.userMessage = '';

    // // Prepare the data to be sent in the API request
    // const requestData = {
    //    // "id": this.uniqId,
    //     "query": userMessage,
    // };

    // Make an API call to your bot here
    // this.panelservice.chatbot(requestData)
    //   .pipe(
    //     catchError((error) => {
    //       console.error(error); // Log the error to the console
    //       return of({ message: "I can't understand. Please try again." }); // Provide a default error response
    //     })
    //   )
    //   .subscribe((response: any) => {
    //     const botMessage = response.data; // Assuming your bot API returns a 'message' field
    //     this.chatMessages.push({ user: 'bot', text: botMessage });
    //   });

}

sendMessage(): void {
  // Handle user messages and bot responses
  this.isLoading = true;
  this.cmessage = this.userMessage;
  const userMessage = this.userMessage.trim();


  if (!userMessage) return;

  this.chatMessages.push({ user: 'user', text: userMessage });
  this.userMessage = '';

  // Prepare the data to be sent in the API request
  const requestData = {
      // "id": this.uniqId,
      "query": userMessage,
  };

  // Make an API call to your bot here
  // this.panelservice.chatbot(requestData)
  //   .pipe(
  //     catchError((error) => {
  //       console.error(error); // Log the error to the console
  //       return of({ message: "I can't understand. Please try again." }); // Provide a default error response
  //     })
  //   )
  //   .subscribe((response: any) => {
  //     const botMessage = response.data; // Assuming your bot API returns a 'message' field
  //     this.chatMessages.push({ user: 'bot', text: botMessage });
  //     this.isLoading=false;
  //   });
}


createassistant() {
  this.loaderservice.loading.next(true);
  // this.chatstatus = true;
  //add file if exists and instructions must to api
  const formData = new FormData();
  if(this.fileData){
    formData.append('file', this.selectedFile);
  }
  if(this.asst_name == 'data_analyzer' || this.asst_name == 'media_assist'){
    formData.append('assistants', this.asst_name);
    formData.append('instructions', '');
  }
  else {
    formData.append('assistants', '');
    formData.append('instructions', this.instructions);
  }

//call api
this.assistservice.createassistant(formData).subscribe(
//if response take assistant_id,file_id
(response: any) => {
  this.loaderservice.loading.next(false);
  console.log(response)
  if (response?.status == 'success') {
    this.chatstatus = true;
    this.createassistantstatus = true;
    this.assistid = response.assistant_id;

    this.fileid = []; // Initialize as an empty array

    if (response.file_id && response.file_id.length > 0) {
        this.fileid.push(response.file_id[0]);
    }

   // alert(response?.message);
    this.toastservice.showSuccess(response?.message);
    this.firstquery();
}

else{
    this.toastservice.showError(response?.message);
    this.chatstatus = true;
    this.txtstatus = true;
   }
},
(error:any) => {
  // Handle the error here
  this.txtstatus = true;
  this.chatstatus = true;
  this.loaderservice.loading.next(false);
  console.error(error);
  this.toastservice.showError(error?.message);
}

);

}

checkquerystatus() {
  this.chatstatus = false;
if(this.firstquerystatus){
  this.firstquery();
}
else{
  this.addquery();
}

}

checkassistantstatus(){
  this.txtstatus = false;
  this.isExpanded = false;
  this.isHidden = false;
  if(!this.createassistantstatus){
    this.createassistant();
  }
  else{
    this.checkquerystatus();
  }

}

firstquery() {
  this.loaderservice.loading.next(true);
  this.fileData = '';
  this.resetins();
  this.isHidden = false;
  // this.selectedPrompt1 = '';
  this.cmessage = this.userMessage;
  const userMessage = this.userMessage.trim();


  if (!userMessage) return;

  this.chatMessages.push({ user: 'user', text: userMessage });
  // this.userMessage = '';
  //add assistant_id and fusermessage as query to api as payload
  const requestData = {
    "assistant_id": this.assistid,
    "query": this.userMessage,
  };
  //make api call to bot here
  this.assistservice.firstquery(requestData).subscribe(
    (response: any) => {
      this.loaderservice.loading.next(false);
      console.log(response)
      if( response?.status == 'success'){
        this.toastservice.showSuccess(response?.message);
        this.runid = response.run_id;
        this.threadid = response.thread_id;
        // this.chatMessages.push({ user: 'bot', text: response.message });
        // this.chatMessages.push({ user: 'bot', text: response.answer });
        this.runstatus();
        this.firstquerystatus = false;
       }else{
        this.chatstatus = true;
        this.txtstatus = true;
        this.toastservice.showError(response?.message);
       }
    },
    (error:any) => {
      // Handle the error here
      this.txtstatus = true;
      this.chatstatus = true;
      this.loaderservice.loading.next(false);
      console.error(error);
      this.toastservice.showError(error?.message);
    }


  );



}

addquery() {
  this.loaderservice.loading.next(true);
  this.cmessage = this.userMessage;
  const userMessage = this.userMessage.trim();
  if (!userMessage) return;
  this.chatMessages.push({ user: 'user', text: userMessage });
  // this.userMessage = '';
  //send run_id,thread_id and usermessage to api as payload
  const requestData = {
    "assistant_id": this.assistid,
    "thread_id": this.threadid,
    "query": this.userMessage,
  };
  //make api call to bot here
  this.assistservice.addquery(requestData).subscribe(
    (response: any) => {
      this.loaderservice.loading.next(false);
      console.log(response)
      if( response?.status == 'success'){
        this.runid = response.run_id;
        this.threadid = response.thread_id;
        this.toastservice.showSuccess(response?.message);
        this.runstatus();
       }else{
        this.chatstatus = true;
        this.txtstatus = true;
        this.toastservice.showError(response?.message);
       }
    },
    (error:any) => {
      this.chatstatus = true;
      this.txtstatus = true;
      // Handle the error here
      this.loaderservice.loading.next(false);
      console.error(error);
      this.toastservice.showError(error?.message);
    }
  );

}












// runstatus() {
//   this.loaderservice.loading.next(true);
//   //send run_id and thread_id to api to get the response
//   const requestData = {
//     "run_id": this.runid,
//     "thread_id": this.threadid,
//   };
//   //make api call to bot here
//   this.assistservice.runstatus(requestData).subscribe(

//     (response: any) => {

//       console.log(response)
//       if( response?.status == 'completed'){
//         this.loaderservice.loading.next(false);
//         this.toastservice.showSuccess(response?.message);
//         this.chatMessages.push({ user: 'bot', text: response.output });
//         this.imagedata = this.latin1ToBase64(response.image);
//        }
//        else if( response?.status == 'in_progress' || response?.status == 'queued'){
//         //call runstatus again after 10s
//         setTimeout(() => {
//           this.runstatus();
//         }, 10000);
//        }

//        else{
//         this.toastservice.showError(response?.message);
//        }
//     },
//     (error:any) => {
//       // Handle the error here
//       this.loaderservice.loading.next(false);
//       console.error(error);
//       this.toastservice.showError(error?.message);
//     }
//   );
// }

// latin1ToBase64(latin1: string): string {
//   // Convert Latin-1 string to a binary string
//   const binaryString = unescape(encodeURIComponent(latin1));

//   // Convert binary string to base64
//   return btoa(binaryString);
// }


runstatus() {
  this.loaderservice.loading.next(true);
  const requestData = {
    "run_id": this.runid,
    "thread_id": this.threadid,
  };

  this.assistservice.runstatus(requestData).subscribe(
    (response: any) => {
      console.log(response);
      if (response?.status === 'completed') {
        this.loaderservice.loading.next(false);
        this.toastservice.showSuccess(response?.message);
        if(response.image == "No images in the content array"){
          //this.fileLink = response.file_links[0];
         // this.toastservice.showSuccess(this.fileLink);
        this.chatMessages.push({ user: 'bot', text: response.output , data: response.file_data , format: response.file_format  });
        this.txtstatus = true;
        this.userMessage = '';

        }
        if(response.image !== "No images in the content array"){

          // this.toastservice.showSuccess(this.fileLink);
          this.chatMessages.push({ user: 'bot', image: this.latin1ToBase64(response.image)});
          this.txtstatus = true;
        this.userMessage = '';
        }
       // this.imagedata = this.latin1ToBase64(response.image);
        this.chatstatus = true;
      } else if (response?.status === 'in_progress' || response?.status === 'queued') {
        this.toastservice.showSuccess("Your response is being generated.Please wait");
        setTimeout(() => {
          this.runstatus();
        }, 10000);
      } else {
        this.loaderservice.loading.next(false);
        this.chatstatus = true;
        this.txtstatus = true;

        this.toastservice.showError(response?.message);
      }
    },
    (error: any) => {
      this.loaderservice.loading.next(false);
      this.txtstatus = true;
      this.chatstatus = true;
      console.error(error);
      this.toastservice.showError(error?.message);

    }
  );
}


// private latin1ToBase64(latin1Data: string): string {
//   const signatures: { [key: string]: string } = {
//     '\x89PNG\r\n\x1a\n': 'png',
//     '\xff\xd8\xff': 'jpeg',
//     'GIF87a': 'gif',
//     'GIF89a': 'gif',
//     'BM': 'bmp',
//     'II*\x00': 'tiff',
//     'MM\x00*': 'tiff',
//     '\x52\x49\x46\x46....\x57\x45\x42\x50': 'webp', // Example for WebP (RIFF....WEBP)
//     '\x00\x00\x01\x00': 'ico', // ICO format
//     // Add more signatures for other image formats as needed
//   };

//   let fileType = 'octet-stream'; // Default file type
//   for (const [signature, type] of Object.entries(signatures)) {
//     if (latin1Data.startsWith(signature)) {
//       fileType = type;
//       break;
//     }
//   }

//   const byteArray = new Uint8Array([...latin1Data].map(char => char.charCodeAt(0)));
//   const base64Data = btoa(String.fromCharCode(...byteArray));
//   return `data:image/${fileType};base64,${base64Data}`;
// }
// private latin1ToBase64(latin1Data: string): string {
//   const signatures: { [key: string]: string } = {
//     '\x89PNG\r\n\x1a\n': 'png',
//     '\xff\xd8\xff': 'jpeg',
//     'GIF87a': 'gif',
//     'GIF89a': 'gif',
//     'BM': 'bmp',
//     'II*\x00': 'tiff',
//     'MM\x00*': 'tiff',
//     '\x52\x49\x46\x46....\x57\x45\x42\x50': 'webp', // Example for WebP (RIFF....WEBP)
//     '\x00\x00\x01\x00': 'ico', // ICO format
//     // Add more signatures for other image formats as needed
//   };

//   if (!latin1Data || typeof latin1Data !== 'string') {
//     throw new Error('Invalid input data');
//   }

//   let fileType = 'octet-stream'; // Default file type
//   for (const [signature, type] of Object.entries(signatures)) {
//     if (latin1Data.startsWith(signature)) {
//       fileType = type;
//       break;
//     }
//   }

//   // Convert Latin1 string to Uint8Array
//   const byteArray = new Uint8Array(latin1Data.length);
//   for (let i = 0; i < latin1Data.length; i++) {
//     byteArray[i] = latin1Data.charCodeAt(i);
//   }

//   // Convert Uint8Array to base64 string
//   const base64Data = btoa(String.fromCharCode(...byteArray));
//   return `data:image/${fileType};base64,${base64Data}`;
// }
// private latin1ToBase64(latin1Data: string): string {
//   const signatures: { [key: string]: string } = {
//     '\x89PNG\r\n\x1a\n': 'png',
//     '\xff\xd8\xff': 'jpeg',
//     'GIF87a': 'gif',
//     'GIF89a': 'gif',
//     'BM': 'bmp',
//     'II*\x00': 'tiff',
//     'MM\x00*': 'tiff',
//     '\x52\x49\x46\x46....\x57\x45\x42\x50': 'webp', // Example for WebP (RIFF....WEBP)
//     '\x00\x00\x01\x00': 'ico', // ICO format
//     // Add more signatures for other image formats as needed
//   };

//   if (!latin1Data || typeof latin1Data !== 'string') {
//     throw new Error('Invalid input data');
//   }

//   let fileType = 'octet-stream'; // Default file type
//   for (const [signature, type] of Object.entries(signatures)) {
//     if (latin1Data.startsWith(signature)) {
//       console.log('Data length:', latin1Data.length);
//       console.log('Signature:', signature);
//       fileType = type;
//       break;
//     }
//   }

//   try {
//     // Convert Latin1 string to Uint8Array
//     const byteArray = new Uint8Array(latin1Data.length);
//     for (let i = 0; i < latin1Data.length; i++) {
//       byteArray[i] = latin1Data.charCodeAt(i);
//     }

//     // Convert Uint8Array to base64 string
//     const base64Data = btoa(String.fromCharCode(...byteArray));
//     return `data:image/${fileType};base64,${base64Data}`;
//   } catch (error) {
//     console.error('Error converting data to Base64:', error);
//     throw new Error('Failed to convert data to Base64');
//   }
// }

private latin1ToBase64(latin1Data: string): string {
  const signatures: { [key: string]: string } = {
    '\x89PNG\r\n\x1a\n': 'png',
    '\xff\xd8\xff': 'jpeg',
    'GIF87a': 'gif',
    'GIF89a': 'gif',
    'BM': 'bmp',
    'II*\x00': 'tiff',
    'MM\x00*': 'tiff',
    '\x52\x49\x46\x46....\x57\x45\x42\x50': 'webp', // Example for WebP (RIFF....WEBP)
    '\x00\x00\x01\x00': 'ico', // ICO format
    // Add more signatures for other image formats as needed
  };

  if (!latin1Data || typeof latin1Data !== 'string') {
    throw new Error('Invalid input data');
  }

  let fileType = 'octet-stream'; // Default file type
  for (const [signature, type] of Object.entries(signatures)) {
    if (latin1Data.startsWith(signature)) {
      fileType = type;
      this.imgtype = fileType;
      break;
    }
  }

  try {
    // Convert Latin1 string to Uint8Array
    const byteArray = new Uint8Array(latin1Data.length);
    for (let i = 0; i < latin1Data.length; i++) {
      byteArray[i] = latin1Data.charCodeAt(i);
    }

    // Convert Uint8Array to base64 string without btoa
    const base64Data = this.arrayBufferToBase64(byteArray.buffer);
    return `data:image/${fileType};base64,${base64Data}`;
  } catch (error) {
    console.error('Error converting data to Base64:', error);
    throw new Error('Failed to convert data to Base64');
  }
}

private arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}


getOHR() {
  let userData = localStorage.getItem('okta-token-storage');
  if (userData) {
    let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
    if (ohr_id) {
      this.userOHR = ohr_id;
      this.toastservice.showmessage("welcome"+''+this.userOHR)
    }
  }
}



modifyassistant() {
  this.loaderservice.loading.next(true);
  //send assistant_id and instructions   to api as payload
  //add requestdata params structure

  const requestData:any = {
    "assistant_id": this.assistid,
    "instructions": this.instructions,
    //send file id in array format
    // "files": [this.fileid],
    "user_id": this.userOHR,

  };
  if(this.selectedFile){
    requestData['files'] = [this.fileid];
  }


  this.assistservice.modifyassistant(requestData).subscribe(
    (response: any) => {
      this.loaderservice.loading.next(false);
      console.log(response)
      if( response?.status == 'success'){
        this.toastservice.showSuccess(response?.message);
        this.assistid = response.assistant_id;
        this.fileid = response.file_id[0];
        this.firstquerystatus = true;
       }else{
        this.toastservice.showError(response?.message);
       }
    },
    (error:any) => {
      // Handle the error here
      this.loaderservice.loading.next(false);
      console.error(error);
      this.toastservice.showError(error?.message);
    }
  );
}






downloadImage(url: any) {
  this.loaderservice.loading.next(true);
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      this.loaderservice.loading.next(false);
      const a = document.createElement('a');
      const objectURL = URL.createObjectURL(blob);

      a.href = objectURL;
      a.download = `image.${this.imgtype}`; // You can set the download filename here
      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(objectURL);
      document.body.removeChild(a);
    })
    .catch(error => {
      this.loaderservice.loading.next(false);
      this.toastservice.showError('Error downloading image:' + error);
      console.error('Error downloading image:', error);
    });
}


downloadFile(url:any): void {
  console.log('Downloading file from:', url);

  this.assistservice.getFile(url).subscribe(blob => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = this.getFileName(this.fileLink);
    a.click();
    window.URL.revokeObjectURL(url);
  });
}

getFileName(fileUrl: string): string {
  const parts = fileUrl.split('/');
  return parts.pop() || 'default-filename.xlsx';
}





latin1ToUint8Array(latin1String: string): Uint8Array {
  const latin1Decoder = new TextDecoder('iso-8859-1');
  const bytes = new Uint8Array(latin1String.length);
  for (let i = 0; i < latin1String.length; i++) {
    bytes[i] = latin1String.charCodeAt(i);
  }
  return bytes;
}

downloadExcelFile(data: string, format: string): void {
  if (!data || !format) {
    console.error('File data or format not available');
    return;
  }

  // Decode Latin-1 encoded string to Uint8Array
  const uint8Array = this.latin1ToUint8Array(data);

  // Determine file extension and MIME type based on format
  let fileName = 'download'; // Default filename if not specified
  let mimeType = 'application/octet-stream'; // Default MIME type if not specified

  switch (format) {
    case 'xlsx':
      fileName = 'selected_columns.xlsx';
      mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'csv':
      fileName = 'selected_columns.csv';
      mimeType = 'text/csv';
      break;
    // Add more formats as needed
    default:
      console.error('Unsupported format:', format);
      return;
  }

  // Convert Uint8Array to Blob
  const blob = new Blob([uint8Array], { type: mimeType });

  // Save Blob to file
  saveAs(blob, fileName);
}



// Helper function to convert Latin-1 string to Uint8Array
private stringToBytes(str: string): ArrayBuffer {
  const buffer = new ArrayBuffer(str.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < str.length; i++) {
    view[i] = str.charCodeAt(i) & 0xff;
  }
  return buffer;
}




































}

