
<app-sidenav [item]="currentItem"></app-sidenav>
<div class="container responsive">

  <div class="row">
    <div class="col s10 text-center" style="margin-top: 35px">
      <img
        class="center"
        src="assets/DarkaskGenpact.svg"
        alt="Genpact"
        style="height: 135px; width: 270px"
      />
    </div>
  </div>
   <br>

  <!-- Example single danger button -->
<div class="col-sm-12">
  <div class="row">
    <div class="col-sm-4"></div>
    <div class="col-sm-4">
      <label class="col-sm-12"> Select Knowledge </label>
      <select
        name="knowedgediv"
        (change)="ChangeKnowledge($event)"
        class="form-control"
        [(ngModel)]="knowledgeSelected"
      >
        <!-- <option>--Select--</option> -->
        <option [value]="item.value" *ngFor="let item of knowledge">
          {{ item.name }}
        </option>
      </select>
    </div>
    <!-- <div class="col-sm-3">
    <label class="col-sm-12">  Select Account  </label>
    <select
      style="display: inline-block"
      name="accountdiv"
      class="form-control"
      [(ngModel)]="accountSelected"
      (change)="ChangeAccount($event)"
    >
      <option [value]="item.name" *ngFor="let item of accountList">
        {{ item.name }}
      </option>
    </select>
    </div> -->
  </div>
</div>
<br>
<div class="row">
  <div class="col-sm-2"></div>
  <div class="col-sm-9">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <!-- <span class="input-group-text" id="basic-addon1">@</span> -->
      </div>
      <input
        type="text"
        class="form-control rounded-pill"
        placeholder="Search your Question Here"
        aria-label="searchtext"
        aria-describedby="basic-addon1"
        [(ngModel)]="userQuery"
        (keydown.enter)="searchQuestion()"
      />
      <button
        type="button"
        class="btn btn-light rounded-pill m-auto"
        style="padding-left: 12px;"
        (click)="searchQuestion()"
      >
      <img src="assets/search.svg" />
      Search
      </button>
    </div>
  </div>
</div>
<div class="row">
  <div *ngIf="loaderDisplay" class="text-center">
    <img src="assets/loader.gif" style="height: 15rem" />
  </div>
</div>

  <div *ngIf="gptDisplay">
    <div class="row">
    <h6>GPT Response</h6>
    <div class="card">
      <div class="card-body">
        {{ gptUserResponse }}
      </div>
    </div>
  </div>
</div>
  <br/>

  <div class="row">
    <div *ngIf="userQueryResultDisplay" style="background-color: #F8F8F8">
      <h4 style="font-style: italic">Thought Process :-</h4>
      <h6 *ngIf="gptSearchQuery">Search Query Used: {{ gptSearchQuery }}</h6>
      <table class="table table-bordered table-responsive">
        <thead>
          <tr>
            <!-- <th scope="col">Confidence</th> -->
            <th scope="col">Source</th>
            <th scope="col">Answer</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of userQueryResponse">
            <!-- <td>{{item.score}}</td> -->
            <td>{{ item.source }}</td>
            <td>
              {{ item.content | slice : 0 : 200 }} ...
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="collapse"
                data-target="#demo"
                (click)="viewMore()"
              >
                {{ buttonTitle }}
              </button>
              <div id="demo" class="collapse">
                {{ item.content | slice : 200 }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

