import { Component, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { DigiService } from 'src/app/digi.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AvatarDisplayComponent } from 'src/app/digital-avatar/avatar-display/avatar-display.component';
import { LoaderComponent } from 'src/app/digital-avatar/loader/loader.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-capture-image',
  standalone: true,
  templateUrl: './capture-image.component.html',
  imports:[CommonModule,FormsModule,AvatarDisplayComponent,LoaderComponent],
  styleUrls: ['./capture-image.component.scss']
})
export class CaptureImageComponent implements AfterViewInit{

  @ViewChild('video') video!: ElementRef<HTMLVideoElement>;
  @ViewChild('uploadCanvas') uploadCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('captureCanvas') captureCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvas') canvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Output() imageUploaded = new EventEmitter<File>();
  capturedImage: File | null = null;


  @Output() avatarGenerated = new EventEmitter<string>();
  imageFile!: File;
  showPopup = false;
  activeTab: string = 'capture';
  selectedButton: string = 'capture';
  isQuiz: boolean = false;
  showTextModal: boolean = false; // Control modal visibility
  userInput: string = ''; 

  context: CanvasRenderingContext2D | null = null;
  generatedAvatarUrl: string = ''; 
  loading = false;
  defaultImage: string = './assets/digital-avatar/upload.svg.svg';
  successMessage: string = 'Image has been successfully generated';

  constructor(private http: HttpClient,private router: Router,private loaderService: LoaderService,private digiservice:DigiService) {}

  ngAfterViewInit() {
    if (this.uploadCanvas?.nativeElement) {
      this.context = this.uploadCanvas.nativeElement.getContext('2d');
      if (this.context) {
        this.drawDefaultImage(this.uploadCanvas.nativeElement); 
      }
    } else {
      console.error('Upload canvas element is not available');
    }
  }

  setActiveTab(tabName: string) {
    this.activeTab = tabName;
  }

  selectButton(buttonName: string) {
    this.selectedButton = buttonName;
    if (buttonName==='quiz'){
      this.isQuiz=true;
      this.router.navigate(['/prompt-gen/avatar-kraft/generator'],{
        queryParams: { Quiz: this.isQuiz },
      }); 
      console.log(this.isQuiz);
    }
    else if (buttonName==='generate'){
      // this.isQuiz=false;
      this.router.navigate(['/prompt-gen/avatar-kraft/generator']);
    } else if(buttonName==='capture'){
      console.log("Capture Image")
      // this.router.navigate(['/prompt-gen/avatar-kraft/capture'], {
      //   queryParams: { prompt: this.userPrompt },
      // }); 
    }
  }

  drawDefaultImage(canvas: HTMLCanvasElement) {
    const context = canvas.getContext('2d');
    if (context) {
      const defaultImage = new Image();
      defaultImage.onload = () => {
        // Clear the canvas
        context.clearRect(0, 0, canvas.width, canvas.height);
        
        const desiredWidth = canvas.width * 0.3; 
        const desiredHeight = (defaultImage.height / defaultImage.width) * desiredWidth; // Maintain aspect ratio

        
        const offsetX = (canvas.width - desiredWidth) / 2;
        const offsetY = (canvas.height - desiredHeight) / 2;

        context.drawImage(defaultImage, offsetX, offsetY, desiredWidth, desiredHeight);
      };
      defaultImage.src = this.defaultImage; 
    }
  }

  onFileSelected(event: any) {
    this.imageFile = event.target.files[0];
    if (this.imageFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (this.uploadCanvas?.nativeElement) {
          this.drawImageOnCanvas(e.target.result, this.uploadCanvas.nativeElement);
          this.onImageCaptured(this.imageFile);
        } else {
          console.error('Upload canvas element is not available');
        }
      };
      reader.readAsDataURL(this.imageFile);
    }
  }
  triggerFileInputClick(): void {
    this.fileInput.nativeElement.click();
    
  }

  captureImage() {
    const modal = document.getElementById('captureModal');
    if (modal) {
      modal.style.display = 'block';
    }
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        if (this.video?.nativeElement) {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.style.display = 'block';
        } else {
          console.error('Video element is not available');
        }
      })
      .catch((error) => {
        console.error('Error accessing camera: ', error);
      });
  }

  captureImageFromVideo() {
    if (this.captureCanvas?.nativeElement) {
      const canvas = this.captureCanvas.nativeElement;
      const context = canvas.getContext('2d');
      if (context && this.video?.nativeElement) {
        context.drawImage(this.video.nativeElement, 0, 0, canvas.width, canvas.height);
  
        // Draw the captured image on the uploadCanvas
        if (this.uploadCanvas?.nativeElement) {
          this.drawImageOnCanvas(canvas.toDataURL('image/jpeg'), this.uploadCanvas.nativeElement);
        } else {
          console.error('Upload canvas element is not available');
        }
  
        // Convert the canvas image to a Blob and create a File object
        canvas.toBlob((blob) => {
          if (blob) {
            this.imageFile = new File([blob], 'captured_image.jpg', { type: 'image/jpeg' });
            this.onImageCaptured(this.imageFile); 
          }
        }, 'image/jpeg');
        this.stopCamera();
        this.closeModal();
      }
    } else {
      console.error('Capture canvas element is not available');
    }
  }

  private stopCamera() {
    const stream = this.video.nativeElement.srcObject as MediaStream;
    const tracks = stream.getTracks();
    tracks.forEach(track => track.stop());
    this.video.nativeElement.style.display = 'none';
  }

  gotohome(): void {
    this.router.navigate(['/prompt-gen/avatar-kraft/']);
    // console.log('Navigate with prompt:', this.userPrompt);
  }
  

  drawImageOnCanvas(imageSrc: string, canvas: HTMLCanvasElement) {
    const context = canvas.getContext('2d');
    if (context) {
      const image = new Image();
      image.onload = () => {
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
  
        
        const aspectRatio = image.width / image.height;
  
        let drawWidth, drawHeight;
        if (aspectRatio > 1) {
          drawWidth = canvasWidth;
          drawHeight = canvasWidth / aspectRatio;
        } else {
          drawWidth = canvasHeight * aspectRatio;
          drawHeight = canvasHeight;
        }
  
        // Clear the canvas before drawing
        context.clearRect(0, 0, canvasWidth, canvasHeight);
  
        const offsetX = (canvasWidth - drawWidth) / 2;
        const offsetY = (canvasHeight - drawHeight) / 2;
  
        context.drawImage(image, offsetX, offsetY, drawWidth, drawHeight);
      };
      image.src = imageSrc;
    }
  }
  

  generateAvatar() {

    
    if (!this.imageFile) {
      alert('Please upload or capture an image first.');
      return;
    }
    this.loading = true;
    this.loaderService.loading.next(true);
    

    const formData = new FormData();
    formData.append('image', this.imageFile);

    

    // First API call: Generate prompt
    this.digiservice.getDigiAvatar(formData)
      .subscribe(response => {
        if (response) {
          const prompt = response;
          const formData = new FormData();
          formData.append('dalle_prompt', prompt);

          // Second API call: Generate image using prompt
          // this.http.post<any>('https://api.playground-dev.genpact.com/poc/avatar/generate-image', formData)
          this.digiservice.getAvatar(formData)
            .subscribe(imageResponse => {
              if (imageResponse) {
                this.generatedAvatarUrl = imageResponse; // Set the generated image URL
                this.avatarGenerated.emit(this.generatedAvatarUrl);
                this.loading = false;
                this.loaderService.loading.next(false); // Emit the avatar URL for the avatar display component
              } else {
                console.error('No image URL in response:', imageResponse);
                alert('Failed to generate image.');
              }
              this.showPopup = false;
            }, error => {
              console.error('Error generating image:', error);
              alert('Error occurred while generating the image.');
            });
        } else {
          console.error('No prompt in response:', response);
          alert('Failed to generate prompt.');
          this.showPopup = false;
        }
      }, error => {
        console.error('Error generating prompt:', error);
        alert('Error occurred while generating the prompt.');
        this.showPopup = false;
      });
  }

  closeModal() {
    const modal = document.getElementById('captureModal');
    if (modal) {
      modal.style.display = 'none';
    }
  }

  onImageCaptured(image: File): void {
    this.capturedImage = image;
    this.imageUploaded.emit(image); 
  }


  openTextModal() {
    this.showTextModal = true;
  }
  
  closeTextModal() {
    this.showTextModal = false;
  }
  
  saveText() {
    console.log("User Input:", this.userInput); // You can send this.userInput to your API
  
    // Perform your API call here
    // Example: this.yourService.sendText(this.userInput).subscribe(...)
  
    // After saving, close the modal
    this.closeTextModal();
  }
  


}
