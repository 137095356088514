<app-sidenav></app-sidenav>



<div class="image-grid">
    <div *ngFor="let image of images" class="image-item">
      <img [src]="image.url" alt="Image" class="grid-image" />
      <div class="image-prompt">{{ image.prompt }}</div>
    </div>
  </div>
  