<div class="image-upload pt-3">
  <label class="label1" style="cursor: pointer;" for="file_upload">
    <!-- <img src="" alt="" class="uploaded-image"> -->
    <div class="h-100" >
      <div class="dplay-tbl">
        <div class="dplay-tbl-cell" >
          <h5><b>{{header}}</b></h5>
          <p>{{ins}}{{ place }}</p>
          <p>{{ placeholder }} </p>

          <!-- <p>{{ place }}</p> -->
        </div>
      </div>
    </div>
    <input
      type="file"
      class="form-control"
      id="file_upload"
      style="display: none;"

      accept="{{ inputAccept }}"
      [multiple]="true"

    />
  </label>
</div>
