import { Component, Input } from '@angular/core';
import { DigiService } from 'src/app/digi.service'; 

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  standalone:true,
  styleUrls: ['./result.component.scss'],
})
export class ResultComponent {
  @Input() finalResponse: string = '';

  constructor(private digiService: DigiService) {}

  
  restartQuiz(): void {
    
    const formData = new FormData();
    formData.append('answers', this.finalResponse);

    this.digiService.gethoroscope(formData).subscribe(
      (response: any) => {
        
        const personalityText = response; 

        
        this.digiService.updatePersonalityTraits(personalityText);
      },
      (error) => {
        console.error('Error fetching personality traits:', error);
      }
    );
  }
}
