<app-sidenav [item]="'playground'"></app-sidenav>


<div class="container mt-5 text-align-center" *ngIf="!showchat" >
    <div style="display: flex;justify-content: center;">
      <div class="instructions-box" >
  
        <h4 class="chat1-header " style="text-align: center;">
          <span class="greeting" >Prompt Generator
          </span><br>
          <!-- <span class="question">Please select/Add a prompt to get started</span> -->
          <span class="question">Enhance your AI experience with well-structured prompts </span>
  
        </h4>
        <p >A prompt generator is important because it helps create specific and clear instructions for AI models, like me, to follow. By providing well-crafted prompts, users can guide the AI to produce more accurate and relevant responses. This ensures that the AI understands the context and delivers useful information or solutions, making interactions more efficient and effective. In essence, a good prompt generator enhances the overall quality and reliability of AI-driven conversations.</p>
      </div>
      </div>
  </div>
  
  <!-- button right-align -->
   <div class="d-flex mt-5" *ngIf="!showchat" style="justify-content: space-around;">
    <button style="background-color:#00aecf" class="btn btn-primary" (click)="showchat=true">Get Started

      <!-- right arrow -->
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
      </svg>
    </button>

    </div>
  
  
  
  
    <!-- related questions -->
    
    <div class="row mt-5  px-md-5" *ngIf="!showchat" >
      <div  class="col-md-12" style="text-align: justify; padding-top: 10px">
        <div class="heading">
  
          <svg class="fontawesomesvg" xmlns="http://www.w3.org/2000/svg" height="1em"
            style="vertical-align: baseline;" viewBox="0 0 576 512">
            <path
              d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z" />
          </svg>
          &nbsp;<h4 style="display: contents;">Recommended Prompts</h4>
          <!-- <div class="answer w-100 mt-3" *ngFor="let answer of researchData"> -->
          <div class="answer w-100 mt-3" *ngFor="let question of relatedQuestionList"
            (click)="prompt=question;getResponse(); ">
  
            <div class="col-sm-12">
              <div class="related-question">
                <div> {{ question }}</div>
                <button class="btn btn-sm border-0 pl-3">
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  

<div class="container py-3" *ngIf="showchat" >
  <button class="btn btn-primary" style="background-color: #00aecf;color:#ffffff;margin-left: 20px;" (click)="showchat=false">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
    </svg>
    Back</button>
    <div class="container1 d-flex">
      <!-- Left side: Text area with generate button -->
      <div class="left-side flex-grow-1">
        <textarea style="max-height:700px;" [(ngModel)]="prompt" class="form-control" rows="10" placeholder="Enter your prompt here"></textarea>
        <button (click)="getResponse()" class="btn btn-primary mt-2">Generate</button>
        <div class="item" >
          <label class="checkbox-label">
            <input type="checkbox" id="uploadCheckbox" [(ngModel)]="isImageExits"
              required [ngModelOptions]="{standalone: true}">
    
            Are you generating  a image
          </label>
          <!-- <div class="message" id="message"></div> -->
        </div>

      </div>
    
      <!-- Right side: Disabled text area with markdown parsing -->
      <div class="right-side flex-grow-1 position-relative">
        <div  class="form-control" rows="10"  style="height:500px;overflow: auto;padding-top:60px;" lineNumbers clipboard markdown
        [data]="output" [disableSanitizer]="true">
    </div>
    
        <!-- Buttons: like, dislike, copy -->
        <div class="btn-group position-absolute top-0 end-0 mt-2 me-2">
             <button *ngIf="isImageExits" class="btn btn-secondary" title="Generate Image" (click)="generateimage()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-image-fill" viewBox="0 0 16 16">
                <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"/>
              </svg>
            </button>

          <button class="btn btn-success" [disabled]="isliked" (click)="likeresponse()" title="Like">
            <i class="fa fa-thumbs-up"></i>
          </button>
          <button class="btn btn-danger" [disabled]="isdisliked" (click)="dislikeresponse()"  title="Dislike">
            <i class="fa fa-thumbs-down"></i>
          </button>
          <button class="btn btn-secondary" title="Copy" (click)="copyToClipboard(output)">
            <i class="fa fa-copy"></i>
          </button>
        </div>
      </div>

    

    </div>
   
    
  </div>

  <!-- Button trigger modal -->
<button style="display:none;" id="imgdbtn" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div style="display:none;" class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Image Viewer</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <img style="max-width:100%;" [src]="generatedAvatarUrl" alt="selectedImage" >
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>
