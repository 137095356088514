import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { SharedService } from './shared.service';
import { FileModel } from '../data/app-model';
import { CodeDocumentation } from '../data/code-docs-model';
import { Observable } from 'rxjs';
import { ApiEndpoints } from '../data/global-vars';

@Injectable({
  providedIn: 'root'
})
export class CodeDocumentationService {

  constructor(private http: HttpClient,
    private baseHttp: BaseHttpService,
    private sharedService: SharedService) { }

  uploadFile(data: CodeDocumentation, files: FileModel[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', files[0].file_data, files[0].file_data.name);
    //let formData: FormData = this.sharedService.addDataToForm(null, files);
    return this.baseHttp.post<any>(ApiEndpoints.UploadFile, formData);
  }

  enhanceCode(data: string): Observable<any> {
    //let formData: FormData = this.sharedService.addDataToForm(data, null);
    return this.baseHttp.post<any>(ApiEndpoints.GetDocumentation, data, true, true);
  }

  downloadDocument(): Observable<any> {
    //let formData: FormData = this.sharedService.addDataToForm(data, null);
    return this.baseHttp.get<any>(ApiEndpoints.DownloadCodeDocumentation);
  }
}
