import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import bootstrap from 'bootstrap';

@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  @Input() carouselItems: { image: string; title: string; description: string }[] = []; activeIndex = 0;



  ngOnInit(): void {  
    setTimeout(() => {  
      const carbtn = document.getElementById('carbtn');  
      if (carbtn) {  
        carbtn.click();  
      }  
    }, 0);  
  } 

  // ngAfterViewInit(): void {  
  //   const carouselElement = document.getElementById('dynamicCarousel');  
  //   if (carouselElement) {  
  //     const carousel = new bootstrap.Carousel(carouselElement, {  
  //       interval: 3000,  
  //       ride: 'carousel',  
  //     });  
  //   }  
  // }  

  // Set active slide when indicator is clicked
  setActiveSlide(index: number) {
    this.activeIndex = index;
  }

  // Show previous slide
  prevSlide() {
    this.activeIndex = (this.activeIndex - 1 + this.carouselItems.length) % this.carouselItems.length;
  }

  // Show next slide
  nextSlide() {
    this.activeIndex = (this.activeIndex + 1) % this.carouselItems.length;
  }
}