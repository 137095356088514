import { Component } from '@angular/core';

@Component({
    selector: 'app-multiple-upload',
    templateUrl: './multiple-upload.component.html',
    styleUrls: ['./multiple-upload.component.scss'],
    standalone: false
})
export class MultipleUploadComponent {

}
