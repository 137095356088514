<app-header></app-header>

<!-- Main navigation links -->
 <div class="row px-5">
  <div class="col-12 mx-5">
    
 
<div class="navigation">
  <!-- "Build Your Project" link with Angular click event -->

  <nav mat-tab-nav-bar>
    <a class="tab" mat-tab-link class="flex-item"  [class.active-link]="isBuildProjectActive()" (click)="toggleBuildProject(); $event.preventDefault()"><img src="../../assets/CGL/images/Group 2.png"> Build Your Project</a>
    <div class="divider-vertical"></div>
    <!-- Other main navigation links -->
  
    <a class="tab" mat-tab-link [ngClass]="{ 'tab-active': isTabActive('/codeGenYLiteAI/documentcode') }" routerLink="/codeGenYLiteAI/documentcode"  routerLinkActive="active-link"><img src="../../assets/CGL/images/Code enhancent.svg"> Code Documentation</a>
    <div class="divider-vertical"></div>
    <a class="tab" mat-tab-link [ngClass]="{ 'tab-active': isTabActive('/codeGenYLiteAI/enhancecode') }" routerLink="/codeGenYLiteAI/enhancecode" (click)="showBuildProject=false" routerLinkActive="active-link"><img src="../../assets/CGL/images/Group 15.svg"> Code Enhancement</a>
    <div class="divider-vertical"></div>
    <a class="tab" mat-tab-link [ngClass]="{ 'tab-active': isTabActive('/codeGenYLiteAI/chatcode') }" routerLink="/codeGenYLiteAI/chatcode" routerLinkActive="active-link">Code Assistant</a>
    <div class="divider-vertical"></div>
    <a class="tab" mat-tab-link [ngClass]="{ 'tab-active': isTabActive('/codeGenYLiteAI/codeadjust') }" routerLink="/codeGenYLiteAI/codeadjust" routerLinkActive="active-link">Code Adjust</a>
    <!-- <div class="divider-vertical"></div>
    <a routerLink="/codeGenYLiteAI/tcgenerate" routerLinkActive="active-link">Test Case Generation</a> -->
  </nav>
</div>
</div>
</div>

<!-- Sub-tabs for "Build Your Project" -->
<div class="row px-5">
  <div class="col-12 mx-5">
<div class="navigation1 mx-5" *ngIf="showBuildProject" >
  <nav mat-tab-nav-bar style="gap:20px">
    <a mat-tab-link routerLinkActive="active-link" [ngClass]="{ 'active': isTabActive('/codeGenYLiteAI/brd') }"  routerLink="/codeGenYLiteAI/brd">BRD/RA</a>
    <a mat-tab-link  routerLinkActive="active-link" [ngClass]="{ 'active': isTabActive('/codeGenYLiteAI/userstory') }"  routerLink="/codeGenYLiteAI/userstory">User Story</a>
    <a mat-tab-link routerLinkActive="active-link" [ngClass]="{ 'active': isTabActive('/codeGenYLiteAI/sdd') }"  routerLink="/codeGenYLiteAI/sdd">SDD</a>
    <a mat-tab-link routerLinkActive="active-link" [ngClass]="{ 'active': isTabActive('/codeGenYLiteAI/apigenerate') }"  routerLink="/codeGenYLiteAI/apigenerate">API Interface</a>
    <a mat-tab-link routerLinkActive="active-link" [ngClass]="{ 'active': isTabActive('/codeGenYLiteAI/codegenerate') }" routerLink="/codeGenYLiteAI/codegenerate">Code Generation</a>
    <a mat-tab-link routerLinkActive="active-link" [ngClass]="{ 'active': isTabActive('/codeGenYLiteAI/tcgenerate') }"  routerLink="/codeGenYLiteAI/tcgenerate">Testcase Generation</a>
  </nav>
</div>
</div>
</div>
<router-outlet></router-outlet>
<!-- <app-footer style="z-index: 1; bottom:0;" *ngIf="title"></app-footer> -->
<app-footer></app-footer>
