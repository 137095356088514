import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-disclaimer',
    templateUrl: './disclaimer.component.html',
    styleUrls: ['./disclaimer.component.scss'],
    standalone: false
})
export class DisclaimerComponent {

  @Input() isPageVisited: boolean = false;
  @Output() isPageVisitedChange = new EventEmitter<boolean>();

  isCheckboxChecked: boolean = false;
  isAcceptButtonActivated: boolean = false;

  constructor(public dialogRef: MatDialogRef<DisclaimerComponent>) { }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onAcceptClick() {
    this.onCloseClick();
    this.isPageVisited = false;
    this.isPageVisitedChange.emit(this.isPageVisited);
  }

  onCheckboxChange() {
    this.isAcceptButtonActivated = this.isCheckboxChecked;
  }
}
