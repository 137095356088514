<header class="header">
    <div class="header-container">
      <!-- Left Section -->
      <div class="header-left">
        <img src="assets/CGL/images/whitelogo.png" alt="Genpact Logo" class="logo" />
        <span class="title">
          Cora<span class="highlight">Code</span>
          <span class="highlight1">GenY</span>
        </span>
      </div>
  
      <!-- Right Section -->
      <div class="header-right">
        <!-- Links Section -->
        <nav class="nav-links">
            <a class="nav-link" href="https://forms.office.com/Pages/ResponsePage.aspx?id=IIrvvayqgE-zoNmjL5n9M90e9cRW2ZJNt3MEN5UWXmJUNDBYN1lPTzI4R1VYNUQ4RDVVMk9UMFBMNS4u" target="_blank">  
                Feedback  
              </a>
              <a  class="nav-link" href="https://genpactonline.sharepoint.com/:f:/s/IT/En9OkLY6kuFOowWGJj-pGnoBh_9YDVA55_NWG_recIAuag" target="_blank">  
                Templates  
              </a>  
              <a class="nav-link" href="https://genpactonline.sharepoint.com/:b:/s/IT/EWLunTNTtGJFqVlhXdOsUK8BpEEdrK4sz_iyLuY-4GGoVA?e=YudkNE" target="_blank">  
                SOP  
              </a> 
        </nav>
  
        <!-- Profile Section -->
        <div class="profile-section">
          <div class="profile-dropdown">
            <span class="profile-initials">{{ username }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
          </div>
          <button class="logout-button"  (click)="exitApp()">
            Logout <i class="fas fa-sign-out-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </header>
  