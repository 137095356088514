<div class="">
  <div class="item">
      <form data-validation="true"   enctype="multipart/form-data">
          <div class="item-inner">
              <div class="item-content p-3 p-md-5">
                  <div class="image-upload">
                    <label class="label1" style="cursor: pointer;" for="file_upload">

                      <img src="" alt="" class="uploaded-image">

                      <div class="h-100">
                             <div class="dplay-tbl">
                              <div class="dplay-tbl-cell">

                                  <h5><b>{{header}}</b></h5>
                                  <p>{{placeholder}} </p>

                              </div>
                          </div>
                      </div>
                      <input [accept]="inputAccept" multiple data-required="image"
                       type="file" name="image_name" id="file_upload" class="image-input"
                       data-traget-resolution="image_resolution"
                       value="" (change)="uploads($event)" (drag)="uploads($event)">
                       </label>
                  </div>
                  <br>

                  <div class="item" >
                    <label class="checkbox-label">
                      <input type="checkbox" id="uploadCheckbox" [(ngModel)]="isImageExits"
                        required [ngModelOptions]="{standalone: true}">

                      Are you uploading document containing image?
                    </label>
                    <!-- <div class="message" id="message"></div> -->
                  </div>
              </div>
          </div>

      </form>

  </div>

</div>

<div *ngIf="currentFile" class="progress my-3">
  <div
    class="progress-bar progress-bar-info"
    role="progressbar"
    attr.aria-valuenow="{{ progress }}"
    aria-valuemin="0"
    aria-valuemax="100"
    [ngStyle]="{ width: progress + '%' }"
  >
    {{ progress }}%
  </div>
</div>
