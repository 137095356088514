import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  private apiUrl = 'YOUR_BACKEND_API_URL'; // Replace with your backend URL

  constructor(private http: HttpClient) {}

  getHistoryData(): Observable<any> {
    return this.http.get(this.apiUrl).pipe(
      catchError(error => {
        console.error('API error, using dummy data:', error);
        return of(this.getDummyData());
      })
    );
  }

  private getDummyData() {
    return [
      {
        title: 'Invention Of The Lightbulb',
        date: 'Dec 7, 2023',
        sourceCount: 4
      },
      {
        title: 'Mugifier Documents',
        date: 'Nov 28, 2023',
        sourceCount: 6
      },
      {
        title: 'Westward Mushrooms',
        date: 'Nov 22, 2023',
        sourceCount: 7
      },
      {
        title: 'Untitled notebook',
        date: 'Sep 21, 2024',
        sourceCount: 0
      }
    ];
  }
}
