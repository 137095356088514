import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  // Observable to track the modal state
  private modalState = new Subject<boolean>();
  modalState$ = this.modalState.asObservable();

  // Image source for the modal
  private imageUrl: string = '';
  imageUrl$ = new Subject<string>();

  // Open the modal
  openModal(imageUrl: string) {
    this.imageUrl = imageUrl;
    this.modalState.next(true);
  }

  // Close the modal
  closeModal() {
    this.modalState.next(false);
  }

  // Get the current image URL
  getCurrentImageUrl(): string {
    return this.imageUrl;
  }
}
