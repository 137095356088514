<div class="popup">
    <div class="popup-head" (click)="onCloseClick()">
            <i class="fa-solid fa-xmark"></i>
    </div>
    <div class="popup-content">
        <div class="popup-icon">
           <img [src]=data.popupIcon alt="">
        </div>
        <div class="popup-text">
            <p>{{ data.popupHeader }}</p>
            <p>{{ data.popupContent }}</p>
        </div>
    </div>
    <div class="popup-btn">
        <!-- <button class="btn btn-custom btn-first" (click)="onCloseClick()">{{ data.firstBtnName }}</button> -->
        <button class="btn btn-custom btn-second" (click)="onOpenClick()">{{ data.secondBtnName }}</button>
    </div>
</div>
