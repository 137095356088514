import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LoaderService } from '../shared/service/loader.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { TranslateService } from '../shared/service/translate.service';
import { UserApiLimitService } from '../shared/service/user-api-limit.service';
import * as Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Clipboard } from '@angular/cdk/clipboard';
import { SystranService } from 'src/app/shared/service/systran.service';

@Component({
  selector: 'app-translate-file',
  templateUrl: './translate-file.component.html',
  styleUrls: ['./translate-file.component.scss']
})
export class TranslateFileComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  @ViewChild('imageinput', { static: false }) imageInput!: ElementRef;
  downloadStatus = false;
  languageArray: any = [];

  languageArray1: any = [];
  processStatus: boolean = false;

  convertTo: any = [{ value:'en',name:'English'}];
  convertFrom: any = [{ value:'en',name:'English'}];
  targetSASUrl: any;
  fileUploadedStatus = false;
  fileData: any;
  userOHR: any;
  glossaryStatus = false;
  ImageStatus = false;
  Images:File[]=[];
  glossaryFileData: any;
  glossaryFileUploadedStatus = false;
  showUpload: boolean = true;
  tableData: any = [{ currentText: '', translatedText: '' }];

  modalRef?: BsModalRef;
  dropdownSettings:IDropdownSettings = {
    singleSelection: true,
    idField: 'value',
    textField: 'name',
    itemsShowLimit: 3,
    maxHeight:160,
    allowSearchFilter: true
  };

  intervalId: any; // Variable to store the interval ID
  endPointDetail: any;
  currentText: any;
  translatedText: any;
  showHeading: boolean = true;
  isGoogleSelected: boolean = false;
  detectedLanguage: any;
  fileName: any;
  mimeType: any;
  file: File | any = null;
  glossaryTable : any;
  fileContent: any;
  fileExtension: any;
  downloadbtn: boolean = false;
notification: any;
chkagree: boolean = false;

istext: boolean = false;
textboxValue: string = 'This is some text to copy';
copied: boolean = false;
textinput: string = '';
textoutput: string = '';


  constructor(
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private toastMessage:ToastMessageService,
    private modalService: BsModalService,
    private clipboard: Clipboard,
    private systranService:SystranService,
    private userApiLimitService: UserApiLimitService
  ) {
    this.getTranslateLanguage();
    this.getUserLimt();
  }

  ngOnInit() {
    //this.startInterval();
    this.onLoading();
    // scroll bottom of the page
    window.scrollTo(0, document.body.scrollHeight);
 this.resetFile();



  }
  onLoading() {
    const modalbtn = document.getElementById('byod_policy_model') as HTMLLIElement;
    modalbtn.click();
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md',ignoreBackdropClick: true });
  }
  addRow() {
    this.tableData.push({ currentText: '', translatedText: '' });
  }

  removeRow() {
    this.tableData.splice(this.tableData?.length - 1, 1);
  }


  //get max count and count left of api
  getUserLimt() {
    this.userApiLimitService.getUserApiCount("translate").subscribe((res: any) => {
      if (res?.response?.status == 'success') {
        this.endPointDetail = res?.response?.data ? res?.response?.data[0] : null;
      }
    })
  }

  // Get all translation langularge
  getTranslateLanguage() {
    this.translateService.translateLanguage().subscribe((res: any) => {
      if (res?.translation) {
        const transformedResponse = Object.keys(res?.translation).map(key => ({
          value: key,
          name: res?.translation[key].name
        }));
        this.languageArray = transformedResponse || [];
        this.languageArray1 = (transformedResponse || []).slice();
        //this.languageArray1 = [...(transformedResponse || [])]; // Create a shallow copy

        //add Auto to languageArray1 only
        console.log('this.languageArray:',this.languageArray);
console.log('this.languageArray1:',this.languageArray1);

        // this.languageArray1.unshift({ value: '', name: 'Auto' });
      }
    })
  }
  //
  toggleFileInputType(): void {
    this.ImageStatus = !this.ImageStatus;
    this.fileUploadAcceptValue();
    this.fileUploadMultipleValue();
  }
  fileUploadAcceptValue(): string {
    return this.ImageStatus ? '.png,.jpeg,.jpg,.PNG,.tif,.tiff' : '.txt,.xlsx,.xls,.pdf,.PDF,.pptx,.PPTX,.ppt,.pptx,.doc,.DOC,.docx,.DOCX';
  }

  fileUploadMultipleValue(): boolean {
    return this.ImageStatus;
  }
//onupload
onFileUpload(event:any){
  if( (this.convertFrom[0].value == this.convertTo[0].value)){
    this.toastMessage.showError('Source and target language cannot be same');
    this.fileInput.nativeElement.value = null;
    this.imageInput.nativeElement.value = null;
    return;
  }

  console.log('event:',event);
  console.log('image status:',this.ImageStatus);
  //if file size greater than 16mb or file type is not of input accept type
  if(event.target.files[0].size>16*1024*1024){
   // alert('Maximum size is 16 mb');
    this.notification = 'Maximum size is 16 mb';
    document.getElementById('notibtn')?.click();
    this.fileInput.nativeElement.value = null;
    this.imageInput.nativeElement.value = null;
    return;
  }
  else if(!event.target.files[0].name.match(/.(txt|pdf|doc|docx|xls|xlsx|ppt|pptx|png|jpeg|jpg|PNG|tif|tiff)$/i)){
    //alert('Please upload file of type .txt, .doc, .pdf, .xls,.png ,.jpeg,.jpg,.PNG,.tif,.tiff and .ppt');

    this.notification = 'Please upload file of type .txt, .doc, .pdf, .xls,.png ,.jpeg,.jpg,.PNG,.tif,.tiff and .ppt';
    document.getElementById('notibtn')?.click();
    this.fileInput.nativeElement.value = null;
    this.imageInput.nativeElement.value = null;
    return;
  }
  else if(!this.ImageStatus && event.target.files[0].name.match(/.(png|jpeg|jpg|PNG|tif|tiff)$/i)){
    // alert('Please check the box before uploading image file');
    this.notification = 'Please upload non image file';
    this.fileInput.nativeElement.value = null;
    this.imageInput.nativeElement.value = null;
    document.getElementById('notibtn')?.click();
    return;
  }
  else if(this.ImageStatus && event.target.files[0].name.match(/.(txt|pdf|doc|docx|xls|xlsx|ppt|pptx)$/i)){
    // alert('Please uncheck the box before uploading non image file');
    this.notification = 'Please  upload  image file';
    document.getElementById('notibtn')?.click();
    this.fileInput.nativeElement.value = null;
    this.imageInput.nativeElement.value = null;
    return;
  }

  //else if non image files lenght>1
  else if(!event.target.files[0].name.match(/.(png|jpeg|jpg|PNG|tif|tiff)$/i) && event.target.files.length>1){
    // alert('Please upload only one file');
    this.notification = 'Please upload only one file if it is not an image file';
    this.fileInput.nativeElement.value = null;
    this.imageInput.nativeElement.value = null;
    document.getElementById('notibtn')?.click();
    return;
  }

   else{
   this.Images = Array.from(event.target.files);
   this.file = event.target.files[0];
   this.fileName = event.target.files[0].name;
    console.log('this.Images:',this.Images);
    this.uploaddecide();
   }
}

uploaddecide(){
if(this.isGoogleSelected && this.ImageStatus){
  this.googleimageUpload(); //google image upload
}

else if(this.isGoogleSelected && !this.ImageStatus){
  this.postGoogleTranslateUpload();
}
else{
  this.postAzureTranslateUpload();
}
}
  // Upload file for translation
  postAzureTranslateUpload() {
    this.loaderService.loading.next(true);


    this.getOHR();


    const formData = new FormData();

    //formData.append('filename', this.fileData?.name);

 //if file is an extension of image
    if(this.ImageStatus)
    {
      for(const file of this.Images){
        formData.append('user_id', this.userOHR);
        formData.append('glossary_status', this.glossaryStatus.toString());
        formData.append('images', file);
      }
      this.loaderService.loading.next(true);
      this.translateService.translateimageUpload(formData)
      .subscribe(
        (res: any) => {
          this.loaderService.loading.next(false);
          this.getUserLimt();

         if( res?.status == 'Success'){
          this.fileUploadedStatus = true;
          this.showUpload = false;
          this.toastMessage.showSuccess("your File(s) uploaded successfully.");
            //if glossary status is true then upload glossary file
            if(this.glossaryStatus){
              this.glossaryFileAzureUpload();

            }


         }else{
          this.toastMessage.showError(res?.message);
         }

        },
        (error) => {
          // Handle the error here
          this.loaderService.loading.next(false);
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );

    }

else{
  for(const file of this.Images){
  formData.append('user_id', this.userOHR);
  formData.append('glossary_status', this.glossaryStatus.toString());
  formData.append('file', file);
  }
    this.translateService.translateUpload(formData)
      .subscribe(
        (res: any) => {
          this.loaderService.loading.next(false);
          this.getUserLimt();

         if( res?.status == 'Success'){
          this.fileUploadedStatus = true;
          this.showUpload = false;
          this.toastMessage.showSuccess(res?.message);
            //if glossary status is true then upload glossary file
            if(this.glossaryStatus){
              this.glossaryFileAzureUpload()

            }


         }else{
          this.toastMessage.showError(res?.message);
         }

        },
        (error) => {
          // Handle the error here
          this.loaderService.loading.next(false);
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );}
  }
  addGlossaryEntry() {
    this.tableData.push({ currentText: '', translatedText: '' });
  }
  removeGlossaryEntry(index: number) {
    this.tableData.splice(index, 1);
  }


// upload glossary file
glossaryFileAzureUpload() {
  this.loaderService.loading.next(true);

  // Convert glossary data to CSV
  const csvData = Papa.unparse(this.tableData);

  const blob = new Blob([csvData], { type: 'text/csv' });
  const formData = new FormData();
  formData.append('file', blob, 'glossary.csv');
  formData.append('user_id', this.userOHR);
  formData.append('glossary_status', this.glossaryStatus.toString());

  this.translateService.translateUploadGlossary(formData).subscribe(
    (res: any) => {
      this.loaderService.loading.next(false);

      if (res?.status == 'Success') {
        this.glossaryFileUploadedStatus = true;
        this.toastMessage.showSuccess(res?.message);
      } else {
        this.toastMessage.showError("Error uploading the glossary file. Please try again.");
      }
    },
    (error) => {
      this.loaderService.loading.next(false);
      console.error(error);
      this.toastMessage.showError(error?.message);
    }
  );
}


  // translate file  after upload
  translateAzureContent() {
if(this.glossaryStatus){
  this.glossaryFileAzureUpload()
}

    this.loaderService.loading.next(true);
    this.getOHR();

    // Adding the data to the tableData array
    // this.tableData.push({ currentText: this.currentText, translatedText: this.translatedText });

    // const json = JSON.stringify(this.tableData);

    const formData = new FormData();
    if(this.ImageStatus)
    {
      formData.append('filename', this.userOHR + '.' + 'pdf');
    }
    else{
      for(const file of this.Images){
      formData.append('filename', file.name);
      }
    }

    formData.append('glossary_filename', 'glossary.csv');
    formData.append('user_id', this.userOHR);
    formData.append('source_lang', this.convertFrom[0]?.value);
    formData.append('target_lang', this.convertTo[0]?.value);
    formData.append('glossary_status', this.glossaryStatus.toString());

    //formData.append('file', new Blob([json], { type: 'application/json' }));



    this.translateService.translateDownload(formData)
      .subscribe(
        (res: any) => {
          this.loaderService.loading.next(false);

          if( res?.status == 'Success' && res?.data?.target_blob_url){
            this.targetSASUrl = res?.data?.target_blob_url || '';
            // this.downloadStatus = true;
            this.toastMessage.showSuccess(res?.message);

            //set to localStorage
            let translateData = res?.data;
            translateData['file_name'] = this.fileData?.name;
            localStorage.setItem('f_tarnslate',JSON.stringify(translateData));

            let translateglossaryData = res?.data;
            translateglossaryData['glossary_file_name'] = this.glossaryFileData?.name;
            localStorage.setItem('glossary_tarnslate',JSON.stringify(translateglossaryData));

            this.startInterval();

          }else{
            this.toastMessage.showError(res?.message);
          }

        },
        (error) => {
          // Handle the error here
          this.loaderService.loading.next(false);
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );
  }



  // check translation status

  getTranslateAzureStatus() {
    let translateData = null;
    this.processStatus = true;
    if(localStorage.getItem('f_tarnslate')){
    this.loaderService.loading.next(true);

     translateData = JSON.parse( localStorage.getItem('f_tarnslate') || '');

     this.fileData = {name:translateData?.file_name};
     this.targetSASUrl = translateData?.target_blob_url || '';


    const formData = new FormData();
    formData.append('translation_id', translateData?.translation_id);


    this.translateService.translateStatus(formData)
      .subscribe(
        (res: any) => {
          if( res?.status == 'Succeeded'){

            this.stopInterval();
            this.loaderService.loading.next(false);
            this.downloadStatus = true;
            this.toastMessage.showSuccess(res?.message);
          }else{
            if(res?.status == 'NotStarted' || res?.status == 'Running'){
              this.toastMessage.showWarning(res?.message);
              // do nothing
            }else{
              this.stopInterval();
              this.resetFile();
              this.loaderService.loading.next(false);
              this.toastMessage.showError(res?.message);
            }
          }

        },
        (error) => {
          // Handle the error here
          this.stopInterval();

          this.loaderService.loading.next(false);
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );


    }
  }


  // Download translated file
// download(){
// const payload = {
//   'url':this.targetSASUrl,
// }
// this.translateService.translatefileDownload(payload).subscribe(
//   (response: Blob) => {

//     const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
//     const url = window.URL.createObjectURL(blob);
//     const a = document.createElement('a');
//     a.href = url;
//     // Assuming process_name is a variable containing the process name
//     const timestamp = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
//     const fileNameParts = this.fileData?.name.split('_');
//     const processName = fileNameParts && fileNameParts.length >= 3 ? fileNameParts[1] : ''; // Assuming process name is the second part

//     const filename = `SDD_${processName}_${timestamp}.docx`;

//     // Set the filename for the download
//     a.download = filename;
//     document.body.appendChild(a);
//     a.click();
//     this.toastMessage.showSuccess('File Downloaded Successfully');
//     // Cleanup
//     window.URL.revokeObjectURL(url);
//     document.body.removeChild(a);

//   }
// );

// }
// downloadAzureFile(){
//   const payload = {
//     'url': this.targetSASUrl,
//   }
//   this.translateService.translatefileDownload(payload).subscribe(
//     (response: Blob) => {
//       // Extract filename from URL
//       const urlParts = this.targetSASUrl.split('?')[0].split('/');
//       const filename = urlParts[urlParts.length - 1];

//       // Create a blob object with the response data
//       const blob = new Blob([response]);

//       // Create a URL for the blob
//       const url = window.URL.createObjectURL(blob);

//       // Create a link element
//       const a = document.createElement('a');
//       a.href = url;

//       // Set the filename for the download
//       a.download = filename;

//       // Append link to the body and trigger click
//       document.body.appendChild(a);
//       a.click();

//       // Show success message
//       this.toastMessage.showSuccess('File Downloaded Successfully');

//       // Cleanup
//       window.URL.revokeObjectURL(url);
//       document.body.removeChild(a);
//       this.resetFile();
//       this.resetglossaryFile();
//     },
//     (error:any) => {
//       // Handle the error here
//       console.error(error);
//       this.toastMessage.showError(error?.message);

//     }

// //   );
// // }
// downloadAzureFile(){
//   this.loaderService.loading.next(true);
//   const payload = {
//     'url': this.targetSASUrl,
//   }
//   this.translateService.translatefileDownload(payload).subscribe(
//     (response: Blob) => {
//       // Extract filename from URL
//       const urlParts = this.targetSASUrl.split('?')[0].split('/');
//       const filename = urlParts[urlParts.length - 1];

//       // Determine file extension based on MIME type
//       let extension = '';
//       const contentType = response.type;
//       switch(contentType) {
//         case 'text/plain':
//           extension = 'txt';
//           break;
//         case 'application/msword':
//           extension = 'doc';
//           break;
//         case 'application/pdf':
//           extension = 'pdf';
//           break;
//         case 'application/vnd.ms-excel':
//           extension = 'xls';
//           break;
//         case 'image/png':
//           extension = 'png';
//           break;
//         case 'image/jpeg':
//           extension = 'jpg';
//           break;
//         case 'image/tiff':
//           extension = 'tiff';
//           break;
//         case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
//           extension = 'ppt';
//           break;
//         default:
//           console.error('Unsupported file type');
//           this.toastMessage.showError('Unsupported file type');
//           return;
//       }

//       // Create a blob object with the response data
//       const blob = new Blob([response], { type: contentType });

//       // Create a URL for the blob
//       const url = window.URL.createObjectURL(blob);

//       // Create a link element
//       const a = document.createElement('a');
//       a.href = url;

//       // Set the filename for the download with the appropriate extension
//       a.download = `${filename}.${extension}`;

//       // Append link to the body and trigger click
//       document.body.appendChild(a);
//       a.click();

//       // Show success message
//       this.toastMessage.showSuccess('File Downloaded Successfully');

//       // Cleanup
//       this.resetFile();
//      this.resetglossaryFile();
//      this.loaderService.loading.next(false);
//       window.URL.revokeObjectURL(url);
//       document.body.removeChild(a);
//     },
//     (error) => {
//       this.loaderService.loading.next(false);
//       console.error('Error downloading file:', error);
//       this.toastMessage.showError('Error downloading file');
//     }
//   );
// }

downloadAzureFile() {
  this.loaderService.loading.next(true);
   // Determine the file extension based on the content type
   const urlParts = this.targetSASUrl.substring(this.targetSASUrl.indexOf('/', 1) + 1, this.targetSASUrl.lastIndexOf('.'));
   //       const filename = urlParts[urlParts.length - 1];

        //  let filename = urlParts;

         const start = this.targetSASUrl.indexOf("playground-translate-out/") + "playground-translate-out/".length;
         const end = this.targetSASUrl.indexOf("?", start);
         const extractedPartWithExtension = this.targetSASUrl.substring(start, end);

         // Extract only the name part without the extension
         let filename = extractedPartWithExtension.split("/").pop().split(".")[0];


const fname = this.ImageStatus ? this.userOHR+this.userOHR + '.' + 'pdf' : this.userOHR+ this.fileName;

  const payload = {
    'url': this.targetSASUrl,
    'filename': fname,
  };

  // if(this.ImageStatus)
  // {

  //   payload.filename= this.userOHR + '.' + 'pdf'
  // }



  this.translateService.translatefileDownload(payload, { responseType: 'blob' }).subscribe(
    (response: Blob) => {
      const contentType = response.type || 'application/octet-stream';


      if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        filename += '.xlsx';
      } else if (contentType.includes('application/pdf')) {
        filename += '.pdf';
      } else if (contentType.includes('application/msword')) {
        filename += '.doc';
      } else if (contentType.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
        filename += '.docx';
      } else if (contentType.includes('text/plain')) {
        filename += '.txt';
      } else if (contentType.includes('image/png')) {
        filename += '.png';
      } // Add more conditions for other file types

      this.toastMessage.showmessage('Downloading file...');

      // Trigger file download
      saveAs(response, filename);

      // Hide loader and show success message
      this.loaderService.loading.next(false);
      this.toastMessage.showSuccess('File downloaded successfully');
      this.resetFile();
      this.fileInput.nativeElement.value = null;

      this.resetglossaryFile();
//
setTimeout(() => {
  this.translatedelete(filename);

}, 2000);
    },
    (error: any) => {
      this.loaderService.loading.next(false);
      this.resetFile();
      this.resetglossaryFile();
      console.error('Error downloading file:', error);
      this.toastMessage.showError('Error downloading file');
    }
  );
}
translatedelete(filename: any) {
  this.getOHR();
  this.loaderService.loading.next(true);
  // const payload = new FormData();
  // payload.append('filename', this.userOHR+filename);
  const fname = this.ImageStatus ? this.userOHR + '.' + 'pdf' :this.fileName;

  this.translateService.translatedelete( fname,this.userOHR).subscribe(
    (response: any) => {
      this.loaderService.loading.next(false);
    //  this.toastMessage.showSuccess('File Deleted Successfully');
      console.log(response?.response);    },
    (error: any) => {
      this.loaderService.loading.next(false);
      console.error('Error deleting file:', error);
     // this.toastMessage.showError('Error deleting file');
    }
  );
}
  // downloadAzureFile() {
  //   window.location.href = this.targetSASUrl;

  //   this.loaderService.loading.next(true);

  //   setTimeout(() => {
  //     this.loaderService.loading.next(false);
  //     this.toastMessage.showSuccess('Downloading File.');

  //     // remove file data from localStorage
  //     // localStorage.removeItem('f_tarnslate');

  //     this.resetFile();
  //     this.resetglossaryFile();

  //   }, 2000);
  // }

  // reset upload file

  resetFile() {
    this.tableData = [{ currentText: '', translatedText: '' }];
    this.fileUploadedStatus = false;
    this.fileData = '';
    this.showUpload = true;
    this.downloadStatus = false;
    this.downloadbtn = false;
    this.targetSASUrl = '';
    this.convertTo = [{ value:'en',name:'English'}];
    localStorage.removeItem('f_tarnslate');
    this.fileInput.nativeElement.value = null;
    this.imageInput.nativeElement.value = null;
    this.Images = [];
    this.file = null;


  }

  resetglossaryFile() {
    this.glossaryFileUploadedStatus = false;
    this.glossaryFileData = '';
    this.glossaryStatus = false;
    // this.showUpload = true;
    this.downloadStatus = false;
    this.targetSASUrl = '';
    localStorage.removeItem('glossary_tarnslate');
  }


  // get user OHR id
  getOHR() {
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      if (ohr_id) {
        this.userOHR = ohr_id;
      }
    }
  }

  // checking status of file per INterver till successed

  startInterval(): void {
    if(localStorage.getItem('f_tarnslate')){
    this.getTranslateAzureStatus();

    this.intervalId = setInterval(() => {
      // Your function to be called every 15 seconds
      console.log('interval call')
      this.getTranslateAzureStatus();

    }, 15000); // 15 seconds in milliseconds

  }
}

  stopInterval() {
    this.processStatus = false;
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  resetForm(event: any) {
    localStorage.removeItem('f_tarnslate');
//refresh page
   // window.location.reload();
   this.resetFile();
    this.stopInterval();
    this.loaderService.loading.next(false);
  }

  azureSelected() {
    this.isGoogleSelected = false;;
  }
  googleSelected() {
    this.isGoogleSelected = true;
  }

  textselected(){
    this.istext = true;

  }
  filesSelected(){
    this.istext = false;

  }

  copyText(output:any) {
    this.clipboard.copy(output);
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 3000); // 3 seconds
  }




  onGoogleUpload(event:any){
    // this.pdfFile =
    this.fileName = event.target.files[0].name;
    //if file size greater than 16mb or file type is not of input accept type
    if(event.target.files[0].size>16*1024*1024){
      alert('Maximum size is 16 mb');
      return;
    }
    else if(!event.target.files[0].name.match(/.(txt|pdf|doc|docx|xls|xlsx|ppt|pptx|png|jpeg|jpg|PNG|tif|tiff)$/i)){
      alert('Please upload file of type .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .png, .jpg, .jpeg, .PNG, .tif, .tiff');
      return;
    }
    else if(!this.ImageStatus && event.target.files[0].name.match(/.(png|jpeg|jpg|PNG|tif|tiff)$/i)){
      alert('Please upload image file');
      return;
    }
    else if(this.ImageStatus && event.target.files[0].name.match(/.(txt|pdf|doc|docx|xls|xlsx|ppt|pptx)$/i)){
      alert('Please upload non image file');
      return;
    }

    //else if non image files lenght>1

     else{
     this.file = event.target.files[0];
      this.postGoogleTranslateUpload();
     }
  }
    // Upload file for translation
    postGoogleTranslateUpload() {
      this.loaderService.loading.next(true);
      this.getOHR();
      const formData = new FormData();
    formData.append('user_id', this.userOHR);
    formData.append('file', this.file);
      this.translateService.uploadGFile(formData)
        .subscribe(
          (res: any) => {
            this.loaderService.loading.next(false);
            this.getUserLimt();

           if( res?.success == true || res?.status == 'success'){
            // console.log(res);
            this.fileUploadedStatus = true;
            this.showUpload = false;
            this.mimeType = res?.mimetype;
            this.detectedLanguage = res?.detected_language;
            this.toastMessage.showSuccess(res?.message);
           }else{
            this.toastMessage.showError(res?.message);
           }
          },
          (error : any) => {
            // Handle the error here
            this.loaderService.loading.next(false);
            console.error(error);
            this.toastMessage.showError(error?.message);
          }
        );
    }
googleimageUpload(){

  this.loaderService.loading.next(true);
  this.getOHR();
  const formData = new FormData();
  for(const file of this.Images){
  formData.append('user_id', this.userOHR);
  formData.append('images', file);
  }
  this.translateService.translateGimageUpload(formData)
    .subscribe(
      (res: any) => {
        this.loaderService.loading.next(false);
        this.getUserLimt();

       if( res?.success == true|| res?.status == 'success'){
        // console.log(res);
        this.fileUploadedStatus = true;
        this.showUpload = false;
        this.mimeType = res?.mimetype;
        this.fileName = res?.file_name;
        this.detectedLanguage = res?.detected_language;
        this.toastMessage.showSuccess(res?.message);
       }else{
        this.toastMessage.showError(res?.message);
       }
      },
      (error : any) => {
        // Handle the error here
        this.loaderService.loading.next(false);
        console.error(error);
        this.toastMessage.showError(error?.message);
      }
    );



}
    translateGoogleContent() {
      this.loaderService.loading.next(true);
      this.getOHR();
      this.glossaryTable = this.tableData.map((row: any) => `${row.currentText},${row.translatedText}\n`).join('');
      let payload: {
        user_id?: any;
        target_language?: any;
        file_name?: any;
        mime_type?: any;
        detected_language?: any;
        glossary_content?: string;
        use_glossary?: boolean;
      } = {};

      if(this.glossaryStatus)
      {
        payload['user_id'] = this.userOHR;
        payload['target_language'] = this.convertTo[0]?.value;
        payload['file_name'] = this.fileName;
        payload['mime_type'] = this.mimeType;
        payload['detected_language'] = this.convertFrom[0].name != 'Auto'? this.convertFrom[0].value : this.detectedLanguage ;
        payload['glossary_content'] = this.glossaryTable;
        payload['use_glossary'] = true;
      }
      else
      {
        payload['user_id'] = this.userOHR;
        payload['target_language'] = this.convertTo[0]?.value;
        payload['file_name'] = this.fileName;
        payload['mime_type'] = this.mimeType;
        payload['detected_language'] = this.convertFrom[0].name != 'Auto'? this.convertFrom[0].value : this.detectedLanguage ;
      }
      this.translateService.translateGFile(payload)
        .subscribe(
          (res: any) => {
            this.loaderService.loading.next(false);
            if(res)
            {
              this.fileContent = res?.translated_file_content;
              this.fileExtension = res?.file_extension;
              this.downloadbtn = true;
            }else{
              this.toastMessage.showError(res?.message);
            }
          },
          (error : any) => {
            // Handle the error here
            this.loaderService.loading.next(false);
            console.error(error);
            this.toastMessage.showError(error?.message);
            this.toastMessage.showError(error?.error);
          }
        );
    }

    // Download translated file
    downloadGoogleFile() {
    const payload = {
      "file_extension" : this.fileExtension,
      "translated_file_content" : this.fileContent
    }
    this.translateService.downloadGFile(payload).subscribe((res: Blob) =>
    {
      const file = new Blob([res], { type: this.mimeType });
      // console.log(this.fileName);
      saveAs(file, this.fileName);
      // saveAs(file, 'output.pdf');
      this.loaderService.loading.next(false);
      this.toastMessage.showSuccess('Downloading File.');
      this.loaderService.loading.next(false);
      this.toastMessage.showSuccess('File downloaded successfully');
      this.resetFile();
      this.fileInput.nativeElement.value = null;

      this.resetglossaryFile();

    },
    (error: any) => {
      this.loaderService.loading.next(false);
      this.resetFile();
      this.resetglossaryFile();
      console.error('Error downloading file:', error);
      this.toastMessage.showError('Error downloading file');
    }
    );
    }

  ngOnDestroy(): void {
     // Clear the interval when the component is destroyed
     this.stopInterval();
  }

  modelcheck(){
if( (this.convertFrom[0].value == this.convertTo[0].value)){
  this.toastMessage.showError('Source and target language cannot be same');
  return;
}

    this.istext=true;
    if(!this.isGoogleSelected){
      this.translate()
    }
    else{
     this.gtranslate();
    }
  }

  translate(){
    // this.storeLogs();
    this.istext=true;
    const payload = {
     source_language: this.convertFrom[0].value,
      target_language:this.convertTo[0].value,
      text:this.textinput
    };
    this.loaderService.loading.next(true);
    this.systranService.translatetext1(payload).subscribe((res: any) => {
  if(res.status === "success" ){
      this.loaderService.loading.next(false);
      this.toastMessage.showSuccess(res.message);
      this.textoutput = res.data;
     // this.output1 = true;

    }
    else{
      this.loaderService.loading.next(false);
      this.toastMessage.showError(res.message);
      this.textoutput = 'error';
    }
    }, (error) => {
      this.loaderService.loading.next(false);
      this.toastMessage.showError('Error in translating text');
      this.textoutput = 'error';
    //  this.vocabulary = 'error';
    });

  }


gtranslate(){

  const payload = {
    source_language: this.convertFrom[0].value,
    target_language:this.convertTo[0].value,
    text:this.textinput
  };
  this.loaderService.loading.next(true);
  this.translateService.gtexttranslate(payload).subscribe((res: any) => {
if(res.status === "success" ){
    this.loaderService.loading.next(false);
    this.toastMessage.showSuccess(res.message);
    this.textoutput = res.translated_text;
   // this.output1 = true;
}
else{
  this.loaderService.loading.next(false);
  this.toastMessage.showError(res.message);
  this.textoutput = 'error';
}
  }, (error) => {
    this.loaderService.loading.next(false);
    this.toastMessage.showError('Error in translating text');
    this.textoutput = 'error';
  //  this.vocabulary = 'error';
});
}



interchange(){
if(this.convertFrom[0].name === 'Auto'){
  this.toastMessage.showError('Auto detect language is selected Please select a language to use interchange');
}
else{
  let temp = this.convertFrom;
  this.convertFrom = this.convertTo;
  this.convertTo = temp;
  // if(this.textoutput){
  // this.textinput = this.textoutput;
  // }
  //this.textoutput = '';
}
}






































}
