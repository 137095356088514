import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageService } from '../shared/service/message.service';

export interface Message {
  type: string;
  message: string;
}

@Component({
  selector: 'app-e360-support',
  templateUrl: './e360-support.component.html',
  styleUrls: ['./e360-support.component.scss'],
})
export class E360SupportComponent {
  isOpen = true;
  currentItem = 'All';
  loading = false;
  messages: Message[] = [];
  chatForm = new FormGroup({
    message: new FormControl('', [Validators.required]),
  });
  @ViewChild('scrollMe') private myScrollContainer: any;

  constructor(private messageService: MessageService) {
    this.messages.push({
      type: 'client',
      message: 'Hi, I am your support agent. How can I help you?',
    });
  }
  ngOnInit() {
    this.messageService.useraccess().subscribe((res: any) => {
      this.knowledgeSelected = 'Select';
      this.accountSelected = 'Select';
    }, (err: any) => {
      console.log('error is', err);
    });

  }
  openSupportPopup() {
    this.isOpen = !this.isOpen;
  }
  documentVisual: boolean = false;
  documentReffArr: any = [];
  urls: any; //= 'https://openaipoc2.s3.ap-south-1.amazonaws.com/processmap.PNG';
  imgVisual: boolean = false;

  buttonTitle = 'View More';
  clicked: boolean = false;
  viewMore() {}
  userQuery: any;
  userQueryResultDisplay: boolean = false;
  userQueryResponse: any = [];
  gptUserResponse: any = '';
  gptDisplay: boolean = false;
  loaderDisplay: boolean = false;
  gptSearchQuery: any;
  filterQuery: boolean = false;
  searchQuestion() {
    let accountSubQuery = '';
    let knowledgeSubQuery = '';
    console.log('userQuery::', this.userQuery);
    this.loaderDisplay = true;
    this.userQueryResultDisplay = false;
    this.gptDisplay = false;
    console.log('loaderDisplay::', this.loaderDisplay);
    this.gptUserResponse = '';
    if (this.accountFilter == true) {
      accountSubQuery = `accountName eq '${this.accountSelected}'`;
      this.filterQuery = true;
    }
    if (this.knowledgeFilter == true) {
      knowledgeSubQuery = `DocumentType eq '${this.knowledgeSelected}'`;
      this.filterQuery = true;
    }
    console.log('accountSubQuery:::', accountSubQuery);
    console.log('knowledgeSubQuery:::', knowledgeSubQuery);
    let filterQueryData = [];
    let _filterQuery: any = null;
    if (this.filterQuery) {
      filterQueryData.push(accountSubQuery);
      filterQueryData.push(knowledgeSubQuery);
      console.log('filterQueryData::', filterQueryData);

      const noEmptyStrings = filterQueryData.filter((str) => str !== '');
      console.log('no emptystring array is::', noEmptyStrings);
      console.log('no emptystring array is::', noEmptyStrings.length);

      if (noEmptyStrings.length === 1) {
        _filterQuery = noEmptyStrings.toString();
        console.log('_filterQuery:', _filterQuery);
      } else if (noEmptyStrings.length === 2) {
        _filterQuery = noEmptyStrings.join(' and ');
        console.log('_filterQuery:', _filterQuery);
      }
    }
    this.messageService
      .sendE360Message(this.userQuery, _filterQuery)
      .subscribe((response: any) => {
        console.log('response message::', response);
        this.loaderDisplay = false;
        this.userQueryResponse = response.message;
        this.gptUserResponse = response.gptResponse;
        this.gptSearchQuery = response.searchQuery;
        this.gptDisplay = true;
        this.userQueryResultDisplay = true;
        this.loading = false;
        this.messages.push({
          type: 'client',
          message: response.message,
        });
        _filterQuery = null;
        //this.reset()
      });
  }
  scrollToBottom() {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop =
          this.myScrollContainer.nativeElement.scrollHeight + 500;
      } catch (err) {}
    }, 150);
  }
  knowledge: any = [
    { id: -1, name: 'Select' },
    {
      id: 1,
      name: 'All',
      value: 'All',
    },
    {
      id: 2,
      name: 'Service Management Fundamentals',
      value: 'SMF',
    },
    {
      id: 3,
      name: 'Portfolio',
      value: 'Portfolio',
    },
  ];
  knowledgeSelected = ``;
  knowledgeFilter = false;
  ChangeKnowledge(e: any) {
    console.log(e.target.value);
    if (e.target.value == 'Select') {
      this.knowledgeFilter = false;
      this.filterQuery = false;
    }

    if (e.target.value !== 'All') {
      this.knowledgeSelected = e.target.value;
      this.knowledgeFilter = true;
      console.log('filter::', this.knowledgeFilter);
    } else {
      this.knowledgeSelected = e.target.value;
      this.knowledgeFilter = false;
      this.filterQuery = false;
      console.log('filter::', this.knowledgeFilter);
    }
  }
  accountList: any = [
    { id: -1, name: 'Select' },
    {
      id: 1,
      name: 'ABC',
    },
    {
      id: 2,
      name: 'CDEF',
    },
    {
      id: 3,
      name: 'GHIJ',
    },
    {
      id: 4,
      name: 'Genpact Internal',
    },
    {
      id: 5,
      name: 'XYZ',
    },
  ];
  accountSelected = ``;
  accountFilter = false;
  ChangeAccount(e: any) {
    console.log(e.target.value);
    if (e.target.value !== 'Select') {
      this.accountSelected = e.target.value;
      this.accountFilter = true;
      console.log('filter::', this.accountFilter);
    } else {
      this.accountSelected = e.target.value;
      this.accountFilter = false;
      this.filterQuery = false;
      console.log('filter::', this.accountFilter);
    }
  }

  reset() {
    this.accountSelected = 'Select';
    this.knowledgeSelected = 'Select';
    this.accountFilter = false;
    this.knowledgeFilter = false;
  }
}
