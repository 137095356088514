<!-- <button (click)="nav()" class="btn btn-secondary float-left m-2 d-flex " style="border-radius: 0px;background-color:#073262;align-items:center;">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
  </svg>
  previous


</button>
<br> -->
<div class="container" >
  <div class="flex-item">
    <div class="navigation">
      <a routerLink="/interview-assist/TalentAdmin" routerLinkActive="active-link">TalentAdmin</a>
        <div class="divider-vertical"></div>

      <ng-container >
        <a  [routerLink]="['/interview-assist', reqid, uniqid, 'IntelliQ&A']" routerLinkActive="active-link">IntelliQ&A</a>
        <div class="divider-vertical"></div>
        <a  [routerLink]="['/interview-assist', reqid, uniqid, 'Report-Gen']" routerLinkActive="active-link">AI ReportGen Pro</a>
      </ng-container>
    </div>
  </div>
</div>
<div class="container mt-3">

  <!-- <div class="col-md-4">
    <ng-multiselect-dropdown
      [placeholder]="'User Id'"
      [settings]="dropdownSettings"
      [data]="userid"
      [(ngModel)]="uniqId">
    </ng-multiselect-dropdown>
  </div> -->

  <mat-tab-group animationDuration="500ms">
    <mat-tab label="Upload">
      <div>
        <div class="form-group">
          <label for="fileTypeSelect">Choose file type:</label>
          <select id="fileTypeSelect" class="form-control" [(ngModel)]="selection" (change)="handleSelectionChange($event)">
            <option value="audio/video" selected>Audio/Video</option>
            <option value="upload transcript">Upload Transcript</option>
          </select>
        </div>



        <div class="form-group file-upload-box mt-3" *ngIf="selection" (click)="fileInput.click()">
          <input type="file" #fileInput style="display: none;" (change)="handleFileChange($event)"
                 [attr.accept]="selection === 'upload transcript' ? '.txt' : 'audio/*,video/*'">
          <div class="upload-area">
            <i class="fa fa-cloud-upload-alt fa-2x"></i>
            <p>Click here to upload a file</p>
            <p class="file-types">{{ selection === 'upload transcript' ? 'Text files (.txt)' : 'Audio/Video files' }}</p>
          </div>
        </div>


        <div *ngIf="fileDetails" class="alert alert-info mt-2">
          <strong>Uploaded File:</strong> {{ fileDetails.name }}
          <div *ngIf="fileDetails.type"><strong>Type:</strong> {{ fileDetails.type }}</div>
        </div>

        <div class="d-flex" style="gap:10px; text-align:center;margin-top: 20px;justify-content: space-evenly;">
          <button *ngIf="selection === 'audio/video'" class="btn btn-primary" (click)="downloadTranscript()">Get Transcript</button>
          <button *ngIf="selection === 'audio/video' && dnld === true" class="btn btn-secondary" (click)="download()">Download Transcript</button>
          <button class="btn btn-primary" (click)="generatereport('normal')">Generate summarized Report</button>
          <button class="btn btn-primary" (click)="generatereport('complete')">Generate Report</button>

        </div>
<br><br><br>
      </div>
    </mat-tab>
    <mat-tab label="Report">
      <div class="text-center mt-4 ">
        <!-- <h3>Report Section</h3>
        <p>Content for the report tab goes here...</p> -->
        <!-- <button class="btn btn-primary" *ngIf="!reportdata" (click)="generatereport()">Generate Report</button> -->
      </div>

<div class="report-container" *ngIf="sumstatus && summaryreportdata">
 <textarea class="form-control" rows="10" [(ngModel)]="summaryreportdata" [disabled]="sumbtn"></textarea>

 <!-- <button class="btn btn-primary" (click)="sumbtn = false" >Edit</button>
 <button class="btn btn-primary" >Save</button> -->
</div>

      <div class="report-container" *ngIf="reportdata && reportstatus">
        <div>
          <h1 class="report-title" style="color: #00aecf; text-align: center;">Interview Evaluation Report</h1>

        </div>
        <div class="action-buttons">
          <!-- (click)="toggleEdit()" -->
          <button (click)="toggleEdit()">{{ isEditing ? 'Cancel' : 'Edit' }}</button>
          <!-- <button (click)="downloadReport()">Download</button> -->
          <button *ngIf="!isEditing" (click)="sendReportAsDocx()" >Save</button>
          <!-- <button >Approve</button>

          <button >Send</button> -->
          <!-- <button *ngIf="!isEditing" (click)="changestatus()" >Finish</button> -->

          <button *ngIf="isEditing" (click)="saveEdits()">Save Changes</button>

        </div>

        <div *ngIf="reportstatus && !isEditing"  id="report-content" class="report-d" style="max-height:500px; overflow: scroll; background-color:#ececec; padding: 25px;">
          <h2 class="report-subtitle" style="color: #00aecf;">Candidate Evaluation Details</h2>

          <div *ngIf="reportdata && !isEditing" class="report-content">
            <div class="section">
              <h3 class="section-title" style="color: #00aecf;">Candidate's Score Card</h3>
              <div lineNumbers markdown>{{ score }}</div>
            </div>
            <div class="section">
              <h3 class="section-title" style="color: #00aecf;">Feedback of the Candidate</h3>
              <div lineNumbers markdown>{{ fcandidate }}</div>
            </div>
            <div class="section">
              <h3 class="section-title" style="color: #00aecf;">Feedback of the Interviewer</h3>
              <div lineNumbers markdown>{{ finterviewer }}</div>
            </div>
            <div class="section">
              <h3 class="section-title" style="color: #00aecf;">Overall Summary</h3>
              <div lineNumbers markdown>{{ osummary }}</div>
            </div>
            <div class="section">
              <h3 class="section-title" style="color: #00aecf;">Review As Per the Job Profile or Requirement</h3>
              <div lineNumbers markdown>{{ creview }}</div>
            </div>
          </div>
        </div>

        <form [formGroup]="reportForm" *ngIf="isEditing">
          <h3 class="section-title">Edit Candidate's Score Card</h3>
          <textarea formControlName="scoreCard" [(ngModel)]="score" ></textarea>

          <h3 class="section-title">Edit Feedback of the Candidate</h3>
          <textarea formControlName="candidateFeedback"

          [(ngModel)]="fcandidate"
          ></textarea>

          <h3 class="section-title">Edit Feedback of the Interviewer</h3>
          <textarea formControlName="interviewerFeedback"

          [(ngModel)]="finterviewer"
          ></textarea>

          <h3 class="section-title">Edit Overall Summary</h3>
          <textarea formControlName="summary"

          [(ngModel)]="osummary"
          ></textarea>

          <h3 class="section-title">Edit Review As Per the Job Profile or Requirement</h3>
          <textarea formControlName="jobProfileReview"

          [(ngModel)]="creview"
          ></textarea>

        </form>


      </div>

    </mat-tab>
  </mat-tab-group>
</div>

<div *ngIf="isLoading" class="overlay">
  <div class="spinner"></div>
  <br>

</div>

<!-- new section -->
