<div class="">

  <app-sidenav [item]="currentItem"></app-sidenav>


  <!-- Main content -->
  <section class="content">

    <div class="container-fluid responsive p-3 px-md-5">

      <div class="row">

        <div class="col-md-3 order-md-2 mb-3 ">

          <div class="floating-advanced-settings" *ngIf="show_completion" [ngClass]="{'setting-r':showAdvancedSettings}">
            <div class="mt-2">
              <div class="row">

                <button type="button" class="btn btn-link" (click)="toggleAdvancedSettings()">
                  <i class="bi bi-gear-fill me-2" [ngClass]="{'bi-gear-fill':!showAdvancedSettings, 'bi-x-circle':showAdvancedSettings}"></i>
                  Advanced settings  
                </button>
              </div>
            </div>

            <div class="collapse" [ngClass]="{ 'show': showAdvancedSettings,'slide-in': showAdvancedSettings}">
              <div class="">
                <!-- Temperature -->
                <div class="row">
                  <div class="col-7">
                    <label class="text-xs">Temperature
                      <!-- Info icon here -->
                      <i class="bi bi-info-circle"
                        title="Controls randomness. Lowering the temperature means that the model will produce more repetitive and deterministic responses. Increasing the temperature will result in more unexpected or creative responses. Try adjusting temperature or Top P but not both.">
                      </i>
                    </label>
                  </div>
                  <div class="col">
                    <input [(ngModel)]="temperature" type="text" class="form-control" />
                  </div>
                </div>
                <!-- Temperature slider here -->
                <div class="row">
                  <div class="range">
                    <input type="range" min="0" max="1" step="0.01" value="0" class="w-100" [(ngModel)]="temperature" />
                  </div>
                </div>
                <!-- Max tokens -->
                <div class="row">
                  <div class="col-7">
                    <label class="text-xs">Max length (tokens)
                      <!-- Info icon here -->
                      <i class="bi bi-info-circle"
                        title="Set a limit on the number of tokens per model response. The API supports a maximum of 4000 tokens shared between the prompt (including system message, examples, message history, and user query) and the model's response. One token is roughly 4 characters for typical English text."></i>
                    </label>

                  </div>
                  <div class="col">
                    <input type="text" [(ngModel)]="max_tokens" class="form-control internal-component" />
                  </div>
                </div>
                <!-- Max tokens slider here -->
                <div class="row">
                  <div class="range">
                    <input type="range" [(ngModel)]="max_tokens" min="1" max="4000" value="1" class="w-100" />
                  </div>
                </div>
                <!-- Top probabilities -->
                <div class="container">
                  <div class="row">
                    <div class="col-7">
                      <label class="text-xs">Top probabilities
                        <!-- Info icon here -->
                        <i class="bi bi-info-circle"
                          title="Similar to temperature, this controls randomness but uses a different method. Lowering Top P will narrow the model’s token selection to likelier tokens. Increasing Top P will let the model choose from tokens with both high and low likelihood. Try adjusting temperature or Top P but not both."></i></label>

                    </div>
                    <div class="col">
                      <input type="text" [(ngModel)]="top_p" class="form-control" />
                    </div>
                  </div>
                </div>
                <!-- Top probabilities slider here -->
                <div class="range">
                  <input type="range" min="0" max="1" step="0.01" value="0.01" class="w-100" [(ngModel)]="top_p" />
                </div>
                <!-- Frequency penalty -->
                <div class="container">
                  <div class="row">
                    <div class="col-7">
                      <label class="text-xs">Frequency penalty
                        <!-- Info icon here -->
                        <i class="bi bi-info-circle"
                          title="Reduce the chance of repeating a token proportionally based on how often it has appeared in the text so far. This decreases the likelihood of repeating the exact same text in a response."></i>
                      </label>
                    </div>
                    <div class="col">
                      <input type="text" [(ngModel)]="frequency_penalty" class="form-control" />
                    </div>
                  </div>
                </div>
                <!-- Frequency penalty slider here -->
                <div class="range">
                  <input type="range" min="0" max="2" step="0.01" value="0.01" class="w-100"
                    [(ngModel)]="frequency_penalty" />
                </div>
                <!-- Presence penalty -->
                <div class="container">
                  <div class="row">
                    <div class="col-7">
                      <label class="text-xs">Presence penalty
                        <!-- Info icon here -->
                        <i class="bi bi-info-circle"
                          title="Reduce the chance of repeating any token that has appeared in the text at all so far. This increases the likelihood of introducing new topics in a response."></i></label>

                    </div>
                    <div class="col">
                      <input [(ngModel)]="presence_penalty" class="form-control" />
                    </div>
                  </div>
                </div>
                <!-- Presence penalty slider here -->
                <div class="range">
                  <input type="range" min="0" max="2" step="0.01" value="0.01" class="w-100"
                    [(ngModel)]="presence_penalty" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-9 py-3order-md-1">

          <div class="container"  *ngIf="show_completion">
            <div class="row">
             <div class="col-md-6">
               <h4 class="text-lg fw-bold">Playground Library</h4>
             </div>
             <div  *ngIf="endPointDetail?.value" class="col-md-6 text-md-right">
              Count Left: <b>{{ endPointDetail?.max - endPointDetail?.value}}</b>
            </div>
            </div>
            <div class="row mt-4">
              <div class="col fw-bold">Deployments</div>
              <div class="col fw-bold">Examples</div>

            </div>

            <div class="row mt-4">
              <div class="col">
                <select [(ngModel)]="deployments" class="form-select" aria-label="Default select example">
                  <option *ngFor="let item of deployment_list" [value]="item.name">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col">
                <select (ngModelChange)="onExampleChange($event)" [(ngModel)]="examples" class="form-select"
                  placeholder="Load an Example">
                  <option *ngFor="let item of examples_list" [value]="item.name">
                    {{ item.name }}
                  </option>
                </select>
              </div>

            </div>




            <div class="row mt-4">
              <div class="col-12 position-relative"> <!-- Add position-relative class to the container -->
                <textarea #textboxPrompt [(ngModel)]="prompt" id="textbox-prompt" class="content textarea form-control large-textbox" [ngClass]="{ 'small-textbox': showAdvancedSettings }">

                </textarea>

                <!-- <textarea *ngIf="speechoff; else addPt" [(ngModel)]="prompt" id="textbox-prompt" class="content textarea form-control large-textbox"></textarea>

                <ng-template #addPt>
                  <textarea [(ngModel)]="prompt" id="textbox-prompt" class="content textarea form-control large-textbox"></textarea>
                </ng-template> -->
                <!-- <textarea *ngIf="speechon" #textboxPrompt [(ngModel)]="prompt+speechservice.text" id="textbox-prompt" class="content textarea form-control large-textbox"
                [ngClass]="{ 'small-textbox': showAdvancedSettings }">

              </textarea> -->

                <!-- <div *ngIf="showLoading" class="loading-overlay">
                  <div class="loading-indicator">
                    <textarea #textboxPrompt [(ngModel)]="this.streamprompt" id="textbox-prompt" class="content textarea form-control large-textbox" [ngClass]="{ 'small-textbox': showAdvancedSettings }">

                    </textarea>  -->
                     <!-- <div><div class="pulsating-circle"></div>
                     {{this.streamprompt}}</div>  -->
                  <!-- </div>
                </div> -->
              </div>
            </div>

            <div class="row">
              <div class="col" style="color: gray; font-size: 12px !important">
                <b><em>Note</em></b> <i class="bi bi-info-circle"
                  title="Please be aware that the messages have been generated by an artificial intelligence model. While efforts have been made to provide accurate and relevant information, there is a possibility of errors or inaccuracies. The response you receive should not be considered as professional or expert advice. We strongly recommend cross-checking the information with reliable sources or consulting a human expert when necessary. The purpose of this AI is to assist and provide general information, but it may not always be perfect. Use the information at your own discretion.">
                </i>

                <span class="float-end" *ngIf="totalUsage"> <span class="font-weight-bold">Total Token Usage :</span> {{totalUsage?.total_tokens  || 0}}</span>
              </div>
            </div>
            <div class="row mt-4">

              <div class="col-md col-md mb-1 mb-md-0">
                <button type="button" class="btn button-genpact w-100 text-white"
                  id="generatebtn" (click)="Generate()" [disabled]="prompt.trim()=== ''">
                  Generate
                </button>
              </div>
              <div class="col-md col-md mb-1 mb-md-0">
                <button type="button" class="btn button-genpact text-white w-100" (click)="undo()" id="undobtn"
                [disabled]="prompt.trim()=== ''">
                  Undo
                </button>
              </div>
              <div class="col-md col-md mb-1 mb-md-0">
                <button id="regeneratebtn" type="button" class="btn button-genpact text-white w-100"
                [disabled]="prompt.trim()=== ''" (click)="Generate()">
                  Regenerate
                </button>
              </div>
              <div class="col-md col-md mb-1 mb-md-0">
                <button id="downloadbtn" type="button" class="btn button-genpact text-white w-100"
                   (click) = "downloadAsDocx()" [disabled]="prompt.trim()=== ''">Download
                   <i class="fa fa-download" aria-hidden="true"></i>
                </button>
              </div>
              <div class="col-md col-md mb-1 mb-md-0" >
              <button class="app-speech-input-btn text-white w-100" style="background-color: transparent;" *ngIf="startspeech" (click)="startService()"></button>
              <button class="app1-speech1-input1-btn1 btn text-white w-100" style="background-color: transparent;" *ngIf="stopspeech"  (click)="stopService()"><i class="fa fa-microphone fa-beat-fade" style="font-size:20px;color:red"></i>
                <div class="micro-animation"></div>
              </button>
             </div>
              <!-- ckl -->

              <!-- cl -->

            </div>
          </div>
           <!-- <button class="app-speech-input-btn" ></button> -->

        </div>


       



      </div>




    </div>
  </section>



<!-- policy & condition toggle -->
<button type="button" class="btn btn-primary d-none" id="playground_policy_model" (click)="openModal(playground_policy_model)">
</button>

<!-- policy & condition block -->
<ng-template #playground_policy_model>
  <div class="modal-body tcolor">
    <div class="row">
      <div class="col-12 text-decoration-underline fw-bold text-primary">Important Instructions</div>
    </div>
    <div class="row">
      <div class="col-12 pt-2" style="font-size: 13px;">
        <ul>
          <li>
            Please be aware that the messages have been generated by an artificial intelligence model. While
            efforts have been made to provide accurate and relevant information, there is a possibility of
            errors or inaccuracies. The response you receive should not be considered as professional or
            expert advice. We strongly recommend cross-checking the information with reliable sources or
            consulting a human expert when necessary. The purpose of this AI is to assist and provide general
            information, but it may not always be perfect. Use the information at your own discretion.
          </li>
          <li>
            The AI-generated response will be limited to 1,000 characters to ensure concise and focused
            answers.
          </li>
          <li>
            If you have any inquiries, we kindly encourage you to consult Genpact's Code of Conduct Document
            and <a
              href="https://prodapps.genpact.com/RSAarcher/default.aspx?requestUrl=../Foundation/workspaceDashboard.aspx?workspaceId%3d4"
              target="_blank">ChatGPT and Use of AI Programs Policy</a>.
          </li>
          <li>
            Kindly note that your inputs maybe subject to audit, and may be used for refining the accuracy of
            responses provided by the BOT
            
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-12 ml-4">
        <input id="byod_check_label" type="checkbox" [(ngModel)]="chkagree" class="form-check-input" />
        <label class="form-check-label text-decoration-underline fw-bold text-primary" style="margin-left: 15px;"
          for="byod_check_label">
          I acknowledge that I have read, understand and agree to above instructions.
        </label>
      </div>

    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn button-genpact text-white  cursor-pointer" [disabled]="!chkagree"
      (click)="modalRef?.hide()">
      Proceed
    </button>
  </div>
</ng-template>
</div>
