<div class="row">
    <div class="col-12 mt-3">
        <div class="parent-block">
            <div class="group-block">
                <ul>
                    <li>
                        <button class="" [routerLink]="['/chat/completion']"  routerLinkActive=" bg-white">
                            <span>GPT</span>
                        </button>
                    </li>
                    <li>
                        <button class="" [routerLink]="['/chat/byod']"  routerLinkActive=" bg-white">
                            <span>BYOD</span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>