import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, forkJoin, map } from 'rxjs';


import { LoaderService } from 'src/app/shared/service/loader.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';

// import { AskGenpactService } from 'src/app/shared/service/ask-genpact.service';
// import { GcpService } from '../shared/service/gcp.service';
// import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
// import { MsaAssistService } from '../shared/service/msa-assist.service';
import { MultiagentService } from '../shared/service/multiagent.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

interface ChatMessage {
  name: string;
  content: string;
}

@Component({
  selector: 'app-colloborative-agent',
  templateUrl: './colloborative-agent.component.html',
  imports: [CommonModule, FormsModule],
  standalone: true,
  styleUrls: ['./colloborative-agent.component.scss']
})
export class ColloborativeAgentComponent {

  @ViewChild('scrollChat') scrollChat!: ElementRef;
  chatData: any[] = []; // Array to hold chat data
  selectedFiles: File[] = []; // Array to hold selected files
  prompt: string = ''; // User input prompt
  inputAccept: string = '.pdf,.doc,.docx,.png,.jpg,.jpeg,.csv,.xlsx,.ppt,.pptx'; // Accepted file types
  //Pdf,docx,excel,png,jpg,jpeg
  isLoading: boolean = false;
  lastResponseLength: number = 0;
  processedMessages: Set<string> = new Set(); // Track processed messages

  chatSubscriber!: Subscription;
  chatPayload: any;
  // chatData: any = [];
  resetChatStatus: boolean = false;
  token: string = '';
  txtarea: boolean = true;
  uploadfilename: string = '';
  threadId: any;










  constructor(

    private toastMessage: ToastMessageService,
    private loaderService: LoaderService,
    private multiagentService: MultiagentService,
  ) { }

  // ngOnInit(): void {
  //   // Initialize or fetch chat data if necessary
  //   this.login();
  //   this.msalBroadcastService.inProgress$.subscribe((status: InteractionStatus) => {
  //     if (status === InteractionStatus.None && this.authService.instance.getAllAccounts().length > 0) {
  //       this.authService.acquireTokenSilent({
  //         scopes: ["Sites.Read.All"]
  //       }).toPromise().then((result: AuthenticationResult | undefined) => {
  //         if (result) {
  //           this.token = result.accessToken;
  //           localStorage.setItem('mstoken', this.token);
  //         }
  //       }).catch(error => {
  //         console.error('Token acquisition error:', error);
  //       });
  //     }
  //   });
  // }
  ngOnDestroy(): void {
    if (this.chatSubscriber) {
      this.chatSubscriber.unsubscribe();
    }
  }

  // login(): void {
  //   this.authService.loginPopup({
  //     scopes: ["Sites.Read.All"]
  //   }).subscribe((response: AuthenticationResult) => {
  //     this.authService.instance.setActiveAccount(response.account);
  //   }, error => {
  //     console.error('Login error:', error);
  //   });
  // }

  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  handleEnter(): void {
    // Handle Enter key press (e.g., send message)
    if (this.prompt.trim()) {
      this.sendMessage();
      // this.getResponse(this.prompt);
    }
  }
  scrollToBottom(): void {
    //scroll to bootom of page
    this.scrollChat.nativeElement.scrollTop = this.scrollChat.nativeElement.scrollHeight;

  }







  getResponse(event: any) {

    this.loaderService.loading.next(true);
    this.resetChatStatus = false;

    this.multiagentService.getThread(this.prompt).subscribe(
      (res: any) => {
        this.loaderService.loading.next(false);
        console.log(res);

        // Extract and format the content
        if (res && res.data) {
          let formattedContent = res.data;

          // let resultResponse = { "role": "assistant", "content": formattedContent };
          this.prompt = res.data;

          //this.chatData.push(resultResponse);

          this.sendMessage();
          // this.chatData.push(resultResponse);

        } else {

          this.sendMessage();
          // this.chatData.push(resultResponse);
        }

        this.resetChatStatus = true;
      },
      (error: any) => {
        this.loaderService.loading.next(false);
        this.toastMessage.showError('Something went wrong');
        this.resetChatStatus = true;
      }
    );
  }











  sendMessage(): void {
    if (this.prompt.trim()) {
      this.isLoading = true;
      const payload: any = {
        task: this.prompt,
      };

      if (this.uploadfilename) {
        payload['filename'] = this.uploadfilename;
      }

      this.chatData.push({ role: 'user', content: this.prompt });

      this.multiagentService.getThread(payload.task).subscribe((res: any) => {
        if (res && res.thread_id) {
          this.threadId = res.thread_id;
          console.log(this.threadId);

          // Start polling chat responses
          this.pollChat(this.threadId);
        } else {
          this.isLoading = false;
          this.toastMessage.showError('Failed to get thread ID');
        }
      }, (error: any) => {
        this.isLoading = false;
        this.toastMessage.showError(error);
        this.resetChatStatus = true;
      });

      this.prompt = '';
      this.scrollToBottom();
    } else {
      this.toastMessage.showError('Question is required');
    }
  }

  pollChat(threadId: number): void {
    const intervalId = setInterval(() => {
      this.multiagentService.getChat(threadId).subscribe((res: any) => {
        if (res && res.data) {
          // Filter and add only new responses
          const newResponses = res.data[0].filter((message: ChatMessage) => {
            const messageId = `${message.name}:${message.content}`;
            if (!this.processedMessages.has(messageId)) {
              this.processedMessages.add(messageId);
              return true;
            }
            return false;
          });

          newResponses.forEach((message: ChatMessage) => {
            const formattedMessage = {
              role: 'bot',
              content: `${message.name}:\n${message.content}`
            };
            this.chatData.push(formattedMessage);
          });

          this.scrollToBottom();
        }

        // Stop polling when status is false
        if (res.status === false) {
          clearInterval(intervalId);
          this.isLoading = false;
        }
      }, (error: any) => {
        this.isLoading = false;
        clearInterval(intervalId);  // Stop polling on error
        this.toastMessage.showError(error);
        this.resetChatStatus = true;
      });
    }, 8000);  // 8 seconds interval
  }




  copyToClipboard(content: string): void {
    navigator.clipboard.writeText(content).then(() => {
      console.log('Copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  regenerate(index: number) {
    this.prompt = this.chatData[index - 1]?.content;
    // this.toastMessage.showSuccess(this.prompt);
    this.sendMessage();
  }

  // Handles file upload input
  uploadFile(action: string = 'upload'): void {
    if (action === 'reset') {
      this.selectedFiles = []; // Reset selected files
      this.uploadfilename = '';
      this.txtarea = true;
    } else {
      // Open the file upload dialog when 'upload' is triggered
      document.getElementById('upload_chatFile')?.click();
      // this.txtarea = false;
    }
  }


  // Processes the selected files
  uploads(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      let fileSizeMB = 0;
      const maxFileSize = 16; // Set max file size limit to 16MB

      // Iterate through selected files
      for (const file of Array.from(input.files)) {
        const fileType = file.name.split('.').pop()?.toLowerCase();

        // Check if file type is allowed
        if (!this.inputAccept.includes(`.${fileType}`)) {
          this.toastMessage.showmessage(`The file type .${fileType} is not accepted. Allowed types: ${this.inputAccept}`);
          this.clearFileInput('upload_chatFile');
          return;
        }

        // Calculate file size in MB
        fileSizeMB += file.size / 1024 / 1024;

        // Check for file size limit
        if (fileSizeMB > maxFileSize) {
          this.clearFileInput('upload_chatFile');
          this.toastMessage.showmessage('Maximum file size limit is 16MB.');
          return;
        }

        // Add the file to the selectedFiles array if size and type are within limits
        this.selectedFiles.push(file);
        // this.gcpupload(); // Call your upload method
      }
    }
  }

  //gcp file upload
  // gcpupload(): void {

  //   this.isLoading = true;
  //   let formData = new FormData();
  //   formData.append('file', this.selectedFiles[0]);
  //   this.gcpService.uploadFile(formData).subscribe((res: any) => {
  //     console.log(res);

  //     if (res?.status === 'success') {
  //       this.chatData.push({ role: 'user', content: this.selectedFiles[0].name });
  //       // this.chatData.push({ role: 'bot', content: res.data });
  //       this.toastMessage.showSuccess(res?.message);
  //       this.uploadfilename = res.data;
  //       this.scrollToBottom();
  //       this.isLoading = false;
  //       this.txtarea = false;

  //     }
  //     else {
  //       this.toastMessage.showError(res?.message);
  //       this.isLoading = false;

  //     }

  //   },
  //     (error: any) => {
  //       this.toastMessage.showError('Something went wrong');
  //       this.isLoading = false;

  //     }
  //   );

  // }











  // Clears the file input
  clearFileInput(elementId: string): void {
    const fileInput = document.getElementById(elementId) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Reset file input field
      this.txtarea = true;
    }
  }
  inputPrompt(question: string): void {
    // Handle question input
    console.log(`Prompting with question: ${question}`);
  }

  newChat(): void {
    // Logic to start a new chat
    this.chatData = [];
    this.selectedFiles = [];
    this.prompt = '';
    this.txtarea = true;
    this.clearFileInput('upload_chatFile');

    console.log('Starting a new chat');
  }

  sentToInput(): void {
    // Handle sending user input
    this.sendMessage();
    // this.getResponse(this.prompt);
  }

}
