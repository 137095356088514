<div class="tab-main">
  <div class="tab-container">
    <ul class="nav nav-tabs">
      <li class="nav-item" *ngFor="let tab of tabsArray; let sr = index" (click)="setTab(sr)">
        <a class="nav-link" [ngClass]="activatedTab == sr ? 'active' : '' ">{{tab}}</a>
      </li>
    </ul>
  </div>
</div>

  <div *ngIf="activatedTab === 0">
    <app-brd (switchTab)="switchTab($event)"></app-brd>
 </div>
 
 <div *ngIf="activatedTab === 1">
    <app-sdd (switchTab)="switchTab($event)"></app-sdd>
  </div>
 
  <div *ngIf="activatedTab === 2">
    <app-generate-code></app-generate-code>
  </div>
