import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SopService } from '../shared/service/sop.service';
import { ToastMessageService } from '../shared/service/toast-message.service';

@Component({
  selector: 'app-converse',
  templateUrl: './converse.component.html',
  styleUrls: ['./converse.component.scss']
})
export class ConverseComponent implements OnInit, OnDestroy {


  languageArray = ['English', 'Non-English'];
  languageSelected = 'Non-English';

  functionList: any = [];
  functionSelected: any;

  chatPayload: any;
  chatData: any = [];
  resetChatStatus:boolean= false;
  subscription!: Subscription;
  chatSubscriber!: Subscription;

  constructor(
    private sopService: SopService,
    private toastMessage: ToastMessageService,
    
  ) { }

  ngOnInit(): void {
    this.getUserRole();
  }

  getFunctionParameters() {
    this.chatPayload = null;
    
    this.chatData = [];
    this.resetChatStatus=true; 

    setTimeout(() => {
      this.chatPayload = this.functionList.find((ele: any) => ele?.value == this.functionSelected);
   
      this.scrollToBottom();
    }, 0);
  }

  getUserRole() {
    this.subscription = this.sopService.activeUserRole.subscribe(
      (res: any) => {
        console.log(res)
        if (res?.all_roles) {
          this.functionList = res['all_roles'] || [];
         
         if(res['all_roles']?.length) this.functionSelected = res['all_roles'][0]['value'];
          this.chatPayload = null;
          this.chatData = [];      
        }
      });
  }

  getResponse(event:any){  
    if(event?.unSubscription){
      this.chatSubscriber?.unsubscribe();
      return;
    }
    
    this.resetChatStatus=false;
    this.chatSubscriber =  this.sopService.complanceChat(this.chatPayload?.value, event?.data)
      .subscribe(
        (res: any) => {
          if (res?.response?.response?.response) {
            let content = '';          
            content = content + res?.response?.response?.response?.message?.content?.answer || 'Something went wrong';
            
            // add to database api
            let prompt =this.chatData[this.chatData?.length -1];
            this.createBotHistroy(prompt?.content,content)

         

            let resultResponse = { "role": "assistant", "content": content  };           
            this.chatData.push(resultResponse);
    
          } else {
            this.toastMessage.showError(res?.error_message);      
          }

          this.resetChatStatus=true;          
        },
        (error) => {      
          this.toastMessage.showError(error?.message);
          this.resetChatStatus = true;
        }
      );
  }



  

  createBotHistroy(prompt: any, answer: any) {   
   
    const payload = {     
      function_type: this.chatPayload?.name || '',
      prompt,
      answer,
      sources: 'sources',
      likes:0,
      language:this.languageSelected
    };

    this.sopService.createUserHistory(payload).subscribe(
      (response: any) => console.log(response),
      (error: any) => console.error(error)
    );
  }

  // scoroll to bottom in chat window 
  scrollToBottom() {
    setTimeout(() => {      
      window.scrollTo(0, document.body.scrollHeight);
    }, 0);
  }


  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
