import { Component } from '@angular/core';
import { AvatarHeaderComponent } from './avatar-header/avatar-header.component';
import { HomeComponent } from './home/home.component';
// import { MultiFormComponent } from "../digital-avatar/multi-form/multi-form.component";

@Component({
  selector: 'app-avatarcraft',
  templateUrl: './avatarcraft.component.html',
  standalone:true,
  imports: [AvatarHeaderComponent, HomeComponent],
  styleUrls: ['./avatarcraft.component.scss']
})
export class AvatarcraftComponent {

}
