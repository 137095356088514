import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ImageGeneratorService } from '../shared/service/image-generator.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { LoaderService } from '../shared/service/loader.service';
import { Base64fileService } from '../shared/service/base64file.service';
import { VoiceRecognitionService } from '../shared/voice-recognition.service';
import { UserApiLimitService } from '../shared/service/user-api-limit.service';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-image-generator',
  templateUrl: './image-generator.component.html',
  styleUrls: ['./image-generator.component.scss'],
  providers: [VoiceRecognitionService]
})
export class ImageGeneratorComponent implements OnInit, OnDestroy {
  prompt: any;
  chatData: any = [];
  startspeech: boolean = true;
  stopspeech: boolean = false;

  imageToImage = false;

  methodSelected: any = {
    name: 'Image Generation',
    description: 'Create images using descriptive text with AI'
  }

  pageTitle: any = 'Welcome to GenAI ImageGenerator World!';
  inputAccept = ".jpg,.JPG,.jpeg,.png";
  imageFile: any;
  imageConfigStatus = false;
  @ViewChild('scrollChat', { static: false }) scrollContainer!: ElementRef;

  styleList: any = [];
  dallestyle: any = [];
  sizelist: any = [];
  qualitylist: any = [];
  cfg_scale: any = 7;
  seed: any = 512;
  start_schedule: any = 0.6;
  steps: any = 30;
  image_strength: any = 0.6;
  style_preset: any = 'photographic';
  style:any ='vivid';
  quality:any='standard';
  size:any='1024x1024';
  dalleurl:any;

  endPointDetail: any;
  promptStatus: boolean = false;

  modalRef?: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  modalImageData: any;
  image_model: string = 'titan';

  chkagree: boolean = false;
  constructor(
    private imageGeneratorService: ImageGeneratorService,
    private toastMessage: ToastMessageService,
    private loaderService: LoaderService,
    private base64fileService: Base64fileService,
    public speechservice: VoiceRecognitionService,
    private userApiLimitService: UserApiLimitService,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) {
    this.styleList = [
      { name: "3D Model", value: "3d-model" },
      { name: "Analog Film", value: "analog-film" },
      { name: "Anime", value: "anime" },
      { name: "Cinematic", value: "cinematic" },
      { name: "Comic Book", value: "comic-book" },
      { name: "Digital Art", value: "digital-art" },
      { name: "Enhance", value: "enhance" },
      { name: "Fantasy Art", value: "fantasy-art" },
      { name: "Isometric", value: "isometric" },
      { name: "Line Art", value: "line-art" },
      { name: "Low Poly", value: "low-poly" },
      { name: "Modeling Compound", value: "modeling-compound" },
      { name: "Neon Punk", value: "neon-punk" },
      { name: "Photographic", value: "photographic" },
      { name: "Pixel Art", value: "pixel-art" },
      { name: "Origami", value: "origami" },
      { name: "Title Texture", value: "title-texture" }
    ];

this.dallestyle = [
  { name: "Vivid", value: "vivid" },
      { name: "Natural", value: "natural" },



    ];
// "1024x1024",“1792x1024”, “1024x1024” and “1024x1792
        this.sizelist = [
          { name: "1024x1024", value: "1024x1024" },
          { name: "1792x1024", value: "1792x1024"},
          { name: "1024x1792", value: "1024x1792" },

        ];

        this.qualitylist = [
          { name: "Standard", value: "standard" },

          { name: "HD", value: "hd" },


    ];

















    this.route.params.subscribe((ele: any) => {
      if (ele?.type == 'image') {
        this.imageToImage = true;
      } else {
        this.imageToImage = false;
      }
    })

    this.speechservice.init();
    this.getUserLimt();

    setTimeout(() => {
      this.onLoading();
    }, 0);

  }

  ngOnInit(): void {
    this.speechservice.voiceRecognitionUpdate.subscribe((updatedText: string) => {
      //// Update your prompt with the live updates
      console.log(updatedText);
      this.prompt = updatedText;
    });

  }


  openModal(template: TemplateRef<any>, data?: any) {
    this.modalImageData = data;
    this.modalRef = this.modalService.show(template, this.config);
  }

  startService() {
    this.speechservice.start()
    this.startspeech = false;
    this.stopspeech = true;


  }

  stopService() {
    this.speechservice.stop()
    this.startspeech = true;
    this.stopspeech = false;

  }

  //get max count and count left of api
  getUserLimt() {
    this.userApiLimitService.getUserApiCount("image-generator").subscribe((res: any) => {
      if (res?.response?.status == 'success') {
        this.endPointDetail = res?.response?.data ? res?.response?.data[0] : null;
      }
    })
  }

  async convertImageUrlToFile(imageUrl: string): Promise<File> {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const filename: any = imageUrl.split('/').pop(); // Extract filename from the URL
    const ext = filename.split('.').pop(); // Extract file extension
    const mimeType = blob.type || `image/${ext}`;
    return new File([blob], filename, { type: mimeType });
  }

  async uploadFile(event: any) {
    if (event?.target?.files[0]) {
      let file = event?.target?.files[0];
      let fileSizeMB = 0;

      console.log(file)


      // checking file type
      if (!this.checkFileSupported(file)) {
        this.clearFileInput('generate_upload_input');
        this.toastMessage.showError('Invalid file format. Only images (.jpg, .jpeg, .png)');
        return;
      }

      //  clacuation file size
      fileSizeMB = fileSizeMB + (file.size / 1024 ** 2);

      if (fileSizeMB > 5) {
        this.clearFileInput('generate_upload_input');
        this.toastMessage.showError('File size should be less than 5 MB');
        return;
      }
      let base64file = await this.base64fileService.convertToBase64(event.target.files[0]);
      this.imageFile = base64file;
      this.toastMessage.showSuccess('Image uploaded Succefully!')
      console.log(this.imageFile)
    }
  }

  async modifyImage(url: string, prompt: string) {
    this.imageFile = url;
  }




  async getGeneratedImage() {
    let imagePrompt = this.prompt || this.speechservice.text;
    imagePrompt = imagePrompt.trim();
    if (!imagePrompt) {
      this.toastMessage.showError('Kindely enter your prompt of image.');
      return;
    }

    this.loaderService.loading.next(true);
    if (this.promptStatus) {
      let updatedPrompt: any = await this.getPromptByAi(imagePrompt);
      if (updatedPrompt) imagePrompt = updatedPrompt;
    }

    let requestBody: any = { text: imagePrompt, model: this.image_model };

    if (this.imageFile) {
      requestBody['file'] = this.imageFile.substring(this.imageFile.indexOf(",") + 1);
    }

    let payload = {
      style_preset: this.style_preset,
      cfg_scale: this.cfg_scale,
      seed: this.seed,
      // start_schedule: this.start_schedule,
      steps: this.steps,
      // image_strength: this.imageFile ? this.image_strength : 0.6,
      "width": 512,
      "height": 512
    }

    let imageApiCall : any;

    if (this.imageFile) {
      // delete requestBody['model'];
      const mergedObject = { ...requestBody, "config": { ...payload } };
      console.log(requestBody)
      imageApiCall = this.imageGeneratorService.modifyImage(mergedObject)
    } else {
      const mergedObject = { ...requestBody, "config": { ...payload } };
      imageApiCall = this.imageGeneratorService.generateImage(mergedObject);
    }

    this.imageConfigStatus = false;
    imageApiCall.subscribe(
      (res: any) => {
        this.loaderService.loading.next(false);
        if (res?.status == 'success') {
          this.loaderService.celebrate.next(true);
          this.getUserLimt();
          // this.toastMessage.showSuccess(res?.message);
          this.chatData.push({ url: 'data:image/png;base64,' + res?.data, prompt: imagePrompt });
          this.imageFile = null
          // this.prompt = '';
          // this.speechservice.text = '';

          this.scrollToBottom();
        } else {
          this.toastMessage.showError(res?.error_message);
        }

      },
      (error : any) => {
        // Handle the error here
        this.loaderService.loading.next(false);
        console.error(error);
        this.toastMessage.showError(error?.error?.error_message);
      }
    );
  }

getdalleImage() {
  this.loaderService.loading.next(true);
  const payload = {
    "style": this.style,
    "quality": this.quality,
    "size": this.size,
    "query": this.prompt
  };
  this.imageGeneratorService.getdalleimage(payload).subscribe((res: any) => {

    if (res?.status == 'success') {
      this.loaderService.celebrate.next(true);
      this.getUserLimt();
      // this.chatData.push({ url: res?.data, prompt: this.prompt });
      this.chatData.push({ url: 'data:image/png;base64,' + res?.data, prompt: this.prompt  });

      this.dalleurl = res?.data;
      this.imageFile = null
      this.prompt = '';
      this.speechservice.text = '';
this.toastMessage.showSuccess(res?.message);
this.loaderService.loading.next(false);
      //this.scrollToBottom();
    } else {
      this.loaderService.loading.next(false);
      this.toastMessage.showError(res?.error_message);
    }

  },
    (error: any) => {
      // Handle the error here
      this.loaderService.loading.next(false);
      console.error(error);
      this.toastMessage.showError(error?.error?.error_message);
    }

);
}

  getPromptByAi(prompt: any) {
    return new Promise((resolve, reject) => {
      this.imageGeneratorService.getImagePrompt({ query: prompt }).subscribe((res: any) => {
        if (res?.status == 'success') {

          let updatedPrompt: any = res?.data;
          let splitPrompt = updatedPrompt.split(':');

          if (splitPrompt?.length > 1) {
            splitPrompt.splice(0, 1);
            updatedPrompt = splitPrompt.join(":")
          }

          resolve(updatedPrompt);
        } else {
          resolve(prompt);
        }
      })
    }).catch((err) => {
      return prompt;
    })
  }







  checkFileExtension(file: any) {
    // checking file type
    let patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    let fileExtension = (file.name).match(patternFileExtension);
    console.log('.' + fileExtension[1])
    return '.' + fileExtension[1];
  }

  checkFileSupported(file: any) {
    return this.inputAccept.split(',').includes(this.checkFileExtension(file));
  }

  clearFileInput(id: string) {
    let input1 = document.getElementById(id) as HTMLInputElement;
    input1.value = '';
  }


  // copy to clipboard
  copyToClipboard(text: any) {
    // Create a temporary input element
    const tempInput = document.createElement('textarea');
    // Set the input's value to the text you want to copy
    tempInput.value = text;
    // Append the input element to the DOM (usually you'd want to append it to the body)
    document.body.appendChild(tempInput);
    // Select the text in the input element
    tempInput.select();
    // Copy the selected text to the clipboard
    document.execCommand('copy');
    // Remove the temporary input element
    document.body.removeChild(tempInput);
  }

  // Download translated file
  downloadFile1(url: string) {
    window.location.href = url;
    this.loaderService.loading.next(true);

    setTimeout(() => {
      this.loaderService.loading.next(false);
      this.toastMessage.showSuccess('Downloading File.');
    }, 2000);
  }


  downloadFile(url: any) {
    this.loaderService.loading.next(true);
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        this.loaderService.loading.next(false);
        const a = document.createElement('a');
        const objectURL = URL.createObjectURL(blob);

        a.href = objectURL;
        a.download = 'image.jpg'; // You can set the download filename here
        document.body.appendChild(a);
        a.click();

        URL.revokeObjectURL(objectURL);
        document.body.removeChild(a);
      })
      .catch(error => {
        this.loaderService.loading.next(false);
        this.toastMessage.showError('Error downloading image:' + error);
        console.error('Error downloading image:', error);
      });
  }


  // scoroll to bottom in chat window
  scrollToBottom() {
    setTimeout(() => {
      const containerElement = this.scrollContainer.nativeElement;
      containerElement.scrollTop = containerElement.scrollHeight;
    }, 100);
  }


  onLoading() {
    const modalbtn = document.getElementById('image_policy_model') as HTMLLIElement;
    modalbtn.click();
  }



  ngOnDestroy(): void {

  }
}
