import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BedrockService } from '../shared/service/bedrock.service';
import { LoaderService } from '../shared/service/loader.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { DigiService } from 'src/app/digi.service';


@Component({
  selector: 'app-promptgenerator',
  templateUrl: './promptgenerator.component.html',
  styleUrls: ['./promptgenerator.component.scss']
})
export class PromptgeneratorComponent implements OnInit, OnDestroy {
  chatSubscriber!: Subscription;
  chatPayload: any;
  chatData: any = [];
  resetChatStatus: boolean = false;
  prompt:string ='';
  output:any;
  showchat:boolean = false;
  isliked:boolean = true;
  isdisliked:boolean = true;
  isImageExits:boolean=false;
  relatedQuestionList: string[] = [
    "1.Create an image of superman.",
    "2.Describe a futuristic city.",
    "3.Write a story about a dragon.",
    "4.Explain quantum computing in simple terms.",
  ];
  generatedAvatarUrl: any;
  constructor(
    private bedrockService: BedrockService,
    private toastMessage: ToastMessageService,
    private digiservice:DigiService,
    private loaderService: LoaderService
  ) {
    this.chatPayload = {
      name: 'Prompt Generator',
      description: "Welcome to the Prompt Generator!. How can I assist you today?"
    }
  }

  ngOnInit(): void {

    
   }

  getResponse() {
    this.showchat = true;
    this.isliked = false;
    this.isdisliked = false;
    let prompt = this.chatData[this.chatData?.length - 1];

    if(this.prompt){
      prompt = {content:this.prompt};
      this.chatData.push({role: 'user', content: this.prompt});
    }

    this.loaderService.loading.next(true);
    this.resetChatStatus = false;

    this.bedrockService.getprompt1(prompt?.content,this.isImageExits).subscribe(
      (res: any) => {

        if(res.status == 'success'){
        this.loaderService.loading.next(false);
        console.log(res);
     this.chatData.push({role: 'assistant', content: res.data});
       this.output = res.data;
       console.log(this.output)
      
        this.resetChatStatus = true;
      }
    else{
      this.loaderService.loading.next(false);
      this.toastMessage.showWarning(res.message);
    }

      },
      (error: any) => {
        this.loaderService.loading.next(false);
        this.toastMessage.showError('Something went wrong');
        this.resetChatStatus = true;
      }
    );
  }

  formatResponse(response: string): string {
    // Clean up the response to handle escape characters
    const cleanedResponse = response.replace(/\\n/g, '\n').replace(/\\\\/g, '\\');

    // Check if the response contains table-like structure
    const tableRegex = /<p>\s*\|\s*(.*?)\s*\|\s*<\/p>/g;
    if (tableRegex.test(cleanedResponse)) {
      // Format as HTML table
      let tableHtml = '<table>';
      let match;
      while ((match = tableRegex.exec(cleanedResponse)) !== null) {
        const rowContent = match[1].trim();
        const cells = rowContent.split(/\s*\|\s*/).filter(cell => cell !== '');
        tableHtml += '<tr>';
        cells.forEach(cell => {
          tableHtml += `<td>${cell}</td>`;
        });
        tableHtml += '</tr>';
      }
      tableHtml += '</table>';
      return tableHtml;
    } else {
      // Return plain text content if no table-like structure found
      return `<p>${cleanedResponse}</p>`;
    }
  }

  copyToClipboard(text: any) {  
    const tempInput = document.createElement('textarea');
    tempInput.value = text;
    document.body.appendChild(tempInput);   
    tempInput.select();   
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  }
likeresponse(){


    let payload = {
      feedback:1,
    }

    this.bedrockService.submit(payload).subscribe((res: any) => {
      if (res?.status == 'success') {
        this.isliked = true;
        this.isdisliked = true;
      } else {
        this.toastMessage.showError(res?.message)
      }
    })
  

}

dislikeresponse(){

  let payload = {
    feedback: -1,
  }

  this.bedrockService.submit(payload).subscribe((res: any) => {
    if (res?.status == 'success') {
      this.isliked = true;
      this.isdisliked = true;
    } else {
      this.toastMessage.showError(res?.message)
    }
  })

}




  escapeForMarkdown(data: string): string {
    return data.replace(/\\/g, '\\\\');
  }

  ngOnDestroy(): void {

  }

  generateimage(){

    this.loaderService.loading.next(true);

    const formData = new FormData;
    formData.append('dalle_prompt',this.output);

          // Second API call: Generate image using prompt
          // this.http.post<any>('https://api.playground-dev.genpact.com/poc/avatar/generate-image', formData)
          this.digiservice.getAvatar(formData)
            .subscribe(imageResponse => {
              if (imageResponse) {
                this.generatedAvatarUrl = imageResponse; // Set the generated image URL
             //   this.avatarGenerated.emit(this.generatedAvatarUrl);
                this.loaderService.loading.next(false); // Emit the avatar URL for the avatar display component
                document.getElementById('imgdbtn')?.click();
              } else {
                console.error('No image URL in response:', imageResponse);
                alert('Failed to generate image.');
              }
              //this.showPopup = false;
            }, error => {
              console.error('Error generating image:', error);
              alert('Error occurred while generating the image.');
            });


  }



}