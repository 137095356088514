import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, single } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class DataAnalyticsService {
  private dataAnalyticsUrl = 'https://6pflcnhze0.execute-api.ap-south-1.amazonaws.com/dev';
  apiURL= environment.apiURL;

  constructor(private http: HttpClient) {}


  generateDataMessage(): Observable<any> {
    const promise = this.getDataAsPromise(`${this.dataAnalyticsUrl}/welcome`); // Calling your method that returns a Promise
    return from(promise); 
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(`${this.dataAnalyticsUrl}/welcome`, { headers });
  }
  
  getDataAsPromise(url:string,data:any={ method: 'GET'}): Promise<any> {
   
    const timeoutDuration = 80000; 
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeoutDuration);
    
    data['signal'] = controller.signal;
    
    
    return new Promise((resolve, reject) => {
      fetch(url,data)
        .then((response) => {
          clearTimeout(timeoutId);
         resolve(response.json())
        })
        // .then(result => resolve(result))
        .catch(error => reject(error)); 
    });
  }
  

  uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const promise = this.getDataAsPromise(`${this.dataAnalyticsUrl}/file-upload`,{ method: 'POST',body:formData} ); // Calling your method that returns a Promise
    return from(promise); 
   

    return this.http.post(`${this.dataAnalyticsUrl}/file-upload`, formData, { headers });
  }

  analyzeData(payload:any): Observable<any> {
    const body = { payload };
    const promise = this.getDataAsPromise(`${this.dataAnalyticsUrl}/sentiments-analysis`,{ method: 'POST',body:JSON.stringify(payload)});  // Calling your method that returns a Promise
    return from(promise); 
    return this.http.post(`${this.dataAnalyticsUrl}/sentiments-analysis`, body);
    
  }



}
