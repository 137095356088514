import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ChatGptService {
  endpoint = environment.nodeEndpoint;
  endpoint1 = environment.apiURL;
  histroyEndpoint = environment.apiURL;


  constructor(private http: HttpClient) { }

  sendChatCompletionRequest(messages: any) {
    return this.http.post(`${this.endpoint}chat-completion`, { message: messages });
  }
  sendChatCompletionRequest1(payload: any) {
    return this.http.post(`${this.endpoint1}/gpt-4o`, payload);
  }
  sendChatCompletionRequest2(payload: any) {
    return this.http.post(`${this.endpoint1}/chat-bot`, payload);
  }
  sendChatCompletionRequestConfig(payload: any) {
    return this.http.post(`${this.endpoint}chat-completion`, payload);
  }

  crateHistory(payload: any) {
    return this.http.post(`${this.histroyEndpoint}/new_chat`, payload);
  }

  updateHistory(payload: any) {
    return this.http.post(`${this.histroyEndpoint}/chat_update`, payload);
  }

  getUserHistory() {
    return this.http.get(`${this.histroyEndpoint}/chat_history`);
  }

  deleteUserHistory(payload: any) {
    return this.http.post(`${this.histroyEndpoint}/delete_p`,payload);
  }

  getDetailHistory(payload: any) {
    return this.http.post(`${this.histroyEndpoint}/old_chat`,payload);
  }

sentimentanalysis(payload: any) {
  return this.http.post(`${this.histroyEndpoint}/analyze_sentiment`,payload);
}

createLog(payload: any) {
  return this.http.post(`${this.histroyEndpoint}/store_logs`, payload);
}

streamChatCompletionRequest(payload: any): Observable<any> {
  return new Observable(observer => {
    // not a good practice, but for the demo purposes - change to prod url
    // const endpoint = 'http://127.0.0.1:5000';
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${this.histroyEndpoint}/chat-bot-stream`, true);
    xhr.setRequestHeader('authorization', 'Bearer '+ localStorage.getItem('token'));
    xhr.setRequestHeader('Content-Type', 'application/json');
    // xhr.setRequestHeader('auth-platform', 'teams');
    
    let buffer = '';
    let lastProcessedIndex = 0;

    xhr.onprogress = () => {
      const newData = xhr.responseText.slice(lastProcessedIndex);
      buffer += newData;
      lastProcessedIndex = xhr.responseText.length;

      let newlineIndex;
      while ((newlineIndex = buffer.indexOf('\n')) !== -1) {
        const chunk = buffer.slice(0, newlineIndex).trim();
        buffer = buffer.slice(newlineIndex + 1);

        if (chunk) {
          try {
            const data = JSON.parse(chunk);
            observer.next(data);
          } catch (error) {
            console.error('Error parsing chunk:', chunk, error);
          }
        }
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        // Process any remaining data in the buffer
        if (buffer.trim()) {
          try {
            const data = JSON.parse(buffer.trim());
            observer.next(data);
          } catch (error) {
            console.error('Error parsing final chunk:', buffer, error);
          }
        }
        observer.complete();
      } else {
        observer.error(new Error(`HTTP error! status: ${xhr.status}`));
      }
    };

    xhr.onerror = () => {
      observer.error(new Error('XHR error'));
    };

    xhr.send(JSON.stringify(payload));

    return () => {
      xhr.abort();
    };
  });
}

}
