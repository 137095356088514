import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-multiple-upload',
  templateUrl: './multiple-upload.component.html',
  styleUrls: ['./multiple-upload.component.scss']
})
export class MultipleUploadComponent {

  @Input() placeholder = "Only .txt, .doc, .pdf, .xls,.png ,.jpeg,.jpg,.PNG,.tif,.tiff and .ppt file are allowed ";
  @Input() inputAccept = '.txt, .doc, .pdf, .xls,.png ,.jpeg,.jpg,.PNG,.tif,.tiff and .ppt';  // Add your accepted file types
  @Input() place = '(max Size 16 MB)';
  @Input() ins = ''
  @Input() maxFileSize = 16;
  @Input() header = 'Choose Your File(s) to Upload';
  @Output() filesSelected: EventEmitter<File[]> = new EventEmitter();

  onFilesSelected(event: any): void {
    //if size greater than 16
    if(event.target.files.size>16){
      alert('Maximum size is 16 mb');
      return;
    }
    else{
    const files: File[] = event.target.files;
    this.filesSelected.emit(files);}
  }
}
