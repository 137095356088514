<div class="code-enhance">
  <div class="custom-container">
    <p class="code-enhance-header">
      Code enhancement boosts productivity by automating bug resolution and code
      refinement, saving development hours and reducing the costs associated
      with manual debugging and code improvement.
    </p>
    <div class="header-links">
      <a class="privacypopup" (click)="openDialog()">Privacy Policy</a></div>
    <p class="code-enhance-desc"></p>

    <div class="file-btn-folder">
      <div class="file btn-upload">
        <img src="../../../../assets/images/uploadIcon.png" alt="" /> Upload
        file
        <input type="file" name="file" (change)="onFileSelected($event)" />
      </div>

      <!-- Add error messages for file validation -->
      <small *ngIf="isFileEmpty" class="text-danger"
        >Please select a file.</small
      >
      <small *ngIf="isFileSizeExceeded" class="text-danger"
        >File size should not exceed 1MB.</small
      >
      <small *ngIf="isInvalidFileType" class="text-danger"
        >Invalid file type. Allowed types: .py, .txt, .vb, .vba, .doc,
        .docx</small
      >
    </div>

    <!-- code box to enhance code -->
    <div class="text-box">
      <ngx-codemirror
        [(ngModel)]="codeEnhance.code"
        [options]="codeMirrorOptionsreadonly"
        (ngModelChange)="setEditorContent($event)"
      ></ngx-codemirror>
      <p *ngIf="isCodeInputError" class="text-danger">
        Code snippet cannot be empty
      </p>
      <p *ngIf="isCodeLengthInvalid" class="text-danger">
        Code length must be between 25 and 40,000 characters
      </p>
      <app-button
        [buttonClass]="'btn-enhance'"
        [buttonText]="'Enhance'"
        (buttonClick)="onButtonClick()"
      ></app-button>
    </div>

    <div *ngIf="isCodeEnhanced">
      <!-- enhanced code box  -->
      <div class="enhanced-code-box" #enhancedWindow>
        <div class="enhanced-header">
          <p>Enhanced code</p>
          <div class="icons">
            <img
              class="img-1"
              src="../../../../assets/images/copy.png"
              alt=""
              (click)="copyText()"
              [matTooltip]="tooltipText"
              [matTooltipPosition]="'above'"
            />
            <img
              class="img-2"
              src="../../../../assets/images/download.png"
              alt=""
              (click)="downloadFile()"
              [matTooltip]="'Download'"
              [matTooltipPosition]="'above'"
            />
            <!--<a [href]="fileUrl" download="downloadFilename"><i class="fa-solid fa-download" (click)="downloadFile()"></i></a>-->
          </div>
        </div>
        <div class="enhanced-content">
          <!--<pre><code>{{codeEnhance.improved_code}}</code></pre>-->
          <ngx-codemirror
            [(ngModel)]="codeEnhance.improved_code"
            [options]="codeMirrorOptions"
          ></ngx-codemirror>
        </div>
      </div>

      <!-- improvements points box  -->
      <div class="improvement-segment">
        <div class="enhanced-header">
          <p class="improvement-head"> Code description and improvements</p>
        </div>
      <div class="improvement-points-box">

        <!-- <pre class="improvement-points">{{codeEnhance.improvement_points}}</pre> -->
        <div
          class="improvement-points"
          [innerHTML]="codeEnhance.improvement_points"
        ></div>
        <!--<ngx-codemirror [(ngModel)]="codeEnhance.improvement_points"
                              [options]="codeMirrorOptions"></ngx-codemirror>-->
      </div>
    </div>
    </div>
  </div>
</div>
