<div class="body">

<!-- Top Navigation Bar -->
<nav class="navbar">
  <!-- Left Section: Menu and Logo -->
  <div class="navbar-left">
    <i class="menu-icon material-icons" (click)="gotohome()">home</i>
    <span class="logo" (click)="gotohome()">Avatar Kraft</span>
  </div>

  <!-- Center Navigation Tabs -->
  <div class="navbar-center">
    <button class="nav-tab" [class.active]="activeTab === 'explore'" (click)="setActiveTab('explore')">Explore</button>
    <button class="nav-tab" [class.active]="activeTab === 'create'" (click)="setActiveTab('create')">Create</button>
    <button class="nav-tab" [class.active]="activeTab === 'edit'" (click)="setActiveTab('edit')">Edit</button>
    <button class="nav-tab" [class.active]="activeTab === 'my-creations'" (click)="setActiveTab('my-creations')">My creations</button>
  </div>

  <!-- Right Section: Notification and Profile -->
  <div class="navbar-right">
    <div class="notification-circle">20</div>
    <div class="profile-circle">V</div>
  </div>
</nav>

<!-- Main Content Area with Sidebar -->
<div class="main-content">
  <!-- Left Sidebar -->
  <aside class="sidebar">
    <button 
      class="sidebar-btn" 
      [class.active]="selectedButton === 'generate'" 
      (click)="selectButton('generate')">
      <i class="material-icons">widgets</i>
      <span>Generate</span>
    </button>
    <button 
      class="sidebar-btn" 
      [class.active]="selectedButton === 'capture'" 
      (click)="selectButton('capture')">
      <i class="material-icons">camera</i>
      <span>Capture Image</span>
    </button>
    <button 
      class="sidebar-btn" 
      [class.active]="selectedButton === 'quiz'" 
      (click)="selectButton('quiz')">
      <i class="material-icons">quiz</i>
      <span>Quiz</span>
    </button>
  </aside>

  <!-- Content Area -->
  <div class="content-area" *ngIf="!isQuiz">
    <div class="generate-images-container">
      <div class="input-section">
        <h3>Generate Images</h3>
        <textarea 
          placeholder="Describe what you want to see" 
          [(ngModel)]="userPrompt" 
          class="prompt-input">
        </textarea>

       <!-- Toggle for Using Prompt-gen -->
<div class="toggle-container">
  <label class="toggle-switch">
    <input type="checkbox" [(ngModel)]="usePromptGen">
    <span class="slider"></span>
  </label>
  <span class="toggle-text">Use Prompt-gen</span>
</div>

<!-- Generated Prompt Section -->
<div *ngIf="usePromptGen && generatedResponse">
  <h3>Generated Prompt:</h3>
  <textarea 
    [(ngModel)]="generatedResponse" 
    class="response-box">
  </textarea>
</div>

               
        <!-- Dropzone for Image Upload -->
        <div class="image-dropzone" (click)="fileInput.click()" (drop)="onDrop($event)">
          <input #fileInput type="file" (change)="onFileSelect($event)" accept="image/jpeg, image/png" hidden />
          <p *ngIf="!uploadedImage">Click or drag ur image to upload </p>
          <img *ngIf="uploadedImage" [src]="uploadedImage" alt="Uploaded" class="preview-image">
        </div>

        <!-- Generate Button -->
        <button mat-raised-button style="background-color:#00aecf" class="btn btn-primary" (click)="generateImage()">Generate</button>
      </div>

      <app-loader 
  *ngIf="loading" >
</app-loader>

      <!-- Right Section: Generated Image Gallery -->
      <div class="gallery-section" *ngIf="!generatedAvatarUrl" style="width: 1200px;">
        <h3 style="color: white;">Previously Generated Images</h3> 
        <div class="image-grid">
          <mat-grid-list cols="4" rowHeight="220px" gutterSize="3px">
            <mat-grid-tile *ngFor="let image of generatedImages; let i = index">
              <mat-card class="image-card">
                <div class="image-container">
                  <img mat-card-image [src]="image" alt="Generated Image" class="original-size-image">
                  <div class="overlay">
                    <span class="image-name">{{ generatedNames[i] }}</span>
                  </div>
                </div>
              </mat-card>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </div>
      
      <div class="display" *ngIf="generatedAvatarUrl">
        <app-avatar-display style="padding-top: 5px;" [avatarUrl]="generatedAvatarUrl"></app-avatar-display>
      
    </div>

      <div class="facts-carousel"  >
        <h3 class="carousel-header">Did You Know?</h3>
        <div class="carousel-container">
          <img [src]="factImages[currentFactIndex]" alt="AI Fact Image" class="fact-image" />
          <div class="fact-content">
            <p>{{ aiFacts[currentFactIndex] }}</p>
          </div>
          <!-- <button class="carousel-btn" (click)="prevFact()">❮</button>
          <button class="carousel-btn" (click)="nextFact()">❯</button> -->
        </div>
      </div>
  </div>
  </div>
  <br>
  <br>
  <div class="quiz" *ngIf="isQuiz">
  <app-ai-quiz (quizCompleted)="onQuizCompleted($event)"></app-ai-quiz>
  <button class="btn btn-primary" (click)="nextStep()" [disabled]="!quizCompleted" style="margin-left: 500px;background-color: #00aecf;padding-top: 5px; margin-top: 10px;">
    Submit and Generate!
  </button>
  <app-loader 
  *ngIf="loading" >
</app-loader>
  <app-personality-text style="margin-left: 500px;" [avatarUrl]="generatedAvatarUrl" [personalityTraits]="personalityTraits"></app-personality-text>
  <!-- <app-avatar-display style="padding-top: 5px; margin-left: 500px;" [personalityTraits]="personalityTraits"></app-avatar-display> -->
</div>
</div>