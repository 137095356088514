
<!-- <div class="row flex-nowrap " id="toside">
  <div class="col-1 d-flex justify-content-center">
    <div
      id="mySidenav"
      class="sidenav"
      style="background-color: rgb(7, 50, 98) !important"
    >
      <img
        style="background-size: 200% 200%;cursor: pointer;"
        height="50px"
        src="assets/genpact.svg"
        [routerLink]="['/home-openai']"
      />

    </div>
  </div>
</div> -->
<!--Navbar-->
<div class="col-12 genpact-color ">
  <div class="row py-3">
      <div class="col-6" style="border: 1px sold red;">
        <button
          class="navbar-toggler hamburger-button"
          type="button"
          data-toggle="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"

          style="z-index: 2"
        >
          <div class="animated-icon">
            <span></span><span></span><span></span>
          </div>
        </button>
        <img
          style="background-size: 200% 200%; margin-left: 40px"
          height="50px"
          src="assets/genpact.svg"
        />
      </div>


    </div>

  <!-- Collapse button -->

  <!-- Navbar brand -->
</div>

<!-- <div class="row">
  <div class="mt-1" style="margin-left:24px;"><p class=" fw-bold txt-genpact" style="font-size: 24px;">Welcome {{username}} ! <span class="wave">👋</span></p></div>
</div> -->


<div class="container-fluid responsive p-2 p-md-5">
  <div class="row" style="text-align: center;">
    <div class="fw-bold" >

     <h3 class="p-2 p-md-5 mt-4 mb-n4">
      You have been successfully logged out.
      <br>
      <br>

    </h3>
    </div>

  </div>
  <div class="row" style="text-align: center;">
   <div>
    <button
    id="regeneratebtn"
    type="button"
    class="btn button-genpact text-white w-120"
    (click)="login()"
  >
    Login
  </button>


  </div>

</div>
