<!-- <app-sop-sidenav></app-sop-sidenav> -->


<div class="background-container">
  <div class="dropdown-container" *ngIf="functionList.length > 0"> 

    <div class="dropdown">
      <label for="converse_function_dropdown">Select Function</label><br>
      <select id="converse_function_dropdown" [(ngModel)]="functionSelected">
        <option *ngFor="let function of functionList" [value]="function?.value">{{function?.name}}</option>
      </select>
    </div>

    <div class="dropdown">
      <label for="converse_language_dropdown">Select Language</label><br>
      <select id="converse_language_dropdown" [(ngModel)]="languageSelected">
        <option *ngFor="let language of languageArray" [value]="language">{{language}}</option>
      </select>
    </div>
    <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="getFunctionParameters()">Submit</button>
  </div>

  <div class="container pb-3" *ngIf="chatPayload">
    <app-chat-layout [methodSelected]="chatPayload" [chatData]="chatData" [isReset]="resetChatStatus" (apiCall)="getResponse($event)"></app-chat-layout>
  </div>

  <div class="container" *ngIf="!chatPayload && functionList.length > 0">
    <div class="p-5 text-muted text-center">
      <h5>Welcome to the AI Assistant! I'm here to provide quick 
        and accurate answers to your selected function questions.
         How can I assist you today?
        </h5>
    </div>
  </div>

  <div class="container" *ngIf="functionList.length == 0">
    <div class="p-5 text-muted text-center">
      <h5>
        Sorry, you are not authorized to access  AI Assistant!.
        </h5>
    </div>
  </div>

</div>

