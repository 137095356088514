import { Component } from '@angular/core';

@Component({
  selector: 'app-dalle',
  templateUrl: './dalle.component.html',
  styleUrls: ['./dalle.component.scss']
})
export class DALLEComponent {
  images = [
    { url: 'assets/promptimgview/cowvsbull.jpg', prompt: 'Cow vs Bull' },
    { url: 'assets/promptimgview/fish.jpg', prompt: 'Muscular Fish' },
    { url: 'assets/promptimgview/monkey.jpg', prompt: 'Monkey teaching humans' },
    { url: 'assets/promptimgview/horse.jpg', prompt: 'Flying horse' },
    { url: 'assets/promptimgview/walking fish.jpg', prompt: 'Walking fish' },
    { url: 'assets/promptimgview/G.jpg', prompt: 'Flying man with G logo on chest' },


    // Add more images with prompts
  ];
}
