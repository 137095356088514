import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonService } from '../Utils/CommonService';
import { Constants } from '../Utils/Constants';
var commonServiceObj = new CommonService();

export class Message {
  constructor(public author: string, public content: string) {}
}
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  Constants: any;
  constructor() {
    this.Constants = Constants;
  }

  conversation = new Subject<Message[]>();

  messageMap: { [key: string]: string } = {
    'Hi': "Hi how may I help you today?",
    'hi': "Hi how may I help you today?",
    'Describe something': "Bootstrap is an HTML, CSS and JS library that focuses on simplifying the development of informative web pages (as opposed to web applications). The primary purpose of adding it to a web project is to apply Bootstrap's choices of color, size, font and layout to that project. As such, the primary factor is whether the developers in charge find those choices to their liking. Once added to a project, Bootstrap provides basic style definitions for all HTML elements. The result is a uniform appearance for prose, tables and form elements across web browsers",
    'describe something': "Bootstrap is an HTML, CSS and JS library that focuses on simplifying the development of informative web pages (as opposed to web applications). The primary purpose of adding it to a web project is to apply Bootstrap's choices of color, size, font and layout to that project. As such, the primary factor is whether the developers in charge find those choices to their liking. Once added to a project, Bootstrap provides basic style definitions for all HTML elements. The result is a uniform appearance for prose, tables and form elements across web browsers",
    'default': "I can't understand. Can you please repeat"
  };

  // Get bot answer for chatbot in popup of preview/Ask question
  async getBotAnswer(msg: string, colectionName: string, file: string) {
    const userMessage = new Message("user", msg);
    this.conversation.next([userMessage]);
    const botResponse = await this.getBotMessage(msg, colectionName, file);
    // const botMessage = new Message("bot",botResponse!);
    // this.conversation.next([botMessage]);

    // setTimeout(() => {
    // }, 5000);
  }


  async getBotMessage(question: string, colectionName: string, file: string) {
    await commonServiceObj.getMethodAxios(Constants.config.query + question + '&collection_name=' + colectionName.replaceAll(' ', '%20') + '&filename=' + file).then(
      (response: any) => {
        let answer = response.data.gpt_response;
        const botMessage = new Message("bot", answer);
        this.conversation.next([botMessage]);
        return answer;
      })
  }

 // Get bot answer for fine tune implementation
  // async getFineTuneBotAnswer(msg: string) {
  //   const userMessage = new Message("user", msg);
  //   this.conversation.next([userMessage]);
  //   const botResponse = await this.getFineTuneBotMessage(msg);
  // }

  // get bot Message for fine tune implementation
  // async getFineTuneBotMessage(question: string) {
  //   let answer = this.messageMap[question.toLowerCase()] || this.messageMap['default'];
  //   const botMessage = new Message("bot",answer);
  //   this.conversation.next([botMessage]);
  //   return answer;
  //  }
}
