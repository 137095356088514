<!-- <app-image-floater></app-image-floater>
<br><br> -->
<div class="container2" style="width: 1255x;color:white;">
    <div class="left-section" >
        <div class="text-section">
          <h1  class="greeting" style=" font-size: 54px;font-weight:bold; color: #FF555F;">Avatar Kraft</h1>
          <p class="para">
            An AI text-to-image that gives you endless results in real time. With an in-built Prompt Generator,<br>Try it now!
          </p>
        </div>
        <!-- <div class="input-section">
          <input type="text" [(ngModel)]="description" placeholder="Describe what you want" />
          <button (click)="generateImage()"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19" width="16" height="16" aria-hidden="true" class="_qmuh3f0 _1k96axr72 _1k96axr2qu _1k96axr1i6"><path d="M16.294 1.888 15.846.872a.623.623 0 0 0-1.14 0l-.448 1.016a.62.62 0 0 1-.319.318l-1.016.448a.623.623 0 0 0 0 1.141l1.016.448a.62.62 0 0 1 .319.319l.448 1.015a.623.623 0 0 0 1.14 0l.448-1.015a.62.62 0 0 1 .319-.32l1.016-.447a.623.623 0 0 0 0-1.14l-1.016-.449a.62.62 0 0 1-.319-.318"></path><path fill-rule="evenodd" d="M9.795 4.976.242 14.53a.88.88 0 0 0 0 1.243l2.486 2.486a.88.88 0 0 0 1.243 0l9.553-9.554a.88.88 0 0 0 0-1.243L11.04 4.976a.88.88 0 0 0-1.244 0Zm-.724 3.211 1.346-1.346 1.243 1.243-1.346 1.346zm0 2.487-5.722 5.72-1.243-1.243 5.721-5.72 1.243 1.243Z" clip-rule="evenodd"></path><path d="m16.07 9.825.307.694c.043.098.12.175.218.218l.694.307c.34.149.34.63 0 .78l-.695.306a.43.43 0 0 0-.217.218l-.307.695a.426.426 0 0 1-.78 0l-.306-.695a.43.43 0 0 0-.218-.218l-.695-.306a.426.426 0 0 1 0-.78l.695-.307a.43.43 0 0 0 .218-.218l.306-.694c.15-.34.63-.34.78 0M7.763 1.906l-.306-.695a.426.426 0 0 0-.78 0l-.306.695a.43.43 0 0 1-.218.218l-.695.306c-.34.15-.34.63 0 .78l.695.307c.097.043.175.12.218.218l.306.694c.15.34.63.34.78 0l.306-.694a.43.43 0 0 1 .218-.218l.695-.307a.426.426 0 0 0 0-.78l-.695-.306a.43.43 0 0 1-.218-.218"></path></svg>     Generate</button>
        </div> -->
        <div class="container">
          <div class="input-section">
            <input type="text" [(ngModel)]="userPrompt" placeholder="Describe what you want" />
            <button (click)="generateImage()"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19" width="18" height="16" aria-hidden="true" class="_qmuh3f0 _1k96axr72 _1k96axr2qu _1k96axr1i6"><path d="M16.294 1.888 15.846.872a.623.623 0 0 0-1.14 0l-.448 1.016a.62.62 0 0 1-.319.318l-1.016.448a.623.623 0 0 0 0 1.141l1.016.448a.62.62 0 0 1 .319.319l.448 1.015a.623.623 0 0 0 1.14 0l.448-1.015a.62.62 0 0 1 .319-.32l1.016-.447a.623.623 0 0 0 0-1.14l-1.016-.449a.62.62 0 0 1-.319-.318"></path><path fill-rule="evenodd" d="M9.795 4.976.242 14.53a.88.88 0 0 0 0 1.243l2.486 2.486a.88.88 0 0 0 1.243 0l9.553-9.554a.88.88 0 0 0 0-1.243L11.04 4.976a.88.88 0 0 0-1.244 0Zm-.724 3.211 1.346-1.346 1.243 1.243-1.346 1.346zm0 2.487-5.722 5.72-1.243-1.243 5.721-5.72 1.243 1.243Z" clip-rule="evenodd"></path><path d="m16.07 9.825.307.694c.043.098.12.175.218.218l.694.307c.34.149.34.63 0 .78l-.695.306a.43.43 0 0 0-.217.218l-.307.695a.426.426 0 0 1-.78 0l-.306-.695a.43.43 0 0 0-.218-.218l-.695-.306a.426.426 0 0 1 0-.78l.695-.307a.43.43 0 0 0 .218-.218l.306-.694c.15-.34.63-.34.78 0M7.763 1.906l-.306-.695a.426.426 0 0 0-.78 0l-.306.695a.43.43 0 0 1-.218.218l-.695.306c-.34.15-.34.63 0 .78l.695.307c.097.043.175.12.218.218l.306.694c.15.34.63.34.78 0l.306-.694a.43.43 0 0 1 .218-.218l.695-.307a.426.426 0 0 0 0-.78l-.695-.306a.43.43 0 0 1-.218-.218"></path></svg>     Generate</button>
          </div>
        </div>
        
        <div class=" te">
          No inspiration? Try this: <div class="wand" style="font-weight: bold;" (click)="sendQuery('Wand')"> Magic wand in the hand</div>
        </div>
    </div>
    <figure >
      <video class="header-video" src="../assets/avatar-craft/ak_video.mp4" width="700" height="460"  muted autoplay loop playsinline crossorigin="anonymous" ></video>
    </figure>
  </div>
  
  
  
  <div class="container1" >
    <div class="ABC" style="margin-top: 0px;padding-top: 20px;">The only AI image generator you need</div>
    <div class="image-contain">
  
      <div>
        <div><img src="/assets/avatar-craft/image1.jpg" class="a"/></div> 
        <div>
          <br>
          <p class="h1" style="font-weight: bold;">Multiple AI Models</p>
          <p class="h2" style="font-size: 20px;">Powered by an inbuilt prompt generator, it seamlessly integrates multiple AI models in the background to craft the perfect prompts for exceptional results.</p>
        </div>
      </div>
  
      <div>
        <div><img src="assets/avatar-craft/styler.jpg" class="a"/></div> 
        <div>
          <br>
          <p class="h1" style="font-weight: bold;">Personalize Avatars Your Way</p>
          <p class="h2" style="font-size: 20px;" >No need for long or complex prompts, just use our inbuilt prompt generator to create the customization you need in the pictures</p>
        </div>
        </div>
  
      <div>
        <div><img src="/assets/avatar-craft/texture.jpg" class="a"/></div> 
        <div>
          <br>
          <p class="h1" style="font-weight: bold;">High-end textures and realism</p>
          <p class="h2" style="font-size: 20px;">Skin tones, textures, details—everything is there</p>
        </div>
      </div>
  
    </div>
  </div>
  
  
  <div class="container2">
      <div class="section-2-left">
        <h2 style="font-weight: bold;">Introducing Prompt-Gen</h2>
        <p>Say goodbye to external platforms and complex prompts!
          Just write down your idea in simple terms, choose a style, and use our intuitive presets of colors, framing, and lighting to craft your perfect images—all within our user-friendly interface.</p>
        <button class="generate-button" (click)="generateprompt()">Generate Your Prompt</button>
      </div>
  
      <div class="section-2-right">
        <img width="480" height="360" src="../assets/avatar-craft/latest_avatar.png" style="border-radius: 18px;">
      </div>
  </div>
  
  <div class="container3">
    <div class="section-3-left">
      <img width="480" height="360" src="../assets/avatar-craft/new.png" style="padding-right: 1px; border-radius: 18px;">
    </div>
    <div class="section-3-right" style="margin-left: 50px;">
      <h2 style="font-weight: bold;">Introducing DAG</h2>
      <p>If you feel like creating your own image instead of using one of our 140+ million stock images, our text-to-image AI makes it easy.</p>
      <p>Thanks to artificial intelligence, you now have the power to turn your words and ideas into stunning visuals!</p>
      <button class="generate-button" (click)="generateImage()">Generate Your Avatar</button>
    </div>
  </div>
  
  <div class="container4">
    <div class="ABC" style="margin: 0px;">Witness the Power of Optimized Prompts: Before vs. After</div>
    <div class="subheading"><p style="font-size: 18px;">Experience the difference a perfect prompt can make in AI image quality.</p></div>
    <div class="grid-container">
      <div class="grid-item">
          <img src="../assets/avatar-craft/walking fish.jpg" alt="Image 1">
          <div class="overlay">
            <div>Walking Fish</div>
            <button class="copy-button" (click)="sendQuery('Fish')">Use this Prompt</button>
          </div>
      </div>
      <div class="grid-item">
          <img src="../assets/avatar-craft/horse.jpg" alt="Image 2">
          <div class="overlay">
            <div>Flying Horse</div>
          <button class="copy-button" (click)="sendQuery('Horse')">Use this Prompt</button>
          </div>
      </div>
      <div class="grid-item">
          <img src="../assets/avatar-craft/superman.jpg" alt="Image 3">
          <div class="overlay">
            <div>Superman</div>
          <button class="copy-button" (click)="sendQuery('Super')">Use this Prompt</button>
          </div>
      </div>
      <div class="grid-item">
          <img src="../assets/avatar-craft/spiderman.jpg" alt="Image 4">
          <div class="overlay">
            <div>Spiderman</div>
          <button class="copy-button" (click)="sendQuery('Spidey')">Use this Prompt</button>
          </div>
      </div>
      <div class="grid-item">
          <img src="../assets/avatar-craft/hulk.jpg" alt="Image 5">
          <div class="overlay">
            <div>Hulk</div>
          <button class="copy-button" (click)="sendQuery('Hulk')">Use this Prompt</button>
        </div>
      </div>
      <div class="grid-item">
          <img src="../assets/avatar-craft/fish.jpg" alt="Image 6">
          <div class="overlay">
            <div>Muscular Fish</div>
          <button class="copy-button" (click)="sendQuery('Mfish')">Use this Prompt</button>
        </div>
      </div>
      <div class="grid-item">
          <img src="../assets/avatar-craft/America.jpg" alt="Image 7">
          <div class="overlay"><div>Captain America</div>
          <button class="copy-button" (click)="sendQuery('Cap')">Use this Prompt</button>
        </div>
      </div>
      <div class="grid-item">
          <img src="../assets/avatar-craft/batman.jpg" alt="Image 8">
          <div class="overlay"><div>Batman</div>
          <button class="copy-button" (click)="sendQuery('Bat')">Use this Prompt</button>
        </div>
      </div>
      <div class="grid-item">
          <img src="../assets/avatar-craft/blackwidow.jpg" alt="Image 9">
          <div class="overlay"><div>Blackwidow</div>
          <button class="copy-button" (click)="sendQuery('Widow')">Use this Prompt</button>
        </div>
          
      </div>
    </div>
    </div>
  
  
  <div class="container5">
    <h1>How to create AI images</h1>
  
    <div class="content">
        <div class="steps">
            <div class="step">
                <h2>1. Upload your reference image </h2>
                <p>Start by uploading your reference image to kick off your avatar creation journey. This helps us craft a design uniquely tailored to you!</p>
            </div>
  
            <div class="step">
                <h2>2. Customize your Prompt</h2>
                <p>You're all set with the previous step. Customize your avatar by creating prompts with our inbuilt Prompt-Gen or by entering your own prompts.</p>
            </div>
  
            <div class="step">
                <h2>3. Save, download, Re-generate</h2>
                <p>Save or download your avatar, recustomize the prompts, or explore the Capture Image feature to upload a live photo directly from your camera for a truly personalized creation!</p>
            </div>
        </div>
  
        <div class="image-container" style="margin-right: 10px;">
            <img src="../assets/avatar-craft/ai-image-generation.webp" alt="Strawberry Image">
        </div>
    </div>
  
    <button class="generate-button" (click)="generateImage()">Generate Your Avatar</button>
  </div>

  <footer class="footer">
    <p> Made with ❤️ by Protege Batch '24 - AIOPS COE</p>
</footer>