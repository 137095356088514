<div class="audio-player-container">
    <audio #audioPlayer></audio>
  
    <div class="controls">
      <!-- Play Button -->
      <div #playIcon class="play-pause-button" (click)="togglePlayPause()"></div>
  
      <!-- Seek Slider -->
      <input type="range" class="seek-slider" #seekSlider max="100" value="0">
  
      <!-- Buffered Progress -->
      <!-- <div class="progress-bar">
        <div class="buffered-amount" #bufferedAmount></div>
      </div> -->
  
      <!-- Duration -->
      <!-- <div class="duration" #durationContainer>0:00</div> -->
  
      <!-- Volume Slider -->
      <input type="range" class="volume-slider" #volumeSlider max="1" min="0" step="0.01" value="1">
  
      <!-- Mute Button -->
      <div #muteIcon class="mute-button" (click)="toggleMute()"></div>
    </div>
  </div>
  