import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '../../service/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent  implements OnInit, OnDestroy {
  loading= false;
  sub!:Subscription;

  constructor(private loaderservice:LoaderService){

  }

  ngOnInit(): void {
    this.sub = this.loaderservice.loading.subscribe((res:any)=>{
      this.loading = res;
    })
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }



}
