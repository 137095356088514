import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-stories',
  templateUrl: './success-stories.component.html',
  styleUrls: ['./success-stories.component.scss']
})
export class SuccessStoriesComponent implements OnInit {
  successStoryRows: any[] = [];
  modalOpen: boolean = false;
  selectedStory: any = {};
  currentRowIndex: number = 0;
  searchText: string = '';

  ngOnInit() {
    // Initialize successStoryRows with your data
    this.successStoryRows = [
      [
        {
          thumbnail: 'assets/successstories/1.png',
          largeImage: 'assets/successstories/1pop.png',
          previewText: 'october 07,2023',
      text1: 'Simplifying agent resolution notes on helpmate',
          showPreview: false,
        },
        {
          thumbnail: 'assets/successstories/2.png',
          largeImage: 'assets/successstories/2pop.png',
          text1: 'Simplifying agent resolution notes on helpmate',
          previewText: 'october 07,2023',
          showPreview: false,
        },
        {
          thumbnail: 'assets/successstories/3.png',
          largeImage: 'assets/successstories/3pop.png',
          text1: 'Simplifying agent resolution notes on helpmate',
          previewText: 'october 07,2023',
          showPreview: false,
        },

      ],
      // [
      //   {
      //     thumbnail: 'assets/successstories/1.png',
      //     largeImage: 'assets/successstories/1pop.png',
      //     previewText: 'Success Story 1 Preview',

      //     showPreview: false,
      //   },
      //   {
      //     thumbnail: 'assets/successstories/2.png',
      //     largeImage: 'assets/successstories/2pop.png',
      //     previewText: 'Success Story 2 Preview',
      //     showPreview: false,
      //   },
      //   {
      //     thumbnail: 'assets/successstories/3.png',
      //     largeImage: 'assets/successstories/3pop.png',
      //     previewText: 'Success Story 3 Preview',
      //     showPreview: false,
      //   },

      // ],
      // [
      //   {
      //     thumbnail: 'assets/successstories/1.png',
      //     largeImage: 'assets/successstories/1pop.png',
      //     previewText: 'october 07,2023',
      //     showPreview: false,
      //   },
      //   {
      //     thumbnail: 'assets/successstories/2.png',
      //     largeImage: 'assets/successstories/2pop.png',
      //     previewText: 'october 07,2023',
      //     showPreview: false,
      //   },
      //   {
      //     thumbnail: 'assets/successstories/3.png',
      //     largeImage: 'assets/successstories/3pop.png',
      //     previewText: 'october 07,2023',
      //     showPreview: false,
      //   },

      // ],// Add more rows and images as needed
    ];
    this.currentRowIndex = 1;
  }

  showPreview(story: any) {
    story.showPreview = true;
    console.log('thisworks');
  }
  hidePreview(story: any) {
    story.showPreview = false;
  }
  showImage(story: any) {
    this.selectedStory = story;
    this.modalOpen = true;
    console.log('thisworks');
  }

  closeImageModal() {
    this.modalOpen = false;
    this.selectedStory = {};
  }
  like(story: any) {
    story.likes = (story.likes || 0) + 1;
  }

  loadMoreStories() {
    this.currentRowIndex++;
  }
  viewLess() {
    this.currentRowIndex--;
  }
  // Function to get the visible rows
  getVisibleRows() {
    return this.successStoryRows.slice(0, this.currentRowIndex);
  }

  isLastRow() {
    return this.currentRowIndex === this.successStoryRows.length;
  }
   // Function to filter success stories by preview text
   filterStories() {
    if (this.searchText) {
      const lowerCaseSearch = this.searchText.toLowerCase();
      this.successStoryRows = this.successStoryRows.filter((row) =>
        row.filter((story: { previewText: string; }) =>
          story.previewText.toLowerCase().includes(lowerCaseSearch)
        )
      );
      this.currentRowIndex = 1;
    } else {
      // If search text is empty, reset the displayed stories
      this.successStoryRows = this.originalSuccessStoryRows;
    }
  }

  originalSuccessStoryRows: any[] = [];

}
