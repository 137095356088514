<div class="popup">
  <div class="popup-head" (click)="onCloseClick()">
          <i class="fa-solid fa-xmark"></i>
  </div>
  <div class="popup-content">
      <div class="popup-icon">
         <img src="../../../../../../assets/contactus.png" alt="">
      </div>
      <div *ngIf="currentRoute === 'LDT'" class="popup-text">
        <p  class="heading">Contact the dev team</p>
       
      
        <p class="content">
          Please drop a mail to<br> 
          <a href="mailto:aiplay-dev@genpactonline.onmicrosoft.com">aiplay-dev&#64;genpactonline.onmicrosoft.com</a><br> 
          to contact the development team.
        </p>
      
     
      </div>

      <div *ngIf="currentRoute === 'IT'" class="popup-text">
        <p class="heading">Feedback</p>
      
      
      
        <p class="content">
            Please share your
            <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=IIrvvayqgE-zoNmjL5n9M90e9cRW2ZJNt3MEN5UWXmJUNDBYN1lPTzI4R1VYNUQ4RDVVMk9UMFBMNS4u" target="_blank">
              feedback
            </a>
          </p>
          
      </div>
      
  </div>
  <div class="popup-btn">
      <button class="btn btn-custom btn-first" (click)="onCloseClick()">Close</button>
  </div>
</div>
