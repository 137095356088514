import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  imports:[CommonModule],
  styleUrls: ['./stepper.component.scss'],
  standalone: true,
})
export class StepperComponent {
  @Input() steps: string[] = [];
  @Input() currentStep: number = 0;
  @Output() currentStepChange: EventEmitter<number> = new EventEmitter<number>();  // Add Output for two-way binding

  isActive(index: number): boolean {
    return index === this.currentStep;
  }

  isCompleted(index: number): boolean {
    return index < this.currentStep;
  }

  // Emit the new step value to the parent component
  goToStep(index: number): void {
    this.currentStep = index;
    this.currentStepChange.emit(this.currentStep);  // Emit the change
  }
}
