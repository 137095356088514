import { CommonModule } from '@angular/common';
import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quiz',
  standalone:true,
  imports:[CommonModule],
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent {

  @Output() finalResult = new EventEmitter<string>();

  public questions = [
    { question: 'Which AI model do you find most fascinating?', answers: ['GPT-4', 'DALL·E', 'MidJourney', 'BERT'] },
    { question: 'How do you feel about AI’s role in creativity (art, music, etc.)?', answers: ['AI will revolutionize creative fields', 'AI can be a great tool but needs human touch', 'AI might dilute creativity', 'Creativity is uniquely human, AI can’t replace it'] },
    { question: 'Which futuristic technology would you like to work with?', answers: ['Brain-Computer Interfaces', 'AI-driven Autonomous Vehicles', 'AI-powered Virtual Reality Worlds', '3D-Printed Human Organs'] },
    { question: 'What do you think is the biggest challenge in building AI models?', answers: ['Ethical concerns and bias', 'Data quality and availability', 'Compute power and cost', 'Model interpretability and explainability'] },
    { question: 'How do you think AI will impact jobs in the future?', answers: ['AI will create more jobs than it displaces', 'AI will replace many jobs but create new types of work', 'AI will heavily automate most industries', 'AI will completely reshape the job market'] }
  
  ];

  public currentIndex = 0;
  public selectedAnswer: string | null = null;
  public responseList: string[] = [];

  get currentQuestion() {
    return this.questions[this.currentIndex];
  }

  get answerOptions() {
    return this.currentQuestion?.answers;
  }

  get isLastQuestion() {
    return this.currentIndex === this.questions.length - 1;
  }

  selectAnswer(answer: string): void {
    this.selectedAnswer = answer;
  }

  nextQuestion(): void {
    if (this.selectedAnswer) {
      this.responseList.push(this.selectedAnswer);
      this.selectedAnswer = null;

      if (this.isLastQuestion) {
        this.finalResult.emit(this.responseList.join(', '));
      } else {
        this.currentIndex++;
      }
    }
  }
}
