<div class="wrapper">
  <app-sidenav [item]="'playground'"></app-sidenav>

  <!-- Main content -->
  <div *ngIf="showHeading" class="mt-2 genpact-color" style="text-align: center;margin-left:5px;margin-right:5px;">
    <!-- <div  class="btn-group-lg px-2 text-center genpact-color" role="group" aria-label="Basic radio toggle button group" style="padding-top: 10px; margin-left: 2px !important; border-radius:0px;">
      <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked (change)="optionSelected()">
      <label class="btn btn-outline-primary" for="btnradio1">Standard</label>
      <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" (change)="optionSelected1()">
      <label class="btn btn-outline-primary" for="btnradio2">Research</label>
      <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" (change)="optionSelected2()">
      <label class="btn btn-outline-primary" for="btnradio3">Custom search</label>
  </div> -->
  </div>
<!-- settings button -->

  <div class="btn-group" style="float: right; margin-right: 10px;" >
    <button type="button" class="btn btn-light shadow" data-bs-toggle="modal" data-bs-target="#exampleModal">
      <i class="fa fa-cog" aria-hidden="true"></i>
    </button>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Settings</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center">
          <div class="row">
            <div class="col-md-12" >
              <div class=" d-sm-flex  selectlang" style="flex-direction: column;
              align-items: flex-start;" >

              <div class="d-flex" style="align-items: flex-end;gap: 70px;
              flex-direction: row;">
                <div style="display: flex; flex-direction: column;">
                  <div class="fw-bold">Filter date</div>
                  <select class="form-select custom-select-width" [(ngModel)]="selectedFreshness" name="selectedFreshness" style="border-radius: 0;"  >
                    <option value="Day">Today</option>
                    <option value="Week">This Week</option>
                    <option value="Month">This Month</option>
                    <option value="Custom">Custom Range</option>
                  </select>

                  <div *ngIf="selectedFreshness === 'Custom'" style="margin-top: 20px; display:flex; flex-direction: column;">
                      <label>From:</label>
                      <input type="date" class="form-control" [(ngModel)]="customFromDate">
                      <label>To:</label>
                      <input type="date" class="form-control" [(ngModel)]="customToDate">
                  </div>
              </div>


                  <div style="display: flex;flex-direction: column;" *ngIf="!psearch">
                    <div class=" fw-bold mt-2">Content type</div>
                    <select class="form-select custom-select-width" placeholder="Load an Example" [(ngModel)]="selectedContent" name="selectedContent" style="border-radius: 0;">
                      <option value="news">News</option>
                      <option value="search">Search</option>
                      <!-- <option value="work">Work</option> -->
                    </select>
                    </div>
                  </div>

                  <div class="d-flex" style="align-items: flex-end;gap: 70px;
                  flex-direction: row;" >



                    <div *ngIf="!psearch" style="display: flex;flex-direction: column;">
                    <div class=" fw-bold mt-2">Sort by</div>
                    <select class="form-select custom-select-width" placeholder="Load an Example" [(ngModel)]="selectedSortby" name="selectedSortby" style="border-radius: 0;">
                      <option value="Date">Date</option>
                      <option value="relevance">Relevance</option>
                    </select>
</div>

<div style="display: flex;flex-direction: column;">

                      <div class=" fw-bold mt-2">Market</div>
                      <ng-multiselect-dropdown style="width:150px;" [placeholder]="'Market'" [settings]="dropdownSettings" [data]="source" [(ngModel)]="Market" >
                      </ng-multiselect-dropdown>
                  </div>

</div>

<div *ngIf="!psearch">
                    <div class=" fw-bold mt-2">suggested urls/sources</div>
                    <!-- input -->
                    <input style="    border: 1px solid;" type="text" class="form-control" [(ngModel)]="suggestedUrl" placeholder="Enter the url" style="border-radius: 0;">
</div>



<div *ngIf="psearch">
  <div class=" fw-bold mt-2">Sources</div>
  <ng-multiselect-dropdown style="width:250px; display:flex;" [placeholder]="'Sources'" [settings]="dropdownSettings1" [data]="sourceurls" [(ngModel)]="sourceurl" >
  </ng-multiselect-dropdown>

  <div class="input-group mb-3 mt-3">
    <input type="text" style="border:1px solid" class="form-control" [(ngModel)]="customUrl"  placeholder="Add urls to sources" aria-label="url" aria-describedby="button-addon2">
    <button class="btn btn-outline-secondary" (click)="updateurl()" type="button" id="button-addon2">Add</button>
  </div>


</div>
  <div>
    <!-- Angular reactive form setup -->
    <!-- <form [formGroup]="form1">
      <select formControlName="selectControl">
        <option *ngFor="let option of options" [value]="option">{{ option }}</option>
        <option [value]="'addNew'">+ Add new option</option>
      </select>
    </form>
  </div> -->

  <div style="margin: 20px;">


  </div>













</div>



</div> </div>
<!-- <div class="col-md-12" *ngIf="checkb1">
  <div class=" d-sm-flex selectlang " style="flex-direction: column;">
    <label class="checkbox-label checkboxx" *ngIf="!checkb">
      <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkbsortby" required
        [ngModelOptions]="{standalone: true}" style="margin-right: 5px;">
        Sort by date
    </label>

  </div></div> -->
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="submitQuestion(prompt)">Save changes</button> -->
        </div>
      </div>
    </div></div></div>
  <section class="content">
    <div class="container-fluid responsive" #scrollResearch>
      <div class="row px-md-5">

        <div  id = "text-d" class="col-md-12">
          <ng-container  *ngFor="let list of researchData; index as i">
            <div class="row mt-5">
              <div class="col-md-12" *ngIf="!checkb">
                <!-- Add the text-center class to center the header -->
                <h4> {{i+1}}. {{ list?.question | titlecase }}</h4>
              </div>
              <!-- for research mode -->
              <div class="col-md-12" *ngIf="checkb">
                <!-- Add the text-center class to center the header -->
                <h3>Q.  {{ list?.question | titlecase }}</h3>
              </div>
            </div>


            <div class="row mt-5" *ngIf="!checkb">
              <div class="col-md" style="text-align: justify;">

                <div class="heading" >
                  <svg class="fontawesomesvg" xmlns="http://www.w3.org/2000/svg" height="1em"
                    style="vertical-align: baseline;" viewBox="0 0 512 512">
                    <path
                      d="M64 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM64 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48-208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z" />
                  </svg>

                  &nbsp;<h4 style="display: contents;">Sources</h4>

                  <!-- <div *ngFor="let thumbnail of (metaThumbnailService.metaThumbnail$ | async)">
                      <img [src]="thumbnail" alt="Thumbnail" width="100" height="100">
                    </div> -->

                  <div class="row mt-3">
                    <ng-container *ngFor="let item of list?.url; let link_index = index;">


                      <div class="col-md-4" *ngIf="(selectedLink == i) ? true : (link_index < maxLink)">

                        <div class=" mb-2 border   w-100 link-block">
                          <div class="links-head">
                            <img class="image-icon" *ngIf="item?.ogImage" [src]="item?.ogImage" alt="Thumbnail">
                            <div class="col-12 detail">
                              <a href="{{ item?.url }}" [title]="item?.title  ? item?.title : item?.url"
                                target="_blank">
                                {{ item?.title ? item?.title : item?.url }}
                              </a>
                              <div class="link-desc" [title]="item?.description"> {{item?.description}}</div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </ng-container>




                    <div class="col-md-4" *ngIf="(list?.url?.length > maxLink) && (selectedLink != i) "
                      (click)="selectedLink = i">
                      <div class=" py-4">
                        <a href="javascript:;" class="append-block">View More...</a>
                      </div>
                    </div>

                    <div class="col-md-4" *ngIf="(selectedLink == i)" (click)="maxLink = 5; selectedLink = null">
                      <div class="py-4">
                        <a href="javascript:;" class="append-block">View Less</a>
                      </div>
                    </div>


                  </div>
                </div>
              </div>

            </div>




            <div class="row mt-5">
              <div class="col-md-12" style="text-align: justify; padding-top: 10px">
                <div class="heading">
                  <svg class="fontawesomesvg" xmlns="http://www.w3.org/2000/svg" height="1em"
                    style="vertical-align: baseline;" viewBox="0 0 512 512">
                    <path
                      d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM64 256c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H96c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                  </svg>
                  &nbsp;<h4 style="display: contents;">Answers</h4>

                  <div class="answer w-100 mt-3" *ngIf="list?.answer; else linkAnswer">
                    <div lineNumbers markdown [data]="list?.answer " [disableSanitizer]="true"></div>
                  </div>

                  <!-- <div class="answer w-100 mt-3" *ngIf="linkAnswer">
                    <div lineNumbers markdown [data]="list?.answer " [disableSanitizer]="true"></div>
                  </div> -->

                  <ng-template #linkAnswer >
                    <div class="answer w-100 mt-3">
                      <ng-container *ngFor="let item of list?.url; let link_index = index;">


                        <div class="col-md-12">

                          <div class=" mb-2 border   w-100 link-block">
                            <div class="links-head  w-100">
                              <img class="image-icon" *ngIf="item?.ogImage" [src]="item?.ogImage" alt="Thumbnail">
                              <div class="col-12 detail">
                                <a href="{{ item?.url }}" [title]="item?.title  ? item?.title : item?.url"
                                  target="_blank">
                                  {{ item?.title ? item?.title : item?.url }}
                                </a>
                                <!-- <div class="link-desc" [title]="item?.description"> typeWriter() {{item?.description}}</div> -->
                              </div>
                            </div>
                          </div>

                          <div class="p-3" lineNumbers markdown [data]="item?.description" [disableSanitizer]="true">
                          </div>
                        </div>
                      </ng-container>


                    </div>
                  </ng-template>

                </div>
              </div>
            </div>
            <!-- back button in research mode -->
            <div class="text-center" *ngIf="checkb">
              <button  type="button" (click) = "back()" title="Back" class="shadow btn text-light btn-plight px-4">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                </button>
            </div>
          </ng-container>

          <div class="row mt-5" *ngIf="!showHeading && (relatedQuestionList?.length > 0)">
            <div class="col-md-12" style="text-align: justify; padding-top: 10px">
              <div class="heading">

                <svg class="fontawesomesvg" xmlns="http://www.w3.org/2000/svg" height="1em"
                  style="vertical-align: baseline;" viewBox="0 0 576 512">
                  <path
                    d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z" />
                </svg>
                &nbsp;<h4 style="display: contents;">Related</h4>
                <!-- <div class="answer w-100 mt-3" *ngFor="let answer of researchData"> -->
                <div class="answer w-100 mt-3" *ngFor="let question of relatedQuestionList"
                  (click)="submitQuestion(question)">

                  <div class="col-sm-12">
                    <div class="related-question">
                      <div> {{ question }}</div>
                      <button class="btn btn-sm border-0 pl-3">
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 10px;">
            <div class="text-center" *ngIf="!checkb">
              <button  type="button" (click) = "back()" title="Back" class="shadow btn text-light btn-plight px-4">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                </button>
            </div>
          </div>
          </div>
          <div class="row mt-5" *ngIf="psearch1" style="overflow:auto;">
            <div class="col-md-12" *ngIf="psearch1">
              <!-- <div style="text-align: center;">
              <img [src]="imageurl" alt="Image">
            </div> -->
              <div class="content">
                <div class="image-wiki">
                <p style="color: #073262;font-family:GuardianEgyp-Regular;">{{ content }}</p>
              </div>
                <!-- <p  style="color: #073262;font-family:GuardianEgyp-Regular;">check out the<a class="source-text" href="{{ personurl }}" target="_blank"> source.</a></p> -->
                <!-- <div >Sources:</div>
                <ul *ngIf="personurl.length > 0">
                  <li *ngFor="let url of personurl">
                    <a href="{{ url }}" target="_blank">{{ url }}</a>
                  </li>
                </ul> -->

               <div *ngIf="this.selectedurl.length > 0" >Sources</div>
               <div *ngIf="this.selectedurl.length > 0" class="purl-container">
                 <div class="purl-box" *ngFor="let url of this.selectedurl">
                   <a href="{{ url }}" target="_blank">{{ url }}</a>
                 </div>
               </div>
<div *ngIf="!deepsearch" >
               <div *ngIf="this.nrmlurl.length > 0" >Sources</div>
               <div *ngIf="this.nrmlurl.length > 0" class="purl-container">
                 <div class="purl-box" *ngFor="let url of this.nrmlurl">
                   <a href="{{ url }}" target="_blank">{{ url }}</a>
                 </div>
               </div>
              </div>
               <!-- <div *ngIf="personurl.length === 0">
                 No URLs to display.
               </div> -->
<br>


              </div>
            </div>

            <!-- <div *ngIf="!psearch" class="loading-message">
              Loading...
            </div> -->

<br>
            <div class="text-center" >
              <button  type="button" (click) = "back()" title="Back" class="shadow btn text-light btn-plight px-4">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                </button>
            </div>
          </div>

          <ng-container *ngIf="showHeading">

            <div class="box messages smooth-scroll mt-5">


              <div  >
            <div class="text-center my-5">
              <h3> Welcome to Research Assistant </h3>
              <p>Nurturing Research Skills and Knowledge</p>
            </div>
            </div>
            </div>
            <form class="input-form p-md-4">

              <div class="col-md-8 offset-md-2">
                <div class="question-container" class="contain">
                  <input *ngIf="!psearch" type="text"  [(ngModel)]="prompt"  class="form-control border-rd0" placeholder="Ask a question" [ngModelOptions]="{standalone: true}">
                  <input *ngIf="psearch" type="text" style="border: 1px solid;"  [(ngModel)]="prompt"  class="form-control border-rd0" placeholder="Search a policy using keywords" [ngModelOptions]="{standalone: true}">
                  <input *ngIf="psearch1" type="text"  [(ngModel)]="prompt"  class="form-control border-rd0" placeholder="Search a policy using keywords" [ngModelOptions]="{standalone: true}">

                  <!-- <button class="app-speech-input-btn" ></button> -->
                   <!-- <button class="app-speech-input-btn" *ngIf="startspeech" (keydown.enter)="$event.preventDefault()" (click)="startService()" (click)="startService()"></button>
                   <button class="app1-speech1-input1-btn1" *ngIf="stopspeech"  (keydown.enter)="$event.preventDefault()" (click)="startService()" (click)="stopService()"><i class="fa fa-microphone fa-beat-fade" style="font-size:20px;color:red"></i></button> -->

                  <!-- ckl -->
                  <button *ngIf="psearch" [disabled]="prompt.trim()=== ''" (click)="submitQuestion1(prompt)"
                    class="btn btn-primary btn-sm px-4 border-rd0">
                    <svg style="width: 24px; height: 24px;" viewBox="0 0 24 24">
                      <path fill="#cccccc" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
                    </svg>
                  </button>

                  <!-- cl -->
                  <button *ngIf="!psearch" [disabled]="prompt.trim()=== ''" (click)="submitQuestion(prompt)"
                    class="btn btn-primary btn-sm px-4 border-rd0">
                    <svg style="width: 24px; height: 24px;" viewBox="0 0 24 24">
                      <path fill="#cccccc" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
                    </svg>
                  </button>
                </div>









                <div *ngIf="psearch" class="py-2 pl-1 checks d-flex">
                  <label class="checkbox-label checkboxx">
                    <input type="checkbox" id="uploadCheckbox" [(ngModel)]="deepsearch" required
                      [ngModelOptions]="{standalone: true}" style="margin-right: 5px;margin-top:5px">


                  </label>  Deep Search mode
                  <i class="bi bi-info-circle" > </i>

                </div>
                <!-- <div class="py-2 pl-1 checks">
                  <label class="checkbox-label checkboxx">
                    <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkb" required
                      [ngModelOptions]="{standalone: true}" style="margin-right: 5px;">
                     Research mode
                  </label>
                  <label class="checkbox-label checkboxx" *ngIf="!checkb">
                    <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkbsortby" required
                      [ngModelOptions]="{standalone: true}" style="margin-right: 5px;">
                      Sort by date
                  </label>
                </div> -->
                <button class="app-speech-input-btn" *ngIf="startspeech"  (keydown.enter)="$event.preventDefault()" (click)="startService()" (click)="startService()"></button>
                   <button class="app1-speech1-input1-btn1" *ngIf="stopspeech"  (keydown.enter)="$event.preventDefault()" (click)="startService()" (click)="stopService()"><i class="fa fa-microphone fa-beat-fade" style="font-size:20px;color:red"></i></button>


                   <!-- <div class="row"  *ngIf="checkb">
                    <div class="col-md-12">
                      <div class="selectlang  d-sm-flex "  >
                        <div class=" fw-bold">Filter date</div>
                            <select class="form-select custom-select-width" placeholder="Load an Example" [(ngModel)]="selectedFreshness" name="selectedFreshness" style="border-radius: 0;">
                              <option value="Day">Today</option>
                              <option value="Week">This Week</option>
                              <option value="Month">This Month</option>
                              <option value="Year">This Year</option>
                            </select>

                            <div class=" fw-bold">Content type</div>
                            <select class="form-select custom-select-width" placeholder="Load an Example" [(ngModel)]="selectedContent" name="selectedContent" style="border-radius: 0;">
                              <option value="news">News</option>
                              <option value="search">Search</option>

                            </select>

                            <div class=" fw-bold">Sort by</div>
                            <select class="form-select custom-select-width" placeholder="Load an Example" [(ngModel)]="selectedSortby" name="selectedSortby" style="border-radius: 0;">
                              <option value="Date">Date</option>
                              <option value="relevance">Relevance</option>
                            </select>


                      </div>

                    </div>
                  </div> -->
                </div>


            </form>
          </ng-container>

        </div>
      </div>
    </div>

    <form class="input-form conversation" *ngIf="!showHeading">
      <div class="row message-alert" >
      <div class="col-md-8 offset-md-2">
        <div class="question-container" class="contain">
          <input *ngIf="!psearch" type="text"  [(ngModel)]="prompt"  class="form-control border-rd0" placeholder="Ask a question" [ngModelOptions]="{standalone: true}">
          <input *ngIf="psearch" type="text"  [(ngModel)]="prompt"  class="form-control border-rd0" placeholder="Search a policy using keywords" [ngModelOptions]="{standalone: true}">

          <!-- <button class="app-speech-input-btn" ></button> -->
           <!-- <button class="app-speech-input-btn" *ngIf="startspeech" (keydown.enter)="$event.preventDefault()" (click)="startService()" (click)="startService()"></button>
           <button class="app1-speech1-input1-btn1" *ngIf="stopspeech"  (keydown.enter)="$event.preventDefault()" (click)="startService()" (click)="stopService()"><i class="fa fa-microphone fa-beat-fade" style="font-size:20px;color:red"></i></button> -->

          <!-- ckl -->
          <button *ngIf="psearch" [disabled]="prompt.trim()=== ''" (click)="submitQuestion1(prompt)"
            class="btn btn-primary btn-sm px-4 border-rd0">
            <svg style="width: 24px; height: 24px;" viewBox="0 0 24 24">
              <path fill="#cccccc" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
            </svg>
          </button>

          <!-- cl -->
          <button *ngIf="!psearch" [disabled]="prompt.trim()=== ''" (click)="submitQuestion(prompt)"
            class="btn btn-primary btn-sm px-4 border-rd0">
            <svg style="width: 24px; height: 24px;" viewBox="0 0 24 24">
              <path fill="#cccccc" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
            </svg>
          </button>

            <div *ngIf="!psearch" class="btn-group">
            <button class="btn btn-light shadow px-1" type="button" (click) = "downloadAsPdf()">
              <i class="fa fa-download" aria-hidden="true"></i>
              </button>
</div>
        </div>
        <button class="app-speech-input-btn" *ngIf="startspeech" (keydown.enter)="$event.preventDefault()" (click)="startService()" (click)="startService()"></button>
        <button class="app1-speech1-input1-btn1" *ngIf="stopspeech"  (keydown.enter)="$event.preventDefault()" (click)="startService()" (click)="stopService()"><i class="fa fa-microphone fa-beat-fade" style="font-size:20px;color:red"></i></button>
        <!-- <div class="item">

            <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkb" tooltipHtml="Please check the box"
              required [ngModelOptions]="{standalone: true}"> -->

        <!-- <div class="message" id="message"></div> -->
        <!-- </div> -->
      </div>
      <!-- <div style="text-align: center;"> <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkb"
          tooltipHtml="research-mode" required [ngModelOptions]="{standalone: true}"></div> -->
        </div>
    </form>

  </section>
  <!-- spinner -->
  <ngx-spinner bdColor = "rgba(51, 51, 51, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise"><p style="font-size: 25px; color:white; font-family: Source Sans Pro;"><br><br><br>{{customText}}</p></ngx-spinner>
<!-- settings button -->

<div class="btn-group" style="float: right; margin-right: 10px;" >
  <button style="display:none;" type="button"  id="togbtn" class="btn btn-light shadow" data-bs-toggle="modal" data-bs-target="#exampleModal1">
    <i class="fa fa-cog" aria-hidden="true"></i>
  </button>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel1">Choose the site/pages to fetch the data</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body text-center" style="width:550px;height:500px;">


        <ng-multiselect-dropdown style="width:450px; display:flex;" [placeholder]="'Sources'" [settings]="dropdownSettings1" [data]="selectedpolicyurl" [(ngModel)]="selectedurl" >
        </ng-multiselect-dropdown>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="policycrawl()">Save & Submit</button>
                </div>

</div>
</div>
</div>
  <!-- <app-stop-process *ngIf="processStatus" (stopProgress)="resetForm($event)"></app-stop-process> -->
<!-- Bootstrap Modal for Custom Date Range -->
<!-- <button style="display:none;" type="button"  id="togbtn1" class="btn btn-light shadow" data-bs-toggle="modal" data-bs-target="#customDateModal">
  <i class="fa fa-cog" aria-hidden="true"></i>
</button>
<div class="modal fade" id="customDateModal" tabindex="-1" aria-labelledby="customDateModalLabel" aria-hidden="true" [class.show]="selectedFreshness === 'Custom'" style="display: block; background: rgba(0,0,0,0.5);">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="customDateModalLabel">Select Custom Date Range</h5>
        <button type="button" class="close" aria-label="Close" data-bs-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div style="display: flex; flex-direction: column;">
            <label>From:</label>
            <input type="date" class="form-control" [(ngModel)]="customFromDate">
            <label>To:</label>
            <input type="date" class="form-control" [(ngModel)]="customToDate">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary"  data-bs-dismiss="modal">save</button>
      </div>
    </div>
  </div>
</div> -->
